import { Component, Input, OnChanges, OnInit, EventEmitter, SimpleChanges, Output, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IMultiSelectOption, IMultiSelectSettings } from 'angular-2-dropdown-multiselect';
import { Activity } from '../models/activity.model';
import { Connection } from '../models/connection.model';
import { AuthService } from '../services/auth.service';
import { LocalStorageService } from '../services/local-storage.service';
import * as  moment from "moment";
import { NavigationHelperService } from '../services/navigation-helper.service';
import { ActivatedRoute, Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-add-activity',
  templateUrl: './add-activity.component.html',
  styleUrls: ['./add-activity.component.css']
})
export class AddActivityComponent implements OnInit {
  @Input() public callBack: Function;
  @Input() public defaultActivity?: Activity;
  @Output() submitForm = new EventEmitter<any>();
  activity_id: string;
  partnersList: any = [];
  invitedTeam: any = [];
  usersList: any = [];
  typeList: any = [];
  priorityList: any = [];
  plansList: any = [];
  solutionList: any = [];
  opportunitiesList: any = [];
  customerList: any = [];
  userSubscription: { type: any, data: any, create_partner: boolean, create_user: boolean } = { type: '', data: '', create_partner: true, create_user: true };
  selfPartners: any[] = [];
  userPartnerEmail = '';
  defaultPartnerID: string = '';
  activity: any = { additional: false, documents: [], assignee: [], partners: [] };
  savedTeam: any[] = [];
  rawAssigneeList: any = [];
  constructor(
    private AuthService: AuthService,
    private formBuilder: FormBuilder,
    private localStorageService: LocalStorageService,
    private router: Router,
    private navHelper: NavigationHelperService,
    private activatedRoute: ActivatedRoute,
    private cdRef: ChangeDetectorRef
  ) {
    this.userData = this.localStorageService.get({ name: 'userData' })
    this.userData = this.userData ? JSON.parse(this.userData) : {}
    this.userPartnerId = this.userData.partner.partner_id;
    this.userPartnerEmail = this.userData.email;
    this.partnerOwners = this.localStorageService.get({ name: 'partnerOwners' })
    this.partnerOwners = this.partnerOwners ? JSON.parse(this.partnerOwners) : []
    if (this.userData.subscription_type_data != null) {
      this.userSubscription.type = 'subscriber'
      this.userSubscription.data = this.userData.subscription_type_data;
      if (this.userData.first_name) {
        this.activity.approvers = `${this.userData.first_name} ${this.userData.last_name}`;
        this.activity.approver_id = this.userData.user_id;
      } else {
        this.activity.approvers = '';
      }
    } else {
      this.userSubscription.type = 'partner'
      this.userSubscription.data = this.userData.subscriber_subscription_type_data;
      if (this.userSubscription.data) {
        this.userSubscription.create_user = this.userSubscription.data.user_by_partner;
      }
    }
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.partnerId) {
        this.defaultPartnerID = params.partnerId;
      }
    })
    this.activatedRoute.params.subscribe((params) => {
      if (params.activityId) {
        this.activity_id = params.activityId;
      }
    })
    this.activityData = localStorage.getItem('activityData')
    this.activityData = this.activityData ? JSON.parse(this.activityData) : {};
    this.activity = { ...this.activity, ...this.activityData };
    this.savedTeam = this.activity.assignee;
    localStorage.removeItem('activityData');
    let teamData = localStorage.getItem('activityInvitedTeam')
    this.invitedTeam = teamData ? JSON.parse(teamData) : [];
    localStorage.removeItem('activityInvitedTeam')
  }
  isUpdate: boolean = false;
  userData: any = {};
  userPartnerId: string = '';
  partnerOwners: any = [];
  ngOnInit(): void {
    this.createForm();
    this.initializeForm();
    $('document').ready(() => {
      this.applyDatePicker();
    })
  }
  activityForm: FormGroup;
  formControls: any;
  createForm(): any {
    this.activityForm = this.formBuilder.group({
      customer_name: [''],
      activity: ['', Validators.compose([Validators.required])],
      partners: [[]],
      priority: ['', Validators.compose([Validators.required])],
      assignee: [[], Validators.compose([Validators.required])],
      end_date: ['', Validators.compose([Validators.required])],
      completion_date: [''],
      type: [''],
      plan_id: [[]],
      additional: [''],
      solution_id: [[]],
      opportunity_id: [[]],
      approval_status: [''],
      comments: ['']
    });
    this.formControls = this.activityForm.controls;
  }
  activityData: any = {};
  fetchingData: boolean = false;
  async initializeForm() {
    this.fetchingData = true;
    await this.loadUserDomain().then((result: any) => {
      this.userDataPartners = result;
      this.userPartnerDomain = result[0].partner.email_domain
      this.userPartnerEmail = result[0].email;
    })
    if (this.activity_id) {
      await this.getActivityData().then((activity: any) => {
        if (activity.partners && activity.partners.length > 0) {
          let index = this.getAllIndexes(activity.partners, this.userData.partner.partner_id);
          index.forEach(i => {
            activity.partners.splice(i, 1);
          });
        }
        if (activity.end_date) {
          activity.end_date = moment(activity.end_date).format('MM/DD/YYYY')
        }
        if (activity.completion_date) {
          activity.completion_date = moment(activity.completion_date).format('MM/DD/YYYY')
        }
        // this.rawAssigneeList = [...activity.assignee]
        // if (activity.assignee && activity.assignee.length > 0) {
        //   let index = this.getAllIndexes(activity.assignee, this.userPartnerEmail);
        //   index.forEach(i => {
        //     activity.assignee.splice(i, 1);
        //   })
        // }
        if (!Array.isArray(activity.documents)) {
          activity.documents = [];
        }
        this.activity = activity;
        this.applyDatePicker(this.activity.completion_date);
        this.approvalsAccessCheck();
      });
    }
    await this.getFormData();
    this.fetchingData = false;
  }
  getAllIndexes(arr, val) {
    var indexes = [], i;
    for (i = 0; i < arr.length; i++)
      if (arr[i] === val)
        indexes.push(i);
    return indexes;
  }
  newPartner() {
    if (this.userSubscription.create_partner) {
      localStorage.setItem('activityData', JSON.stringify(this.activity));
      localStorage.setItem('activityInvitedTeam', JSON.stringify(this.invitedTeam));
      localStorage.setItem('redirect', this.router.url)
      this.router.navigate(["/create-partner"]);
    } else {
      $('#alertModalX').modal('show');
    }
  }
  getAccess(type): boolean {
    if (type == 'partner') {
      if (this.userSubscription.type == 'subscriber') {
        if (this.userSubscription.data.no_partner == null) {
          return true;
        } else if (this.partnersList.length < this.userSubscription.data.no_partner) {
          return true;
        } else {
          return false;
        }
      } else if (type == 'partner') {
        if (this.userSubscription.data && this.userSubscription.data.partner_by_partner) {
          return true;
        }
        return false;
      }
    } else if (type == 'user') {
      if (this.userSubscription.type == 'subscriber') {
        if (this.userSubscription.data.no_user == null) {
          return true;
        } else if (this.userSubscription.data.no_user < this.partnersList.length) {
          return true;
        } else {
          return false;
        }
      } else if (type == 'partner') {
        if (this.userSubscription.data && this.userSubscription.data.user_by_partner) {
          return true;
        }
        return false;
      }
    }
  }
  getActivityData() {
    return new Promise((resolve, reject) => {
      this.AuthService.get('activity/' + this.activity_id).subscribe(
        (result) => {
          resolve(result.data);
        },
        (error) => {
          resolve({});
        }
      )
    })
  }
  userDataPartners: any = '';
  userPartnerDomain: any = '';
  async loadUserDomain() {
    return new Promise((resolve, reject): any => {
      this.AuthService.get('user/me').subscribe(
        (result: any) => {
          resolve(result.data)
        },
        () => {
          resolve(this.userData)
        }
      )
    })
  }
  async getFormData() {
    return new Promise(async (resolve) => {
      this.customerList = await this.getCustomers();
      await this.getAllPartners().then(async (resultList: any) => {
        this.partnersList = resultList;
        if (this.activity_id) {
          if (this.activity.partners && this.activity.partners.length > 0) {
            let unAvailablePartners = [];
            this.activity.partners.forEach((partner_id) => {
              if (resultList.findIndex((partner) => partner.partner_id == partner_id) == -1) {
                unAvailablePartners.push(partner_id);
              }
            })
            this.partnersList = await this.getAllPartners(unAvailablePartners.join());
            this.cdRef.detectChanges();
          }
        }
      })
      this.userSubscription.create_partner = this.getAccess('partner');
      if (!this.userSubscription.create_partner) {
        $('#partnerButton_1').addClass('no-partner');
        $('#partnerButton_2').addClass('no-partner');
      }
      if (this.defaultPartnerID && this.defaultPartnerID != '') {
        this.activity.partners = [...this.activity.partners, this.defaultPartnerID];
      }
      await this.loadTeamsList().then(async (resultList) => {
        this.usersList = resultList;
        if (this.activity_id) {
          if (this.activity.partners && this.activity.partners.length > 0) {
            this.usersList = await this.loadTeamsList(this.activity.partners.join());
          }
        }
      });
      this.priorityList = await this.getPriorityList();
      this.typeList = await this.getTypesList();
      await this.getPlans().then(async (resultList) => {
        this.plansList = resultList;
        if (this.activity_id) {
          if (this.activity.plan_id && this.activity.plan_id.length > 0) {
            this.plansList = await this.getPlans(this.activity.plan_id.join());
          }
        }
      });
      await this.getSolutions().then(async (resultList) => {
        this.solutionList = resultList;
        if (this.activity_id) {
          if (this.activity.solution_id && this.activity.solution_id.length > 0) {
            this.solutionList = await this.getSolutions(this.activity.solution_id.join());
          }
        }
      });
      await this.getOpportunities().then(async (resultList) => {
        this.opportunitiesList = resultList;
        if (this.activity_id) {
          if (this.activity.opportunity_id && this.activity.opportunity_id.length > 0) {
            this.opportunitiesList = await this.getOpportunities(this.activity.opportunity_id.join());
            this.cdRef.detectChanges();
          }
        }
      });
      resolve(true);
    })
  }
  getTypesList() {
    return new Promise((resolve, reject) => {
      this.AuthService.get('activity_type').subscribe((result) => {
        resolve(result.data);
      }, (error) => {
        resolve([]);
      })
    })
  }
  getPriorityList() {
    return new Promise(((resolve, reject) => {
      this.AuthService.get('priorities').subscribe((result) => {
        let list = result.data.map(type => {
          if (type.name.toLowerCase() == 'high') {
            type.color = 'red'
          } else if (type.name.toLowerCase() == 'medium') {
            type.color = '#ff9900'
          } else {
            type.color = '#0cb50c';
          }
          return type;
        })
        resolve(list)
      }, (error) => {
        resolve([]);
      })
    }))
  }
  async onPartnerSelect(value) {
    this.usersList = await this.loadTeamsList(value.join());
    this.activity.assignee = this.activity.assignee.filter(assignee => (<any[]>this.usersList).findIndex(user => user.email == assignee) != -1);
    if (this.savedTeam && this.savedTeam.length && (this.savedTeam.filter(assignee => (this.usersList.findIndex(user => user.email == assignee) != -1)).length == this.savedTeam.length)) {
      this.activity.assignee = this.savedTeam.slice();
      this.savedTeam = []
    }
  }

  allPartners: any[] = []
  getAllPartners(partner_id?: string) {
    return new Promise((resolve, reject) => {
      let apiString = '';
      if (this.activity_id && partner_id) {
        apiString = 'partners?partner_id=' + partner_id
      } else {
        apiString = 'partners'
      }
      this.AuthService.get(apiString).subscribe((result) => {
        let list = [];
        if (partner_id) {
          list = [...this.partnersList, ...result.data]
        } else {
          list = this.selfPartners = result.data
        }
        list = list.filter((v, i, a) => {
          if (v.partner_id === this.userData.partner.partner_id) {
            return false;
          } else {
            return (a.findIndex(t => { return (t.partner_id === v.partner_id) }) === i)
          }
        });
        resolve(list)
      }, (error) => {
        resolve([]);
      })
    })
  }
  connections: any[]
  domainList: any = [];
  loadTeamsList(partnerId?: string) {
    return new Promise((resolve, reject) => {
      let apiString = 'partners/users'
      if (partnerId && partnerId.length > 0) {
        apiString = apiString + '?partner_id=' + partnerId + ',' + this.userPartnerId
      } else {
        apiString = "users"
      }
      this.AuthService.get(`${apiString}`).subscribe(
        (result: any) => {
          this.domainList = result.data.map(partnerUser => { return partnerUser.email_domain });
          resolve(result.data);
        },
        (err: any) => {
          resolve([])
        });
    })

  }

  applyDatePicker(completion_date?) {
    var d = new Date();
    $('#activity-end-date').datepicker({
      format: 'mm/dd/yyyy',
      minDate: d,
      close: (e) => {
        this.activity.end_date = $('#activity-end-date').val()
      },
      place: function () {
        var element = this.component ? this.component : this.element;
        element.after(this.picker);
      }
    });
    $('#activity-completion-date').datepicker({
      format: 'mm/dd/yyyy',
      maxDate: d,
      close: (e) => {
        this.activity.completion_date = $('#activity-completion-date').val()
        this.applyCheckBox();
      },
      place: function () {
        var element = this.component ? this.component : this.element;
        element.after(this.picker);
      }
    });
    if (completion_date) {
      this.activity.completion_date = completion_date;
      $('#activity-completion-date').val(completion_date);
    }
    setTimeout(() => {
      this.applyCheckBox();
    }, 100);
  }
  applyCheckBox() {
    let $this = this;
    $(".cbk").change(function () {
      if ($this.activity.approval_status == $(this).val()) {
        $(".cbk").not(this).prop('checked', false);
        $this.activity.approval_status = null;
      } else {
        $(".cbk").prop('checked', false);
        $(this).prop('checked', true);
        $this.activity.approval_status = $(this).val()
        $this.cdRef.detectChanges();
      }
    });
  }
  confirmMessage: any = 0;
  // 1 for create activity
  // 2 for save changes
  // 3 for sent for approval
  // 4 for save and marked as completed
  openConfirmPopup() {
    if (!(this.activity.approval_status == 'Approved' || this.activity.approval_status == 'Rejected')) {
      this.activity.approval_status = null;
    }
    if (this.activityForm.valid) {
      this.approvalsAccessCheck();
      let approvers = this.activity.approvers && this.activity.approvers.length > 0 && this.activity.approvers[0] != '';
      if (!this.activity_id) {
        this.confirmMessage = 1;
      } else if (this.activity.completion_date) {
        if (this.approvalsAccess) {
          if (!this.activity.approval_status) {
            this.activity.approval_status = "Completed";
            this.confirmMessage = 4;
          } else {
            this.confirmMessage = 2;
          }
        } else {
          if (approvers) {
            this.confirmMessage = 3;
            this.activity.approval_status = "Submitted";
          } else {
            this.confirmMessage = 2;
            this.activity.approval_status = "Completed";
          }
        }
      } else {
        this.confirmMessage = 2;
      }
      $('#confirmPopup').modal('show');
    } else {
      this.formError = true;
    }
  }
  getCustomers() {
    return new Promise((resolve, reject) => {
      this.AuthService.get("customers?limit=100").subscribe(
        (result: any) => {
          let sortedList = (result.data || []).map((a) => {
            a.name = a.name.toLowerCase();
            return a
          });
          resolve(sortedList);
        }, (error) => {
          resolve([]);
        });
    })
  }
  approvalsAccess: boolean = false;
  approvalsAccessCheck() {
    if (this.activity_id) {
      if (this.activity.approver_id.includes(this.userData.user_id)) {
        this.approvalsAccess = true;
      }
    }
  }
  filteredCustomerList: any = [];
  getFilteredCustomers(e) {
    if (this.activity.customer_name != '' && this.activity.customer_name != undefined) {
      this.filteredCustomerList = this.customerList.filter((a) => a.name.toLowerCase().indexOf(this.activity.customer_name.toLowerCase()) != -1).sort((a: any, b: any) => {
        var keyA = a.name,
          keyB = b.name;
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });
    } else {
      this.filteredCustomerList = this.customerList.sort((a: any, b: any) => {
        var keyA = a.name,
          keyB = b.name;
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });
    }
  }
  setCustomer(c) {
    this.activity.customer_name = c.name;
    this.filteredCustomerList = []
  }
  blurCustomer() {
    setTimeout(() => {
      this.filteredCustomerList = []
    }, 500);
  }
  // DOcuments related code start
  files: any = [];
  file: File;
  downloadFile(file) {
    location.href = this.AuthService.baseUrl + 'download?file=' + file.path
  }
  errors: any = {};
  handleFileSelect(evt) {
    this.errors.document = '';
    let files = evt.target.files;
    let file = files[0];
    this.errors.image = '';
    if (files && file) {
      if (file.size <= 5000000) {
        // let reader = new FileReader();
        this.file = file;
      } else {
        evt.target.value = ''
        this.errors.document = 'Image should be less than 5mb';
      }
    }
  }
  isProgress: any = false
  addDocument(description) {
    if (!this.isProgress) {
      this.isProgress = true;
      let formData = new FormData()
      formData.append('file', this.file)
      this.AuthService.upload('upload', formData, (err) => {
        this.isProgress = false;
      }).subscribe((a: any) => {
        this.isProgress = false;
        if (this.activity.documents && this.activity.documents.length)
          this.activity.documents.push({
            "fileName": a.originalname,
            "path": a.key,
            "createdDate": new Date(),
            "description": description
            //  filename: this.file.name, docType: type
          });
        else {
          this.activity.documents = this.activity.documents || []
          this.activity.documents.push({
            "fileName": a.originalname,
            "path": a.key,
            "createdDate": new Date(),
            "description": description
            // filename: this.file.name, docType: type, date: new Date() 
          });
        }
        this.files.push(this.file);
        $('#docModal').modal('hide');
        $('input[name="file_upload"').val('');
        $('input[name="file_name"').val('');
      })
    }
  }
  selectedDocument: any = '';
  removeDoc(i, isDelete = false) {
    if (isDelete) {
      this.activity.documents.splice(i, 1)
      $('#myModalcnfrmDelete').modal('hide');
    }
    else {
      this.selectedDocument = i;
      $('#myModalcnfrmDelete').modal('show');
    }
  }
  //Documents related code end
  setObjectName(name, idList) {
    let list = [];
    if (idList && (idList.length > 0)) {
      idList.forEach(id => {
        if (id && id != '') {
          let object = this[name].find((a) => a.id == id);
          let objName = () => {
            if (name == 'plansList') {
              return object.plan_name;
            } else if (name == 'solutionList') {
              return object.solution_name;
            } else if (name == 'opportunitiesList') {
              return object.name;
            }
          }
          if (object && object.id) {
            list.push(objName());
          }
        }
      })
    }
    if (name == 'plansList') {
      this.activity.plan_name = list;
    } else if (name == 'solutionList') {
      this.activity.solution_name = list;
    } else if (name == 'opportunitiesList') {
      this.activity.opportunity_name = list;
    }
  }
  goBack() {
    $('.modal').modal('hide')
    let previousUrl = this.navHelper.getUrl()
    // let redirect = localStorage.getItem('redirect2');
    // if ((previousUrl && previousUrl != this.router.url) || redirect) {
    if (previousUrl && (previousUrl != this.router.url)) {
      // this.router.navigate([redirect || previousUrl])
      if (previousUrl == '/dashboard') {
        this.router.navigate([previousUrl, 'solutions']);
      } else {
        this.router.navigate([previousUrl])
      }
    } else {
      this.router.navigate(['/activity'])
    }
  }
  closeModal() {
    $('.modal').modal('hide');
  }
  getPlans(plan_id?) {
    return new Promise((resolve, reject) => {
      let list = [];
      let apiString = 'plans'
      if (plan_id && plan_id != '') {
        apiString = apiString + '?plan_id=' + plan_id
      } else {
        apiString = 'plans'
      }
      this.AuthService.get(apiString, (err) => {
        resolve([])
      }).subscribe((result: any) => {
        if (this.activity_id && plan_id && plan_id != '') {
          list = [...this.plansList, ...result.data]
        } else {
          list = result.data;
        }
        list = list.filter((v, i, a) => a.findIndex(t => (t.plan_id === v.plan_id)) === i);
        resolve(list);
      }, (err: any) => {
        resolve([])
        console.log(err);
      });
    })
  }
  getSolutions(solution_id?) {
    return new Promise((resolve, reject) => {
      let list = [];
      let apiString = 'solutions'
      if (solution_id && solution_id != '') {
        apiString = apiString + '?solution_id=' + solution_id
      }
      this.AuthService.get(apiString, (err) => {
        resolve([])
      }).subscribe((result: any) => {
        if (this.activity_id && solution_id && solution_id != '') {
          list = [...this.solutionList, ...result.data]
        } else {
          list = result.data;
        }
        list = list.filter((v, i, a) => a.findIndex(t => (t.solution_id === v.solution_id)) === i);
        resolve(list)
      }, (err: any) => {
        resolve([])
        console.log(err);
      });
    })
  }
  getOpportunities(Opportunities_id?) {
    return new Promise((resolve, reject) => {
      let list = [];
      let apiString = 'opportunities'
      if (Opportunities_id && Opportunities_id != '') {
        apiString = apiString + '?opportunity_id=' + Opportunities_id
      }
      this.AuthService.get(apiString, (err) => {
        resolve([])
      }).subscribe((result: any) => {
        if (this.activity_id && Opportunities_id && Opportunities_id != '') {
          list = [...this.opportunitiesList, ...result.data]
        } else {
          list = result.data;
        }
        list = list.filter((v, i, a) => a.findIndex(t => (t.opportunity_id === v.opportunity_id)) === i);
        resolve(list)
      }, (err: any) => {
        resolve([])
        console.log(err);
      });
    })
  }
  openTeam() {
    $('#teamModal').modal('show');
  }
  saveTeam($event) {
    if ($event) {
      this.invitedTeam = $event;
    }
    if (this.invitedTeam.length > 0 && !this.domainMatchError) {
      this.activityForm.get('assignee').setValidators([]);
      this.activityForm.get('assignee').updateValueAndValidity();
    } else {
      this.activityForm.get('assignee').setValidators([Validators.required]);
      this.activityForm.get('assignee').updateValueAndValidity();
    }
    $('#teamModal').modal('hide');
  }
  domainMatchError: boolean = false;
  domainChange($event) {
    this.domainMatchError = $event;
    this.cdRef.detectChanges()
  }
  closeTeam() {
    $('#teamModal').modal('hide');
  }
  formError: boolean = false;
  displayLoading: boolean = false;
  displayResult: string = '';
  errorMessage: string = '';
  submitInProgress: boolean = false;
  extractEmails(arr: any[]) {
    let list = [];
    arr.forEach(item => {
      if (list.indexOf(item.email) == -1) {
        list.push(item.email);
      }
    })
    return list;
  }
  submit() {
    this.closeModal();
    this.displayResult = '';
    this.submitInProgress = false;
    if (this.activityForm.valid) {
      this.submitInProgress = true;
      this.setObjectName('plansList', this.activity.plan_id);
      this.setObjectName('solutionList', this.activity.solution_id);
      this.setObjectName('opportunitiesList', this.activity.opportunity_id);
      let final_data = JSON.parse(JSON.stringify(this.activity));
      if (final_data.partners && final_data.partners.length > 0) {
        final_data.partners = [...[this.userData.partner.partner_id], ...final_data.partners];
      } else {
        final_data.partners = [...[this.userData.partner.partner_id]];
      }
      final_data.assignee = [...final_data.assignee, ...this.extractEmails(this.invitedTeam)];
      if (final_data.approval_status == 'Rejected') {
        final_data.completion_date = null;
      }
      if (!Array.isArray(final_data.approvers)) {
        final_data.approvers = final_data.approvers == null ? [] : [final_data.approvers];
      }
      if (!Array.isArray(final_data.approver_id)) {
        final_data.approver_id = final_data.approver_id == null ? [] : [final_data.approver_id];
      }
      if (!this.activity_id) {
        if (!final_data.assignee.includes(this.userPartnerEmail)) {
          final_data.assignee.push(this.userPartnerEmail);
        }
      }
      // if (this.rawAssigneeList.includes(this.userPartnerEmail)) {
      //   final_data.assignee.push(this.userPartnerEmail);
      // }
      let alertReq = ''
      if (final_data.approval_status == 'Submitted' && !this.approvalsAccess && this.activity_id) {
        alertReq = '?show_alert=false'
      }
      $('#successModal').modal('show');
      //if form is valid submit to backend
      this.AuthService[this.activity_id ? 'update' : 'add']('activity' + (this.activity_id ? '/' + this.activity_id : '') + alertReq, final_data).subscribe(
        (result: any) => {
          this.submitInProgress = false
          this.displayResult = 'success';
          if (alertReq != '') {
            this.AuthService.add('alerts/custom_alerts', {
              users: result.data.users,
              moduleName: "activity",
              message: `${this.userData.first_name} ${this.userData.last_name} of Partner ${this.userData.partner.partner_name} has submitted Activity ${result.data.activity} for approval`,
              partners: result.data.partners
            }).subscribe((result) => {
              console.log(result);
            })
          }
        },
        (error) => {
          this.submitInProgress = false
          this.displayResult = "failure"
          this.errorMessage = error.error.meta.message
        },
        () => { }
      )
    } else {
      this.formError = true
    }
  }
}
