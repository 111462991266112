

import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { NavigationHelperService } from '../services/navigation-helper.service';
import { CommonEmitterService } from '../services/common-emitter';

declare var $: any, Highcharts: any;

// import { Currency } from '../models/currency.model';
@Component({
  selector: 'app-plan-detail',
  templateUrl: './plan-detail.component.html',
  styleUrls: ['./plan-detail.component.css'],

})
export class PlanDetailComponent implements OnInit {
  plan: any = {};
  planId: any = '';
  planGraph: string = 'loading';
  startDate: Date;
  endDate: Date;
  timeDifference: any;
  totalDays: any;
  completedDays: any;
  viewAll: boolean = false;
  userData: any;
  totalTime() {
    this.startDate = new Date(moment(this.plan.start_date).format('YYYY-MM-DD'));
    this.endDate = new Date(moment(this.plan.end_date).format('YYYY-MM-DD'));
    this.timeDifference = this.endDate.getTime() - this.startDate.getTime();
    this.totalDays = this.timeDifference / (1000 * 3600 * 24);
  }
  completedTime() {
    this.startDate = new Date(moment(this.plan.start_date).format('YYYY-MM-DD'));
    let today = new Date();
    this.endDate = new Date(moment(today).format('YYYY-MM-DD'));
    this.timeDifference = this.endDate.getTime() - this.startDate.getTime()
    this.completedDays = this.timeDifference / (1000 * 3600 * 24);
  }
  constructor(
    private AuthService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private navHelper: NavigationHelperService,
    private CommonEmitter: CommonEmitterService,
  ) {
    let partner = localStorage.getItem('userData');
    partner = JSON.parse(partner);
    this.userData = partner || {};
    localStorage.removeItem('redirect2');
    if (this.navHelper.navigationTracker.length == 0) {
      this.navHelper.setUrl(this.router.url)
    }
    this.route.params.subscribe((a) => {
      if (a.planId) {
        this.planId = a.planId;
      }
    })
  }
  graphData: any = [];
  listOfPartners: any = [];
  getPartnerName(array?) {
    array = this.plan.partners;
    let listOfPartners: string[] = [];
    this.allPartners.forEach(partner => {
      array.forEach((element, i) => {
        if (partner.partner_id == element)
          listOfPartners.push(partner.partner_name);
      });
    })
    console.log(array, this.allPartners)
    this.listOfPartners = listOfPartners
  }
  allPartners: any[] = [];
  getUsers(partner_id) {
    this.AuthService.get(`partners?partner_id=${partner_id}`).subscribe(
      (result) => {
        console.log(result);
        this.allPartners = result.data
        this.getPartnerName();
      },
      (err) => {
        console.log(err.error);
        this.allPartners = []
      }, () => {
      }
    )
  }
  getPlan() {
    this.AuthService.get('plans/' + this.planId, (err) => {
    }).subscribe(async (result: any) => {
      console.log("result is:", result.data)
      this.plan = result.data
      this.plan.partnerDetails.filter((aa) => aa.partner_id != this.userData.partner.partner_id).map((aa) => {
        this.listOfPartners.push(aa.partner_name)
      })
      // this.getPartnerName(this.plan.team);
      this.getOpportunities();
      console.log("result is:", this.plan)
      this.CommonEmitter.setTitle(this.plan.plan_name);
      this.totalTime();
      this.completedTime();
      if (this.plan.partners.indexOf(this.userData.partner.partner_id) != -1) {
        this.plan.partners = this.plan.partners.filter((a) => a != this.userData.partner.partner_id);
      }
      // this.getUsers(this.plan.partners.join());
    }, (err: any) => {
      this.plan = [];
      console.log(err);
    }, () => {
    });
  }
  ngOnInit(): void {
    this.getPlan();
  }
  opportunities: any[] = [];
  getOpportunities() {
    this.AuthService.get('opportunities').subscribe((result: any) => {
      if (result.data) {
        this.opportunities = result.data;
        this.opportunities = this.opportunities.reverse()
      }
      this.planByOpp()
    }, (err) => {
      console.log(err), () => { };
    })
  }
  totalTargetValue: any = '';
  planByOpp() {
    let plan = this.plan
    let totalTarget: any = 0;
    let totalAchievement: any = 0;
    let remainingAchievement: any = 0;
    totalTarget = Number(plan.target)
    this.opportunities.filter((a) => a.plan_id.includes(plan.id)).map((aa) => {
      // if (aa.deal_status != 'Lost')

      if (aa.stage == 'Closed' && aa.deal_status == 'Won') {
        totalAchievement = totalAchievement + Number(aa.value)
      }
    })
    this.totalTargetValue = totalTarget;
    remainingAchievement = totalTarget - totalAchievement;
    remainingAchievement = remainingAchievement > 0 ? remainingAchievement : 0;
    if (totalAchievement != 0 || remainingAchievement != 0) {
      this.planGraph = 'true';
      this.graphData = [['Achieved Target', totalAchievement], ['Remaining Target', remainingAchievement]];
      // this.options.series[0].data.push(['Achieved Target', totalAchievement]);
      // this.options.series[0].data.push(['Remaining Target', remainingAchievement]);
      setTimeout(() => {
        Highcharts.setOptions({
          lang: {
            thousandsSep: ','
          }
        });
        this.genChart(this.graphData)
      }, 100)
    } else {
      this.planGraph = 'false';
    }
  }
  gotoUpdatePlan() {
    localStorage.setItem('redirect2', this.router.url);
    this.router.navigate(['/update-plan', this.plan.id]);
  }
  genChart(data) {
    let $this = this;
    Highcharts.chart('container', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
        events: {
          load: function () {
            $this.addLabel(this)
          },
          redraw: function () {
            $this.addLabel(this)
          }
        }
      },
      title: {
        text: 'Achievements',
        align: 'center',
        verticalAlign: 'middle',
        y: 60
      },
      tooltip: {
        pointFormat: '<b>{point.y:,.0f}</b>'
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: false,
            distance: -50,
            style: {
              fontWeight: 'bold',
              color: 'white'
            }
          },
          startAngle: -90,
          endAngle: 90,
          center: ['50%', '75%'],
          size: '110%'
        }
      },
      colors: [
        '#2fc2a8', '#f15424'
      ],
      series: [{
        type: 'pie',
        innerSize: '0%',
        data: data
      }]
    });
  }
  addLabel(chart) {
    let series = chart.series[0]
    let point = series.points[0];
    let plotX = point.shapeArgs.x;
    let plotY = point.shapeArgs.y;
    let pieRadius = point.shapeArgs.r
    $('#target-start').remove();
    $('#target-end').remove();
    chart.renderer.html('0', plotX - pieRadius, plotY + 25).attr({ id: `target-start` }).css({
      fontSize: '15px',
      color: '#44a2f8'
    }).add();
    chart.renderer.html(`${this.totalTargetValue.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`, plotX + pieRadius, plotY + 25)
      .attr({ id: `target-end` })
      .css({
        fontSize: '15px',
        color: '#44a2f8'
      }).add();
    let translateX = $('#target-end').width();
    $('#target-end').css({ "transform": `translate(-${translateX / 2}px,0)` });
  }
}

