import { AfterViewInit, Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { NavigationHelperService } from '../services/navigation-helper.service';
import * as moment from "moment";
import { LocalStorageService } from '../services/local-storage.service';
import { IMultiSelectTexts, IMultiSelectSettings } from 'angular-2-dropdown-multiselect';
import { Location } from '@angular/common';
import { CommonEmitterService } from '../services/common-emitter';
import { Subscription } from 'rxjs';
declare var $: any
declare var Highcharts: any;
@Component({
  selector: 'app-sales-dashboard',
  templateUrl: './sales-dashboard.component.html',
  styleUrls: ['./sales-dashboard.component.css']
})
export class SalesDashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  selected: any = { startDate: moment(), endDate: moment() };
  alwaysShowCalendars: boolean;
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }
  invalidDates: moment.Moment[] = []//[moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];

  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some(d => d.isSame(m, 'day'))
  }
  opportunities: any[]
  barSeries: any = [];
  categories: any = [];
  Dates: any = [];
  milisecondInDay: any = 86400000
  userData: any = { partner: {} };
  userPartnerId: any;
  filterData: any = {}
  users: any = {
    plan: [],
    solution: [],
    opportunity: [],
  };
  start_date: any = moment().format('YYYY-MM-DD');
  end_date: any = moment().format('YYYY-MM-DD');
  is_subscriber: any = false;
  refreshSubscription: Subscription;
  constructor(private router: Router,
    private route: ActivatedRoute,
    private AuthService: AuthService,
    private location: Location,
    private navHelper: NavigationHelperService,
    private CommonEmitterService: CommonEmitterService,
    private localStorageService: LocalStorageService
  ) {
    if (localStorage.getItem('dates')) {
      let dates = (JSON.parse(localStorage.getItem('dates')))
      console.log(dates)
      this.selected = {
        endDate: moment(dates.endDate),
        startDate: moment(dates.startDate)
      };
      this.start_date = moment(this.selected.startDate || new Date()).format('YYYY-MM-DD');
      this.end_date = moment(this.selected.endDate || new Date()).format('YYYY-MM-DD');
    }
    this.alwaysShowCalendars = true;
    if (this.navHelper.navigationTracker.length == 0) {
      this.navHelper.setUrl(this.router.url)
    }


    this.refreshSubscription = this.CommonEmitterService.pageRefresh.subscribe((val) => {
      this.reload();
    })
  }
  totalTargetValue = { plan: '', solution: '', opportunity: '' }
  selectedTable: string = 'plans'
  graph: any = {};
  timer: any;
  getUserData() {
    return new Promise((resolve, reject) => {
      this.AuthService.get('user/me').subscribe(
        (result: any) => {
          this.userData = result.data[0]
          this.is_subscriber = this.userData ? this.userData.subscription_type_data ? true : false : false;
          if (this.userData.partner) {
            this.userPartnerId = this.userData.partner.partner_id;
          }
        },
        () => { }
      )
    })

  }
  async ngOnInit() {
    await this.getUserData();


    if (!this.AuthService.isLoggedIn()) {
      this.router.navigate(['/sign-in']);
    }
    this.getDashboardData();
  }
  dateChaged(e) {
    console.log(e, this.selected);
    localStorage.setItem('dates', JSON.stringify(e));
    this.start_date = moment(e.startDate || new Date()).format('YYYY-MM-DD');
    this.end_date = moment(e.endDate || new Date()).format('YYYY-MM-DD');

    this.getDashboardData();
  }
  ngAfterViewInit(): void {
    // this.genChart();
  }
  connectionRedirect(path: string) {
    $('#zeroObjects').modal('hide');
    this.router.navigate([`/${path}`])
  }
  redirect() {
    this.router.navigate(['create-connection'])
  }


  reload() {
    this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/dashboard', this.selectedTable]);
    });
  }

  counterData: any = {};
  getDashboardData() {
    this.AuthService.raw('get', this.AuthService.baseUrl + 'customer/getcounters?start_date=' + this.start_date + '&end_date=' + this.end_date, '').subscribe((result: any) => {
      if (result.data) {
        console.log(result.data)
        this.counterData = result.data;
      } else {
        this.opportunities = [];
      }
    }, (err: any) => {
      this.opportunities = [];
      console.log(err);
    }, () => {
    });
    // this.AuthService.get('customer/getcounters?start_date=' + this.start_date + '&end_date=' + this.end_date, (err) => {
    // }).subscribe((result: any) => {
    //   if (result.data) {
    //     console.log(result.data)
    //     this.counterData = result.data;
    //   } else {
    //     this.opportunities = [];
    //   }
    // }, (err: any) => {
    //   this.opportunities = [];
    //   console.log(err);
    // }, () => {
    // });
  }
  goToList(type, val, type2 = '') {
    // todo uncomment
    // if (val != 0 && val != '0')
    // if (type == 'commission' &&
    //   !this.is_subscriber && (this.userData &&
    //     this.userData.email != this.userData.partner.partner_owner)) {
    // } else {
    //   this.router.navigate(['sales-list'], { queryParams: { type: type, start_date: this.start_date, end_date: this.end_date } });
    // }
    let params: any = { type: type, start_date: this.start_date, end_date: this.end_date };
    if (type2) {
      params.type2 = type2;
    }
    this.router.navigate(['sales-list'], { queryParams: params });
  }
  getPartnerName(array) {
    let listOfPartners: any = [];
    this.allPartners.forEach(partner => {
      array.forEach((element, i) => {
        if (partner.partner_id == element) {
          if (!listOfPartners.includes(partner.partner_name)) {
            listOfPartners.push(partner.partner_name);
          }
        }
      });
    })
    return listOfPartners
  }
  allUsers: any[] = [];
  getUsers() {
    this.AuthService.get('admin/users').subscribe(
      (result) => {
        console.log(result);
        this.allUsers = result.data
      },
      (err) => {
        console.log(err.error);
        this.allUsers = []
      }, () => {
      }
    )
  }
  allPartners: any[] = [];
  getPartners() {
    this.AuthService.get('partners').subscribe(
      (result) => {
        console.log(result);
        this.allPartners = result.data
      },
      (err) => {
        console.log(err.error);
        this.allPartners = []
      }, () => {
      }
    )
  }

  getQuarterStartEndDate(date) {
    var now = new Date(date);
    var quarter = Math.floor((now.getMonth() / 3));
    var firstDate = new Date(now.getFullYear(), quarter * 3, 1);
    var endDate = new Date(firstDate.getFullYear(), firstDate.getMonth() + 3, 0);
    return {
      firstDate: new Date(moment(firstDate).format('YYYY-MM-DD') + ' 00:01'),
      endDate: new Date(moment(endDate).format('YYYY-MM-DD') + ' 23:59')
    }
  }
  copyToClipboard(element) {
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val(element).select();
    document.execCommand("copy");
    console.log('copied');
    $temp.remove();
  }

  ngOnDestroy(): void {
    this.refreshSubscription.unsubscribe();
  }
}
