<form [formGroup]="partnerForm">
  <div class="pad_div">
    <!-- <div class="row mrgbtm" style="margin-top: 15px;">
      <div class="col-lg-5 col-md-6 col-sm-6 col-xs-6 col-6 label">
        <h2 class="head_type">
          Partner ID:
          <p class="data_field">(Auto generated)</p>
        </h2>
        
      </div>
      <div class="col-lg-7 col-md-6 col-sm-6 col-xs-6 col-6">
        <h5 class="data_field"></h5>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <hr style="border: 0px solid #e0e0e0;" />
      </div>
    </div> -->
    <div class="row mrgbtm">
      <div class="col-lg-5 col-md-4 col-sm-12 col-xs-12 col-12 label">
        <p class="head_type">Partner Name<sub class="required">*</sub> :</p>
      </div>
      <div class="col-lg-7 col-md-6 col-sm-6 col-xs-6 col-6">
        <input
          type="text"
          matInput
          formControlName="partnerName"
          placeholder="Enter Partner name...."
          (blur)="validatePartner()"
          [(ngModel)]="partner.partner_name"
        />
        <div
          class="mrgbtm"
          *ngIf="
            (formControls['partnerName'].dirty ||
              formControls['partnerName'].touched ||
              formError) &&
            formControls['partnerName'].errors
          "
        >
          <div
            class="errorMessage"
            *ngIf="formControls['partnerName'].errors.required"
          >
            Partner name is required
          </div>
          <div
            class="errorMessage"
            *ngIf="formControls['partnerName'].errors.notMatch"
          >
            Cannot create partner with this name
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <hr style="border: 0px solid #e0e0e0" />
      </div>
    </div>
    <div class="row mrgbtm">
      <div class="col-lg-5 col-md-4 col-sm-12 col-xs-12 col-12 label">
        <p class="head_type">Location Country<sub class="required">*</sub>:</p>
      </div>
      <div class="col-lg-7 col-md-6 col-sm-6 col-xs-6 col-6">
        <select
          class="form-control"
          formControlName="location"
          [(ngModel)]="partner.country_code"
        >
          <option value="">Select</option>
          <option
            *ngFor="let country of countries"
            [value]="country.country_code"
          >
            {{ country.country_name }}
          </option>
        </select>
        <div
          class="mrgbtm"
          *ngIf="
            (formControls['location'].dirty ||
              formControls['location'].touched ||
              formError) &&
            formControls['location'].errors
          "
        >
          <div
            class="errorMessage"
            *ngIf="formControls['location'].errors.required"
          >
            Location is required
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <hr style="border: 0px solid #e0e0e0" />
      </div>
    </div>
    <div class="row mrgbtm">
      <div class="col-lg-5 col-md-4 col-sm-12 col-xs-12 col-12 label">
        <p class="head_type">Partner Type<sub class="required">*</sub>:</p>
      </div>
      <div class="col-lg-7 col-md-6 col-sm-6 col-xs-6 col-6">
        <select
          class="form-control"
          formControlName="partnerType"
          [(ngModel)]="partner.partner_type"
        >
          <option value="">Select</option>
          <option [value]="type" *ngFor="let type of partnerTypes">
            {{ type }}
          </option>
        </select>
        <div
          class="mrgbtm"
          *ngIf="
            (formControls['partnerType'].dirty ||
              formControls['partnerType'].touched ||
              formError) &&
            formControls['partnerType'].errors
          "
        >
          <div
            class="errorMessage"
            *ngIf="formControls['partnerType'].errors.required"
          >
            Partner Type is required
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <hr style="border: 0px solid #e0e0e0" />
      </div>
    </div>
    <div class="row mrgbtm">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 label">
        <p class="head_type">
          Business Description<sub class="required">*</sub>:
        </p>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 description">
        <textarea
          matInput
          style="margin-left: 15px; margin-right: 15px; border: none"
          placeholder="Enter Business Description"
          formControlName="description"
          [(ngModel)]="partner.description"
        ></textarea>

        <div
          class="mrgbtm col-12"
          *ngIf="
            (formControls['description'].dirty ||
              formControls['description'].touched ||
              formError) &&
            formControls['description'].errors
          "
        >
          <div
            class="errorMessage"
            *ngIf="formControls['description'].errors.required"
          >
            Business description is required
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="row mrgbtm">
      <div class="col-lg-5 col-md-4 col-sm-12 col-xs-12 col-12 label">
        <p class="head_type">App Url<sub class="required">*</sub> :</p>
      </div>
      <div class="col-lg-7 col-md-6 col-sm-6 col-xs-6 col-6">
        <input type="text" matInput formControlName="app_url" placeholder="Enter App Url"
          [(ngModel)]="partner.app_url" />
        <div class="mrgbtm" *ngIf="
            (formControls['app_url'].dirty ||
              formControls['app_url'].touched ||
              formError) &&
            formControls['app_url'].errors
          ">
          <div class="errorMessage" *ngIf="formControls['app_url'].errors.required">
            App Url is required
          </div>
        </div>
      </div>
    </div> -->
    <div class="row">
      <div class="col-md-12">
        <hr style="border: 0px solid #e0e0e0" />
      </div>
    </div>
    <div class="row mrgbtmn">
      <div class="col-lg-5 col-md-4 col-sm-12 col-xs-12 col-12 label">
        <p class="head_type">Validate Domain:</p>
      </div>
      <div class="col-lg-7 col-md-6 col-sm-6 col-xs-6 col-6">
        <input
          type="checkbox"
          [(ngModel)]="validateDomain"
          [ngModelOptions]="{ standalone: true }"
          (ngModelChange)="domainCheckChange()"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <hr style="border: 0px solid #e0e0e0" />
      </div>
    </div>
    <div class="row mrgbtmn">
      <div class="col-lg-5 col-md-4 col-sm-12 col-xs-12 col-12 label">
        <p class="head_type">Partner Owner<sub class="required">*</sub>:</p>
      </div>
      <div class="col-lg-7 col-md-6 col-sm-6 col-xs-6 col-6">
        <!-- <a href="#" class="data_field_a">Invite</a> -->
        <input
          matInput
          formControlName="partnerOwner"
          [(ngModel)]="partner.partner_owner"
          type="text"
          [value]=""
          (blur)="validatePartnerEmail()"
          placeholder=" Enter Email"
        />
        <div
          class="mrgbtm"
          *ngIf="
            (formControls['partnerOwner'].dirty ||
              formControls['partnerOwner'].touched ||
              formError) &&
            formControls['partnerOwner'].errors
          "
        >
          <div
            class="errorMessage"
            *ngIf="formControls['partnerOwner'].errors.required"
          >
            Partner Owner is required
          </div>
          <div
            class="errorMessage"
            *ngIf="formControls['partnerOwner'].errors.pattern"
          >
            Enter Valid Email Id
          </div>
          <div
            class="errorMessage"
            *ngIf="formControls['partnerOwner'].errors.domainMatch"
          >
            Cannot create partner with this partner owner
          </div>
          <div
            class="errorMessage"
            *ngIf="formControls['partnerOwner'].errors.notMatch"
          >
            Cannot create partner with this Email
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <hr style="border: 0px solid #e0e0e0" />
      </div>
    </div>
    <div class="row mrgbtmn">
      <div class="col-lg-5 col-md-6 col-sm-6 col-xs-6 col-6 label">
        <p class="head_type">Partner Super Users:</p>
      </div>
      <div class="col-lg-7 col-md-6 col-sm-6 col-xs-6 col-6">
        <div id="button_wrapper">
          <span (click)="openTeam()" style="cursor: pointer; color: #038dff">
            <i
              class="fa fa-plus"
              aria-hidden="true"
              style="color: #44a2f8; margin-right: 5px"
            ></i>
            <span>{{
              partner.team.length > 0
                ? "Added " +
                  this.partner.team.length +
                  " user" +
                  (partner.team.length > 1 ? "s" : "")
                : " Add Users"
            }}</span></span
          >
        </div>
      </div>
    </div>
    <div *ngIf="this.formControls['partnerOwner'].invalid" class="row">
      <div class="col">
        <span class="tooltiptext errorMessage"
          >Partner owner required to add Super users</span
        >
      </div>
    </div>
    <!-- <div class="row mrgbtmn">
      <div class="col-lg-5 col-md-6 col-sm-6 col-xs-6 col-6">
        <h4 class="head_type">Target Revenue<sub class="required">*</sub>:</h4>
      </div>
      <div class="col-lg-7 col-md-6 col-sm-6 col-xs-6 col-6">
        <div class="d-flex">
          <h4 style="margin-top: 6px;">$</h4>
          <input
            type="text"
            class="form-control"
            formControlName="revenue"
            placeholder="Enter Amount"
            [(ngModel)]="partner.target_revenue"
          />
        </div>
        <div
          class="mrgbtm"
          *ngIf="
            (formControls['revenue'].dirty ||
              formControls['revenue'].touched ||
              formError) &&
            formControls['revenue'].errors
          "
        >
          <div
            class="errorMessage"
            *ngIf="formControls['revenue'].errors.required"
          >
            Total Revenue is required
          </div>
          <div
            class="errorMessage"
            *ngIf="formControls['revenue'].errors.pattern"
          >
            Total Revenue must be a valid number or decimal
          </div>
        </div>
      </div>
    </div> -->
    <div *ngIf="getDomainError()" class="row mrgbtm">
      <div class="col-md-12">
        <div align="center" class="errorMessage">
          Partner Owner and Super users domains does not match.
        </div>
      </div>
    </div>
    <div *ngIf="partnerMatchError" class="row mrgbtm">
      <div class="col-md-12">
        <div align="center" class="errorMessage">
          Cannot enter same email for super users and partner owner.
        </div>
      </div>
    </div>
    <div *ngIf="formError && !this.partnerForm.valid" class="row mrgbtm">
      <div class="col-md-12">
        <div align="center" class="errorMessage">
          Please fill all required(*) fields to create partner
        </div>
      </div>
    </div>

    <div class="row mrgbtm" style="margin-top: 20px">
      <div class="col-md-12">
        <div align="center">
          <button
            style="border: none"
            (click)="goBack()"
            type="button"
            class="btn cnclbtn"
          >
            CANCEL
          </button>

          <button type="button" class="btn addbtn" (click)="partnerPopUp()">
            {{ partnerId ? "UPDATE" : "CREATE" }} PARTNER
          </button>
        </div>
      </div>
    </div>
  </div>
</form>

<div class="modal" id="teamModal">
  <div class="modal-dialog teams-modal modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal body -->
      <div
        class="modal-body"
        style="text-align: center; display: inline; padding: 0"
      >
        <app-add-team
          [onlySuperUsers]="true"
          [partner_owner_email]="formControls['partnerOwner'].value"
          (submitForm)="saveTeam($event)"
          (closeModal)="closeTeam()"
          (domainMismatch)="domainChange($event)"
          (partnerMatch)="getPartnerMatch($event)"
          [validateDomain]="validateDomain"
        >
        </app-add-team>
      </div>
    </div>
  </div>
</div>
<div class="modal" id="myModal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal body -->
      <div class="modal-body" style="text-align: center; display: inline">
        <h5 class="modal-title mrgbtm">Are You Sure?</h5>
        <p>
          Partner record
          <span style="font-weight: bold">{{ partner.partner_name }}</span>
          will be {{ partnerId ? "updated" : "created" }}
          <!-- with owner as
          <span style="font-weight: bold;">{{
            this.selectedPartnerOwner ? this.selectedPartnerOwner.partner.partner_name   : ""   }} 
        </span>-->
        </p>
        <div class="errorMessage" *ngIf="responseError != ''">
          {{ responseError }}
        </div>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer" style="text-align: center; display: inline">
        <button type="button" (click)="closeConfirmModal()" class="btn cnclbtn">
          CANCEL
        </button>

        <button
          type="button"
          [disabled]="isProgress"
          class="btn addbtn"
          (click)="submit()"
        >
          CONFIRM
        </button>
      </div>
    </div>
  </div>
</div>
<div id="selectPartner" class="modal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <div class="card text-center border-dark mb-3">
          <div class="card-header">Select Owner Partner</div>
          <div class="card-body">
            <button
              *ngFor="let owner of partner_owners"
              (click)="confirmPopUP(owner)"
              class="btn addbtn mb-3"
            >
              {{ owner.owner_partner.partner_name | titlecase }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="modal" id="successModal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content modal-custom">
      <div *ngIf="displayResult == 'success'" class="modal-body" style="text-align: center; display: inline;">
        <div class="row mrgbtmn">
          <div class="col-md-12">
            <h5 class="modal-title mrgbtm">Partner Created!</h5>
          </div>
        </div>
        <div class="row mrgbtm">
          <div class="col-md-12">
            <div align="center">
              <button (click)="this.goBack()" type="button" class="btn addbtn">
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="displayResult == 'failure'" class="modal-body" style="text-align: center; display: inline;">
        <div>
          <div class="row mrgbtm">
            <div class="col-md-12">
              <div align="center" class="errorMessage">
                {{ errorMessage }}
              </div>
            </div>
          </div>
          <div class="row mrgbtm">
            <div class="col-md-12">
              <div align="center">
                <button (click)="closeModal()" type="button" class="btn addbtn">
                  CLOSE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="displayResult == ''" class="modal-body" style="text-align: center; display: inline;">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <p>
          Creating the Partner...
        </p>
      </div>
    </div>
  </div>
</div> -->
