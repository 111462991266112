import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Partner, PartnerOwner } from '../models/partner.model';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { DateCheck } from 'src/validators/date.validator';
import { Location } from '@angular/common';
import { LocalStorageService } from '../services/local-storage.service';
import { NavigationHelperService } from '../services/navigation-helper.service';
import * as  moment from "moment";
import { CommonEmitterService } from '../services/common-emitter';

declare var $: any;
@Component({
  selector: 'app-create-solution',
  templateUrl: './create-solution.component.html',
  styleUrls: ['./create-solution.component.css']
})
export class CreateSolutionComponent implements OnInit {
  createSolutionForm: FormGroup
  formControls: any
  solutionId: any = ''
  isAdditionalInfo: any = false;
  partner: Partner;
  solution: any = { documents: [], product_category: [], product: [], solution_stage: '' };
  partners: any = [];
  partnerOptions: IMultiSelectOption[] = [];
  partnerUsersOptions: IMultiSelectOption[] = [];
  partnerTeamOptions: IMultiSelectOption[] = [];
  partnerUsers: any = [];
  formError: boolean = false;
  defaultPartnerID: string = '';
  defaultPartnerEmail: string;
  selectedPartner: string;
  selectedPartnerDomain: string;
  partnerOwners: any = [];
  selectedPartners: string[]
  partnerUsersModel: any = [];
  final_data: any;
  domainList: any[] = []
  selectedTeamMembers: any[] = []
  productCategories: any[] = []
  products: any[] = []
  files: any = [];
  file: File;
  userData: any = {};
  displayResult: string = '';
  errorMessage: string = '';
  displayId: string = '';
  userPartnerDomain: any;
  userPartnerEmail: any;
  multiSelectionSettings: IMultiSelectSettings = {
    enableSearch: true,
    checkedStyle: "checkboxes",
    buttonClasses: "btn fz-16 btn-default",
    dynamicTitleMaxItems: 0
  };
  multiSelectionTexts: IMultiSelectTexts = {
    defaultTitle: 'Choose From',
    checked: 'added',
    checkedPlural: "added"
  }
  solutionStages: any = [];
  isEdit: any = true;
  errors: any = {}
  defaultConnectionName: string;
  userSubscription: { type: any, data: any, create_partner: boolean, create_user: boolean } = { type: '', data: '', create_partner: true, create_user: true };
  userDataPartners: any = [];
  partner_error: any = false;
  constructor(
    private cdRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private activeRoute: ActivatedRoute,
    private AuthService: AuthService,
    private CommonEmitterService: CommonEmitterService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private navHelper: NavigationHelperService
  ) {
    if (this.navHelper.navigationTracker.length == 0) {
      this.navHelper.setUrl(this.router.url)
    }
    this.userData = this.localStorageService.get({ name: 'userData' })
    this.userData = this.userData ? JSON.parse(this.userData) : {}
    if (this.userData.subscription_type_data != null) {
      this.userSubscription.type = 'subscriber'
      this.userSubscription.data = this.userData.subscription_type_data;
    } else {
      this.userSubscription.type = 'partner'
      this.userSubscription.data = this.userData.subscriber_subscription_type_data;
      if (this.userSubscription.data) {
        this.userSubscription.create_user = this.userSubscription.data.user_by_partner;
      }
    }

    this.activeRoute.queryParams.subscribe((a) => {
      if (a.partnerId) {
        this.defaultPartnerID = a.partnerId;
      }
    })
    this.createSolutionForm = this.formBuilder.group({
      solution_name: ['', Validators.compose([Validators.required])],
      partners: [],
      product_category: [''],
      revenue: ['', Validators.compose([Validators.required, Validators.pattern("^[0-9]*(\\.[0-9]{1,})?$")])],
      solution_stage: ['', Validators.compose([Validators.required])],
      description: ['', Validators.compose([Validators.required])],
      start_date: ['', Validators.compose([Validators.required])],
      product: [''],
      teams: [''],
      additional: [''],
      documents: [''],
      plan_id: [''],
      plan_name: [''],
    })
    this.formControls = this.createSolutionForm.controls;
  }
  getSolutionDetail() {
    this.AuthService.get('solutions/' + this.solutionId, (err) => {
      console.log(err);
    }).subscribe((result: any) => {
      this.solution = result.data;
      this.solution.start_date = moment(new Date(this.solution.start_date)).format('MM/DD/YYYY')
      this.solution.partners = this.solution.partners.filter((a) => a != this.userData.partner.partner_id)
      this.loadPartners(this.solution.partners.join());
      this.getPlans(this.solution.plan_id);
      this.partnerUsersModel = (this.solution.team || []).filter((a) => a.email != this.userData.email).map((a) => a.email + '~3');
      // this.createSolutionForm.patchValue({ 'teams': this.solution.team })
      this.getProductCategories()
      if (this.solution.product_category) {
        this.solution.product_category = Array.isArray(this.solution.product_category) ? this.solution.product_category : [this.solution.product_category]
        let cat = this.productCategories.find((a) => this.solution.product_category.indexOf(a.category_name) != -1)
        console.log(cat, this.productCategories)
        this.getProducts(this.solution.product_category)
      }
    }, (err: any) => {
      this.solution = {};
      console.log(err);
    });
  }
  plans: any = []
  getPlans(plan_id?) {
    let apiString = 'plans'
    if (plan_id && plan_id != '') {
      apiString = apiString + '?plan_id=' + plan_id
    } else {
      apiString = 'plans'
    }
    this.AuthService.get(apiString, (err) => {
      console.log(err);
    }).subscribe((result: any) => {
      if (this.solutionId && plan_id && plan_id != '') {
        this.plans = [...this.plans, ...result.data]
      } else {
        this.plans = result.data;
      }
      this.plans = this.plans.filter((v, i, a) => a.findIndex(t => (t.plan_id === v.plan_id)) === i);
      console.log(this.solution.product_category, this.productCategories);
      setTimeout(() => { this.solution = this.solution; }, 100)
    }, (err: any) => {
      this.plans = [];
      setTimeout(() => { this.solution = this.solution; }, 100)
      console.log(err);
    });
  }
  setPlanName(planIds) {
    let planName = []
    if (planIds && (planIds.length > 0)) {
      planIds.forEach(planId => {
        if (planId && planId != '') {
          let plan = this.plans.find((a) => a.id == planId);
          if (plan) {
            planName.push(plan.plan_name)
          }
        }
      });
      this.createSolutionForm.patchValue({ plan_name: planName });
      this.solution.plan_name = planName;
    }
  }
  getProductCategories() {
    let vars = '';
    if (this.solution.partners && this.solution.partners.length) {
      vars = '?partner_id=' + this.solution.partners.join();
      vars = vars + `,${this.userData.partner.partner_id}`
    }
    this.AuthService.get('product_categories' + vars, (err) => {
      console.log(err);
    }).subscribe((result: any) => {
      this.productCategories = result.data;
      console.log(this.solution.product_category, this.productCategories);
      setTimeout(() => { this.solution = this.solution; }, 100)
      if (this.solution.product_category) {
        let cat = this.productCategories.find((a) => this.solution.product_category.indexOf(a.category_name) != -1)
        this.getProducts(cat)
      }
    }, (err: any) => {
      this.productCategories = [];
      console.log(err);
    });
  }
  getProducts(cat?) {
    console.log(cat, this.solution.product_category)
    if (this.solution.product_category) {
      let cats = []
      if (typeof cat == 'string') {
        let cat1 = this.productCategories.find((a) => a.category_name == cat)
        console.log(cat1, cat)
        cat = cat1;
      }
      if (Array.isArray(this.solution.product_category)) {
        this.solution.product_category.map((a1) => {
          let cat1 = this.productCategories.find((a) => a.category_name == a1)
          if (cat1)
            cats.push(cat1.category_id)
        })
      }
      this.AuthService.get('products?category_id=' + cats.join(), (err) => {
        console.log(err);
      }).subscribe((result: any) => {
        this.products = result.data;
        setTimeout(() => { this.solution = this.solution; }, 100)

      }, (err: any) => {
        this.products = [];
        setTimeout(() => { this.solution = this.solution; }, 100)
        console.log(err);
      });
    }
  }
  getStages(partner_id?: string) {
    let apiString = 'customData'
    if (partner_id && partner_id != '') {
      apiString = apiString + '?partner_id=' + partner_id
    }
    this.AuthService.get(`${apiString}`, (err) => {
      console.log(err);
    }).subscribe((result: any) => {
      this.solutionStages = result.data.solution_stages.map((a) => { return { name: a } });
      setTimeout(() => { this.solution = this.solution; }, 100)
    }, (err: any) => {
      this.solutionStages = [];
      setTimeout(() => { this.solution = this.solution; }, 100)
      console.log(err);
    });
  }
  ngOnInit(): void {
    this.formControls = this.createSolutionForm.controls;
    this.getStages()
    this.getPlans();
    this.loadUserDomain();
    this.getProductCategories();
    this.activeRoute.params.subscribe((a) => {
      if (a.solutionId) {
        this.solutionId = a.solutionId;
        this.getSolutionDetail()
      }
    })
    var d = new Date();
    d.setDate(d.getDate() - 1);
    setTimeout(() => {
      $('select').dropdown();
      $('#date1').datepicker({
        format: 'mm/dd/yyyy',
        minDate: d,
        close: (e) => {
          this.createSolutionForm.patchValue({ start_date: $('#date1').val() });
          console.log('Change is fired' + $('#date1').val());
        },
        place: function () {
          /*var offset = this.component ? this.component.offset() : this.element.offset();
             this.picker.css({
                  top: offset.top + this.height,
                  left: offset.left
             });*/

          var element = this.component ? this.component : this.element;
          element.after(this.picker);
        }
      });
      let planData = localStorage.getItem('solutionData')
      this.solution = planData ? JSON.parse(planData) : {}
      localStorage.removeItem('solutionData')
      let teamData = localStorage.getItem('solutionTeamData')
      this.partnerUsersModel = teamData ? JSON.parse(teamData) : [];
      localStorage.removeItem('solutionTeamData')
    }, 100);

  }
  loadUserDomain() {
    this.AuthService.get('user/me').subscribe(
      (result: any) => {
        this.userDataPartners = result.data;
        this.userPartnerDomain = result.data[0].partner.email_domain
        this.userPartnerEmail = result.data[0].email;
        this.loadPartners();
      },
      () => { }
    )
  }
  newPartner() {
    if (this.userSubscription.create_partner) {
      localStorage.setItem('solutionData', JSON.stringify(this.solution))
      localStorage.setItem('solutionTeamData', JSON.stringify(this.partnerUsersModel))
      localStorage.setItem('redirect', this.router.url)
      this.router.navigate(["/create-partner"]);
    } else {
      $('#alertModalX').modal('show');
    }
  }
  onPartnerSelect($event?) {
    let connectionString: string = '';
    (this.formControls['partners'].value || []).map((connection, index) => {
      if (index == 0) {
        connectionString = connection
      } else {
        connectionString = connectionString + ',' + connection
      }
    })
    this.getProductCategories()
    this.loadUsers(connectionString)
    this.getStages(connectionString);
  }
  loadPartnerOwners(partnerId: string) {
    //needs to append partnerId
    this.AuthService.get(`partners/owners?partner_id=${partnerId}`).subscribe(
      (result: any) => {
        this.partnerOwners = [...this.partnerOwners, ...result.data];
      },
      (err: any) => {
        this.partnerOwners = null;
      }
    );
  }
  selfPartners: any = [];
  loadPartners(partner_id?) {
    let apiString = '';
    if (this.solutionId && partner_id) {
      apiString = 'partners?partner_id=' + partner_id
    } else {
      apiString = 'partners'
    }
    this.AuthService.get(`${apiString}`).subscribe(
      (result: any) => {
        if (this.defaultPartnerID) {
          this.solution.partner2_owner = this.defaultPartnerEmail
          this.partners = result.data;
          if (this.solutionId && partner_id) {
            this.partners = [...this.partners, ...result.data]
          } else {
            this.selfPartners = result.data;
            this.partners = result.data;
            this.userSubscription.create_partner = this.getAccess('partner');
            if (!this.userSubscription.create_partner) {
              $('#partnerButton_1').addClass('no-partner');
              $('#partnerButton_2').addClass('no-partner');
            }
          }
          this.partners = this.partners.filter((v, i, a) => {
            if (v.partner_id === this.userData.partner.partner_id) {
              return false;
            } else {
              return (a.findIndex(t => { return (t.partner_id === v.partner_id) }) === i)
            }
          });
          this.partnerOptions = this.partners.map(
            (partner) => {
              if (partner.partner_id === this.defaultPartnerID) {
                this.solution.partner2_id = partner.partner_id
                this.createSolutionForm.patchValue({ partners: [this.defaultPartnerID] })
              }
              return { id: partner.partner_id, name: partner.partner_name };
            }
          );
          this.cdRef.detectChanges();
          $('#partnerOwner').attr('disabled', 'true');
          $('#partnerName').attr('disabled', 'true');
          console.log(this.partnerOptions)
          this.onPartnerSelect();
        } else {
          if (this.solutionId && partner_id) {
            this.partners = [...this.partners, ...result.data]
          } else {
            this.partners = result.data
            this.selfPartners = result.data;
            this.userSubscription.create_partner = this.getAccess('partner');
            if (!this.userSubscription.create_partner) {
              $('#partnerButton_1').addClass('no-partner');
              $('#partnerButton_2').addClass('no-partner');
            }
          }
          this.partners = this.partners.filter((v, i, a) => {
            if (v.partner_id === this.userData.partner.partner_id) {
              return false;
            } else {
              return (a.findIndex(t => { return (t.partner_id === v.partner_id) }) === i)
            }
          });
          this.partnerOptions = this.partners.map(
            (partner) => {
              return { id: partner.partner_id, name: partner.partner_name };
            }
          );
          if (!this.solutionId) {
            let owner_partner_ids = this.userDataPartners.map((a) => a.partner.owner_partner_id).filter(a => a && a != this.userData.partner.partner_id);
            console.log(owner_partner_ids);
            if (owner_partner_ids.length) {
              if (owner_partner_ids.length == 1) {
                this.solution.partners = [owner_partner_ids[0]]
                this.createSolutionForm.patchValue({ partners: this.solution.partners })
                this.getProductCategories()
              }
            }
          }
          this.cdRef.detectChanges();
        }
        console.log(this.partnerOptions)
      },
      (err: any) => {
        this.partners = null;
      })
  }
  loadUsers(partners_list: string) {
    partners_list = this.userData.partner.partner_id + (partners_list ? ',' + partners_list : partners_list);
    this.AuthService.get(`partners/users?partner_id=${partners_list}`).subscribe(
      (result: any) => {
        let filteredList = result.data.filter(
          (user: any) => { return user.email != this.userPartnerEmail })
        this.partnerTeamOptions = filteredList.map(
          (partnerUser) => {
            return { id: partnerUser.email + '~3', name: partnerUser.email };
          }
        );
        this.domainList = filteredList.map(partnerUser => { return partnerUser.email_domain });
      },
      (err: any) => {
        this.partnerUsers = [];
      }
    );
  }
  onChange(event) {
    this.selectedTeamMembers = []
    this.partnerUsersModel.forEach(t => {
      let values = t.split('~')
      if (values[0] != '') {
        this.selectedTeamMembers.push({ email: values[0], user_role: '3' })
      }
    });
    console.log("this.connection:", this.selectedTeamMembers);
  }
  downloadFile(file) {
    location.href = this.AuthService.baseUrl + 'download?file=' + file.path
  }
  //************** Invite teams *************//
  invitedTeam: any[] = [];
  //********** save team *************//
  saveTeam($event) {
    if ($event) {
      this.invitedTeam = $event;
    }
    $('#teamModal').modal('hide');
  }
  domainMatchError: boolean = false;
  domainChange($event) {
    this.domainMatchError = $event;
    this.cdRef.detectChanges();
  }
  closeTeam() {
    $('#teamModal').modal('hide');
  }
  partnerMatchError: boolean = false;
  getPartnerMatch($event) {
    this.partnerMatchError = $event;
    this.cdRef.detectChanges();
  }
  openTeam() {
    $('#teamModal').modal('show');
  }
  //************** Invite teams end *************//
  //************** add both teams ***********//
  openConfirmModal(cat, prod) {
    this.errors = {}
    console.log(cat, prod)
    if (cat && prod) {
      $('#myModal').modal('hide');
      $('#myModalcnfrm').modal('show');
    } else {
      if (!cat) {
        this.errors.category = 'Category is required.'
      }
      if (!prod) {
        this.errors.product = 'Product is required.'
      }
    }
  }
  confirmProduct(cat, prod) {
    this.errors = {}
    if (!this.isProgress) {
      this.isProgress = true;
      if (cat && prod) {
        this.AuthService.add(`product_categories`, { category_name: cat }).subscribe(
          (resultCat: any) => {

            this.AuthService.add(`products`, { product_name: prod, category_id: resultCat.data.category_id }).subscribe(
              (result: any) => {
                this.isProgress = false;
                this.solution.product_category.push(resultCat.data.category_name);
                this.getProductCategories()
                this.solution.product = result.data.product_name;
                // this.getProducts(resultCat.data)
                this.createSolutionForm.patchValue({ product_category: resultCat.data.category_name });
                this.createSolutionForm.patchValue({ product: result.data.product_name });
                $('#myModalcnfrm').modal('hide')
              },
              (err: any) => {
                this.isProgress = false;
              }
            );
          },
          (err: any) => {
            this.isProgress = false;
          }
        );
      } else {
        this.isProgress = false;
        if (!cat) {
          this.errors.category = 'Category is required.'
        }
        if (!prod) {
          this.errors.product = 'Product is required.'
        }
      }
    }
  }
  handleFileSelect(evt) {
    this.errors.document = '';
    let files = evt.target.files;
    let file = files[0];
    console.log(file)
    this.errors.image = '';
    if (files && file) {
      if (file.size <= 5000000) {
        // let reader = new FileReader();
        this.file = file;
      } else {
        evt.target.value = ''
        this.errors.document = 'Image should be less than 5mb';
      }
    }
  }
  isProgress: any = false
  addDocument(type) {
    if (!this.isProgress) {
      this.isProgress = true;
      let formData = new FormData()
      formData.append('file', this.file)

      this.AuthService.upload('upload', formData, (err) => {
        this.isProgress = false
        console.log(err)
      }).subscribe((a: any) => {
        this.isProgress = false
        console.log(a)
        if (this.solution.documents && this.solution.documents.length)
          this.solution.documents.push({
            "fileName": a.originalname,
            "path": a.key,
            "createdDate": new Date(),
            "type": type
            //  filename: this.file.name, docType: type
          });
        else {
          this.solution.documents = this.solution.documents || []
          this.solution.documents.push({
            "fileName": a.originalname,
            "path": a.key,
            "createdDate": new Date(),
            "type": type
            // filename: this.file.name, docType: type, date: new Date() 
          });
        }
        this.files.push(this.file);
        console.log(this.files, this.solution)
        $('#docModal').modal('hide');
        $('input[name="file_upload"').val('');
        $('input[name="file_name"').val('');
      })
    }
  }
  selectedDocument: any = '';
  removeDoc(i, isDelete = false) {
    if (isDelete) {
      this.solution.documents.splice(i, 1)
      $('#myModalcnfrmDelete').modal('hide');
    }
    else {
      this.selectedDocument = i;
      $('#myModalcnfrmDelete').modal('show');
    }
  }

  openConfirmPopup() {
    if (this.createSolutionForm.valid && !this.domainMatchError) {
      if (!this.solutionId) {
        let owner_partner_ids = this.userDataPartners.map((a) => a.partner.owner_partner_id).filter(a => a && a != this.userData.partner.partner_id);
        console.log(owner_partner_ids);
        if (owner_partner_ids.length && !this.createSolutionForm.value.partners.length) {
          this.formError = true;
          this.partner_error = true;
        } else {
          this.formError = false;
          $('#confirmPopup').modal('show');
        }
      } else {
        this.formError = false;
        $('#confirmPopup').modal('show');
      }
    } else {
      this.formError = true;
    }
  }
  closeConfirmPopup() {
    $('#confirmPopup').modal('hide')
  }

  myFunction() {
    console.log("hi")
  }
  submit() {
    if (!this.isProgress) {
      this.isProgress = true
      this.createSolutionForm.patchValue({ documents: this.solution.documents });
      console.log(this.createSolutionForm.value)
      if (this.createSolutionForm.valid) {
        this.formError = false;
        // let sticker = this.createSolutionForm.value['start_date'].split('-')
        // let newDate = new Date(Number(sticker[0]), Number(sticker[1]) - 1, Number(sticker[2]))
        this.final_data = this.createSolutionForm.value
        let date = this.final_data.start_date.split('/')
        this.final_data.start_date = new Date(date[2] + '-' + date[0] + '-' + date[1])
        // this.final_data.end_date = this.formatDate(this.addDays(Number(this.createSolutionForm.value['duration']), newDate));
        this.sortTeamMembers()
        delete this.final_data.teams
        delete this.final_data.selectedTeam
        console.log(this.final_data)
        if (this.solutionId) {
          this.final_data.id = this.solutionId
        }
        if (!this.solution.id) {
          this.final_data.created_date = new Date()
        } else {
          if (!this.solution.created_date) {
            this.final_data.created_date = new Date()
          }
          this.final_data.updated_date = new Date()
        }
        if (this.final_data.partners && this.final_data.partners.length > 0) {
          this.final_data.partners = [...[this.userData.partner.partner_id], ...this.final_data.partners];
        } else {
          this.final_data.partners = [...[this.userData.partner.partner_id]];
        }
        this.closeConfirmPopup();
        $('#successModal').modal('show')
        this.AuthService[this.solution.id ? 'update' : 'add']('solutions' + (this.solution.id ? '/' + this.solutionId : ''), this.final_data).subscribe((result: any) => {
          this.isProgress = false
          this.displayId = result.data.id
          this.displayResult = 'success';
          this.CommonEmitterService.updateObjectCount.next();
        }, (err: any) => {
          this.isProgress = false
          this.errorMessage = err.error.meta.message
          this.displayResult = 'failure'
          console.log(err);
        });

      } else {
        this.isProgress = false
        console.log(this.createSolutionForm)
        this.formError = true;
      }
    }
  }
  applyDropdown() {
    console.log(this.solution.additional)
    if (this.solution.additional) {
      setTimeout(() => {
        $('.additiona-box select').dropdown();
      }, 100)

    }
  }
  addDays(days, newDate) {
    newDate.setDate(newDate.getDate() + days);
    return newDate
  }
  formatDate(date) {
    return date.getFullYear() + '-' + getMonth() + '-' + date.getDate()
    function getMonth() {
      let month = date.getMonth() + 1
      if (month < 10) {
        return "0" + month
      } else return month
    }
  }
  sortTeamMembers() {
    let final_team: any[] = []
    final_team = this.invitedTeam.filter(member => { return member.email != '' })
    console.log(final_team, this.invitedTeam, this.selectedTeamMembers, this.final_data)
    this.selectedTeamMembers.map((eachMember) => {
      final_team.push({ email: eachMember.email, user_role: 3 })
    })
    this.final_data.team = final_team
  }
  goBack() {
    $('.modal').modal('hide')
    let previousUrl = this.navHelper.getUrl()
    // let redirect = localStorage.getItem('redirect2')
    console.log(previousUrl)
    // if ((previousUrl && previousUrl != this.router.url) || redirect) {
    if (previousUrl && (previousUrl != this.router.url)) {
      // this.router.navigate([redirect || previousUrl])
      if (previousUrl == '/dashboard') {
        this.router.navigate([previousUrl, 'solutions']);
      } else {
        this.router.navigate([previousUrl])
      }
    } else {
      this.router.navigate(['/solution-dashboard'])
    }
  }
  closeModal() {
    this.displayResult = ''
    $('#successModal').modal('hide');
  }
  getAccess(type): boolean {
    if (type == 'partner') {
      if (this.userSubscription.type == 'subscriber') {
        if (this.userSubscription.data.no_partner == null) {
          return true;
        } else if (this.partners.length < this.userSubscription.data.no_partner) {
          return true;
        } else {
          return false;
        }
      } else if (type == 'partner') {
        if (this.userSubscription.data && this.userSubscription.data.partner_by_partner) {
          return true;
        }
        return false;
      }
    } else if (type == 'user') {
      if (this.userSubscription.type == 'subscriber') {
        if (this.userSubscription.data.no_user == null) {
          return true;
        } else if (this.userSubscription.data.no_user < this.partners.length) {
          return true;
        } else {
          return false;
        }
      } else if (type == 'partner') {
        if (this.userSubscription.data && this.userSubscription.data.user_by_partner) {
          return true;
        }
        return false;
      }
    }
  }
}
