import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Connection } from '../../models/connection.model';
import { AuthService } from '../../services/auth.service';
import { CommonEmitterService } from '../../services/common-emitter';
import { NavigationHelperService } from '../../services/navigation-helper.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { RxwebValidators } from '@rxweb/reactive-form-validators';


declare var $: any
@Component({
  selector: 'app-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.css']
})

export class BrandComponent implements OnInit {
  imageError: any = '';
  file: File;
  isProgress: any = false;
  brandData: any = {};
  initPath = this.AuthService.baseUrl
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private AuthService: AuthService,
    private navHelper: NavigationHelperService,
    private CommonEmitter: CommonEmitterService,
  ) {
    if (this.navHelper.navigationTracker.length == 0) {
      this.navHelper.setUrl(this.router.url)
    }
  }
  handleFileSelect(evt) {
    this.imageError = { error: false, message: '' };
    var _URL = window.URL || window.webkitURL;
    let files = evt.target.files;
    let file = files[0];
    let type = file.type.split('/')[0]
    if (type == 'image') {
      if (files && file) {
        if (file.size <= 5000000) {
          this.file = file;
          if (!this.isProgress) {
            this.isProgress = true;
            let formData = new FormData()
            formData.append('file', this.file)
            this.AuthService.upload('upload', formData, (err) => {
              this.isProgress = false
              console.log(err)
            }).subscribe((a: any) => {
              this.isProgress = false
              console.log(a)
              this.brandData.logo = a.key;
              $('input[name="file_upload"').val('');
              $('input[name="file_name"').val('');
            })
          }
        } else {
          evt.target.value = ''
          this.imageError = { error: true, message: 'Image size should be less than 5mb (5000000 bytes)' };
        }
      }
    }
    else {
      evt.target.value = ''
      this.imageError = { error: true, message: 'Only image files are allowed' };
    }
  }
  openUpload() {
    $('#profile_pic_upload').click();
  }
  user: any;
  getUserDetails() {
    this.AuthService.get("user/me", () => { }).subscribe(
      (result: any) => {
        console.log("header says", result);
        this.user = result.data[0];
        this.getDefaultData();
      },
      err => {
        console.log(err);
      }
    );
  }
  customerData: any = {};
  getDefaultData(partner_id?: string) {
    let apiString = 'custom_data'
    if (partner_id && partner_id != '') {
      apiString = apiString + '?partner_id=' + partner_id
    }
    this.AuthService.get(`${apiString}`, (err) => {
      console.log(err);
    }).subscribe((result: any) => {
      this.customerData = result.data;
      this.brandData = {
        _id: this.customerData._id,
        partner_id: this.user.partner.partner_id,
        brand: this.customerData.brand,
        company: this.customerData.company,
        term_condition_text: this.customerData.term_condition_text,
        logo: this.customerData.logo
      }
    }, (err: any) => {
      // this.solutionStages = [];
      console.log(err);
    });
  }
  updateBrand() {
    this.AuthService.update('custom_data/' + this.brandData._id + '/update', this.brandData).subscribe((result: any) => {
      $('.modal').modal('hide');
      this.getDefaultData()
      this.CommonEmitter.updateBrand();
    })
  }
  ngOnInit(): void {
    this.getUserDetails();
  }
}
