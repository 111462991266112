import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { CommonEmitterService } from '../services/common-emitter';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { LocalStorageService } from '../services/local-storage.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';

declare var $: any
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  // isSideBar: any = true
  private connections: any; //TODO: create Model
  newUser: boolean;
  userInfo: any;
  loggedIn: any;
  app_version: string = '';
  sanitizedPolicyUrl: SafeResourceUrl;
  sanitizedTermsUrl: SafeResourceUrl;
  timedOut = false;
  userData: any = {};
  is_subscriber: any = false;
  constructor(
    private idle: Idle,
    private router: Router,
    private AuthService: AuthService,
    private CommonEmitterService: CommonEmitterService,
    private LocalStorageService: LocalStorageService,
    public sanitizer: DomSanitizer
  ) {
    this.userData = localStorage.getItem('userData');
    this.userData = JSON.parse(this.userData);
    this.is_subscriber = this.userData ? this.userData.subscription_type_data ? true : false : false;
    this.CommonEmitterService.getMessage().subscribe(data => {
      if (data.app_version) {
        this.app_version = data.app_version
      }
      if (data.saveUserData) {
        this.LocalStorageService.put({ name: 'userData', value: data.saveUserData });
        this.userData = localStorage.getItem('userData');
        this.userData = JSON.parse(this.userData);
        this.is_subscriber = this.userData ? this.userData.subscription_type_data ? true : false : false;
      }
    });
    this.updateObjectCount();
    this.CommonEmitterService.updateObjectCount.subscribe(item => {
      this.updateObjectCount();
    })
    this.loggedIn = (localStorage.getItem('isLogin') != null) ? JSON.parse(localStorage.getItem('isLogin')) : false;
    localStorage.removeItem('isLogin');
  }
  objectCount: number = 0;
  updateObjectCount() {
    this.planCount();
    this.oppCount();
    this.solCount();
  }
  planCount() {
    this.AuthService.get('plans', () => { }).subscribe((result) => {
      if (result.data) {
        this.objectCount += result.data.length
      } else {
        this.objectCount += 0;
      }
    });
  }
  oppCount() {
    this.AuthService.get('opportunities', () => { }).subscribe((result) => {
      if (result.data) {
        this.objectCount += result.data.length
      }
    });
  }
  solCount() {
    this.AuthService.get('solutions', () => { }).subscribe((result) => {
      if (result.data) {
        this.objectCount += result.data.length
      }
    });
  }
  ngAfterViewInit() {
  }
  getUserDetails() {
    let userDetails = this.LocalStorageService.get({ name: 'userData' });
    return JSON.parse(userDetails);
  }
  async ngOnInit() {
    if (!this.AuthService.isLoggedIn()) {
      this.router.navigate(['/sign-in']);
    }
    if (this.AuthService.isLoggedIn()) {
      this.setUpInactivity();
    }
    // ------------------------------------------------------- //
    // Sidebar Functionality
    // ------------------------------------------------------ //
    $('.sidebar-toggle').on('click', function () {
      $(this).toggleClass('active');

      $('#sidebar').toggleClass('shrinked');
      $('.page-content').toggleClass('active');
      $(document).trigger('sidebarChanged');

      if ($('.sidebar-toggle').hasClass('active')) {
        $('.navbar-brand .brand-sm').addClass('visible');
        $('.navbar-brand .brand-big').removeClass('visible');
        // $(this).find('i').attr('class', 'fa fa-long-arrow-right');
      } else {
        $('.navbar-brand .brand-sm').removeClass('visible');
        $('.navbar-brand .brand-big').addClass('visible');
        // $(this).find('i').attr('class', 'fa fa-long-arrow-left');
      }
    });
    this.getAccess();
  }
  modalShow(modal: string) {
    $(`#${modal}`).modal('show')
  }
  isAdmin: boolean = false;
  getAccess() {
    this.AuthService.get("user/me", () => { }).subscribe(
      (result: any) => {
        if (this.loggedIn) {
          this.userInfo = result.data[0];
          this.sendNotification(result.data[0], 'logged in');
        }
        this.isAdmin = result.data[0].admin_user;
      },
      err => {
        console.log(err);
      }
    );
  }
  capitalize(val: string) {
    return val.charAt(0).toUpperCase() + val.slice(1);
  }
  sendNotification(userData, message) {
    let payLoad = {
      message: `${this.capitalize(userData.first_name.toLowerCase())} ${this.capitalize(userData.last_name.toLowerCase())} has logged in.`,
      customData: {
        user_name: `${this.capitalize(userData.first_name.toLowerCase())} ${this.capitalize(userData.last_name.toLowerCase())}`,
        partner_name: userData.partner.partner_name,
        action: message,
        created_partner: null
      },
      moduleName: 'User'
    }
    this.AuthService.add('notifications', payLoad).subscribe(
      (result) => {
        console.log(result);
      }
    )
  }
  setUpInactivity() {
    this.idle.setIdle(1740);
    this.idle.setTimeout(60);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.onTimeout.subscribe(() => {
      console.log('timedOut');
      this.AuthService.get("user/logout").subscribe(
        () => {
          this.idle.stop();
          this.CommonEmitterService.sendMessage("showLogOutPage", true);
        },
        err => {
        }
      );
    })
    this.resetInActivity();
  }
  resetInActivity() {
    this.idle.watch();
    this.timedOut = false;
  }
  ngOnDestroy(): void {
    this.idle.stop();
  }
}
