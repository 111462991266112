<div class="modal-content">
  <!-- Modal body -->
  <form [formGroup]="teamForm">
    <div class="modal-body mrgbtm" formArrayName="team">
      <div class="mrgbtm row">
        <h5 class="col-sm-8 fz-14 head_type">
          Team Members:
        </h5>

        <!-- <div class="col-sm-3">
          <h5 class="head_type fz-14">
            Set Access:
          </h5>
        </div> -->
        <div class="col-sm-4"></div>
      </div>
      <div id="teamList" style="max-height: 380px; overflow: auto; overflow-x: hidden;">
        <div *ngFor="let control of teamArray.controls; let i = index" [id]="'team' + i" [formGroupName]="i">
          <div class="row mrgbtm">
            <div class="col-sm-8">
              <input class="mrgbtm" type="text" placeholder="Enter Email Id" formControlName="email"
                (keyup)="submit()" />
              <div style="text-align: left;" *ngIf="
                  (control.get('email').dirty ||
                    control.get('email').touched) &&
                  control.get('email').errors
                ">
                <div class="errorMessage" *ngIf="control.get('email').errors.required">
                  Email is required
                </div>
                <div class="errorMessage" *ngIf="control.get('email').errors.pattern">
                  Enter valid email id
                </div>
                <div class="errorMessage" *ngIf="control.get('email').errors.unique">
                  Email already exists
                </div>
                <div class="errorMessage" *ngIf="control.get('email').errors.isPartner">
                  Cannot add partner owner again
                </div>
                <div class="errorMessage" *ngIf="control.get('email').errors.validDomain">
                  User Email domain does not match partner owner email domain.
                </div>
                <div class="errorMessage" *ngIf="control.get('email').errors.notAllowed">
                  You have reached the limit on users allowed for this partner. Please contact your admin.
                </div>
              </div>
            </div>
            <!-- <div class="col-sm-3">
              <div style="
                  display: flex;
                  justify-content: space-between;
                  padding-right: 10px;
                ">
                <div>
                  <label [style.pointer-events]="onlySuperUsers ? 'none' : 'auto'">
                    <input type="radio" class="hide" value="1" formControlName="user_role" />
                    <i data-toggle="tooltip" data-placement="top" title="View" class="fa fa-fw fa-eye"></i>
                  </label>
                </div>
                <div>
                  <label [style.pointer-events]="onlySuperUsers ? 'none' : 'auto'">
                    <input type="radio" class="hide" formControlName="user_role" value="2" />
                    <i data-toggle="tooltip" data-placement="top" title="Edit" class="fa fa-fw fa-pencil"></i>
                  </label>
                </div>
                <div>
                  <label>
                    <input type="radio" class="hide" formControlName="user_role" value="3" />
                    <i data-toggle="tooltip" data-placement="top" title="Invite" class="fa fa-fw fa-plus-circle"></i>
                  </label>
                </div>
              </div>
            </div> -->
            <div class="col-sm-4 remove-member-btn">
              <button class="custom-remove" (click)="removeTeamMember(i)">
                REMOVE
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mrgbtmn">
      <div class="col-md-12">
        <p style="cursor: pointer;" (click)="addTeamMember()">Add More Users</p>
      </div>
    </div>
    <div class="row" style="margin-bottom: 16px;">
      <div class="col-md-12">
        <div align="center">
          <button type="button" (click)="closeModalEmit()" class="btn cnclbtn">
            CANCEL
          </button>
          <button (click)="submit()" type="button" class="btn addbtn">
            OK
          </button>
        </div>
      </div>
    </div>
  </form>
</div>