<div class="pad_div">
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <h3>Achievements</h3>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div id="container"></div>
    </div>
  </div>
  <div class="mrgbtm row">
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="mrgbtm row">
        <div class="col-4">
          <h4 class="head_type">Goal:</h4>
        </div>
        <div class="col-8">
          <a class="data_field_a">{{ plan.target | number }}</a>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="mrgbtm row">
        <div class="col-4">
          <h4 class="head_type">Team Members:</h4>
        </div>
        <div class="col-8">
          <div *ngFor="let item of plan.team; let i = index" [ngStyle]="
              i > 1
                ? viewAll
                  ? { display: 'block' }
                  : { display: 'none' }
                : { display: 'block' }
            ">
            <a class="data_field_a">{{ item.email }}</a> <br />
          </div>
          <p *ngIf="plan.team ? plan.team.length > 2 : false" style="padding-top: 5px;" (click)="viewAll = !viewAll">
            {{ viewAll ? "Less.." : "More.." }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="mrgbtmn row">
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <h4 class="head_type mrgbtm">Description:</h4>
      <a class="data_field_a">
        {{ plan.description }}
      </a>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <h4 class="head_type mrgbtm">Partners:</h4>
      <a *ngFor="let partner of listOfPartners;let i=index;" class="data_field_a">
        <span style="padding-right: 10px;">{{ partner }}{{ i<listOfPartners.length-1 ? ', ' : ''}}</span>
      </a>
      <a *ngIf="!listOfPartners.length" class="data_field_a">
        <span style="padding-right: 10px;">Internal</span>
      </a>
    </div>
  </div>
  <div class="row mrgbtm" style="margin-top: 5px;">
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <h4 class="head_type mrgbtm">Timeline:</h4>
      <div class="progress">
        <div class="progress-bar" role="progressbar" [ngStyle]="{ width: (completedDays / totalDays) * 100 + '%' }">
        </div>
      </div>
    </div>
  </div>
  <div class="row col-12 text-right">
    <a class="float-right col data_field_a" href="javascript:;" (click)="gotoUpdatePlan()">Update Plan</a>
  </div>
</div>