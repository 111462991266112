<div class="row">
  <div class="col" style="text-align: right; padding: 25px 5%;"></div>
</div>
<div class="row col-12 mt-4">
  <!-- <div class="col" style="text-align: left; padding: 25px 5%;">
    <a class="link" style="cursor: pointer;" (click)="filter={}">Reset Filter</a>
  </div> -->
  <div class="col-11 mt-4" style="text-align: right;">
    <a class="link light_blue" routerLink="/admin/add-user" style="font-size: 20px;">Add Users</a>
  </div>
  <div class="col-1">
  </div>
</div>
<div class="col-12" style="align-items: center;justify-content: center;">
  <div class="table_wrapper mt-2">
    <table class="table table-condensed table-bordered">
      <thead>
        <th scope="col" class="table-secondary">
          <div class="table-header">
            <div>Organization</div>
            <!-- changeSort('partner_name') -->
            <div (click)="filter.showOrg=!filter.showOrg; filter.partner_name=[];filterList()" style="cursor: pointer;">
              <i class="fa fa-filter"></i>
              <!-- <span class="material-icons">
              sort
            </span> -->
            </div>
            <div (click)="changeSort('partner_name')" style="cursor: pointer;margin: 0 0 0 -15px;">
              <i class="fa fa-sort"></i>
              <!-- <span class="material-icons">
              sort
            </span> -->
            </div>
          </div>
          <div class="row text-center" *ngIf="filter.showOrg">
            <ss-multiselect-dropdown [options]="organisations" [(ngModel)]="filter.partner_name"
              [settings]="multiSelectionSettings" (ngModelChange)="filterList()" class="col-12">
            </ss-multiselect-dropdown>
          </div>
        </th>
        <th scope="col" class="table-secondary">
          <div class="table-header">
            <div>Email</div>
            <div (click)="changeSort('email')" style="cursor: pointer;margin: 0 0 0 -15px;">
              <i class="fa fa-sort"></i>
              <!-- <span class="material-icons">
              sort
            </span> -->
            </div>
          </div>
        </th>
        <th scope="col" class="table-secondary">
          <div class="table-header">
            <div>Super User</div>
            <!-- changeSort('super_owner') -->
            <div (click)="filter.showSuper = !filter.showSuper; filter.super_owner=[];filterList()"
              style="cursor: pointer;">
              <i class="fa fa-filter"></i>
              <!-- <span class="material-icons">
              sort
            </span> -->
            </div>
            <div (click)="changeSort('super_owner')" style="cursor: pointer;margin: 0 0 0 -15px;">
              <i class="fa fa-sort"></i>
              <!-- <span class="material-icons">
              sort
            </span> -->
            </div>
          </div>
          <div class="row text-center" *ngIf="filter.showSuper">
            <ss-multiselect-dropdown [options]="yesno" [(ngModel)]="filter.super_owner"
              [settings]="multiSelectionSettings" (ngModelChange)="filterList()" class="col-12">
            </ss-multiselect-dropdown>
          </div>
        </th>
        <th scope="col" class="table-secondary">
          <div class="table-header">
            <div>Admin User</div>
            <!-- changeSort('admin_user') -->
            <div (click)="filter.showAdmin = !filter.showAdmin; filter.admin_user=[];filterList()"
              style="cursor: pointer;">
              <i class="fa fa-filter"></i>
              <!-- <span class="material-icons">
              sort
            </span> -->
            </div>
            <div (click)="changeSort('admin_user')" style="cursor: pointer;margin: 0 0 0 -15px;">
              <i class="fa fa-sort"></i>
              <!-- <span class="material-icons">
              sort
            </span> -->
            </div>
          </div>
          <div class="row text-center" *ngIf="filter.showAdmin">
            <ss-multiselect-dropdown [options]="yesno" [(ngModel)]="filter.admin_user"
              [settings]="multiSelectionSettings" (ngModelChange)="filterList()" class="col-12">
            </ss-multiselect-dropdown>
          </div>
        </th>
        <th scope="col" class="table-secondary">Actions</th>
      </thead>
      <tbody>
        <tr *ngIf="this.allUsers.length == 0">
          <td align="center">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </td>
          <td>
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </td>
          <td>
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </td>
          <td>
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </td>
          <td>
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </td>
        </tr>
        <tr class="table-content" *ngFor="let user of allUsers; index as i">
          <!-- <td>{{user.}}</td> -->
          <td><label class="dark_gray">{{ user.partner_name | titlecase }}</label></td>
          <td><label class="dark_gray">{{ user.email }}</label></td>
          <td><label class="dark_gray">{{ user.super_user ? "Y" : "N" }}</label></td>
          <td><label class="dark_gray">{{ user.admin_user ? "Y" : "N" }}</label></td>
          <td>
            <span (click)="editUser(i, user)" class="material-icons">
              create
            </span>
            <span (click)="openDeleteModal(user)" class="material-icons">
              delete
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="modal" data-backdrop="static" id="editModal">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <form style="padding: 26px;">
          <div class="row mrgbtmn">
            <div class="col">
              <h5>User Email</h5>
            </div>
            <div class="col">
              <h5>Super User(Y/N)</h5>
            </div>
            <div class="col">
              <h5>Admin User(Y/N)</h5>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <h5>{{ selectedUserEmail }}</h5>
            </div>
            <div class="col">
              <span style="cursor: pointer;" (click)="changeAccess('super_owner')">
                <input name="checkbox-1" type="checkbox" style="pointer-events: none;"
                  [checked]="selectedUserSuperOwner" />
              </span>
            </div>
            <div class="col">
              <span style="cursor: pointer;" (click)="changeAccess('admin_user')">
                <input name="checkbox-2" type="checkbox" style="pointer-events: none;"
                  [checked]="selectedUserAdminUser" />
              </span>
            </div>
          </div>
          <!-- <div class="row">
            <div class="col">
              <div class="btn-group-1">
                <button class="btn btn-primary submit" (click)="closeEdit()">
                  Close
                </button>
                <button class="btn btn-primary submit" (click)="submitEdit()">
                  Save Changes
                </button>
              </div>
            </div>
          </div> -->
        </form>
      </div>
      <div class="modal-footer" style="text-align: center; display: inline;">
        <button type="button" (click)="closeEdit()" class="btn cnclbtn">
          CLOSE
        </button>

        <button type="button" class="btn addbtn" (click)="submitEdit()">
          SAVE CHANGES
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="errorModal">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row">
          <div class="col errorMessage" *ngIf="errorId == 1">
            Neither Super user nor Admin user can be assigned
          </div>
          <div class="col errorMessage" *ngIf="errorId == 2">
            Cannot make changes to your access
          </div>
          <div class="col errorMessage" *ngIf="errorId == 3">
            Cannot edit or delete your own account
          </div>
        </div>
      </div>
      <div class="modal-footer" style="text-align: center; display: inline;">
        <button type="button" (click)="closeErrorModal()" class="btn addbtn">
          OK
        </button>
      </div>
    </div>
  </div>
</div>



<div id="deleteModal" class="modal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row mrgbtmn" style="color: #e61610;">
          <div class="col">
            <h4>
              <span class="material-icons" style="color: #e61610; position: relative; bottom: -3px;">
                report_problem
              </span>
              Are you sure?
            </h4>
          </div>
        </div>

        <div class="row mrgbtmn" style="color: #e61610;">
          <div class="col">
            <h6>
              {{ this.selectedUserEmail | titlecase }} will be deactivated and
              this user will no longer be able to access plans, solutions,
              opportunities or activities shared with them.
            </h6>
          </div>
        </div>
        <div>
        </div>
      </div>
      <!-- Modal footer -->
      <div class="modal-footer" style="text-align: center; display: inline;">
        <button type="button" (click)="closeDeleteModal()" class="btn cnclbtn">
          CANCEL
        </button>

        <button type="button" class="btn addbtn" (click)="deleteUser()">
          DELETE USER
        </button>
      </div>
    </div>
  </div>
</div>