export class Connection {
    public partner2_id;
    public team: any[];
    private _model: string[];
    public partner2_owner;
    public name;
    public revenue;
    public people;
    public opportunities;
    public pipeline;
    public connection_id

    constructor() {
        this.partner2_id = "";
        this.partner2_owner = ""
        this.team = [];
        this._model = [];
    }

    get model(): any[] {
        return this.model;
    }

    set model(val) {
        if (val && val.length != 0) {
            this._model = val;
            this.team = val.map(t => {
                return { first_name: '', last_name: '', email: '', role: '' }
            })
        }
    }
}
