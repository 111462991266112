<div class="row">
  <div class="col" style="text-align: right; padding: 25px 5%;">
    <br />
  </div>
</div>
<div class="table_wrapper">
  <table class="table table-condensed table-bordered">
    <thead>
      <th scope="col" class="table-secondary">
        <div class="table-header">
          <div>Organization Name</div>
          <div (click)="filter.showOrg=!filter.showOrg; filter.partner_name=[];filterList()" style="cursor: pointer;">
            <i class="fa fa-filter"></i>
            <!-- <span class="material-icons">
            sort
          </span> -->
          </div>
          <div
            (click)="filter.sortOrg=!filter.sortOrg?filter.sortOrg='ASC':filter.sortOrg=='ASC'?'DESC':'ASC';filter.sortEmail=false; filterList()"
            style="cursor: pointer;margin: 0 0 0 -55px;">
            <i class="fa fa-sort"></i>
            <!-- <span class="material-icons">
            sort
          </span> -->
          </div>
        </div>
        <div class="row text-center" *ngIf="filter.showOrg">
          <ss-multiselect-dropdown [options]="organisations" [(ngModel)]="filter.partner_name"
            [settings]="multiSelectionSettings" (ngModelChange)="filterList()" class="col-12">
          </ss-multiselect-dropdown>
        </div>
      </th>
      <th scope="col" class="table-secondary">
        <div class="table-header">
          <div>Partner Owner</div>
          <div
            (click)="filter.sortOrg=false;filter.sortEmail=!filter.sortEmail?filter.sortEmail='ASC':filter.sortEmail=='ASC'?'DESC':'ASC'; filterList()"
            style="cursor: pointer;margin: 0 0 0 -15px;">
            <i class="fa fa-sort"></i>
            <!-- <span class="material-icons">
            sort
          </span> -->
          </div>
        </div>
      </th>
      <th scope="col" class="table-secondary text-center" style="text-align:center">
        <div class="table-header" style="justify-content: center;">
          <div># of Users</div>
        </div>
      </th>
      <th scope="col" class="table-secondary text-center" style="text-align:center">Actions</th>
    </thead>
    <tbody>
      <tr *ngIf="this.allConnections.length == 0 && loadedConnections">
        <td style="text-align: center !important;" colspan="4">
          <span>No Connections to display</span>
        </td>
      </tr>
      <tr *ngIf="this.allConnections.length == 0 && !loadedConnections">
        <td style="text-align: center !important;" colspan="4">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </td>
      </tr>
      <tr class="table-content" *ngFor="let connection of allConnections; index as i">
        <td style="text-align:center !important"><span class="dark_gray">{{ connection.partner_name }}</span> </td>
        <td style="text-align:center !important"><span class="dark_gray">{{ connection.email }}</span> </td>
        <td style="text-align:center !important"><span class="dark_gray">{{ connection.count }}</span> </td>
        <td style="text-align:center !important">
          <span (click)="editUser(i, connection)" class="material-icons">
            create
          </span>
          <span (click)="openDeleteModal(connection)" class="material-icons">
            delete
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="modal" id="editModal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <form>
          <div class="row mrgbtmn" style="margin-top: 10px;">
            <div class="col-5 label">
              <span class="black_text_color">Partner:</span>
            </div>
            <div class="col-7 input">
              <input type="text" style="text-align: start;color: black;" name="owner" disabled
                [value]="selectedUserName" />
            </div>
          </div>
          <div class="row" style="margin-top: 20px;" *ngIf="
              (newName.dirty || newName.touched || showError) && newName.errors
            ">
            <div class="col errorMessage" align="center" *ngIf="newName.errors.required">
              Organization name is required
            </div>
            <div class="col errorMessage" align="center" *ngIf="newName.errors.exists">
              Organization with this name already exists.
            </div>
            <div class="col errorMessage" align="center" *ngIf="newName.errors.ourCompany">
              Cannot use our organization name as partner name.
            </div>
          </div>
          <div class="row mrgbtmn" style="margin-top: 10px;">
            <div class="col-5 label">
              <span class="black_text_color">Partner Owner:</span>
            </div>
            <div class="col-7">
              <span class="black_text_color" style="padding: 0 0 0 13px;">{{ this.selectedOwner }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-5 label">
              <span class="black_text_color">Update Owner:</span>
            </div>
            <div class="col-7 input">
              <input type="text" style="text-align: start;color: black;" name="owner"
                placeholder="Enter new owner email" [formControl]="newOwner" />
            </div>
          </div>
          <div class="row" style="margin-top: 20px;" *ngIf="
              (newOwner.dirty || newOwner.touched || showError) &&
              newOwner.errors
            ">
            <div class="col errorMessage" align="center" *ngIf="newOwner.errors.required">
              Email id is required
            </div>
            <div class="col errorMessage" align="center" *ngIf="newOwner.errors.pattern">
              Enter Valid Email Id
            </div>
            <div class="col errorMessage" align="center" *ngIf="newOwner.errors.invalidDomain">
              New owner email domain must match old domain
            </div>
            <div class="col errorMessage" align="center" *ngIf="newOwner.errors.isPartner">
              Current partner cannot be new partner
            </div>
          </div>
          <div class="row" *ngIf="showError && this.newOwner.invalid">
            <div class="col errorMessage">
              Valid email id is required to save changes.
            </div>
          </div>
          <!-- <div class="row">
            <div class="col">
              <div class="btn-group-1">
                <button class="btn btn-primary submit" (click)="closeEdit()">
                  CANCEL
                </button>
                <button class="btn btn-primary submit" (click)="submitEdit()">
                  UPDATE CONNECTION 
                </button>
              </div>
            </div>
          </div> -->
          <div class="row mrgbtm mt-4">
            <div class="col-md-12">
              <div align="center">
                <button type="button" (click)="closeEdit()" class="btn cnclbtn" data-dismiss="modal">
                  CANCEL
                </button>
                <button type="button" class="btn addbtn" (click)="submitEdit()">
                  UPDATE PARTNER
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>



<div id="deleteModal" class="modal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row mrgbtmn" style="color: #e61610;">
          <div class="col">
            <h4>
              <span class="material-icons" style="color: #e61610; position: relative; bottom: -3px;">
                report_problem
              </span>
              Are you sure?
            </h4>
          </div>
        </div>
        <div class="row mrgbtmn" style="color: #e61610;">
          <div class="col">
            <h6>
              {{ this.selectedUserName | titlecase }} will be deactivated and
              users of this partner will no longer be able to access plans,
              solutions, opportunities or activities shared with them
            </h6>
          </div>
        </div>

        <div class="row mrgbtm mt-4">
          <div class="col-md-12">
            <div align="center">
              <button type="button" (click)="closeDeleteModal()" class="btn cnclbtn" data-dismiss="modal">
                CANCEL
              </button>

              <button type="button" class="btn addbtn" (click)="deleteUser()">
                DELETE PARTNER
              </button>
            </div>
          </div>
        </div>
        <!--<div class="row">
          <div class="col">
            <div class="btn-group-1">
              <button class="btn btn-primary submit" (click)="closeDeleteModal()">
                Cancel
              </button>
              <button class="btn btn-primary submit" (click)="deleteUser()">
                Delete Connection
              </button>
            </div>
          </div>
        </div>-->
        <div></div>
      </div>
    </div>
  </div>
</div>