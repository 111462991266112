import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
// import { Partner } from '../models/partner.model';
import { Connection } from '../models/connection.model';
import { AuthService } from '../services/auth.service';
import { LocalStorageService } from '../services/local-storage.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import * as moment from 'moment';

declare var $: any;
@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.css']
})
export class PartnerComponent implements OnInit {
  @Input() onlyAddForm: boolean;
  partnersList: any;
  userData: any = {};
  defaultPartner: any;
  constructor(
    private formBuilder: FormBuilder,
    private AuthService: AuthService,
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute,
    private location: Location
  ) {
    this.userData = this.localStorageService.get({ name: 'userData' })
    this.userData = this.userData ? JSON.parse(this.userData) : {};
  }
  //********** fetch connections ********//
  getConnections() {
    return new Promise((resolve, reject) => {
      this.AuthService.get('partners').subscribe((result) => {
        resolve(result.data);
      },
        (error) => {
          console.log(error.error.meta.message)
          resolve((<any>[]));
        });
    })
  }
  //*********** fetch table data ***********//
  getPartners() {
    return new Promise((resolve, reject) => {
      this.AuthService.get('partner').subscribe(
        (result) => {
          let list = [];
          if (result.data) {
            list = result.data;
          }
          resolve(list)
          setTimeout(() => {
            $('.ui').dropdown();
          }, 100)
        },
        (error) => {
          console.log(error);
          resolve([]);
        })
    })
  }
  ngOnInit(): void {
    this.initializePage();
  }
  getPartnerIndexes(arr, val) {
    var indexes = [], i;
    for (i = 0; i < arr.length; i++)
      if (arr[i].partner_id === val)
        indexes.push(i);
    return indexes;
  }
  getUserIndexes(arr, val) {
    var indexes = [], i;
    for (i = 0; i < arr.length; i++)
      if (arr[i] === val)
        indexes.push(i);
    return indexes;
  }
  async initializePage() {
    await this.getPartners().then((result: any) => {
      this.partnersList = result;
    })
    this.route.params.subscribe((a) => {
      if (a.partnerId) {
        this.defaultPartner = a.partnerId;
        this.location.replaceState('partner');
      }
    })
  }
  selectedPartner: any = {};
  enableUpdate(updateData) {
    this.selectedPartner = updateData;
    setTimeout(() => {
      $('#AddPartner').modal('show');
    }, 100)
  }
}
