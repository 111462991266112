<div
  style="height: 100%; padding: 0; margin: 0;"
  class="d-flex flex-column justify-content-between align-items-between"
>
  <div
    class="main-container"
    style="background-image: url(assets/background@3x.png);"
  >
    <div style="height: 100%;" class="row">
      <div class="col-lg-3" style="margin-bottom: 10px;">
        <img width="240" src="./assets/ICONS/logo@2x.png" />
      </div>
      <div
        style="display: flex; justify-content: center; align-content: center;"
        class="col-lg-6"
      >
        <div class="d-flex justify-content-center align-items-center">
          <div class="middle-container">
            <div style="padding: 10px; text-align: center;">
              <h4 class="mrngbtm"><strong>Sign Up Completed!</strong></h4>
              <h6>
                Account confirmation mail sent to <strong>{{ email }}</strong
                >. Check your mail to activate account.
              </h6>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <!-- dummy div for Alignment -->
      </div>
    </div>
  </div>
  <footer
    class="footer"
    style="width: 100%; height: 60px; background-color: #44A2F8;"
  ></footer>
</div>
