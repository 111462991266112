import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { PlanInfo } from '../models/plan.model';
import { AuthService } from '../services/auth.service';
import { NavigationHelperService } from '../services/navigation-helper.service';
import { LocalStorageService } from '../services/local-storage.service';
import * as moment from 'moment';
import { CdkTableExporterModule } from 'mat-table-exporter';
import * as jsPDF from 'jspdf';

declare var Highcharts: any;
declare var $: any;
@Component({
  selector: 'app-sales-list',
  templateUrl: './sales-list.component.html',
  styleUrls: ['./sales-list.component.css']
})
export class SalesListComponent implements OnInit, AfterViewInit, CdkTableExporterModule {
  @ViewChild('planSort', { static: false }) planSort: MatSort;
  // @ViewChild('planPaginator', { static: false }) planPaginator: MatPaginator;
  plan: any = [];
  loadedPlans: boolean = false;
  message: string = '';
  planButton: boolean = false;
  totalTargetValue: any = '';
  userPartnerId: any;
  userData: any = {};
  filterData: any = {};
  filterOption: any = { items: [], partners: [], users: [], createdBy: [] };
  type: any = '';
  type2: any = '';
  start_date: any = ''
  end_date: any = ''
  constructor(
    private AuthService: AuthService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private navHelper: NavigationHelperService
  ) {
    this.userData = this.localStorageService.get({ name: 'userData' })
    this.userData = this.userData ? JSON.parse(this.userData) : {}
    if (this.userData.partner) {
      this.userPartnerId = this.userData.partner.partner_id;
    }
    if (this.navHelper.navigationTracker.length == 0) {
      this.navHelper.setUrl(this.router.url)
    }
    this.activeRoute.queryParams.subscribe((a) => {
      this.type = a.type;
      this.type2 = a.type2;
      this.start_date = a.start_date;
      this.end_date = a.end_date;
    });
  }
  public planData = new MatTableDataSource<any>();
  public planColumns = ['id', 'plan_name', 'partners', 'target', 'achievement', 'oppy'];
  ngOnInit(): void {
    // (<any>window).analytics.page();
    if (this.type == 'invitedCustomer' || this.type == 'registeredCustomer') {
      this.planColumns = ['name', 'partner_name', 'is_registered', 'email', 'phone']
    } else if (this.type == 'commission') {
      if (this.userData.email == this.userData.partner.partner_owner) {
        let column = ['partner', 'no_orders', 'no_customer', 'amount', 'commission', 'team_commission'];
        if (this.type2) {
          column = ['partner', 'no_orders', 'no_customer', 'amount', 'team_commission'];
        }
        this.planColumns = column;
      } else {
        this.planColumns = ['partner', 'no_orders', 'no_customer', 'amount', 'commission'];
      }
    } else {

      this.planColumns = ['name', 'status', 'amount', 'date']
    }
    console.log(this.planColumns, this.userData.email == this.userData.partner.partner_owner, this.type2)
    this.getPartners();
    this.getUsers();
    this.getList();
    this.datePickerApply();
  }
  allUsers: any[] = [];
  getUsers() {
    this.AuthService.get('admin/users').subscribe(
      (result) => {
        console.log(result);
        this.allUsers = result.data
      },
      (err) => {
        console.log(err.error);
        this.allPartners = []
      }, () => {
      }
    )
  }
  filter() {
    this.plan.filter((aa) => {
      let status = true;
      if (this.filterData.start_date) {
        if (new Date(aa.end_date).getTime() <= new Date(moment(this.filterData.start_date).startOf('day').format('YYYY-MM-DD h:mm:ss')).getTime()) {
          status = false;
        }
      }
      if (this.filterData.end_date) {
        if (new Date(aa.start_date).getTime() >= new Date(moment(this.filterData.end_date).endOf('day').format('YYYY-MM-DD h:mm:ss')).getTime()) {
          status = false;
        }
      }
      if (this.filterData.team && this.filterData.team.length) {
        let stat = false;
        aa.team.map((aaa) => {
          if (this.filterData.team.indexOf(aaa.email) != -1) {
            stat = true;
          }
        })
        if (!stat) {
          status = false;
        }
      }
      if (this.filterData.createdBy && this.filterData.createdBy.length) {
        let stat = false;
        if (this.filterData.createdBy.indexOf(aa.created_by) != -1) {
          stat = true;
        }
        if (!stat) {
          status = false;
        }
      }
      if (this.filterData.partner && this.filterData.partner.length) {
        let stat = false;
        aa.partners.map((aaa) => {
          if ((this.filterData.partner.length == 1) && (this.filterData.partner.indexOf(this.userPartnerId) != -1)) {
            if ((aa.partners.length == 1) && (aa.partners.indexOf(this.userPartnerId) != -1)) {
              stat = true;
            }
          } else {
            if (this.filterData.partner.indexOf(aaa) != -1) {
              stat = true;
            }
          }
        })
        if (!stat) {
          status = false;
        }
      }
      if (this.filterData.plan && this.filterData.plan.length) {
        let stat = false;
        if (this.filterData.plan.indexOf(aa.id) != -1) {
          stat = true;
        }
        if (!stat) {
          status = false;
        }
      }
      return status;
    });
    $('#filterModal').modal('hide');
  }
  resetFilter() {
    this.filterData = {};
    $('#date1').val('');
    $('#date2').val('');
    this.datePicker1.destroy();
    this.datePicker2.destroy();
    this.datePickerApply();
  }
  datePickerApply() {
    setTimeout(() => {
      this.datePicker1 = $('#date1').datepicker({
        format: 'mm/dd/yyyy',
        close: (e) => {
          let date = $('#date1').val()
          this.filterData.start_date = date;
        }
      });
      this.datePicker2 = $('#date2').datepicker({
        format: 'mm/dd/yyyy',
        close: (e) => {
          let date = $('#date2').val()
          this.filterData.end_date = date;
        }
      });
    }, 100);
  }
  datePicker1: any;
  datePicker2: any;
  ngAfterViewInit(): void {
    this.planData.sort = this.planSort;
    // this.planData.paginator = this.planPaginator;
  }
  openFilterModal() {
    $('#filterModal').modal('show');
  }
  getList() {
    this.AuthService.get('customer/getlist?type=' + this.type + '&start_date=' + this.start_date + '&end_date=' + this.end_date, (err) => {
    }).subscribe(async (result: any) => {
      console.log("result is:", result.data)
      if (result.data) {
        let data = (result.data || []).map((a) => {
          if (a.partnerDetails) {
            a.partnerName = a.partnerDetails.filter((aa) => aa.partner_id != this.userPartnerId).map((aa) => aa.partner_name)
          }
          return a;
        }).sort((a: any, b: any) => {
          var keyA = new Date(a.updated_at || a.created_at || 0),
            keyB = new Date(b.updated_at || b.created_at || 0);
          if (keyA > keyB) return -1;
          if (keyA < keyB) return 1;
          return 0;
        });
        if (this.type == 'commission') {
          let usersList = []
          usersList = result.data.map(eachOpp => { return eachOpp.team_member }).filter((item, i, ar) => ar.indexOf(item) === i);
          let data = []
          usersList.forEach((user, i) => {
            data[i] = []
            data[i].orders = 0
            data[i].customers = 0
            data[i].totalAmount = 0
            data[i].commission = 0
            data[i].team_commission = 0
            result.data.forEach(eachOpp => {
              if (user == eachOpp.team_member) {
                data[i].name = eachOpp.salesman;
                data[i].orders += 1;
                data[i].customers = eachOpp.count;
                data[i].totalAmount += Number(eachOpp.fixed_revenue);
                data[i].team_commission += Number(eachOpp.team_member_percent);
                data[i].commission += Number(eachOpp.partner_owner_percent);
              }
            })
          })
          this.plan = data;
        } else {
          this.plan = data;
        }
        this.planData.data = this.plan;
        // this.plan.forEach((plan) => {
        //   this.filterOption.items = [...this.filterOption.items, { id: plan.id, name: plan.plan_name }];
        //   plan.partnerDetails.map((aa) => {
        //     if (!this.filterOption.partners.filter((a11) => a11.id == aa.partner_id).length) {
        //       // let partner = this.allPartners.find((a) => a.partner_id == aa)
        //       this.filterOption.partners = [...this.filterOption.partners, { id: aa.partner_id, name: aa.partner_id != this.userPartnerId ? aa.partner_name : 'Internal' }]
        //     }
        //   })
        //   this.allUsers.forEach((user) => {
        //     if (!this.filterOption.createdBy.filter((a11) => a11.id == plan.created_by).length) {
        //       if (plan.created_by == user.user_id) {
        //         this.filterOption.createdBy = [...this.filterOption.createdBy, { id: user.user_id, name: user.email }];
        //       }
        //     };
        //   })
        //   if (Array.isArray(plan.team)) {
        //     plan.team.map((aa) => {
        //       if (!this.filterOption.users.filter((a11) => a11.id == aa.email).length) {
        //         this.filterOption.users = [...this.filterOption.users, { id: aa.email, name: aa.email }];
        //       }
        //     })
        //   }
        // })
      } else {
        this.plan = [];
      }
      this.loadedPlans = true;
    }, (err: any) => {
      this.plan = [];
      this.loadedPlans = true;
    });
  }
  getPartnerName(array) {
    let listOfPartners: string[] = [];
    this.allPartners.forEach(partner => {
      array.forEach((element, i) => {
        if (partner.partner_id == element)
          listOfPartners.push(partner.partner_name);
      });
    })
    return listOfPartners
  }
  allPartners: any[] = [];
  getPartners() {
    this.AuthService.get('partners').subscribe(
      (result) => {
        console.log(result);
        this.allPartners = result.data
      },
      (err) => {
        console.log(err.error);
        this.allPartners = []
      }, () => {
      }
    )
  }
  $this = this;
  @ViewChild('content') content: ElementRef;
  public SavePDF(): void {
    let content = document.getElementById("content-table");
    let doc = new jsPDF('p', 'pt', 'letter');
    let _elementHandlers =
    {
      '#editor': function (element, renderer) {
        return true;
      }
    };
    doc.fromHTML(content.innerHTML, 15, 15, {

      // 'width': 200,
      'elementHandlers': _elementHandlers
    });

    doc.save('data.pdf');
  }
}
