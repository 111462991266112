import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { FormBuilder } from "@angular/forms";
import { AuthService } from "../services/auth.service";
import { Connection } from "../models/connection.model";
import { Partner, PartnerOwner } from "../models/partner.model";
import {
  IMultiSelectOption,
  IMultiSelectSettings
} from "angular-2-dropdown-multiselect";
import { NavigationHelperService } from '../services/navigation-helper.service';

declare var $: any
@Component({
  selector: "app-create-connection",
  templateUrl: "./create-connection.component.html",
  styleUrls: ["./create-connection.component.css"]
})
export class CreateConnectionComponent implements OnInit {
  connection: Connection;
  partners: Partner[];
  partnerUsers: PartnerOwner[];
  partnerOwners: PartnerOwner[];
  partnerUsersOptions: IMultiSelectOption[];
  partnerUsersModel: string[];
  multiSelectionSettings: IMultiSelectSettings = {
    enableSearch: true,
    checkedStyle: "checkboxes",
    buttonClasses: "btn btn-default",
    dynamicTitleMaxItems: 0
  };

  singleSelectionSettings: IMultiSelectSettings = {
    enableSearch: true,
    checkedStyle: "checkboxes",
    buttonClasses: "btn btn-default",
    dynamicTitleMaxItems: 0,
    selectionLimit: 1
  };
  formError: boolean = false;
  formErrors = {
    partner2OwnerEmail: {
      valid: '',
      domain: '',
      required: ''
    },
    team: '',
    partner2Owner: ''
  }
  selectedPartner: string;
  selectedPartnerDomain: string;
  userPartnerDomain: string;
  userPartnerEmail: string;
  public teamMembers = [
    {
      email: "",
      user_role: 1
    }
  ];
  teamErrors = [{ domain: '', duplicate: '', valid: '' }]
  defaultPartnerID: string;
  defaultPartnerEmail: string;
  responseError: string = ''
  constructor(
    private router: Router,
    private _location: Location,
    private formBuilder: FormBuilder,
    private AuthService: AuthService,
    private route: ActivatedRoute,
    private navHelper: NavigationHelperService
  ) {
    if (this.navHelper.navigationTracker.length == 0) {
      this.navHelper.setUrl(this.router.url)
    }
  }

  ngOnInit(): void {
    this.defaultPartnerID = this.route.snapshot.queryParams.partnerId;
    this.defaultPartnerEmail = this.route.snapshot.queryParams.partnerEmail;
    this.connection = new Connection();
    this.loadPartners();
    this.loadUserDomain()

  }

  newPartner() {
    this.router.navigate(["/create-partner"]);
  }
  goBack() {
    let previousUrl = this.navHelper.getUrl()
    if (previousUrl) {
      this.router.navigate([previousUrl])
    } else {
      this.router.navigate(['/dashboard'])
    }
  }
  loadUserDomain() {
    this.AuthService.get('user/me').subscribe(
      (result: any) => {
        this.userPartnerDomain = result.data[0].partner.email_domain
        this.userPartnerEmail = result.data[0].email
      },
      () => { }
    )
  }

  loadPartners() {
    this.AuthService.get("partners").subscribe(
      (result: any) => {
        if (this.defaultPartnerID) {
          this.connection.partner2_owner = this.defaultPartnerEmail
          let partner1 = ''
          this.partners = result.data.filter(partner => {
            if (partner.partner_id === this.defaultPartnerID) {
              partner1 = partner.partner_id
              return partner
            }
          })
          // console.log(this.partners, this,)
          this.connection.partner2_id = partner1;
          $('#partnerOwner').attr('disabled', 'true');
          $('#partnerName').attr('disabled', 'true');
         // this.onPartnerSelect();
        } else {
          this.partners = result.data
        }
      },
      (err: any) => {
        this.partners = null;
      })
  }

  loadUsers(partnerId: string) {
    this.AuthService.get(`partners/users?partner_id=${partnerId}`).subscribe(
      (result: any) => {
        this.partnerUsers = result.data;
        this.partnerUsers = this.partnerUsers.filter(
          (user: PartnerOwner) => { return user.email != this.userPartnerEmail })
        this.partnerUsersOptions = this.partnerUsers.map(
          (partnerUser: PartnerOwner) => {
            return { id: partnerUser.email + '~' + partnerUser.user_role, name: partnerUser.email };
          }
        );
      },
      (err: any) => {
        this.partnerUsers = null;
      }
    );
  }
  onPartnerSelect($event?) {
    console.log(this.connection.partner2_id);
    let selectPartnerData: Partner = this.partners.find(
      partner => partner.partner_id == this.connection.partner2_id
    );
    this.selectedPartner = selectPartnerData.partner_name;
    this.selectedPartnerDomain = selectPartnerData.email_domain;

    
    // this.loadPartnerOwners(this.connection.partner2_id);
    // this.loadUsers(this.connection.partner2_id);

    this.connection.partner2_owner=''
    this.teamMembers[0].email = ""
    this.loadPartnerOwners(selectPartnerData.partner_id);
    this.loadUsers(selectPartnerData.partner_id);
  }


  loadPartnerOwners(partnerId: string) {
    //needs to append partnerId
    this.AuthService.get(`partners/owners?partner_id=${partnerId}`).subscribe(
      (result: any) => {
        this.partnerOwners = result.data;
      },
      (err: any) => {
        this.partnerOwners = null;
      }
    );
  }
  onChange(event) {
    this.connection.team = []
    this.partnerUsersModel.forEach(t => {
      let values = t.split('~')
      if (values[0] != '') {
        this.connection.team.push({ email: values[0], user_role: values[1] })
      }
    });
    console.log("this.connection:", this.partnerUsersModel);
  }
  confirmModal() {
    $('#myModal').modal('show')
  }
  validateForm() {
    let error: boolean = false;
    let invitedTeams = this.teamMembers.filter(eachmember => {
      return (eachmember.email != '');
    });
    this.connection.team = this.connection.team.concat(invitedTeams)
    delete this.connection.model;
    if (this.formErrors.partner2OwnerEmail.valid != '') {
      error = true;
    }
    if (this.formErrors.partner2OwnerEmail.domain != '') {
      error = true;
    }
    if (this.connection.partner2_id == '') {
      this.formErrors.partner2Owner = 'Choose Partner'
      error = true;
    }
    if (this.connection.partner2_owner == '') {
      this.formErrors.partner2OwnerEmail.required = 'Connection Owner Email is required'
      error = true;
    }
    if (this.connection.team.length == 0) {
      error = true;
      this.formErrors.team = 'Select or invite minimum one team member'
    }
    if (!error) {
      $('#myModal').modal('show')
    } else {
      this.formError = true
    }
  }
  submit() {
    this.AuthService.add("connections", this.connection, error => {
      this.responseError = error.error.meta.message
    }).subscribe(
      (result: any) => {
        $('#myModal').modal('hide');
        let previousUrl = this.navHelper.getUrl()
        if (previousUrl) {
          this.router.navigate([previousUrl], { queryParams: { connection: result.data.connection_id } })
        } else {
          this.router.navigate(["/dashboard"], { queryParams: { connection: result.data.connection_id } });
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
  addMoreMembers(index: number) {
    if (this.validEmail(index) && this.validDomain(index) && this.notDuplicate(index)) {
      this.teamMembers.push({
        email: "",
        user_role: 1
      });
      this.teamErrors.push({
        domain: '',
        duplicate: '',
        valid: ''
      })
    }
  }

  async validEmail(index: number, response?: boolean) {
    let error: boolean = true;
    if (index == -1) {
      if (!(/\S+@\S+\.\S+/.test(this.connection.partner2_owner))) {
        this.formErrors.partner2OwnerEmail.valid = 'Enter valid email'
        this.formErrors.partner2OwnerEmail.domain = ''
        this.formErrors.partner2OwnerEmail.required = ''
      } else {
        this.formErrors.partner2OwnerEmail.valid = ''
      }
    } else {
      this.teamErrors[index] = { domain: '', duplicate: '', valid: '' }
      if (!(/\S+@\S+\.\S+/.test(this.teamMembers[index].email))) {
        this.teamErrors[index].valid = 'Enter a valid email'
        error = false
      } else {
        this.teamErrors[index].valid = ''
        error = true
      }
      if (response != false) {
        return error
      }
    }


  }
  validDomain(index: number) {
    if (index == -1) {
      if (this.formErrors.partner2OwnerEmail.valid == '') {
        let partnerOwnerEmail = this.connection.partner2_owner.trim().split('@')
        if (this.selectedPartnerDomain != partnerOwnerEmail[1]) {
          this.formErrors.partner2OwnerEmail.domain = 'Enter valid email domain'
        } else {
          this.formErrors.partner2OwnerEmail.domain = ''
        }
      }

    } else {
      let error: boolean = true;
      let testEmail = (this.teamMembers[index].email).trim().split('@')
      let emailDomain = testEmail[1]
      if (this.selectedPartnerDomain != 'undefined') {
        if ((emailDomain != this.selectedPartnerDomain.trim()) && (emailDomain != this.userPartnerDomain.trim())) {
          this.teamErrors[index].domain = 'Email domain does not match'
          error = false
        } else {
          this.teamErrors[index].domain = ''
          error = true
        }
      } else error = true
      return error
    }
  }
  notDuplicate(index: number) {
    let error: boolean = true;
    let duplicates: number[] = []
    this.teamMembers.forEach((v, i) => {
      if (this.teamMembers[index].email === v.email) {
        duplicates.push(i)
      }
    })
    if (duplicates.length > 1) {
      this.teamErrors[index].duplicate = 'Email already exists'
      error = false
    } else {
      this.teamErrors[index].duplicate = ''
      error = true
    }
    return error
  }
  async validateAllMembers(close?: boolean) {
    if (this.teamMembers.length == 1 && this.teamMembers[0].email == '' && close != false) {
      $('#inviteModal').modal('hide');
      return;
    }
    if (this.teamMembers.length > 1) {
      if (this.teamMembers[this.teamMembers.length - 1].email === '') {
        this.teamMembers.pop()
        this.teamErrors.pop()
      }
    }
    await this.teamMembers.forEach((v, i) => {
      if (this.validEmail(i)) {
        this.validDomain(i)
        this.notDuplicate(i)
      }
    })
    let errors = this.teamErrors.filter((eachError, i) => {
      return ((eachError.domain || eachError.duplicate || eachError.valid) != '')
    })
    if (errors.length === 0 && close != false) {
      $('#inviteModal').modal('hide');
    } else if (this.connection.partner2_id == '' && close != false) {
      $('#inviteModal').modal('hide');
    }
    console.log(errors);

  }
  removeMember(index: number) {
    if (index != -1) {
      this.teamMembers.splice(index, 1);
      this.teamErrors.splice(index, 1)
      this.validateAllMembers(false)
    } else {
      this.teamMembers = [
        {
          email: "",
          user_role: 1
        }
      ]
      this.teamErrors = [{ domain: '', duplicate: '', valid: '' }]
    }
  }

  addPermission(index: number, perm: number) {
    this.teamMembers[index].user_role = perm;
  }
}