<nav id="sidebar" class="shrinked">
  <!-- Sidebar Navidation Menus-->
  <ul class="list-unstyled">
    <li>
      <a class="sidebar-toggle" style="cursor: pointer">
        <i class="fa fa-bars" aria-hidden="true"></i>Menu</a
      >
    </li>
    <li>
      <a routerLinkActive="active" routerLink="/dashboard" class="home-a"
        ><span class="material-icons home"> home </span><span>Home</span>
      </a>
    </li>

    <!-- <li>
      <a routerLinkActive="active" routerLink="/plan-dashboard" class="work-a">
        <span class="material-icons work"> work </span><span>Plans</span></a>
    </li> -->
    <li>
      <a
        routerLinkActive="active"
        routerLink="/solution-dashboard"
        class="settings-a"
      >
        <i class="fa fa-gears settings"> </i><span>Solutions</span></a
      >
    </li>
    <li>
      <a
        routerLinkActive="active"
        routerLink="/solution-dashboard"
        class="settings-a"
      >
        <i class="fas fa-people-arrows settings"> </i><span>Solutions</span></a
      >
    </li>
    <li>
      <a
        routerLinkActive="active"
        routerLink="/opportunity-dashboard"
        class="filter_alt-a"
      >
        <i class="fa fa-filter filter_alt"> </i>
        <span style="padding-left: 5px">Opportunities</span></a
      >
    </li>
    <li>
      <a routerLinkActive="active" routerLink="/activity" class="event_note-a">
        <span class="material-icons event_note"> event_note </span
        ><span>Activity</span></a
      >
    </li>
    <li>
      <a routerLinkActive="active" routerLink="/report" class="bar_chart-a">
        <span class="material-icons bar_chart"> bar_chart </span
        ><span>Reports</span></a
      >
    </li>
    <li *ngIf="isAdmin">
      <a routerLinkActive="active" routerLink="/admin" class="dashboard-a">
        <span class="material-icons dashboard">dashboard </span
        ><span>Admin</span></a
      >
    </li>
  </ul>
</nav>
