import { Location } from '@angular/common';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Country } from '../models/country.model';
import { Partner } from '../models/partner.model';
import { AuthService } from '../services/auth.service';
import { NavigationHelperService } from '../services/navigation-helper.service';

declare var $: any;

@Component({
  selector: 'app-create-partner',
  templateUrl: './create-partner.component.html',
  styleUrls: ['./create-partner.component.css']
})
export class CreatePartnerComponent implements OnInit {
  partnerForm: FormGroup
  formControls: any
  partner: Partner;
  countries: Country[];
  currencies: any = [];
  partnerOwners: any = [];
  partnerTypes: any = [];
  formError: boolean = false;
  userPartnerName: string;
  userPartnerDomain: string;
  userPartnerEmail: string;
  responseError: string = ''
  partnerId: any = '';
  validateDomain: boolean = false;
  constructor(
    private cdRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private _location: Location,
    private AuthService: AuthService,
    private navHelper: NavigationHelperService,
    private router: Router,
    private activeRoute: ActivatedRoute
  ) {
    if (this.navHelper.navigationTracker.length == 0) {
      this.navHelper.setUrl(this.router.url)
    }
    this.activeRoute.params.subscribe((a) => {
      if (a.partnerId) {
        this.partnerId = a.partnerId;
        this.getPartner();
      }
    });
    this.getUserPartnerDate()
    this.partnerForm = this.formBuilder.group({
      partnerName: ['', Validators.compose([Validators.required,])],
      location: ['', Validators.compose([Validators.required])],
      partnerType: ['', Validators.compose([Validators.required])],
      description: ['', Validators.compose([Validators.required])],
      partnerOwner: ['', Validators.compose([Validators.required, Validators.pattern(/\S+@\S+\.\S+/)])],
      // app_url: ['', Validators.compose([Validators.required])],
      cuurency: [],
      team: []

    })
  }
  get partner_owners() { return this.AuthService.getPartnerOwners() }
  ngOnInit(): void {
    this.partner = new Partner();
    this.formControls = this.partnerForm.controls;
    this.loadCountries();
    this.partner.country_code = ''
    this.partner.partner_type = ''
    this.partner.partner_owner = ''
    this.partner.target_revenue = ''
    this.partner.app_url = ''
    this.partner.team = [];
    this.AuthService.get('customData/get', (err) => {
      console.log(err);
    }).subscribe((result: any) => {
      this.partnerTypes = result.data.partner_types;
    }, (err: any) => {
      this.partnerTypes = [];
      console.log(err);
    });
  }
  userDetails: any;
  getUserPartnerDate() {
    this.AuthService.get('user/me').subscribe(
      (result: any) => {
        this.userDetails = result.data[0]
          ; this.userPartnerDomain = result.data[0].partner.email_domain;
        this.userPartnerName = result.data[0].partner.partner_name;
        this.userPartnerEmail = result.data[0].email;
      },
      () => { }
    )
  }
  sendNotification(userData, message, created_partner) {
    this.AuthService.add('notifications', {
      message: `${userData.first_name} ${userData.last_name} has logged in.`,
      customData: {
        user_name: `${userData.first_name} ${userData.last_name}`,
        partner_name: userData.partner.partner_name,
        action: message,
        created_partner: created_partner
      },
      moduleName: 'Partner'
    }).subscribe(
      (result) => {
        console.log(result);
      }
    )
  }
  domainCheckChange() {
    console.log(this.validateDomain);
  }
  saveTeam($event) {
    this.partnerForm.patchValue({ 'team': $event });
    console.log(this.partnerForm.value);
    if ($event) {
      this.partner.team = $event;
    }
    $('#teamModal').modal('hide');
  }
  domainMatchError: boolean = false;
  domainChange($event) {
    this.domainMatchError = $event;
    this.cdRef.detectChanges();
  }
  getDomainError() {
    return this.domainMatchError;
  }
  closeTeam() {
    $('#teamModal').modal('hide');
  }
  partnerMatchError: boolean = false;
  getPartnerMatch($event) {
    this.partnerMatchError = $event;
  }
  openTeam() {
    if (this.formControls['partnerOwner'].invalid) {
      $('.tooltiptext').css("display", "block");
    } else {
      $('#teamModal').modal('show');
    }
  }
  loadCountries() {
    this.AuthService.get('countries?limit=all').subscribe((result: any) => {
      this.countries = result.data;
    }, (err: any) => {
      this.countries = [];
      console.log(err);
    });
  }

  loadCurrencies() {
    this.AuthService.get('currencies?limit=all').subscribe((result: any) => {
      this.currencies = result.data;
    }, (err: any) => {
      this.countries = [];
      console.log(err);
    });
  }
  validatePartner() {
    if (!this.partnerForm.controls['partnerName'].errors) {
      if (this.partnerForm.controls['partnerName'].value.trim() == this.userPartnerName) {
        this.partnerForm.controls['partnerName'].setErrors({ notMatch: true })
      }
    }
  }
  validatePartnerEmail() {
    if (!this.partnerForm.controls['partnerOwner'].errors) {
      if (this.partnerForm.controls['partnerOwner'].value.trim() == this.userPartnerEmail) {
        this.partnerForm.controls['partnerOwner'].setErrors({ notMatch: true })
      } else {
        this.partnerForm.controls['partnerOwner'].setErrors(null)
      }
    }
    if (!this.partnerForm.controls['partnerOwner'].errors) {
      if (this.partnerForm.controls['partnerOwner'].value.trim().split('@')[1] == this.userPartnerDomain) {
        this.partnerForm.controls['partnerOwner'].setErrors({ domainMatch: true })
      } else {
        this.partnerForm.controls['partnerOwner'].setErrors(null)
      }
    }
  }
  selectedPartnerOwner: any;
  confirmPopUP(owner) {
    $('#selectPartner').modal('hide')
    this.selectedPartnerOwner = owner
    $('#myModal').modal('show');
  }
  partnerPopUp() {
    if (this.partnerForm.valid && !this.domainMatchError && !this.partnerMatchError) {
      this.formError = false
      if (this.partner_owners.length == 1) {
        this.selectedPartnerOwner = this.partner_owners[0];
        $('#myModal').modal('show');

      } else {
        let p = this.partner_owners.find((a) => a.subscription);
        this.selectedPartnerOwner = p;
        $('#myModal').modal('show');
        // $('#selectPartner').modal('show')
      }
    } else {
      this.formError = true
    }
  }
  loadPartnerOwners() {
    this.AuthService.get('partners/owners')
      .subscribe((result: any) => {
        this.partnerOwners = result.data;
      }, (err: any) => {
        this.partnerOwners = [];
        console.log(err);
      });
  }
  goBack() {
    let previousUrl = this.navHelper.getUrl()
    let redirect = localStorage.getItem('redirect')
    localStorage.removeItem('redirect');
    if (previousUrl || redirect) {
      this.router.navigate([redirect || previousUrl])
    } else {
      this.router.navigate(['/dashboard'])
    }
  }
  isProgress: boolean = false;
  submit() {
    this.isProgress = true;
    this.partner.email_domain = this.partner.partner_owner.split('@')[1]
    this.partner.partner_id = this.selectedPartnerOwner.partner.partner_id
    this.partner.team = this.partner.team ? this.partner.team : [];
    // if(!this.partnerId){
    this.partner.validate_domain = this.validateDomain;
    // }
    // let modal = this.partnerId ? this.AuthService.raw : this.AuthService.raw;
    this.AuthService.raw(this.partnerId ? 'put' : 'post', this.AuthService.baseUrl + (this.partnerId ? 'partners/' + this.partnerId + '/updatePartner' : 'partners'), this.partner).subscribe((result: any) => {
      this.isProgress = false;
      this.sendNotification(this.userDetails, (this.partnerId ? 'update' : 'created')
        + ' partner', this.partner.partner_name);
      let previousUrl = this.navHelper.getUrl()
      $('#myModal').modal('hide')
      if (previousUrl || localStorage.getItem('redirect')) {
        this.router.navigate([localStorage.getItem('redirect') || previousUrl], { queryParams: { partnerId: result.data.partner_id, partnerEmail: this.partner.partner_owner } });
      } else {
        this.goBack()
      }
    }, (err: any) => {
      this.isProgress = false;
      this.responseError = err.error.meta.message
      console.log(err);
    });
  }
  closeConfirmModal() {
    $('#myModal').modal('hide')
    this.responseError = ''
  }
  getPartnerUsers() {
    this.AuthService.get('partners/users?partner_id=' + this.partnerId)
      .subscribe((result: any) => {
        this.partner.team = result.data.map((a) => { email: a.email });
      }, (err: any) => {
        console.log(err);
      });
  }
  getPartner() {
    this.AuthService.get('partners/' + this.partnerId + '/getDetail')
      .subscribe((result: any) => {
        this.partner = { ...result.data, ...{ team: [] } };
        this.validateDomain = this.partner.validate_domain;
        // this.getPartnerUsers();
      }, (err: any) => {
        console.log(err);
      });
  }
}
