import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Activity } from '../models/activity.model';
import { Connection } from '../models/connection.model';
import { AuthService } from '../services/auth.service';
import { LocalStorageService } from '../services/local-storage.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import * as moment from 'moment';

declare var $: any;
@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.css']
})
export class ActivityComponent implements OnInit {
  @Input() onlyAddForm: boolean;
  activitiesList: any;
  userData: any = {};
  defaultActivity: any;
  constructor(
    private formBuilder: FormBuilder,
    private AuthService: AuthService,
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute,
    private location: Location
  ) {
    this.userData = this.localStorageService.get({ name: 'userData' })
    this.userData = this.userData ? JSON.parse(this.userData) : {};
  }
  //********** fetch connections ********//
  connections: any;
  getConnections() {
    return new Promise((resolve, reject) => {
      this.AuthService.get('partners').subscribe((result) => {
        resolve(result.data);
      },
        (error) => {
          console.log(error.error.meta.message)
          resolve((<any>[]));
        });
    })
  }
  //*********** fetch table data ***********//
  getActivities() {
    return new Promise((resolve, reject) => {
      this.AuthService.get('activity').subscribe(
        (result) => {
          let list = [];
          if (result.data) {
            list = result.data.filter((activity: Activity) => { return activity.activity }).sort((a: any, b: any) => {
              var keyA = new Date(a.completion_date || 0),
                keyB = new Date(b.completion_date || 0);
              if (keyA < keyB) return -1;
              if (keyA > keyB) return 1;
              return 0;
            });
          }
          resolve(list)
          setTimeout(() => {
            $('.ui').dropdown();
          }, 100)
        },
        (error) => {
          console.log(error);
          resolve([]);
        })
    })
  }
  ngOnInit(): void {
    this.initializePage();
  }
  getPartnerIndexes(arr, val) {
    var indexes = [], i;
    for (i = 0; i < arr.length; i++)
      if (arr[i].partner_id === val)
        indexes.push(i);
    return indexes;
  }
  getUserIndexes(arr, val) {
    var indexes = [], i;
    for (i = 0; i < arr.length; i++)
      if (arr[i] === val)
        indexes.push(i);
    return indexes;
  }
  async initializePage() {
    this.connections = await this.getConnections();
    await this.getActivities().then((result: any) => {
      result.map((activity) => {
        if (activity.partnerDetails && activity.partnerDetails.length > 0) {
          let index = this.getPartnerIndexes(activity.partnerDetails, this.userData.partner.partner_id);
          index.forEach(i => {
            activity.partnerDetails.splice(i, 1);
          });
          // if (activity.assignee && activity.assignee.length > 0) {
          //   let index = this.getUserIndexes(activity.assignee, this.userData.email);
          //   index.forEach(i => {
          //     activity.assignee.splice(i, 1);
          //   })
          // }
          activity.priority = (<string>activity.priority).toLowerCase();
          activity.partners = activity.partnerDetails.map((selectedPartner) => {
            let partnerDetails = this.connections.find(partner => partner.partner_id == selectedPartner.partner_id);
            if (partnerDetails) { return partnerDetails.partner_name };
          });
          activity.isEdit = true;
          let today = moment().format('MM-DD-YYYY');
          let targetEndDate = moment(activity.end_date).format('MM-DD-YYYY');
          if (moment(today).isAfter(targetEndDate)) {
            if (!(activity.approver_id.includes(this.userData.user_id)) && !this.userData.admin_user) {
              activity.isEdit = false;
            }
          }
          if (activity.approval_status == 'Approved') {
            activity.isEdit = false;
          }
          if (activity.approval_status == 'Completed') {
            if (!(activity.approvers && activity.approvers.length > 0 && activity.approvers[0] != '')) {
              activity.isEdit = false;
            }
          }
        }
        return activity;
      })
        .sort((a: any, b: any) => {
          var keyA = new Date(a.completion_date || 0),
            keyB = new Date(b.completion_date || 0);
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        })
      this.activitiesList = result;
    })
    this.route.params.subscribe((a) => {
      if (a.activityId) {
        this.defaultActivity = a.activityId;
        this.location.replaceState('activity');
      }
    })
  }
  selectedActivity: Activity = new Activity;
  enableUpdate(updateData: Activity) {
    this.selectedActivity = updateData;
    setTimeout(() => {
      $('#AddActivity').modal('show');
    }, 100)
  }
}
