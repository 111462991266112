import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Connection } from '../../models/connection.model';
import { AuthService } from '../../services/auth.service';
import { CommonEmitterService } from '../../services/common-emitter';
import { NavigationHelperService } from '../../services/navigation-helper.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { RxwebValidators } from '@rxweb/reactive-form-validators';


declare var $: any
@Component({
  selector: 'app-adduser',
  templateUrl: './adduser.component.html',
  styleUrls: ['./adduser.component.css']
})

export class AddUserComponent implements OnInit {
  connections: Connection[];
  data: any = [];
  app_version: string = '';
  userForm: FormGroup;
  users: FormArray
  userDomain: string;
  noOfAdditionalUsers: any;
  constructor(private router: Router, private route: ActivatedRoute,
    private fb: FormBuilder,
    private AuthService: AuthService,
    private navHelper: NavigationHelperService,
    private LocalStorageService: LocalStorageService) {
    if (this.navHelper.navigationTracker.length == 0) {
      this.navHelper.setUrl(this.router.url)
    }
    this.userDomain = JSON.parse(this.LocalStorageService.get({ name: 'userData' })).email.split('@')[1].trim().toLowerCase()
    this.userForm = this.fb.group({
      users: this.fb.array([this.eachUser()])
    })
    for (let i = 0; i < 4; i++) {
      this.addmore(true);
    }
    this.getUsers();
  }
  allUsers: any[] = [];
  getUsers() {
    this.AuthService.get('admin/users').subscribe(
      (result) => {
        console.log(result);
        this.allUsers = result.data
        this.getPartners()
      },
      (err) => {
        console.log(err.error);
        this.allUsers = []
      }, () => {
      }
    )
  }
  get partnerDetails() { return this.AuthService.getPartnerOwners()[0].partner }
  get usersArray() {
    return <FormArray>this.userForm.get('users');
  }
  subscribeChanges() {
    this.usersArray.valueChanges.subscribe(val => {
      this.showError = false;
    })
    this.usersArray.controls.forEach((control, i) => {
      control.get('email').valueChanges.subscribe(val => {
        let inputEmail = val
        this.checkDomain(inputEmail, control);
        this.checkIfExists(inputEmail, control);
        if (control.get('email').errors) {
          this.resetAccess(i, control)
        }
      })

    });
  }
  checkDomain(value, control) {
    if (control.get('email').errors && !control.get('email').errors.invalidDomain) {
      return;
    }
    if (value.split('@').length > 1) {
      value = value.split('@')[1].trim().toLowerCase()
      if (value != this.userDomain) {
        control.get('email').setErrors({ invalidDomain: true })
      } else {
        control.get('email').setErrors(null)
      }
    }
  }
  checkIfExists(value, control) {
    if (control.get('email').errors && !control.get('email').errors.exists) {
      return;
    }
    value = value.trim().toLowerCase()
    if (this.validPartners.includes(value)) {
      control.get('email').setErrors({ exists: true })
    } else {
      control.get('email').setErrors(null)
    }
  }
  resetAccess(i, user: AbstractControl) {
    $(`#admin${i}`).prop('checked', false)
    user.get('admin_user').patchValue(false);
    $(`#super${i}`).prop('checked', false)
    user.get('super_user').patchValue(false);
  }
  changeAdminAccess(event, user: AbstractControl) {
    let checkbox = event.currentTarget
    let i = checkbox.id.charAt(checkbox.id.length - 1)
    if (user.get('email').value != "" && user.get('email').valid) {
      $(`#admin${i}`).prop('checked', !user.value.admin_user)
      user.get('admin_user').patchValue(!user.value.admin_user);
      $(`#super${i}`).prop('checked', false)
      user.get('super_user').patchValue(false);
    } else {
      $(`#${checkbox.id}`).prop('checked', false)
      this.errorId = 1;
      $('#errorModal').modal('show');
    }
  }
  changeSuperAccess(event, user: AbstractControl) {

    let checkbox = event.target
    let i = checkbox.id.charAt(checkbox.id.length - 1)
    console.log(user.get('email').value, user.get('email').valid, user.get('super_user'), event, i, checkbox)
    if (user.get('email').value != "" && user.get('email').valid) {
      $(`#super${i}`).attr('checked', !user.value.super_user)
      user.get('super_user').patchValue(!user.value.super_user);
      $(`#admin${i}`).attr('checked', false)
      user.get('admin_user').patchValue(false);
    } else {
      $(`#${checkbox.id}`).attr('checked', false)
      this.errorId = 1;
      $('#errorModal').modal('show');
    }
    this.subscribeChanges()
  }
  eachUser() {
    return this.fb.group({
      email: new FormControl("", [RxwebValidators.unique(), Validators.pattern(/\S+@\S+\.\S+/)]),
      super_user: new FormControl(false),
      admin_user: new FormControl(false),
      partner_name: (this.partnerDetails.partner_name),
      partner_id: (this.partnerDetails.partner_id)

    })
  }
  ngOnInit(): void { }
  validPartners: any[] = [];
  getPartners() {
    this.allUsers.forEach(user => {
      let partner: string = user.email.trim().toLowerCase()
      if (!this.validPartners.includes(partner)) {
        this.validPartners.push(partner)
      }
    })
  }
  superuser(event) {
    console.log('this is checkbox event==>', event)
  }
  addmore(init) {
    $('#addMoreModal').modal('hide');
    this.users = this.userForm.get('users') as FormArray
    if (init) {
      this.users.push(this.eachUser());
      this.subscribeChanges()
    }
    let limit = this.noOfAdditionalUsers ? Number(this.noOfAdditionalUsers) : 0;
    if (!init && limit && limit > 0) {
      for (let i = 0; i < limit; i++) {
        this.users.push(this.eachUser());
        this.subscribeChanges()
      }
    }
  }
  showError: boolean = false;
  confirmModal() {
    let finalUsers = this.usersArray.value.filter(element => element.email != '');
    if (this.userForm.valid && finalUsers.length > 0) {
      $('#confirmModal').modal('show')
    } else {
      this.showError = true;
    }
  }
  adduser() {
    $('#confirmModal').modal('hide');
    let finalUsers = this.usersArray.value.filter(element => element.email != '');
    this.AuthService.add('admin/users', finalUsers).subscribe(
      (result: any) => {
        console.log(result.data)
        $('#successModal').modal('show')
        setTimeout(() => {
          $('#successModal').modal('hide')
          console.log(this.userForm.value);
          this.router.navigate(['/admin/dashboard'])
        }, 3000);
      },
      (error) => {
        console.log(error.error);

        if (error.error.meta.message == `Cannot read property 'status' of undefined`) {
          $('#successModal').modal('show')
          setTimeout(() => {
            $('#successModal').modal('hide')
            this.router.navigate(['/admin/dashboard'])
          }, 3000);
        };
      });
  }
  errorId: number = 0;
  closeErrorModal() {
    $('#errorModal').modal('hide');
    this.errorId = 0;
  }
}
