import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { IMultiSelectSettings } from 'angular-2-dropdown-multiselect';
declare var $: any;
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  errorId: number = 0;
  multiSelectionSettings: IMultiSelectSettings = {
    enableSearch: true,
    checkedStyle: "checkboxes",
    buttonClasses: "btn btn-default",
    dynamicTitleMaxItems: 0
  };
  constructor(private AuthService: AuthService, private fb: FormBuilder) {
  }
  ngOnInit(): void {
    this.getUserDetails()
    this.getUsers()
  }
  user: any;
  userList: any = [];
  filter: any = {};
  organisations: any = []
  yesno: any = [{ name: 'Y', id: true }, { name: 'N', id: false }]
  getUserDetails() {
    this.AuthService.get("user/me", () => { }).subscribe(
      (result: any) => {
        console.log("header says", result);
        this.user = result.data[0];
      },
      err => {
        console.log(err);
      }
    );
  }
  filterList() {
    // console.log(this.filter)
    this.allUsers = this.userList.filter((a) => {
      let status = true;
      if (this.filter.partner_name && this.filter.partner_name.length && this.filter.partner_name.indexOf(a.partner_name) == -1) {
        status = false
      }
      if (this.filter.admin_user && this.filter.admin_user.length && this.filter.admin_user.indexOf(a.admin_user) == -1) {
        status = false
      }
      if (this.filter.super_owner && this.filter.super_owner.length && this.filter.super_owner.indexOf(a.super_user) == -1) {
        status = false
      }
      return status;
    })
  }
  allUsers: any[] = [];
  getUsers() {
    this.AuthService.get('admin/users').subscribe(
      (result) => {
        console.log(result);
        this.allUsers = this.userList = result.data.map((a) => {
          if (this.organisations.filter((aa) => aa.name == a.partner_name).length == 0) {
            this.organisations.push({ name: a.partner_name, id: a.partner_name });
          }
          return a;
        })
      },
      (err) => {
        console.log(err.error);
        this.allUsers = []
      }, () => {
        this.sortedByPartner = this.sort('partner_name');
        this.sortedByEmail = this.sort('email');
        this.sortedBySuperUser = this.sort('super_owner')
        this.sortedByAdminUser = this.sort('admin_user')
        this.changeSort(this.selectedSort)
      }
    )
  }
  selectedUserEmail: string = '';
  selectedUserSuperOwner: boolean = false;
  selectedUserAdminUser: boolean = false;
  selectedUserID: any;
  selectedUserIndex: number;
  selectedUserPartnerName: string = '';
  editUser(i: number, user: any) {
    if (this.user.email != user.email) {
      this.finalUser = user
      this.selectedUserIndex = i;
      this.selectedUserID = user.user_id;
      this.selectedUserEmail = user.email;
      this.selectedUserPartnerName = user.partner_name;
      this.selectedUserSuperOwner = user.super_user;
      this.selectedUserAdminUser = user.admin_user;
      $('#editModal').modal('show')
    } else {
      this.errorId = 3
      $('#errorModal').modal('show')
    }
  }

  changeAccess(access) {
    // let userDomain = this.user.email.split('@')[1].trim().toLowerCase()
    // let selectedDomain = this.selectedUserEmail.split('@')[1].trim().toLowerCase()
    // if (userDomain == selectedDomain) {
    if (access == 'super_owner') {
      this.selectedUserSuperOwner = !this.selectedUserSuperOwner
      this.selectedUserAdminUser = false;
    }
    if (access == 'admin_user') {
      this.selectedUserAdminUser = !this.selectedUserAdminUser
      this.selectedUserSuperOwner = false
    }
    // } else {
    //   this.errorId = 1;
    //   $('#errorModal').modal('show');
    // }
  }
  openDeleteModal(user) {
    if (this.user.email != user.email) {
      this.finalUser = user;
      this.selectedUserID = user.user_id;
      this.selectedUserEmail = user.email;
      this.selectedUserPartnerName = user.partner_name;
      this.selectedUserSuperOwner = user.super_user;
      this.selectedUserAdminUser = user.admin_user;
      $('#deleteModal').modal('show');
    } else {
      this.errorId = 3
      $('#errorModal').modal('show')
    }
  }
  deleteUser() {
    if (this.user.email != this.selectedUserEmail) {
      this.AuthService.delete(`admin/users/${this.selectedUserID}`, this.finalUser).subscribe(
        (result: any) => {
          console.log(result.meta);
          this.getUsers();
          this.closeDeleteModal();
        }, error => {
          console.log(error);

        })
    } else {
      this.errorId = 3
      $('#errorModal').modal('show')
    }
  }
  closeDeleteModal() {
    $('#deleteModal').modal('hide');
  }
  closeErrorModal() {
    $('#errorModal').modal('hide')
    this.errorId = 0;
  }
  closeEdit() {
    $('#editModal').modal('hide')
  }
  finalUser: any;
  getFinalUser() {
    this.finalUser.admin_user = this.selectedUserAdminUser;
    this.finalUser.super_owner = this.selectedUserSuperOwner;
    this.finalUser.super_user = this.selectedUserSuperOwner;
    console.log(this.finalUser);

    return this.finalUser
  }
  submitEdit() {
    this.getFinalUser();
    this.AuthService.update(`admin/users/${this.selectedUserID}`, this.getFinalUser()).subscribe(
      (result) => {
        console.log(result);
        this.getUsers()
        $('#editModal').modal('hide');
      },
      (error) => { console.log(error) },
      () => { }
    )
  }
  selectedSort: any = '';
  changeSort(value) {
    this.selectedSort = value;
    switch (value) {
      case 'partner_name':
        if (this.allUsers == this.sortedByPartner) this.allUsers = this.sortedByPartner.slice().reverse();
        else if (this.allUsers == this.sortedByPartner.slice().reverse()) this.allUsers = this.sortedByPartner;
        else this.allUsers = this.sortedByPartner;
        break;
      case 'email':
        if (this.allUsers == this.sortedByEmail) this.allUsers = this.sortedByEmail.slice().reverse();
        else if (this.allUsers == this.sortedByEmail.slice().reverse()) this.allUsers = this.sortedByEmail;
        else this.allUsers = this.sortedByEmail;
        break;
      case 'super_owner':
        if (this.allUsers == this.sortedBySuperUser) this.allUsers = this.sortedBySuperUser.slice().reverse();
        else if (this.allUsers == this.sortedBySuperUser.slice().reverse()) this.allUsers = this.sortedBySuperUser;
        else this.allUsers = this.sortedBySuperUser;
        break;
      case 'admin_user':
        if (this.allUsers == this.sortedByAdminUser) this.allUsers = this.sortedByAdminUser.slice().reverse();
        else if (this.allUsers == this.sortedByAdminUser.slice().reverse()) this.allUsers = this.sortedByAdminUser;
        else this.allUsers = this.sortedByAdminUser;
        break;
      default:
        break;
    }
  }
  sortedByPartner: any[] = [];
  sortedByEmail: any[] = [];
  sortedBySuperUser: any[] = [];
  sortedByAdminUser: any[] = [];
  sortingCondition: string = '';
  sort(condition) {
    this.sortingCondition = condition
    return this.mergeSort(this.allUsers);
  }
  mergeSort(unsortedArray) {
    if (unsortedArray.length <= 1) {
      return unsortedArray;
    }
    const middle = Math.floor(unsortedArray.length / 2);
    const left = unsortedArray.slice(0, middle);
    const right = unsortedArray.slice(middle);
    return this.merge(
      this.mergeSort(left), this.mergeSort(right)
    );
  }
  merge(left, right) {
    let resultArray = [], leftIndex = 0, rightIndex = 0, condition = this.sortingCondition;
    if (condition == 'super_owner' || condition == 'admin_user') {
      while (leftIndex < left.length && rightIndex < right.length) {
        if (left[leftIndex][condition] > right[rightIndex][condition]) {
          resultArray.push(left[leftIndex]);
          leftIndex++;
        } else {
          resultArray.push(right[rightIndex]);
          rightIndex++;
        }
      }
    } else {
      while (leftIndex < left.length && rightIndex < right.length) {
        if (left[leftIndex][condition].toUpperCase() < right[rightIndex][condition].toUpperCase()) {
          resultArray.push(left[leftIndex]);
          leftIndex++;
        } else {
          resultArray.push(right[rightIndex]);
          rightIndex++;
        }
      }
    }

    return resultArray
      .concat(left.slice(leftIndex))
      .concat(right.slice(rightIndex));
  }
}