<div class="row" style="margin-top: 20px; margin-bottom: 10px; padding: 0 80px;">
  <!-- <div class="col-12 text-right">
    <div class="tbl_in_icon">
      <a href="#" data-toggle="modal" data-target="#myModaladd">
        <i class="fa fa-plus-circle" aria-hidden="true" style="color: #44a2f8;"></i>
      </a>
      <i class="fa fa-filter" aria-hidden="true"></i>
      <i class="fa fa-cog" aria-hidden="true"></i>
    </div>
  </div> -->
</div>
<div class="row mrgbtmn" style="padding: 0 3%;padding-left:6%;"
  [ngClass]="opportunityId && !isEdit ? 'disabled-form' : ''">
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <div class="arrow-steps clearfix" style="overflow: hidden;">
      <div class="step" [id]="st.id" [ngClass]="st.id == opportunity.order_status_id ? 'current' : ''"
        *ngFor="let st of orderStatuses">
        {{ st.label }}
      </div>
      <!-- <div class="step">Move Stage</div>
      <div class="step">Evaluation</div>
      <div class="step">Negotiation</div>
      <div class="step">Closed</div> -->
    </div>
  </div>
</div>

<form style="width: 85%;margin: auto;" [formGroup]="createOpportunityForm">
  <div class="row" style="margin-top: 20px;" [ngClass]="opportunityId && !isEdit ? 'disabled-form' : ''">
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" style="padding-right: O;">
      <div class="row mrgbtmn">
        <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12 col-12">
          <h4 class="head_type fz-14 c-gray-1">
            Customer Name<sub class="required">*</sub>:
          </h4>
        </div>
        <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12 col-12">
          <input matInput type="text" id="autocomplete" autocomplete=off class="fc-black-1 border-none"
            placeholder="Enter Customer Name" formControlName="customer_name" (blur)="blurCustomer()"
            [(ngModel)]="opportunity.customer_name" (focus)="getFilteredCustomers($event)"
            (keyup)="getFilteredCustomers($event)" />
          <div class="tt-menu" style="
              position: absolute;
              top: 60%;
              left: 9px;
              z-index: 100;
              display: block;
              width: 100%;
            " *ngIf="filteredCustmers.length">
            <div class="tt-dataset tt-dataset-cars" *ngFor="let cus of filteredCustmers">
              <div class="tt-suggestion tt-selectable" (click)="setCustomer(cus)">
                {{ cus.name }}
              </div>
            </div>
          </div>
          <div class="mrgbtm" *ngIf="
              (formControls['customer_name'].dirty ||
                formControls['customer_name'].touched ||
                formError) &&
              formControls['customer_name'].errors
            ">
            <div class="errorMessage" *ngIf="formControls['customer_name'].errors.required">
              Customer name is required.
            </div>
          </div>
        </div>
        <hr class="col-12" style="padding: 0;" />
      </div>
      <div class="row mrgbtmn">
        <div class="col-lg-5 col-md-5 col-sm-6 col-xs-6 d-f f-d-c">
          <h4 class="head_type fz-14 fc-gray-1">
            Owner<sub class="required">*</sub>:
          </h4>
        </div>
        <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 d-f f-d-c">
          <ng-select class="border-none owner" formControlName="owner" [(ngModel)]="opportunity.owner" [items]="owners"
            bindLabel="name" bindValue="id" placeholder="Select Owner"></ng-select>
          <!-- <ss-multiselect-dropdown [disabled]="!owners" [options]="owners" [(ngModel)]="opportunity.owner"
            [settings]="multiCustomerSettings" formControlName="owner">
          </ss-multiselect-dropdown> -->
          <div class="mrgbtm" *ngIf="
              (formControls['owner'].dirty ||
                formControls['owner'].touched ||
                formError) &&
              formControls['owner'].errors
            ">
            <div class="errorMessage" *ngIf="formControls['owner'].errors.required">
              Owner is required.
            </div>
          </div>
        </div>
        <div class="col-2"></div>
      </div>
      <div class="row mrgbtmn">
        <div class="col-lg-5 col-md-5 col-sm-6 col-xs-6 col-5 d-f f-d-c">
          <h4 class="head_type fz-14 fc-gray-1">
            Partner:
          </h4>
        </div>
        <div class="col-lg-5 col-md-5 col-sm-6 col-xs-6 col-5 d-f f-d-c">
          <ng-select class="border-none owner" [multiple]="true" formControlName="partners"
            [(ngModel)]="opportunity.partners" (ngModelChange)="onPartnerSelect($event)" [items]="partnerOptions"
            bindLabel="name" bindValue="id" placeholder="Select Partner"></ng-select>

          <!-- <ss-multiselect-dropdown [disabled]="!party_owners" [options]="party_owners"
            [(ngModel)]="opportunity.partner_owner" [settings]="multiCustomerSettings" formControlName="partner_owner">
          </ss-multiselect-dropdown> -->
          <div class="mrgbtm" *ngIf="
              (formControls['partners'].dirty ||
                formControls['partners'].touched ||
                formError) &&
              formControls['partners'].errors
            ">
            <div class="errorMessage" *ngIf="formControls['partners'].errors.required">
              Partner is required.
            </div>
          </div>
        </div>
        <div class="col-2">
          <a style="cursor: pointer; margin: 6px 2px 3px -15px;font-size: 10px;" (click)="newPartner()"
            class="data_field_a"><i class="fa fa-plus fc-blue" id="partnerButton_1" aria-hidden="true"
              style="font-size: 24px;"></i>
            <span (click)="newPartner()" id="partnerButton_2"
              style="position: absolute;bottom:90%;width:fit-content">Add Partner</span>
          </a>
        </div>
        <div class="mrgbtm col-12  text-center" *ngIf="partner_error">
          <div class="errorMessage col-11" *ngIf="partner_error">
            Partner is required
          </div>
        </div>
      </div>
      <div class="row mrgbtmn">
        <div class="col-lg-5 col-md-5 col-sm-6 col-xs-6 col-5 d-f f-d-c">
          <h4 class="head_type fz-14 fc-gray-1">
            Partner Owner:
          </h4>
        </div>
        <div class="col-lg-5 col-md-5 col-sm-6 col-xs-6 col-5 d-f f-d-c">
          <ng-select class="border-none owner" [multiple]="true" formControlName="partner_owner"
            [(ngModel)]="opportunity.partner_owner" (ngModelChange)="onPartnerOwnerSelect($event)"
            [items]="party_owners" bindLabel="name" bindValue="id" placeholder="Select Partner Owner"></ng-select>

          <!-- <ss-multiselect-dropdown [disabled]="!party_owners" [options]="party_owners"
            [(ngModel)]="opportunity.partner_owner" [settings]="multiCustomerSettings" formControlName="partner_owner">
          </ss-multiselect-dropdown> -->
          <div class="mrgbtm" *ngIf="
              (formControls['partner_owner'].dirty ||
                formControls['partner_owner'].touched ||
                formError) &&
              formControls['partner_owner'].errors
            ">
            <div class="errorMessage" *ngIf="formControls['partner_owner'].errors.required">
              Partner Owner is required.
            </div>
          </div>
        </div>
        <div class="col-2"></div>
      </div>
      <div class="row mrgbtmn">
        <div class="col-lg-5 col-md-5 col-sm-6 col-xs-6 col-5 d-f f-d-c">
          <h4 class="head_type fz-14 fc-gray-1">
            Team Members:
          </h4>
        </div>
        <div class="col-lg-5 col-md-5 col-sm-3 col-xs-3 col-5">
          <ng-select class="border-none owner w-mc" [multiple]="true" formControlName="teams"
            [(ngModel)]="opportunity.team" (ngModelChange)="onChange($event)" [items]="partnerTeamOptions"
            bindLabel="name" bindValue="id" placeholder="Select">
          </ng-select>
          <div class="mrgbtm" *ngIf="
          (formControls['teams'].dirty ||
            formControls['teams'].touched ||
            formError) &&
          formControls['teams'].errors
        ">
            <div class="errorMessage" *ngIf="formControls['teams'].errors.required">
              Team members are required.
            </div>
            <div class="errorMessage" *ngIf="formControls['teams'].errors.partnersMismatch">
              Select atleast team member from each partner.
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-4 col-xs-2 col-2">
          <a style="cursor: pointer; margin: 6px 2px 3px -15px;font-size: 10px;" (click)="openTeam()"
            class="data_field_a"><i class="fa fa-plus" aria-hidden="true" style="color: #44a2f8;font-size: 24px;"></i>
            <span
              style="position: absolute;bottom:90%;width:fit-content">{{this.invitedTeam.length < 1 ? "Invite" : this.invitedTeam.length + " invited"}}</span>
          </a>
        </div>
        <!-- <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6 d-f f-d-c">
          <h4 class="head_type fz-14 fc-gray-1">
            Team Members:
          </h4>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6 d-f f-d-c">
          <ng-select class="border-none owner" [multiple]="true" formControlName="partner_owner"
            [(ngModel)]="opportunity.partner_owner" [items]="party_owners" bindLabel="name" bindValue="id"
            placeholder="Select Partner Owner"></ng-select>
          <div class="mrgbtm" *ngIf="
              (formControls['partner_owner'].dirty ||
                formControls['partner_owner'].touched ||
                formError) &&
              formControls['partner_owner'].errors
            ">
            <div class="errorMessage" *ngIf="formControls['partner_owner'].errors.required">
              Opportunity Owner from Partner is required.
            </div>
          </div>
        </div> -->
      </div>
      <div class="row mrgbtmn">
        <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12 col-10">
          <h4 class="head_type fz-14 fc-black-1">
            Opportunity Name<sub class="required">*</sub>:
          </h4>
          <input matInput type="text" class="fc-blue border-none" placeholder="Enter Opportunity Name"
            formControlName="name" [(ngModel)]="opportunity.name" />
          <div class="mrgbtm" *ngIf="
              (formControls['name'].dirty ||
                formControls['name'].touched ||
                formError) &&
              formControls['name'].errors
            ">
            <div class="errorMessage" *ngIf="formControls['name'].errors.required">
              Opportunity name is required.
            </div>
          </div>
        </div>
        <hr class="col-12" style="padding: 0;" />
      </div>
      <div class="row mrgbtmn">
        <div class="col-lg-5 col-md-5 col-sm-6 col-xs-6 col-5 d-f f-d-c">
          <h4 class="head_type fz-14 fc-gray-1">
            Stage<sub class="required">*</sub>:
          </h4>
        </div>
        <div class="col-lg-5 col-md-5 col-sm-6 col-xs-6 col-5 d-f f-d-c">
          <ng-select #stage class="border-none stage" placeholder="Select Stage" formControlName="stage_id"
            [(ngModel)]="opportunity.stage_id" (change)="changeStage($event)" [items]="dealStages" bindLabel="label"
            bindValue="id" placeholder="Select Deal Stage"></ng-select>
          <!-- <select #stage class="fz-14 border-none mt-3 stage" formControlName="stage" [(ngModel)]="opportunity.stage"
            id="exampleFormControlSelect3" (change)="changeStage($event)">
            <option *ngFor="let sg of dealStages" [value]="sg">{{ sg }}</option>
          </select> -->
          <div class="mrgbtm" *ngIf="
              (formControls['stage'].dirty ||
                formControls['stage'].touched ||
                formError) &&
              formControls['stage'].errors
            ">
            <div class="errorMessage" *ngIf="formControls['stage'].errors.required">
              Stage is required.
            </div>
          </div>
        </div>
        <div class="col-2"></div>
      </div>
      <div class="row mrgbtmn">
        <div class="col-lg-5 col-md-5 col-sm-6 col-xs-6 col-5 d-f f-d-c">
          <h4 class="head_type fz-14 fc-gray-1">
            Deal Status<sub class="required">*</sub>:
          </h4>
        </div>
        <div class="col-lg-5 col-md-5 col-sm-6 col-xs-6 col-5 d-f f-d-c">
          <ng-select #deal_status class="border-none deal_status" placeholder="Select Deal Status"
            formControlName="deal_status" [(ngModel)]="opportunity.deal_status" (change)="changeStatus($event)"
            [items]="dealStatuses" bindLabel="label" bindValue="label" placeholder="Select Deal Status"></ng-select>
          <!-- <select #deal_status class="fz-14 border-none mt-3 deal_status" formControlName="deal_status"
            [(ngModel)]="opportunity.deal_status" id="exampleFormControlSelect2">
            <option *ngFor="let st of dealStatuses" [value]="st">{{
              st
            }}</option>
          </select> -->
          <div class="mrgbtm" *ngIf="
              (formControls['deal_status'].dirty ||
                formControls['deal_status'].touched ||
                formError) &&
              formControls['deal_status'].errors
            ">
            <div class="errorMessage" *ngIf="formControls['deal_status'].errors.required">
              Deal status is required.
            </div>
          </div>
          <div class="mrgbtm" *ngIf="errors_deal_status">
            <div class="errorMessage" *ngIf="errors_deal_status">
              {{ errors_deal_status }}
            </div>
          </div>
        </div>
      </div>
      <div class="row mrgbtmn">
        <div class="col-lg-5 col-md-5 col-sm-6 col-xs-6 col-5 d-f f-d-c">
          <h4 class="head_type fz-14 fc-gray-1">
            Order Status<sub class="required">*</sub>:
          </h4>
        </div>
        <div class="col-lg-5 col-md-5 col-sm-6 col-xs-6 col-5 d-f f-d-c">
          <ng-select class="border-none stage" formControlName="order_status_id"
            [(ngModel)]="opportunity.order_status_id" (change)="changeOrderStatus($event)" [items]="orderStatuses"
            bindLabel="label" bindValue="id" placeholder="Select Order Status"></ng-select>
          <!-- <select #stage class="fz-14 border-none mt-3 stage" formControlName="stage" [(ngModel)]="opportunity.stage"
            id="exampleFormControlSelect3" (change)="changeStage($event)">
            <option *ngFor="let sg of dealStages" [value]="sg">{{ sg }}</option>
          </select> -->
          <div class="mrgbtm" *ngIf="
              (formControls['order_status'].dirty ||
                formControls['order_status'].touched ||
                formError) &&
              formControls['order_status'].errors
            ">
            <div class="errorMessage" *ngIf="formControls['order_status'].errors.required">
              Order Status is required.
            </div>
          </div>
        </div>
        <div class="col-2"></div>
      </div>
      <!-- <div class="row mrgbtm">
        <div class="col-lg-5 col-md-5 col-sm-6 col-xs-6 col-5 d-f f-d-c">
          <h4 class="head_type fz-14 fc-gray-1">
            Value<sub class="required">*</sub>:
          </h4>
        </div>
        <div class="col-lg-5 col-md-5 col-sm-6 col-xs-6 col-2 d-f f-d-c">
          <div class="d-flex">
            <h4 class="fz-16 fc-blue" style="margin: auto;">
              $
            </h4>
            <input matInput type="text" class="fc-blue border-none" style="margin: 15px 0;"
              placeholder="Enter Opportunity Value" formControlName="value" [(ngModel)]="opportunity.value" />
          </div>
          <div class="mrgbtm" *ngIf="
              (formControls['value'].dirty ||
                formControls['value'].touched ||
                formError) &&
              formControls['value'].errors
            ">
            <div class="errorMessage" *ngIf="formControls['value'].errors.required">
              Opportunity value is required.
            </div>
            <div class="errorMessage" *ngIf="formControls['value'].errors.pattern">
              Opportunity value must be a number
            </div>
          </div>
        </div>
        <div class="col-2"></div>
      </div> -->
      <div class="row mrgbtm">
        <div class="col-lg-5 col-md-5 col-sm-6 col-xs-6 col-5 d-f f-d-c">
          <h4 class="head_type fz-14 fc-gray-1">
            Fixed Revenues<sub class="required">*</sub>:
          </h4>
        </div>
        <div class="col-lg-5 col-md-5 col-sm-6 col-xs-6 col-5 d-f f-d-c">
          <div class="d-flex">
            <!-- <h4 class="fz-16 fc-blue" style="margin: auto;">
              $
            </h4> -->
            <input matInput type="text" class="fc-blue border-none" style="margin: 15px 0;"
              placeholder="Enter Fixed Revenues" formControlName="fixed_revenue"
              [(ngModel)]="opportunity.fixed_revenue" />
          </div>
          <div class="mrgbtm" *ngIf="
              (formControls['fixed_revenue'].dirty ||
                formControls['fixed_revenue'].touched ||
                formError) &&
              formControls['fixed_revenue'].errors
            ">
            <div class="errorMessage" *ngIf="formControls['fixed_revenue'].errors.required">
              Fixed Revenue is required.
            </div>
            <div class="errorMessage" *ngIf="formControls['fixed_revenue'].errors.pattern">
              Fixed Revenue value must be a number
            </div>
          </div>
          <!-- <h4 class="data_field_a">
                    $5,000,000</h4> -->
        </div>
        <div class="col-2"></div>
      </div>
      <div class="row mrgbtm">
        <div class="col-lg-5 col-md-5 col-sm-6 col-xs-6 col-5 d-f f-d-c">
          <h4 class="head_type fz-14 fc-gray-1">
            Recurring Revenues:
          </h4>
        </div>
        <div class="col-lg-5 col-md-5 col-sm-6 col-xs-6 col-5 d-f f-d-c">
          <div class="row">
            <div class="col-11 r-pd-0">
              <div class="d-flex">
                <!-- <h4 class="fz-16 fc-blue" style="margin: auto;">
                  $
                </h4> -->
                <input matInput type="text" class="fc-blue border-none" style="margin: 15px 0;"
                  placeholder="Enter Recurring Revenues" formControlName="recurring_revenue"
                  [(ngModel)]="opportunity.recurring_revenue" />
              </div>
            </div>
            <div class="col-1 mg-hr-a l-pd-0 r-pd-0 ">per</div>
          </div>
          <div class="mrgbtm" *ngIf="
              (formControls['recurring_revenue'].dirty ||
                formControls['recurring_revenue'].touched ||
                formError) &&
              formControls['recurring_revenue'].errors
            ">
            <div class="errorMessage" *ngIf="formControls['recurring_revenue'].errors.required">
              Recurring Revenue is required.
            </div>
            <div class="errorMessage" *ngIf="formControls['recurring_revenue'].errors.pattern">
              Recurring Revenue value must be a number
            </div>
          </div>
          <div class="mrgbtm" *ngIf="
              (formControls['recurring_frequency'].dirty ||
                formControls['recurring_frequency'].touched ||
                formError) &&
              formControls['recurring_frequency'].errors
            ">
            <div class="errorMessage" *ngIf="formControls['recurring_frequency'].errors.required">
              Recurring Frequency is required.
            </div>
            <div class="errorMessage" *ngIf="formControls['recurring_frequency'].errors.pattern">
              Recurring Revenue value must be a number
            </div>
          </div>
        </div>
        <div class="col-2">
          <ng-select #stage class="border-none stage w-mc" style="margin: 15px 0;min-width: 100px;" placeholder="Select"
            formControlName="recurring_frequency" [(ngModel)]="opportunity.recurring_frequency" [items]="customTerms"
            bindLabel="name" bindValue="id" placeholder="Select Term"></ng-select>
        </div>
      </div>
      <div class="row mrgbtm">
        <div class="col-lg-5 col-md-5 col-sm-6 col-xs-6 col-5 d-f f-d-c">
          <h4 class="head_type fz-14 fc-gray-1">
            Term:
          </h4>
        </div>
        <div class="col-lg-5 col-md-5 col-sm-6 col-xs-6 col-5 d-f f-d-c">
          <input matInput type="text" class="fc-blue border-none" placeholder="Enter terms" formControlName="terms"
            [(ngModel)]="opportunity.terms" />
          <div class="mrgbtm" *ngIf="
              (formControls['terms'].dirty ||
                formControls['terms'].touched ||
                formError) &&
              formControls['terms'].errors
            ">
            <div class="errorMessage" *ngIf="formControls['terms'].errors.required">
              Term is required.
            </div>
            <div class="errorMessage" *ngIf="formControls['terms'].errors.pattern">
              Term value must be a number
            </div>
          </div>
        </div>
        <div class="col-2" style="margin-top: auto;margin-bottom: auto;padding-left: 0;">
          <span>
            {{formControls['recurring_frequency'].value + 's' | titlecase}}
          </span>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" style="padding-left: 80px; padding-top: 15px;">
      <div class="row mrgbtmn">
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6 d-f f-d-c">
          <h4 class="head_type fz-14 fc-gray-1">
            Probability<sub class="required">*</sub>:
          </h4>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6 d-f f-d-c">
          <input matInput type="text" class="fc-blue border-none" id="probability" placeholder="Probability"
            formControlName="probability" [(ngModel)]="opportunity.probability" />
          <div class="mrgbtm" *ngIf="
              (formControls['probability'].dirty ||
                formControls['probability'].touched ||
                formError) &&
              formControls['probability'].errors
            ">
            <div class="errorMessage" *ngIf="formControls['probability'].errors.required">
              Probability is required.
            </div>
            <div class="errorMessage" *ngIf="formControls['probability'].errors.pattern">
              Probability must be a number
            </div>
            <div class="errorMessage" *ngIf="formControls['probability'].errors.max">
              Probability must be less than 100
            </div>
          </div>
          <!-- <h5 class="data_field_a">30%</h5> -->
        </div>
      </div>
      <div class="row mrgbtmn">
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6 d-f f-d-c">
          <h4 class="head_type fz-14 fc-gray-1">
            Joint Plans:
          </h4>
          <span class="fz-14 fc-gray-1">(Optional)</span>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6 d-f f-d-c">
          <!-- (change)="setSolution(plan_id.value)" -->
          <ng-select #plan_id class="border-none plan_id" formControlName="plan_id" [(ngModel)]="opportunity.plan_id"
            [multiple]="true" (ngModelChange)="setPlanName($event)" [items]="planOptions" bindLabel="name"
            bindValue="id" placeholder="Select Plans">
          </ng-select>
          <!-- <select multiple #plan_id class="fz-14 border-none mt-3 plan_id" formControlName="plan_id"
            [(ngModel)]="opportunity.plan_id" id="exampleFormControlSelect6">
            <option *ngFor="let plan of plans" [value]="plan.id">
              {{ plan.plan_name }}
            </option>
          </select> -->
          <!-- <div class="mrgbtm" *ngIf="
                      (formControls['solution_id'].dirty ||
                        formControls['solution_id'].touched ||
                        formError) &&
                      formControls['solution_id'].errors
                    ">
                        <div class="errorMessage" *ngIf="formControls['solution_id'].errors.required">
                            Solution is required.
                        </div>
                    </div> -->
        </div>
      </div>
      <div class="row mrgbtmn">
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6 d-f f-d-c">
          <h4 class="head_type fz-14 fc-gray-1">
            Joint Solutions:
          </h4>
          <span class="fz-14 fc-gray-1">(Optional)</span>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6 d-f f-d-c">
          <!-- (change)="setSolution(solution_id.value)" -->
          <ng-select #solution_id class="border-none solution_id" formControlName="solution_id"
            [(ngModel)]="opportunity.solution_id" (ngModelChange)="setSolutionName($event)" [multiple]="true"
            [items]="solutionOptions" bindLabel="name" bindValue="id" placeholder="Select Solutions"></ng-select>
          <!-- <select multiple #solution_id class="fz-14 border-none mt-3 solution_id" formControlName="solution_id"
            [(ngModel)]="opportunity.solution_id" id="exampleFormControlSelect6">
            <option *ngFor="let sg of solutions" [value]="sg.id">
              {{ sg.solution_name }}
            </option>
          </select> -->
        </div>
      </div>
      <div class="row mrgbtmn">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 d-f f-d-c">
          <h4 class="head_type fz-14 fc-gray-1">
            Product Category:
          </h4>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 d-f f-d-c">
          <ng-select [multiple]="true" #product_category class="border-none product_category"
            formControlName="product_category" [(ngModel)]="opportunity.product_category"
            (change)="getProducts(opportunity.product_category)" [items]="productCategories" bindLabel="category_name"
            bindValue="category_name" placeholder="Select Category"></ng-select>
          <!-- <select #product_category class="fz-14 border-none mt-3 product_category" formControlName="product_category"
            [(ngModel)]="opportunity.product_category" (change)="getProducts(product_category.value)">
            <option value="" [selected]="!opportunity.product_category">Select</option>
            <option *ngFor="let cat of productCategories" [value]="cat.category_name">
              {{ cat.category_name }}
            </option>
          </select> -->
        </div>
      </div>
      <div class="row mrgbtmn">
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-12 d-f f-d-c">
          <h4 class="head_type fz-14 fc-gray-1">
            Product:
          </h4>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-12 d-f f-d-c">
          <ng-select [multiple]="true" #product class="border-none product" formControlName="product"
            [(ngModel)]="opportunity.product" [items]="products" bindLabel="product_name" bindValue="product_name"
            placeholder="Select Product"></ng-select>

          <!-- <select #product class="fz-14 border-none mt-3 product" formControlName="product" [(ngModel)]="opportunity.product">
            <option value="" [selected]="!opportunity.product">Select</option>
            <option *ngFor="let prod of products" [value]="prod.product_name">{{ prod.product_name }}
            </option>
          </select> -->
        </div>
      </div>
      <div class="row mrgbtmn">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 d-f f-d-c">
          <h4 class="head_type fz-14 fc-gray-1">
            Created By:
          </h4>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 d-f f-d-c">
          <h4 class="data_field_a fz-16 fc-black-1 fw-4">
            <span *ngIf="!this.opportunityId">
              {{ userData.first_name + " " + userData.last_name }}
            </span>
            <span
              *ngIf="this.opportunityId && (this.opportunity.created_user != null || this.opportunity.created_user != '')">
              {{ this.opportunity.created_user }}
            </span>
            <i class="fa fa-search" aria-hidden="true" style="padding-left: 15px; color: #038dff; float: right;"></i>
          </h4>
        </div>
      </div>
      <div class="row mrgbtmn">
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6 d-f f-d-c">
          <h4 class="head_type fz-14 fc-gray-1">
            Estimated Close Dt<sub class="required">*</sub>:
          </h4>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6 d-f f-d-c">
          <input type="text" id="date-closed" [(ngModel)]="opportunity.close_date" formControlName="close_date"
            placeholder="Close Date" autocomplete="off" />
          <div class="mrgbtm" *ngIf="
              (formControls['close_date'].dirty ||
                formControls['close_date'].touched ||
                formError) &&
              formControls['close_date'].errors
            ">
            <div class="errorMessage" *ngIf="formControls['close_date'].errors.required">
              Close Date is required.
            </div>
          </div>
        </div>
      </div>
      <div class="row mrgbtmn" *ngIf="selectedStage && (selectedStage.quoteDt || selectedStage.quoteVal)">
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6 d-f f-d-c">
          <h4 class="head_type fz-14 fc-gray-1">
            Latest Quote:
          </h4>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6 d-f f-d-c"></div>
      </div>
      <div class="row mrgbtmn" *ngIf="selectedStage && selectedStage.quoteDt">
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6 d-f f-d-c">
          <h4 class="head_type fz-14 fc-gray-1">
            Quote Dt:
          </h4>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6 d-f f-d-c">
          <input type="text" id="date2" [(ngModel)]="opportunity.quote_date" formControlName="quote_date"
            placeholder="Quote Date" autocomplete="off" />
          <div class="mrgbtm" *ngIf="
              (formControls['quote_date'].dirty ||
                formControls['quote_date'].touched ||
                formError) &&
              formControls['quote_date'].errors
            ">
            <div class="errorMessage" *ngIf="formControls['quote_date'].errors.required">
              Quote Date is required.
            </div>
          </div>
        </div>
      </div>
      <div class="row mrgbtmn" *ngIf="selectedStage &&  selectedStage.quoteVal">
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6 d-f f-d-c">
          <h4 class="head_type fz-14 fc-gray-1">
            Quote Value:
          </h4>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6 d-f f-d-c">
          <div class="d-flex">
            <!-- <h4 class="fz-16 fc-blue" style="margin: auto;">
              $
            </h4> -->
            <input matInput type="text" class="fc-blue border-none" style="margin: 15px 0;"
              placeholder="Enter Quote Value" formControlName="quote_value" [(ngModel)]="opportunity.quote_value" />
          </div>
          <div class="mrgbtm" *ngIf="
              (formControls['quote_value'].dirty ||
                formControls['quote_value'].touched ||
                formError) &&
              formControls['quote_value'].errors
            ">
            <div class="errorMessage" *ngIf="formControls['quote_value'].errors.required">
              Quote value is required.
            </div>
            <div class="errorMessage" *ngIf="formControls['quote_value'].errors.pattern">
              Quote value must be a number
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="isEdit || !opportunityId" class="row mrgbtm">
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6 d-f f-d-c">
          <h4 class="head_type fz-14 fc-gray-1">
            Add New Activity:
          </h4>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6">
          <a>
            <span style="font-size: 24px;" (click)="addActivity()" class="material-icons">
              add
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</form>
<div *ngIf="domainMatchError" class="row mrgbtm">
  <div class="col-md-12">
    <div align="center" class="errorMessage">
      Partner Owner(s) and Team members domains does not match.
    </div>
  </div>
</div>
<div *ngIf="formError" class="row mrgbtm">
  <div class="col-md-12">
    <div align="center" class="errorMessage">
      Please fill all details to create opportunity
    </div>
  </div>
</div>
<div class="row mrgbtmn">
  <div class="col-md-12">
    <div align="center">
      <a (click)="goBack()" type="button" class="btn cnclbtn fz-14">CANCEL</a>
      <button type="button" (click)="openConfirmPopup()" [disabled]="isProgress" *ngIf="!opportunityId"
        class="btn addbtn fz-14">
        CREATE OPPORTUNITY
      </button>
      <button type="button" (click)="isEdit = true" *ngIf="opportunityId && !isEdit" class="btn addbtn fz-14">
        EDIT OPPORTUNITY
      </button>
      <button type="button" [disabled]="isProgress" (click)="openConfirmPopup()" *ngIf="opportunityId && isEdit"
        class="btn addbtn fz-14">
        UPDATE
      </button>
    </div>
  </div>
</div>

<div class="modal" id="myModal2">
  <div class="modal-dialog">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header" style="text-align: left; display: inline;">
        <h4 class="modal-title">SDC Bank</h4>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <div class="mrgbtm row">
          <label for="inputPassword" class="col-sm-12 col-form-labelin">Name:</label>
          <div class="col-sm-12">
            <input type="text" id="" placeholder="Phone Banking...." />
          </div>
        </div>
        <div class="mrgbtm row">
          <label for="inputPassword" class="col-sm-4 col-form-label">Deal Status:</label>
          <div class="col-sm-8 p-r-10">
            <select id="exampleFormControlSelect4">
              <option>Commit</option>
              <option>won</option>
              <option>lost </option>
              <option>upside</option>
            </select>
          </div>
        </div>
        <div class="mrgbtm row">
          <label for="inputPassword" class="col-sm-4 col-form-label">Stage:</label>
          <div class="col-sm-8">
            <select id="exampleFormControlSelect5">
              <option>Closed</option>
              <option>Open</option>
            </select>
          </div>
        </div>
        <div class="mrgbtm row">
          <label for="inputPassword" class="col-sm-4 col-form-label">Value:</label>
          <div class="col-sm-8">
            <h5 class="data_field_a" style="padding-top: 7px;">5,000,000</h5>
          </div>
        </div>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer" style="text-align: center; display: inline;">
        <a href="index.html" type="button" class="btn cnclbtn" data-dismiss="modal">CANCEL</a>

        <button type="button" class="btn addbtn">SAVE CHANGES?</button>
      </div>
    </div>
  </div>
</div>

<!-----------model popup-------->
<!-- The Modal -->
<div class="modal" id="myModal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header" style="text-align: center; display: inline;">
        <h4 class="modal-title">Add Product</h4>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <div class="mrgbtm row">
          <label for="inputPassword" class="col-sm-4 col-form-label">Product Category:</label>
          <div class="col-sm-8 row">
            <div class="col-sm-6">
              <!-- <ng-select #product_category1 class="fz-14 border-none mt-3 product_category1" formControlName="product"  (change)="
              setIsCat = product_category1.value == -1 ? false : true"
                [(ngModel)]="opportunity.product" [items]="productCategories" bindLabel="category_name" bindValue="category_id"
                placeholder="Select Category"></ng-select> -->

              <select #product_category1 (change)="
                  setIsCat = product_category1.value == -1 ? false : true
                " class="data_field_a mt-3">
                <option value="" selected>Select</option>
                <option *ngFor="let cat of productCategories" [value]="cat.category_id">
                  {{ cat.category_name }}
                </option>
                <option value="-1">Add New</option>
              </select>
            </div>
            <div class="col-sm-6" [ngStyle]="{
                display: product_category1.value == -1 ? 'block' : 'none'
              }">
              <input type="text" #prod_cat placeholder="Enter...." style="min-height: 42px;" />
            </div>

            <div class="mrgbtm col-12" *ngIf="errors.category">
              <div class="errorMessage" *ngIf="errors.category">
                {{ errors.category }}
              </div>
            </div>
          </div>
        </div>
        <div class="mrgbtm row">
          <label for="inputPassword" class="col-sm-4 col-form-label">Product:</label>
          <div class="col-sm-8">
            <input type="text" #prod placeholder="Enter....." style="min-height: 42px;" />
            <div class="mrgbtm" *ngIf="errors.product">
              <div class="errorMessage" *ngIf="errors.product">
                {{ errors.product }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer" style="text-align: center; display: inline;">
        <a type="button" class="btn cnclbtn" data-dismiss="modal">CANCEL</a>

        <button type="button" class="btn addbtn" (click)="
            openConfirmModal(
              product_category1.value == -1
                ? prod_cat.value
                : product_category1.value,
              prod.value
            )
          ">
          CONFIRM
        </button>
      </div>
    </div>
  </div>
</div>
<!-----------model popup confirm-------->
<!-- The Modal -->
<div class="modal" id="myModalcnfrm">
  <div class="modal-dialog">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header" style="text-align: center; display: inline;">
        <h4 class="modal-title">Are You Sure?</h4>
      </div>

      <!-- Modal body -->
      <div class="modal-body" style="text-align: center; display: inline;">
        <p>You want to add product category and product.</p>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer" style="text-align: center; display: inline;">
        <button type="button" class="btn cnclbtn text-uppercase" data-dismiss="modal">
          CANCEL
        </button>

        <button type="button" [disabled]="isProgress" (click)="
            confirmProduct(
              product_category1.value == -1
                ? prod_cat.value
                : product_category1.value,
              prod.value
            )
          " class="btn addbtn text-uppercase">
          CONFIRM
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="confirmPopup">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal body -->
      <div class="modal-body" style="text-align: center; display: inline;">
        <h5 class="modal-title mrgbtm">Are You Sure?</h5>
        <p *ngIf="!opportunityId">
          Opportunity will be visible to <strong>everyone</strong> with access
          to the Plan
        </p>
        <p *ngIf="opportunityId">All changes will be saved, on confirmation</p>
      </div>
      <!-- Modal footer -->
      <div class="modal-footer" style="text-align: center; display: inline;">
        <button type="button" (click)="closeConfirmPopup()" class="btn cnclbtn">
          CANCEL
        </button>

        <button type="button" class="btn addbtn" (click)="submit()">
          CONFIRM
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="successModal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content modal-custom">
      <!-- Modal body -->
      <div *ngIf="displayResult == 'success'" class="modal-body" style="text-align: center; display: inline;">
        <!-- <h3 class="modal-title mrgbtm">Congratulations!</h3>
        <p>
          {{
            opportunityId
              ? "Your opportunity (" + displayId + ") is successfully updated"
              : "Your opportunity (" + displayId + ") is successfully created"
          }}
        </p> -->
        <div class="row mrgbtmn">
          <div class="col-md-12">
            <h5 *ngIf="!opportunityId" class="modal-title mrgbtm">Opportunity Created!</h5>
            <h5 *ngIf="opportunityId" class="modal-title mrgbtm">Opportunity Updated!</h5>
          </div>
        </div>
        <div class="row mrgbtm">
          <div class="col-md-12">
            <div align="center">
              <button (click)="this.goBack()" type="button" class="btn addbtn">
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="displayResult == 'failure'" class="modal-body" style="text-align: center; display: inline;">
        <div>
          <div class="row mrgbtm">
            <div class="col-md-12">
              <div align="center" class="errorMessage">
                {{ errorMessage }}
              </div>
            </div>
          </div>
          <div class="row mrgbtm">
            <div class="col-md-12">
              <div align="center">
                <button (click)="closeModal()" type="button" class="btn addbtn">
                  CLOSE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="displayResult == ''" class="modal-body" style="text-align: center; display: inline;">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <p>
          {{ opportunityId ? "Updating the" : "Creating a" }} Opportunity...
        </p>
      </div>
    </div>
  </div>
</div>
<div class="modal" id="teamModal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal body -->
      <div class="modal-body" style="text-align: center; display: inline; padding: 0;">
        <app-add-team [onlySuperUsers]="false" [teamList]="rawTeamList" [meData]="userDataPartners"
          [selfPartners]="selfPartners" [domainList]="this.domainList" [partner_owner_email]="this.userPartnerEmail"
          (submitForm)="saveTeam($event)" (closeModal)="closeTeam()" (domainMismatch)="domainChange($event)">
        </app-add-team>
      </div>
    </div>
  </div>
</div>