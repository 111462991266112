import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import * as moment from "moment";
declare var Highcharts: any, $: any;
@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {
  opportunties: any = [];
  partners: any = [];
  solutions: any = [];
  isLoader: any = true;
  opportunity_stages: any = []
  sulution_stages: any = []
  filter: any = {};
  years: any = []
  partner_types: any = []
  milisecondInDay: any = 86400000
  showPartners: any = 6;
  userData: any = {}
  constructor(
    private AuthService: AuthService,
  ) {
    let now = new Date()
    this.years = [now.getFullYear() - 1, now.getFullYear(), now.getFullYear() + 1]
  }


  getOpportunities() {
    this.AuthService.get('reports', (err) => {
    }).subscribe((result: any) => {
      //console.log("sol result is:", result)
      if (result.data) {
        this.opportunties = result.data;
        this.opportunties.map((a) => {
          console.log(a.solutions)
          let stageExist = this.opportunity_stages.filter((aa) => aa == a.stage);
          if (!stageExist.length && a.stage) {
            this.opportunity_stages.push(a.stage)
          }
          a.solutions.map((a11) => {
            if (a11) {
              let solstageExist = this.sulution_stages.filter((aa) => aa == a11.solution_stage);
              if (!solstageExist.length && a11) {
                this.sulution_stages.push(a11.solution_stage)
              }
              let solutionExist = this.sulution_stages.filter((aa) => aa.id == a11.id);
              if (!solutionExist.length && a11.id) {
                this.solutions.push(a11)
              }
            }
          });

          (a.partners || []).map((a1) => {
            let partnerExist = this.partners.filter((aa) => aa.partner_id == a1.partner_id)
            // console.log(partnerExist, this.partners, a1)
            if (!partnerExist.length && a1.partner_id) {
              this.partners.push(a1)
            }
            let partnerTypeExist = this.partner_types.filter((aa) => aa == a1.partner_type)
            // console.log(partnerExist, this.partners, a1)
            if (!partnerTypeExist.length && a1.partner_type) {
              this.partner_types.push(a1.partner_type)
            }
          })


        })
        this.isLoader = false;
        $('.loader-rp').hide()
        $('.report-div').removeClass('hide');
        setTimeout(() => {
          $('.ui').dropdown();
        }, 100);
        this.loadCharts()
        console.log(this)
      }
    }, (err: any) => {
      this.opportunties = [];
      //console.log(err);
      this.isLoader = false;
      this.loadCharts()
      $('.loader-rp').hide()
      $('.report-div').removeClass('hide');
      //console.log(err, err.status);
      if (err.status == 400)
        this.getOpportunities()
    });
  }

  ngOnInit(): void {
    this.getOpportunities()
  }

  getQuarterDates(date, type = 'Q') {
    let today = new Date(date);
    let endYear = new Date(moment(today).endOf('year').toDate());
    let dates = [], lastDate;

    //console.log(type)
    let quarter1;
    if (type == 'Q') {
      quarter1 = this.getQuarterStartEndDate(today);
    }
    if (type == 'M') {
      quarter1 = this.getMonthStartEndDate(today);
    }
    if (type == 'W') {
      quarter1 = this.getWeakStartEndDate(today);
    }
    let dt1 = quarter1.firstDate;
    let dt12 = lastDate = quarter1.endDate;
    dates.push([dt1, dt12]);
    // console.log(dates,lastDate, endYear,quarter1)
    if (type && type != 'Q' && type != ' ') {
      while (lastDate < endYear) {
        let quarter2;
        if (type == 'Q') {
          quarter2 = this.getQuarterStartEndDate(lastDate.getTime() + (85 * this.milisecondInDay));
        }
        if (type == 'M') {
          quarter2 = this.getMonthStartEndDate(lastDate.getTime() + (25 * this.milisecondInDay));
        }
        if (type == 'W') {
          quarter2 = this.getWeakStartEndDate(lastDate.getTime() + (6 * this.milisecondInDay));
        }
        //console.log(lastDate, quarter2)
        if (quarter2.endDate < endYear) {
          lastDate = quarter2.endDate;
          dates.push([quarter2.firstDate, quarter2.endDate])
        } else {
          if (quarter2.firstDate < endYear) {
            lastDate = endYear;
            dates.push([quarter2.firstDate, endYear])
            break;
          } else {
            break;
          }
        }
      }
    } else {
      let quarter2 = this.getQuarterStartEndDate(dt12.getTime() + (85 * this.milisecondInDay))
      let dt2 = quarter2.firstDate;
      let dt22 = quarter2.endDate;
      dates.push([dt2, dt22])
      let quarter3 = this.getQuarterStartEndDate(dt22.getTime() + (85 * this.milisecondInDay))
      let dt3 = quarter3.firstDate;
      let dt32 = quarter3.endDate;
      dates.push([dt3, dt32])
      let quarter4 = this.getQuarterStartEndDate(dt32.getTime() + (85 * this.milisecondInDay))
      let dt4 = quarter4.firstDate;
      let dt42 = quarter4.endDate;
      dates.push([dt4, dt42])
      // console.log(dates)
    }
    return dates
  }
  async getSeries(DATES, ops, type = 'Q') {
    return new Promise(async (resolve, reject) => {
      let i = 0;
      let series = DATES.map((a) => {
        let label = a.map((aa) => moment(aa).format('L')).join(' to ')
        label = type == 'Q' ? 'Q' + moment(a[0]).quarter() + ' ' + new Date(a[0]).getFullYear() : type == 'M' ? moment(a[0]).format('MMM-YYYY') : 'W' + moment(a[0]).week() + ' ' + new Date(a[0]).getFullYear();
        i++
        return [label, 0, 90];
      })
      // console.log(ops, DATES)
      for (let i = 0; i < DATES.length; i++) {
        let total = 0
        await ops.map((a) => {
          let opdate: any = new Date(a.close_date);
          //console.log(opdate, DATES[i])
          if (opdate.getTime() >= DATES[i][0].getTime() && opdate.getTime() <= DATES[i][1].getTime()) {
            total = Number(total) + Number(a.value)
          }
        })
        series[i][1] = total;
      }
      resolve(series)
    })
  }
  async loadCharts(chart?) {
    Highcharts.setOptions({
      lang: {
        decimalPoint: '.',
        thousandsSep: ','
      }
    });
    let today = new Date()
    let DATES = []
    let ops = []
    if (!chart || chart == 1) {
      let series1: any = []
      DATES = this.getQuarterDates(today)
      //console.log(this.filter)
      let status = true;
      if (chart == 1) {
        if (this.filter.year && this.filter.year != ' ') {
          today = new Date(new Date(new Date().setFullYear(this.filter.year)).setMonth(1))
          DATES = this.getQuarterDates(today)
        }

      }
      ops = this.opportunties.filter(a => {
        let status = true;
        // if (a.stage == 'Closed') {
        //   status = false
        // }
        if (chart == 1) {
          if (this.filter.stage)
            if ((this.filter.stage != ' ' && this.filter.stage != a.stage)) {
              status = false
            }
          if (this.filter.partner)
            if ((this.filter.partner != ' ' && !(a.partners || []).filter((aa) => aa.partner_id == this.filter.partner).length)) {
              status = false
            }
        }
        return status;
      })
      series1 = await this.getSeries(DATES, ops)
      console.log(series1, this.filter, ops)
      Highcharts.chart('bar-1', {
        // lang: {
        //   thousandsSep: ','
        // },
        chart: {
          type: 'variwide'
        },

        title: {
          text: 'Pipeline'
        },

        // subtitle: {
        //   text: 'Source: <a href="http://ec.europa.eu/eurostat/web/' +
        //     'labour-market/labour-costs/main-tables">eurostat</a>'
        // },

        xAxis: {
          enabled: false,
          type: 'category'
        },

        caption: {
          text: 'Sum of Opportunity value'
        },

        legend: {

        },
        colors: ['#3DAFB1', '#8C4CFF', '#5BE1E8', '#2171b5'],
        series: [{
          name: 'Opportunities',
          data: series1,
          dataLabels: {
            enabled: false,
            format: '&dollar;{point.y:,.0f}'
          },
          tooltip: {
            pointFormat: 'Opportunity Value : <b> {point.y:,.0f}</b><br>'
          },
          showInLegend: false,
          colorByPoint: true
        }]

      });
    }
    // Create the bar chart
    if (!chart || chart == 2) {
      let series2: any = []
      // console.log(dt1, dt12, dt2, dt22, dt3, dt32, dt4, dt42)
      if (!this.filter.year2 || this.filter.year2 == ' ') {
        //console.log("call date 0")
        DATES = this.getQuarterDates(today, this.filter.time2 == ' ' ? 'Q' : this.filter.time2)
      }
      if (chart == 2) {

        if (this.filter.year2 && this.filter.year2 != ' ') {
          today = new Date(moment(new Date().setFullYear(this.filter.year2)).startOf('year').toDate())
          // console.log(today)
          if (!this.filter.time2 || this.filter.time2 == ' ') {
            //console.log("call date 1")
            DATES = this.getQuarterDates(today, this.filter.time2 == ' ' ? 'Q' : this.filter.time2)
          }
        }
        if (this.filter.time2 && this.filter.time2 != ' ') {
          if (this.filter.year2 && this.filter.year2 != ' ') {
            today = new Date(moment(new Date().setFullYear(this.filter.year2)).startOf('year').toDate())
          }
          //console.log("call date 2", DATES.length)
          if (!DATES.length) {
            //console.log("call date 2")
            DATES = this.getQuarterDates(today, this.filter.time2 == ' ' ? 'Q' : this.filter.time2)
          }
        }
      }
      ops = this.opportunties.filter(a => {
        let status = true;
        if (a.stage != 'Closed') {
          status = false
        }
        if (chart == 2) {

          if (this.filter.partner2)
            if ((this.filter.partner2 != ' ' && !(a.partners || []).filter((aa) => aa.partner_id == this.filter.partner2).length)) {
              status = false
            }
        }
        return status
      })
      series2 = await this.getSeries(DATES, ops, this.filter.time2 == ' ' ? 'Q' : this.filter.time2)
      //console.log(series2, this.filter)
      Highcharts.chart('bar-2', {
        chart: {
          type: 'variwide'
        },

        title: {
          text: 'Opportunities closed'
        },

        // subtitle: {
        //   text: 'Source: <a href="http://ec.europa.eu/eurostat/web/' +
        //     'labour-market/labour-costs/main-tables">eurostat</a>'
        // },

        xAxis: {
          enabled: false,
          type: 'category'
        },

        caption: {
          text: 'Sum of Opportunity closed'
        },

        legend: {
          enabled: true,
        },
        colors: ['#3DAFB1', '#8C4CFF', '#5BE1E8', '#2171b5'],
        series: [{
          name: 'Opportunities',
          data: series2,
          dataLabels: {
            enabled: false,
            format: '&dollar;{point.y:.f}'
          },
          tooltip: {
            pointFormat: 'Opportunity Value: <b> {point.y:,.0f}</b><br>'
          },
          showInLegend: false,
          colorByPoint: true
        }]

      });
    }
    // Build the pie chart
    if (!chart || chart == 3) {
      let partners = this.partners.filter((a) => {
        let status = true;
        if (chart == 3) {
          if (this.filter.partner_type3 && this.filter.partner_type3 != ' ') {
            if (a.partner_type != this.filter.partner_type3) {
              status = false
            }
          }
        }
        return status;
      });
      let series3 = partners.map((p) => {
        let total = 0;
        ops = this.opportunties.filter(a => {
          let status = true;
          // if (a.stage == 'Closed') {
          //   status = false
          // }
          if (chart == 3) {
            if (this.filter.year3 && this.filter.year3 != ' ') {
              let startYear = new Date(moment(new Date().setFullYear(this.filter.year3)).startOf('year').toDate())
              let endYear = new Date(moment(new Date().setFullYear(this.filter.year3)).endOf('year').toDate())
              if (new Date(a.close_date) < startYear || new Date(a.close_date) > endYear) {
                status = false
              }
            }
            if (this.filter.stage3)
              if ((this.filter.stage3 != ' ' && this.filter.stage3 != a.stage)) {
                status = false
              }
          }
          // console.log(opDoamain[1], status, this.filter)
          if (status) {
            if ((a.partners || []).filter((aa) => aa.partner_id == p.partner_id).length) {
              total = total + Number(a.value)
            }
          }
          return a
        })
        return { name: p.partner_name, y: total };
      }).sort((a, b) => b.y - a.y)
      //console.log(series3)
      let seriesAr = [];
      for (let i = 0; i < series3.length; i++) {
        if (seriesAr.length < this.showPartners) {
          seriesAr.push(series3[i])
          if (seriesAr.length == this.showPartners) {
            seriesAr[this.showPartners - 1].name = 'Others'
          }
        } else {
          seriesAr[this.showPartners - 1].y = seriesAr[this.showPartners - 1].y + series3[i].y;
        }
      }
      //console.log(series3, seriesAr)
      Highcharts.chart('pieghraph', {

        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
        },
        title: {
          text: 'Opportunity by Partner'
        },
        tooltip: {
          pointFormat: '{series.name} : <b>{point.y:,.0f}</b>'
        },
        accessibility: {
          point: {
            valueSuffix: '%'
          }
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            colors: ['#9ecae1', '#08306b', '#08519c', '#2171b5', '#4292c6', '#6baed6'],
            dataLabels: {
              enabled: true,
              format: '<b>{point.name} </b><br>{point.y:,.0f}',
              distance: -50,
              filter: {
                property: 'percentage',
                operator: '>',
                value: 4
              }
            }
          }
        },
        series: [{
          name: 'Opportunity Value',
          data: seriesAr,/* [
            { name: 'Flyero', y: 61.41 },
            { name: 'Logicshore', y: 11.84 },
            { name: 'Risban', y: 10.85 },
          ],*/
          showInLegend: true
        }]
      });
    }
    if (!chart || chart == 4) {
      let series4 = this.solutions.map((s) => {
        let total = 0;
        let status2 = true;
        if (chart == 4) {
          if (this.filter.solution_stage4)
            if ((this.filter.solution_stage4 != ' ' && this.filter.solution_stage4 != s.solution_stage)) {
              status2 = false
            }
        }
        if (status2) {
          this.opportunties.map(a => {
            let status = true;
            if (chart == 4) {
              if (this.filter.year4 && this.filter.year4 != ' ') {
                let startYear = new Date(moment(new Date().setFullYear(this.filter.year4)).startOf('year').toDate())
                let endYear = new Date(moment(new Date().setFullYear(this.filter.year4)).endOf('year').toDate())
                if (new Date(a.close_date) < startYear || new Date(a.close_date) > endYear) {
                  status = false
                }
              }
              if (this.filter.stage4)
                if ((this.filter.stage4 != ' ' && this.filter.stage4 != a.stage)) {
                  status = false
                }
              if (this.filter.partner4)
                if ((this.filter.partner4 != ' ' && !(a.partners || []).filter((aa) => aa.partner_id == this.filter.partner4).length)) {
                  status = false
                }
            }
            //console.log(opDoamain[1], status, this.filter)
            if (status) {
              if ((a.solution_id || []).indexOf(s.id) !== -1) {
                total = total + Number(a.value)
              }
            }
          });
        }
        return [s.solution_name, total, 90]
      })
      // console.log(series4)
      Highcharts.chart('pyramid', {
        chart: {
          type: 'variwide'
        },

        title: {
          text: 'Opportunity by Solution'
        },

        // subtitle: {
        //   text: 'Source: <a href="http://ec.europa.eu/eurostat/web/' +
        //     'labour-market/labour-costs/main-tables">eurostat</a>'
        // },

        xAxis: {
          enabled: false,
          type: 'category'
        },

        caption: {
          text: 'Sum of Opportunity by solution'
        },

        legend: {

        },
        colors: ['#3DAFB1', '#8C4CFF', '#5BE1E8'],
        series: [{
          name: 'Opportunities',
          data: series4,
          dataLabels: {
            enabled: false,
            format: '&dollar;;{point.y:.f}'
          },
          tooltip: {
            pointFormat: 'Opportunity Value: <b> {point.y:,.0f}</b><br>'
          },
          showInLegend: false,
          colorByPoint: true
        }]
      });
    }
  }
  getQuarterStartEndDate(date) {
    var now = new Date(date);
    var quarter = Math.floor((now.getMonth() / 3));
    var firstDate = new Date(now.getFullYear(), quarter * 3, 1);
    var endDate = new Date(firstDate.getFullYear(), firstDate.getMonth() + 3, 0);
    return {
      firstDate: new Date(moment(firstDate).format('YYYY-MM-DD') + ' 00:01'),
      endDate: new Date(moment(endDate).format('YYYY-MM-DD') + ' 23:59')
    }
  }
  getMonthStartEndDate(date) {
    var now = new Date(date);
    var firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
    var lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    return {
      firstDate: new Date(moment(firstDay).format('YYYY-MM-DD') + ' 00:01'),
      endDate: new Date(moment(lastDay).format('YYYY-MM-DD') + ' 23:59')
    }
  }
  getWeakStartEndDate(date) {
    var now = new Date(date);
    var startOfWeek = new Date(moment(now).startOf('week').toDate());
    var endOfWeek = new Date(moment(now).endOf('week').toDate());
    // console.log(now, startOfWeek)
    return {
      firstDate: startOfWeek,
      endDate: endOfWeek
    }
  }
}
