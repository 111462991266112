<div>
  <form [formGroup]="signinForm">
    <div class="row">
      <div class="col-md">
        <input
        matInput
          type="password"
          formControlName="new_password"
          placeholder="Enter new password"
        />
        <div
          *ngIf="
            (formControls['new_password'].dirty ||
              formControls['new_password'].touched ||
              formError) &&
            formControls['new_password'].errors
          "
        >
          <div
            class="errorMessage"
            *ngIf="formControls['new_password'].errors.required"
          >
            Password is required
          </div>
          <div
            class="errorMessage"
            *ngIf="formControls['new_password'].errors.pattern"
          >
            Password should be Min *=8 digit alphanumeric, with a special
            character and atleast one capital alphabet
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md">
        <input
        matInput
          type="password"
          formControlName="confirmPassword"
          placeholder="Confirm New Password"
        />
        <div
          *ngIf="
            (formControls['confirmPassword'].dirty ||
              formControls['confirmPassword'].touched ||
              formError) &&
            formControls['confirmPassword'].errors
          "
        >
          <div
            class="errorMessage"
            *ngIf="formControls['confirmPassword'].errors.required"
          >
            Confirm Password is required
          </div>
          <div
            class="errorMessage"
            *ngIf="formControls['confirmPassword'].errors.mustMatch"
          >
            Password is not matching
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="formError && !this.signinForm.valid">
      <div class="row">
        <div class="col" style="text-align: center;">
          <div class="errorMessage">
            {{ errMessage }}
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md">
        <button
          type="button"
          (click)="submit()"
          class="btn btn-block btn-primary submit"
        >
          Change password
        </button>
      </div>
    </div>
  </form>
</div>

<div id="success_modal" class="modal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body" style="text-align: center;">
        <div class="row" style="margin-top: 20px; margin-bottom: 20px;">
          <div *ngIf="respStatus == 'success'" class="col">
            Password changes are saved
          </div>
          <div *ngIf="respStatus == 'error'" class="col">
            {{ respMessage }}
          </div>
        </div>
        <div class="row mrgbtmn">
          <div class="col">
            <button
              (click)="goToLogin()"
              class="btn addbtn"
              style="min-width: 100px;"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
