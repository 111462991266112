import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { PlanInfo } from '../models/plan.model';
import { AuthService } from '../services/auth.service';
import { NavigationHelperService } from '../services/navigation-helper.service';
import { LocalStorageService } from '../services/local-storage.service';
import * as moment from 'moment';
declare var Highcharts: any;
declare var $: any;
@Component({
  selector: 'app-plan-dashboard',
  templateUrl: './plan-dashboard.component.html',
  styleUrls: ['./plan-dashboard.component.css']
})
export class PlanDashboardComponent implements OnInit, AfterViewInit {
  @ViewChild('planSort', { static: false }) planSort: MatSort;
  // @ViewChild('planPaginator', { static: false }) planPaginator: MatPaginator;
  plan: any = [];
  loadedPlans: boolean = false;
  message: string = '';
  planButton: boolean = false;
  totalTargetValue: any = '';
  userPartnerId: any;
  userData: any = {};
  filterData: any = {};
  filterOption: any = { items: [], partners: [], users: [], createdBy: [] };
  constructor(private AuthService: AuthService,
    private localStorageService: LocalStorageService,
    private router: Router, private navHelper: NavigationHelperService) {
    this.userData = this.localStorageService.get({ name: 'userData' })
    this.userData = this.userData ? JSON.parse(this.userData) : {}
    if (this.userData.partner) {
      this.userPartnerId = this.userData.partner.partner_id;
    }
    if (this.navHelper.navigationTracker.length == 0) {
      this.navHelper.setUrl(this.router.url)
    }
  }
  public planData = new MatTableDataSource<any>();
  public planColumns = ['id', 'plan_name', 'partners', 'target', 'achievement', 'oppy'];
  ngOnInit(): void {
    // (<any>window).analytics.page();
    this.getPartners();
    this.getUsers();
    this.jointPlan();
    this.datePickerApply();
  }
  allUsers: any[] = [];
  getUsers() {
    this.AuthService.get('admin/users').subscribe(
      (result) => {
        console.log(result);
        this.allUsers = result.data
      },
      (err) => {
        console.log(err.error);
        this.allPartners = []
      }, () => {
      }
    )
  }
  filter() {
    this.getChartData(this.plan.filter((aa) => {
      let status = true;
      if (this.filterData.start_date) {
        if (new Date(aa.end_date).getTime() <= new Date(moment(this.filterData.start_date).startOf('day').format('YYYY-MM-DD h:mm:ss')).getTime()) {
          status = false;
        }
      }
      if (this.filterData.end_date) {
        if (new Date(aa.start_date).getTime() >= new Date(moment(this.filterData.end_date).endOf('day').format('YYYY-MM-DD h:mm:ss')).getTime()) {
          status = false;
        }
      }
      if (this.filterData.team && this.filterData.team.length) {
        let stat = false;
        aa.team.map((aaa) => {
          if (this.filterData.team.indexOf(aaa.email) != -1) {
            stat = true;
          }
        })
        if (!stat) {
          status = false;
        }
      }
      if (this.filterData.createdBy && this.filterData.createdBy.length) {
        let stat = false;
        if (this.filterData.createdBy.indexOf(aa.created_by) != -1) {
          stat = true;
        }
        if (!stat) {
          status = false;
        }
      }
      if (this.filterData.partner && this.filterData.partner.length) {
        let stat = false;
        aa.partners.map((aaa) => {
          if ((this.filterData.partner.length == 1) && (this.filterData.partner.indexOf(this.userPartnerId) != -1)) {
            if ((aa.partners.length == 1) && (aa.partners.indexOf(this.userPartnerId) != -1)) {
              stat = true;
            }
          } else {
            if (this.filterData.partner.indexOf(aaa) != -1) {
              stat = true;
            }
          }
        })
        if (!stat) {
          status = false;
        }
      }
      if (this.filterData.plan && this.filterData.plan.length) {
        let stat = false;
        if (this.filterData.plan.indexOf(aa.id) != -1) {
          stat = true;
        }
        if (!stat) {
          status = false;
        }
      }
      return status;
    }));
    $('#filterModal').modal('hide');
  }
  resetFilter() {
    this.filterData = {};
    $('#date1').val('');
    $('#date2').val('');
    this.datePicker1.destroy();
    this.datePicker2.destroy();
    this.datePickerApply();
  }
  datePickerApply() {
    setTimeout(() => {
      this.datePicker1 = $('#date1').datepicker({
        format: 'mm/dd/yyyy',
        close: (e) => {
          let date = $('#date1').val()
          this.filterData.start_date = date;
        }
      });
      this.datePicker2 = $('#date2').datepicker({
        format: 'mm/dd/yyyy',
        close: (e) => {
          let date = $('#date2').val()
          this.filterData.end_date = date;
        }
      });
    }, 100);
  }
  datePicker1: any;
  datePicker2: any;
  ngAfterViewInit(): void {
    this.planData.sort = this.planSort;
    // this.planData.paginator = this.planPaginator;
  }
  planGraphData: any = [];
  async getChartData(data) {
    let totalTarget: any = 0;
    let totalAchievement: any = 0;
    let remainingAchievement: any = 0;
    data.map((plan) => {
      let count = 0;
      let planAchievement = 0;
      totalTarget = totalTarget + Number(plan.target)
      this.opportunities.filter((a) => a.plan_id.includes(plan.id)).map((aa) => {
        if (aa.deal_status != 'Lost') {
          count = count + 1;
        }
        if (aa.stage == 'Closed' && aa.deal_status == 'Won') {
          totalAchievement = totalAchievement + Number(aa.value)
          planAchievement = planAchievement + Number(aa.value)
        }
      })
      plan.oppy = count;
      plan.achievement = planAchievement;
    })
    this.totalTargetValue = totalTarget;
    remainingAchievement = totalTarget - totalAchievement
    remainingAchievement = remainingAchievement > 0 ? remainingAchievement : 0;
    this.planData.data = data;
    this.planGraphData = [];
    // this.options.series[0].data = [];
    if (totalAchievement != 0 || remainingAchievement != 0) {
      this.planGraphData.push(['Achieved Target', totalAchievement], ['Remaining Target', remainingAchievement])
      // this.options.series[0].data.push(['Achieved Target', totalAchievement]);
      // this.options.series[0].data.push(['Remaining Target', remainingAchievement]);
      setTimeout(() => {
        Highcharts.setOptions({
          lang: {
            thousandsSep: ','
          }
        });
        this.genChart(this.planGraphData);
      }, 100)
    }
  }
  openFilterModal() {
    $('#filterModal').modal('show');
  }
  opportunities: any = []
  getOpportunities() {
    this.AuthService.get('opportunities', (err) => {
    }).subscribe((result: any) => {
      if (result.data) {
        this.opportunities = result.data;
        this.getChartData(this.plan)
      } else {
        this.opportunities = [];
        this.getChartData(this.plan)
      }
    }, (err: any) => {
      this.opportunities = [];
      this.getChartData(this.plan)
    });
  }
  jointPlan() {
    this.AuthService.get('plans', (err) => {
    }).subscribe(async (result: any) => {
      console.log("result is:", result.data)
      if (result.data) {
        this.plan = (result.data || []).filter(plan => (!plan.email || plan.email == ""))
          .map((a) => {
            if (a.partnerDetails) {
              a.partnerName = a.partnerDetails.filter((aa) => aa.partner_id != this.userPartnerId).map((aa) => aa.partner_name)
            }
            return a;
          }).sort((a: any, b: any) => {
            var keyA = new Date(a.updated_at || a.created_at || 0),
              keyB = new Date(b.updated_at || b.created_at || 0);
            if (keyA > keyB) return -1;
            if (keyA < keyB) return 1;
            return 0;
          })
        this.plan.forEach((plan) => {
          this.filterOption.items = [...this.filterOption.items, { id: plan.id, name: plan.plan_name }];
          plan.partnerDetails.map((aa) => {
            if (!this.filterOption.partners.filter((a11) => a11.id == aa.partner_id).length) {
              // let partner = this.allPartners.find((a) => a.partner_id == aa)
              this.filterOption.partners = [...this.filterOption.partners, { id: aa.partner_id, name: aa.partner_id != this.userPartnerId ? aa.partner_name : 'Internal' }]
            }
          })
          this.allUsers.forEach((user) => {
            if (!this.filterOption.createdBy.filter((a11) => a11.id == plan.created_by).length) {
              if (plan.created_by == user.user_id) {
                this.filterOption.createdBy = [...this.filterOption.createdBy, { id: user.user_id, name: user.email }];
              }
            };
          })
          if (Array.isArray(plan.team)) {
            plan.team.map((aa) => {
              if (!this.filterOption.users.filter((a11) => a11.id == aa.email).length) {
                this.filterOption.users = [...this.filterOption.users, { id: aa.email, name: aa.email }];
              }
            })
          }
        })
        this.getOpportunities()
      } else {
        this.plan = [];
      }
      this.loadedPlans = true;
    }, (err: any) => {
      this.plan = [];
      this.loadedPlans = true;
    });
  }
  getPartnerName(array) {
    let listOfPartners: string[] = [];
    this.allPartners.forEach(partner => {
      array.forEach((element, i) => {
        if (partner.partner_id == element)
          listOfPartners.push(partner.partner_name);
      });
    })
    return listOfPartners
  }
  allPartners: any[] = [];
  getPartners() {
    this.AuthService.get('partners').subscribe(
      (result) => {
        console.log(result);
        this.allPartners = result.data
      },
      (err) => {
        console.log(err.error);
        this.allPartners = []
      }, () => {
      }
    )
  }
  $this = this;
  genChart(data) {
    let $this = this;
    Highcharts.chart('container', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
        events: {
          load: function () {
            $this.addLabel(this)
          },
          redraw: function () {
            $this.addLabel(this)
          }
        }
      },

      title: {
        text: 'Achievement',
        align: 'center',
        verticalAlign: 'middle',
        y: 100
      },
      tooltip: {
        pointFormat: '<b>{point.y:,.0f}</b>'
      },
      exporting: {
        enabled: false
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: false,
            distance: -50,
            style: {
              fontWeight: 'bold',
              color: 'white'
            }
          },
          startAngle: -90,
          endAngle: 90,
          center: ['50%', '75%'],
          size: '110%'
        }
      },
      colors: [
        '#2fc2a8', '#f15424'
      ],
      series: [{
        type: 'pie',
        innerSize: '0%',
        data: data
      }]
    })
  }
  addLabel(chart) {
    console.log(chart);
    let series = chart.series[0]
    let point = series.points[0];
    let plotX = point.shapeArgs.x;
    let plotY = point.shapeArgs.y;
    let pieRadius = point.shapeArgs.r
    $('#target-start').remove();
    $('#target-end').remove();
    chart.renderer.html('0', plotX - pieRadius, plotY + 25).attr({ id: `target-start` }).css({
      fontSize: '15px',
      color: '#44a2f8'
    }).add();
    chart.renderer.html(`${this.totalTargetValue.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`, plotX + pieRadius, plotY + 25)
      .attr({ id: `target-end` })
      .css({
        fontSize: '15px',
        color: '#44a2f8'
      }).add();
    let translateX = $('#target-end').width();
    $('#target-end').css({ "transform": `translate(-${translateX / 2}px,0)` });
  }
}
