import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigationHelperService {
  navigationTracker: string[] = [];
  constructor(private router: Router) {
    this.router.events.subscribe((a) => {
      let route = '/' + this.router.url.split('/')[1]
      if (a instanceof NavigationEnd) {
        this.navigationTracker.push(route)
      }
    })
  }
  setUrl(x: string) {
    this.navigationTracker.push(x)
  }

  getUrl(): string {
    this.navigationTracker.pop()
    return this.navigationTracker.pop()
  }
}
