import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  IMultiSelectOption,
  IMultiSelectSettings,
  IMultiSelectTexts
} from "angular-2-dropdown-multiselect";
import { AuthService } from '../services/auth.service';
import { LocalStorageService } from '../services/local-storage.service';
import { NavigationHelperService } from '../services/navigation-helper.service';
import * as  moment from "moment";
import { CommonEmitterService } from '../services/common-emitter';
declare var $: any;

@Component({
  selector: 'app-create-plan',
  templateUrl: './create-plan.component.html',
  styleUrls: ['./create-plan.component.css']
})
export class CreatePlanComponent implements OnInit {
  createPlanForm: FormGroup
  formControls: any
  planId: any = ''
  plans: any = {};
  defaultConnectionName: string;
  defaultPartnerEmail: string;
  selectedPartnerDomain: string;
  formError: boolean = false;
  multiSelectionSettings: IMultiSelectSettings = {
    enableSearch: true,
    checkedStyle: "checkboxes",
    buttonClasses: "btn btn-default fz-16 fc-blue mw-200",
    dynamicTitleMaxItems: 0
  };
  displayResult: string = '';
  errorMessage: string = '';
  partners: any[];
  userData: any = {};
  userSubscription: { type: any, data: any, create_partner: boolean, create_user: boolean } = { type: '', data: '', create_partner: true, create_user: true };
  multiSelectionTexts: IMultiSelectTexts = {
    defaultTitle: 'Display Name',
    checked: 'added',
    checkedPlural: "added"
  }
  userDataPartners: any = [];
  constructor(
    private cdRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private _location: Location,
    private AuthService: AuthService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private CommonEmitterService: CommonEmitterService,
    private LocalStorageService: LocalStorageService,
    private navHelper: NavigationHelperService
  ) {
    localStorage.removeItem('redirect')
    let partner = this.LocalStorageService.get({ name: 'userData' })
    partner = JSON.parse(partner);
    this.userData = partner || {};
    if (this.userData.subscription_type_data != null) {
      this.userSubscription.type = 'subscriber'
      this.userSubscription.data = this.userData.subscription_type_data;
    } else {
      this.userSubscription.type = 'partner'
      this.userSubscription.data = this.userData.subscriber_subscription_type_data;
      if (this.userSubscription.data) {
        this.userSubscription.create_user = this.userSubscription.data.user_by_partner
      }
    }
    if (this.navHelper.navigationTracker.length == 0) {
      this.navHelper.setUrl(this.router.url)
    }
    this.activeRoute.queryParams.subscribe((a) => {
      if (a.partnerId) {
        localStorage.removeItem('redirect');
        this.defaultConnectionName = a.partnerId;
      }
    });
    this.createPlanForm = this.formBuilder.group({
      plan_name: ['', Validators.compose([Validators.required])],
      // partner2_id: ['', Validators.compose([Validators.required])],
      partners: [],
      target: ['', Validators.compose([Validators.required, Validators.pattern("^[0-9]*(\\.[0-9]{1,})?$")])],
      budget: ['', Validators.compose([Validators.pattern("^[0-9]*(\\.[0-9]{1,})?$")])],
      description: ['', Validators.compose([Validators.required])],
      start_date: ['', Validators.compose([Validators.required])],
      duration: ['30', Validators.compose([Validators.min(1)])],
      teams: ['', Validators.compose([Validators.minLength(1)])],
    })
    this.formControls = this.createPlanForm.controls;
  }
  ngOnInit(): void {
    var d = new Date();
    d.setDate(d.getDate() - 1);
    setTimeout(() => {
      $('#date1').datepicker({
        format: 'mm/dd/yyyy',
        minDate: d,
        close: (e) => {
          let date = $('#date1').val()
          this.createPlanForm.patchValue({ start_date: date });
          console.log('Change is fired' + $('#date1').val());
        }
      });
      let planData = JSON.parse(localStorage.getItem('planData2'));
      if (planData != null) {
        this.plans = planData;
      }
      let planPartners = JSON.parse(localStorage.getItem('planPartners'));
      if (planPartners != null) {
        this.selectedConnections = planPartners;
      }
      let planTeamMembers = JSON.parse(localStorage.getItem('planTeamMembers'));
      if (planTeamMembers != null) {
        this.selectedTeamMembers = planTeamMembers;
      }
      localStorage.removeItem('planData2');
      localStorage.removeItem('planPartners');
      localStorage.removeItem('planTeamMembers');
    }, 100);
    this.activeRoute.params.subscribe((a) => {
      if (a.planId) {
        this.planId = a.planId;
        this.loadUserDomain();
        setTimeout(() => {
          this.getPlanDetail()
        }, 500);
      } else {
        this.loadUserDomain();
      }
    })


  }
  getPlanDetail() {
    this.AuthService.get('plans/' + this.planId).subscribe(
      (result: any) => {
        this.plans = result.data
        this.plans.start_date = moment(this.plans.start_date).format('MM/DD/YYYY')
        this.selectedConnections = this.plans.partners.filter(aa => aa != this.userData.partner.partner_id);
        this.loadConnections(this.selectedConnections.join());
        this.selectedTeamMembers = this.plans.team.filter((a) => a.email != this.userData.email).map(
          (eachMember) => {
            return eachMember.email;
          }
        );
        if (this.defaultConnectionName) {
          this.selectedConnections.push(this.defaultConnectionName);
          this.createPlanForm.patchValue({ partners: [this.defaultConnectionName] })
          this.onConnectionSelect();
        }
      },
      () => { }
    )
  }
  //*************** Loading User Data **************//
  userPartnerDomain: any;
  userPartnerEmail: any;
  loadUserDomain() {
    this.AuthService.get('user/me').subscribe(
      (result: any) => {
        this.userDataPartners = result.data;
        this.userPartnerDomain = result.data[0].partner.email_domain
        this.userPartnerEmail = result.data[0].email;
        this.loadConnections();
      },
      () => { }
    )
  }
  //****************** Loading Connections *****************//
  partnersList: IMultiSelectOption[] = [];
  selectedConnections: any[] = [];
  selfPartners: any = [];
  loadConnections(partner_id?) {
    let apiString = '';
    if (this.planId && partner_id) {
      apiString = 'partners?partner_id=' + partner_id
    } else {
      apiString = 'partners'
    }
    this.AuthService.get(`${apiString}`).subscribe(
      (result: any) => {
        console.log(result.data);
        if (this.planId && partner_id) {
          this.partners = [...this.partners, ...result.data]
        } else {
          this.selfPartners = result.data;
          this.partners = result.data;
        }
        this.partners = this.partners.filter((v, i, a) => {
          if (v.partner_id === this.userData.partner.partner_id) {
            return false;
          } else {
            return (a.findIndex(t => { return (t.partner_id === v.partner_id) }) === i)
          }
        });
        this.userSubscription.create_partner = this.getAccess('partner');
        if (!this.userSubscription.create_partner) {
          $('#partnerButton').css('background-color', '#606060');
        }
        this.partnersList = this.partners.map(
          (connection) => {
            return { id: connection.partner_id, name: connection.partner_name };
          }
        );
        if (!this.planId) {
          let owner_partner_ids = this.userDataPartners.map((a) => a.partner.owner_partner_id).filter(a => a && a != this.userData.partner.partner_id);
          console.log(owner_partner_ids);
          if (owner_partner_ids.length) {
            if (owner_partner_ids.length == 1) {
              this.selectedConnections = [owner_partner_ids[0]]
              this.createPlanForm.patchValue({ partners: this.selectedConnections })
            }
          }
        }
        if (this.defaultConnectionName) {
          this.selectedConnections.push(this.defaultConnectionName);
          this.createPlanForm.patchValue({ partners: [this.defaultConnectionName] })
          this.onConnectionSelect();
        }

      },
      (err: any) => {
        // this.partners = null;
      }, () => {
        if (this.defaultConnectionName) {
          this.selectedConnections.push(this.defaultConnectionName);
          this.createPlanForm.patchValue({ partners: [this.defaultConnectionName] })
          this.onConnectionSelect();
        }
      })
  }
  getAccess(type): boolean {
    if (type == 'partner') {
      if (this.userSubscription.type == 'subscriber') {
        if (this.userSubscription.data.no_partner == null) {
          return true;
        } else if (this.partners.length < this.userSubscription.data.no_partner) {
          return true;
        } else {
          return false;
        }
      } else if (type == 'partner') {
        if (this.userSubscription.data && this.userSubscription.data.partner_by_partner) {
          return true;
        }
        return false;
      }
    } else if (type == 'user') {
      if (this.userSubscription.type == 'subscriber') {
        if (this.userSubscription.data && this.userSubscription.data.no_user == null) {
          return true;
        } else if (this.userSubscription.data.no_user < this.partners.length) {
          return true;
        } else {
          return false;
        }
      } else if (type == 'partner') {
        if (this.userSubscription.data && this.userSubscription.data.user_by_partner) {
          return true;
        }
        return false;
      }
    }
  }
  //****************** change in selected connection *****************//
  // change in connection should reload team list based on new partners
  selectedPartners: any = [];
  onConnectionSelect($event?) {
    console.log(this.createPlanForm.controls['partners'].value);
    let connectionString: string = '';
    (this.formControls['partners'].value || []).map((connection, index) => {
      if (index == 0) {
        connectionString = connection
      } else {
        connectionString = connectionString + ',' + connection
      }
    })
    // to load new teams
    this.loadTeamsList(connectionString)
  }
  //****************** team list based on partners *****************//
  //**********Domain list needs to be updated based on partners ****//
  teamList: IMultiSelectOption[] = [];
  domainList: any[] = [];
  loadTeamsList(partnerId: string) {
    partnerId = this.userData.partner.partner_id + (partnerId ? ',' + partnerId : partnerId);
    this.AuthService.get(`partners/users?partner_id=${partnerId}`).subscribe(
      (result: any) => {
        let filteredList = result.data.filter(
          (user: any) => { return user.email != this.userPartnerEmail })
        this.teamList = filteredList.map(
          (eachMember) => {
            return { id: eachMember.email, name: eachMember.email };
          }
        );
        this.domainList = filteredList.map(partnerUser => { return partnerUser.email.split('@')[1] });
        if (filteredList.length && this.plans.team && this.plans.team.length) {
          this.selectedTeamMembers = this.plans.team.filter((a) => a.email != this.userData.email).filter((a) => this.teamList.filter((aa) => aa.name == a.email).length).map(
            (eachMember) => {
              return eachMember.email;
            }
          );
        }
      },
      (err: any) => {
        this.teamList = null;
      }
    );
  }
  ///************** Load connection's partner data ****************/ 
  partnerOwners: any = [];
  loadPartnerOwners(partnerId: string) {
    //needs to append partnerId
    this.AuthService.get(`partners/owners?partner_id=${partnerId}`).subscribe(
      (result: any) => {
        this.partnerOwners = result.data;
      },
      (err: any) => {
        this.partnerOwners = null;
      }
    );
  }
  //*********** when User clicks on new connection ***********//
  newConnection() {
    // store entered data in local storage to maintain form data
    if (this.userSubscription.create_partner) {
      localStorage.setItem('planData2', JSON.stringify(this.plans))
      localStorage.setItem('planPartners', JSON.stringify(this.selectedConnections));
      localStorage.setItem('planTeamMembers', JSON.stringify(this.selectedTeamMembers));
      localStorage.setItem('redirect', this.router.url)
      this.router.navigate(["/create-partner"]);
    } else {
      $('#alertModalX').modal('show');
    }
  }
  //*********** when User clicks on cancel ***********//
  goBack() {
    $('.modal').modal('hide')
    let previousUrl = this.navHelper.getUrl()
    let redirect = localStorage.getItem('redirect2')
    console.log(previousUrl)
    if ((previousUrl && previousUrl != this.router.url) || redirect) {
      if ((redirect == '/dashboard') || (previousUrl == '/dashboard')) {
        this.router.navigate([redirect || previousUrl, 'plans'])
      } else {
        this.router.navigate([redirect || previousUrl])
      }
    } else {
      this.router.navigate(['/plan-dashboard'])
    }
  }
  //************** Invite teams *************//
  invitedTeam: any[] = [];
  //********** save team *************//
  saveTeam($event) {
    if ($event) {
      this.invitedTeam = $event;
    }
    $('#teamModal').modal('hide');
  }
  domainMatchError: boolean = false;
  domainChange($event) {
    this.domainMatchError = $event;
    this.cdRef.detectChanges();
  }
  closeTeam() {
    $('#teamModal').modal('hide');
  }
  partnerMatchError: boolean = false;
  getPartnerMatch($event) {
    this.partnerMatchError = $event;
    this.cdRef.detectChanges();
  }
  openTeam() {
    $('#teamModal').modal('show');
  }
  //************** Invite teams end *************//
  //************** add both teams ***********//
  selectedTeamMembers: string[] = [];
  invitedMembers: any[] = [];
  sortTeamMembers() {
    let final_team: any[] = []
    final_team = this.invitedTeam.filter(member => { return member.email != '' })
    this.invitedMembers = this.invitedTeam.filter(member => { return member.email != '' });
    console.log(this.selectedTeamMembers)
    this.selectedTeamMembers.map((eachMember) => {
      final_team.push({ email: eachMember.split('~')[0].toLowerCase(), user_role: '3' })
    })
    this.final_data.team = final_team
  }
  //***********MIsc functions */
  // add duration to start date, to get end date 
  addDays(days, newDate) {
    let nd = new Date(newDate)
    nd.setDate(newDate.getDate() + days);
    return nd
  }
  formatDate(date) {
    return date.getFullYear() + '-' + getMonth() + '-' + date.getDate()
    function getMonth() {
      let month = date.getMonth() + 1
      if (month < 10) {
        return "0" + month
      } else return month
    }
  }
  closeModal() {
    $('#successModal').modal('hide')
    this.displayResult = '';
  }
  //******** Open confirm popup **********//
  openConfirmPopup() {
    this.partner_error = false;
    if (this.createPlanForm.valid && !this.domainMatchError) {
      if (!this.planId) {
        let owner_partner_ids = this.userDataPartners.map((a) => a.partner.owner_partner_id).filter(a => a && a != this.userData.partner.partner_id);
        console.log(owner_partner_ids);
        if (owner_partner_ids.length && !this.createPlanForm.value.partners.length) {
          this.formError = true;
          this.partner_error = true;
        } else {
          this.formError = false;
          $('#confirmPopup').modal('show');
        }
      } else {
        this.formError = false;
        $('#confirmPopup').modal('show');
      }

    } else {
      this.formError = true;
    }

  }
  //******** Close confirm popup **********//
  closeConfirmPopup() {
    $('#confirmPopup').modal('hide');
  }
  //********* Sending Data to API ********/
  final_data: any;
  partner_error: any = false;
  submit() {
    //** check if entered data is valid **//
    console.log(this.createPlanForm)
    if (this.createPlanForm.valid) {
      //set formError to false, if showing any previous errors
      this.formError = false;
      const data = this.createPlanForm.value;
      this.final_data = data;
      let date = moment(this.createPlanForm.value["start_date"]).format('MM/DD/YYYY').split('/');
      this.final_data.start_date = new Date(date[2] + '-' + date[0] + '-' + date[1])
      // generate end date from duration and start date
      this.final_data.end_date = this.addDays(Number(this.createPlanForm.value['duration']), this.final_data.start_date);
      // generate final team members list from dropdown and invite modal
      this.sortTeamMembers()
      delete this.final_data.teams
      delete this.final_data.selectedTeam;


      if (this.final_data.partners && this.final_data.partners.length > 0) {
        this.final_data.partners = [...[this.userData.partner.partner_id], ...this.final_data.partners];
      } else {
        this.final_data.partners = [...[this.userData.partner.partner_id]];
      }
      if (!this.planId) {
        this.final_data.created_data = new Date();
      }
      this.closeConfirmPopup();
      console.log(this.final_data);
      // return
      $('#successModal').modal('show')
      this.AuthService[this.planId ? 'update' : 'add']('plans' + (this.planId ? '/' + this.planId : ''), this.final_data).subscribe((result: any) => {
        this.displayResult = 'success'
        this.CommonEmitterService.updateObjectCount.next();
      }, (err: any) => {
        this.displayResult = 'failure'
        this.errorMessage = err.error.meta.message
      });
    } else {
      console.log(this.createPlanForm)
      this.formError = true;
    }
  }
}

