import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationHelperService } from '../services/navigation-helper.service';
import { Router } from '@angular/router';
import { CommonEmitterService } from '../services/common-emitter';
declare var $: any;
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  updatedUserDetails: FormGroup;
  currentUserDetails: any = { email: '', country_code: '' };
  formControls: any;
  showPage: string = 'profile';
  adminUsers: any[];
  ourUsers: any[];
  isAdmin: any;
  loadedData = { users: false, connections: false };
  formError = { status: false, message: '' };
  initPath = this.AuthService.baseUrl
  picPath = 'file/2020-08-07/1596807539174-Image.png'
  constructor(
    private AuthService: AuthService,
    private fb: FormBuilder,
    private router: Router,
    private CommonEmitter: CommonEmitterService,
    private navHelper: NavigationHelperService
  ) {
    if (this.navHelper.navigationTracker.length == 0) {
      this.navHelper.setUrl(this.router.url)
    }
    this.getCountries();
    this.updateStatus();
  }
  generateForm() {
    this.updatedUserDetails = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern(/\S+@\S+\.\S+/)])],
      first_name: ['', Validators.compose([Validators.required])],
      last_name: ['', Validators.compose([Validators.required])],
      phone_number: ['', Validators.compose([Validators.required, Validators.pattern("^((\\+[0-9]{0,2}-?)|0)?[0-9]{10}$")])],
      role: [],
      profile_pic: [''],
      country_code: ['']
    })
    this.updatedUserDetails.valueChanges.subscribe(val => {
      this.formError.status = false;
    })
    this.formControls = (this.updatedUserDetails.controls)
  }

  goBack() {
    $('.modal').modal('hide')
    let previousUrl = this.navHelper.getUrl()
    if (previousUrl) {
      this.router.navigate([previousUrl])
    } else {
      this.router.navigate(['/dashboard'])
    }
  }
  changedDropdown: boolean = false;
  patchValues() {
    if (this.currentUserDetails.profile_pic) {
      this.picPath = this.currentUserDetails.profile_pic
    }
    this.updatedUserDetails.setValue({
      email: this.currentUserDetails.email,
      first_name: this.currentUserDetails.first_name,
      last_name: this.currentUserDetails.last_name,
      country_code: this.currentUserDetails.country_code,
      phone_number: this.currentUserDetails.phone.trim(),
      role: this.currentUserDetails.role ? this.currentUserDetails.role : '',
      profile_pic: this.currentUserDetails.profile_pic ? this.currentUserDetails.profile_pic : null
    })
    $(document).ready(() => {
      if (!this.changedDropdown) {
        this.changedDropdown = true;
        $('select').dropdown()
      };
    })
  }
  async updateStatus() {
    this.isAdmin = await this.AuthService.isAdmin();
    console.log(this.isAdmin);
  }
  file: any;
  imageError = {
    error: false,
    message: ''
  };
  handleFileSelect(evt) {
    this.imageError = { error: false, message: '' };
    var _URL = window.URL || window.webkitURL;
    let files = evt.target.files;
    let file = files[0];
    let type = file.type.split('/')[0]
    if (type == 'image') {
      if (files && file) {
        if (file.size <= 5000000) {
          this.file = file;
          this.addDocument('profile_pic');
        } else {
          evt.target.value = ''
          this.imageError = { error: true, message: 'Image size should be less than 5mb (5000000 bytes)' };
        }
      }
    }
    else {
      evt.target.value = ''
      this.imageError = { error: true, message: 'Only image files are allowed' };
    }
  }
  isProgress: any = false
  addDocument(type) {
    if (!this.isProgress) {
      this.isProgress = true;
      let formData = new FormData()
      formData.append('file', this.file)
      this.AuthService.upload('upload', formData, (err) => {
        this.isProgress = false
        console.log(err)
      }).subscribe((a: any) => {
        this.isProgress = false
        console.log(a)
        this.updatedUserDetails.patchValue({ profile_pic: a.key })
        this.updatedUserDetails.markAsDirty();
        this.updatedUserDetails.markAsTouched();
        this.picPath = a.key
        $('input[name="file_upload"').val('');
        $('input[name="file_name"').val('');
      })
    }
  }
  openUpload() {
    $('#profile_pic_upload').click();
  }
  countries: any = {};
  countryArray: any[] = [];
  getCountries() {
    this.AuthService.get('countries?limit=all')
      .subscribe((result: any) => {
        this.countryArray = result.data;
        this.countries = this.convertArrayToObject(result.data, 'country_code');
        console.log("this.countries:", this.countries);
      }, (err: any) => {
        console.log(err);
      });
  }
  ngOnInit(): void {
    this.getUserDetails();
    this.generateForm();
  }
  convertArrayToObject = (array, key) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: item,
      };
    }, initialValue);
  };
  update() {
    if (this.updatedUserDetails.pristine) {
      this.formError = { status: true, message: 'No changes made to save.' };
      return;
    }
    if (this.updatedUserDetails.valid) {
      this.AuthService.update(`users/${this.currentUserDetails.user_id}`, this.updatedUserDetails.value).subscribe(
        (result) => {
          console.log(result);
          $('#success_modal').modal('show');
          this.getUserDetails();
          this.getUsers();
        },
        (error) => {
          console.log(error.error);
        },
        () => { });
    } else {
      this.formError = { status: true, message: 'Please fill in all required details' }
    }
  }
  allUsers: any[] = [];
  getUsers() {
    this.AuthService.get('users').subscribe(
      (result) => {
        this.allUsers = result.data;
        this.adminUsers = this.allUsers.filter(user => user.admin_user === true);
        this.ourUsers = this.allUsers.filter(user => user.email_domain == this.currentUserDetails.partner.email_domain);
      },
      (err) => {
        console.log(err.error);
        this.allUsers = []
      }, () => {
      }
    )
    this.loadedData.users = true;
  }
  allConnections: any[] = [];
  getConnections() {
    this.AuthService.get('partners').subscribe(
      (result) => {
        console.log(result.data)
        this.allConnections = result.data;
      },
      (error) => {
        console.log(error.error);
        this.allConnections = [];
      },
    )
    this.loadedData.connections = true;
  }
  subscription: any;
  getUserDetails() {
    this.AuthService.get("user/me", () => { }).subscribe(
      (result: any) => {
        console.log("header says", result);
        this.currentUserDetails = result.data[0];
        if (this.currentUserDetails.subscription_type_data) {
          this.subscription = this.currentUserDetails.subscription_type_data;
        } else {
          this.subscription = { name: 'No subscription details' }
        }
        this.CommonEmitter.updateUserData.next();
        this.patchValues();
        if (!this.loadedData.users) {
          this.getUsers();
        }
        if (!this.loadedData.connections) {
          this.getConnections();
        }
      },
      err => {
        console.log(err);
      }
    );
  }
}
