<div>
  <form [formGroup]="forgotPasswordForm">
    <div class="text-center mt-4">
      <!-- <h5>Forgot Password</h5> -->
      Enter your registered email id and <br>we’ll send you a link to reset your password.
    </div>
    <div class="row">
      <div class="col-md">
        <input matInput type="text" formControlName="email" placeholder="Email" />
        <div *ngIf="
            (formControls['email'].dirty ||
              formControls['email'].touched ||
              displayError) &&
            formControls['email'].errors
          ">
          <div class="errorMessage" *ngIf="formControls['email'].errors.required">
            Email is required
          </div>
          <div class="errorMessage" *ngIf="formControls['email'].errors.pattern">
            Enter Valid email id.
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="displayError && forgotPasswordForm.invalid" class="row">
      <div class="col errorMessage" style="text-align: center;">
        Please enter your registered email id.
      </div>
    </div>

    <div class="row">
      <div class="col d-f j-c-c">
        <button type="button" [routerLink]="['/sign-in']" class="btn cnclbtn">
          CANCEL
        </button>
        <button type="submit" (click)="submit()" class="btn addbtn">
          SUBMIT
        </button>
      </div>
    </div>
  </form>
</div>
<div id="success_modal" class="modal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body" style="text-align: center;">
        <div class="row" style="margin-top: 20px; margin-bottom: 20px;">
          <div *ngIf="respStatus == 'success'" class="col">
            Password reset email has been sent to
            {{ forgotPasswordForm.get("email").value }}.
          </div>
          <div *ngIf="respStatus == 'error'" class="col">
            {{ respMessage }}
          </div>
        </div>
        <div class="row mrgbtmn">
          <div class="col">
            <button data-target="success_modal" data-dismiss="modal" class="btn addbtn" style="min-width: 100px;">
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>