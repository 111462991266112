import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { CommonEmitterService } from './common-emitter';
import { LocalStorageService } from './local-storage.service';
declare var $: any;
@Injectable({
  providedIn: 'root',
})
export class HttpConfigInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private Router: Router,
    private CommonEmitterService: CommonEmitterService,
    private LocalStorageService: LocalStorageService
  ) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    console.log('isLoader')
    let h = request.headers.get('Content-Type')
    console.log(request.url, request.headers.get('Content-Type'));
    request = request.clone(this.authService.getHeaders());

    if (!request.headers.has('Content-Type') && !h) {
      request = request.clone({ headers: request.headers.set('Content-Type', h || 'application/json') });
    }
    this.CommonEmitterService.setLoading(true)
    // $('#loader').show();
    this.CommonEmitterService.sendMessage("loading", "show");
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {

        setTimeout(() => {
          let height = $(document).height();
          // console.log(height + ' ---- ' + (height - 142))
          // $('nav#sidebar').css({ height: (height - 100) + 'px' })
          this.CommonEmitterService.setLoading(false)
        }, 200)
        // $('#loader').hide();
        return event;
      }),
      catchError((error: any) => {
        console.log(error)
        this.CommonEmitterService.sendMessage("loading", "hide");
        if (error.status == 401 || error.status == 403) {
          //set popup here..
          if (this.authService.showLoginOnSessionOut == true) {
            this.CommonEmitterService.sendMessage("showLogOutPage", true);
            this.LocalStorageService.put({
              name: "refreshCurrentPage",
              value: "false"
            });
          } else this.Router.navigate(["/sign-in"]);
        } else
          // TODO: send the error to remote logging infrastructure
          if (error.status != 404 && error.error.meta) {
            // alert(error.error.meta.message);
            // this.LocalStorageService.remove({ name: 'accessToken' });
            // this.Router.navigate(['/sign-in'])
          }
        return throwError(error);
      }));
  }
}