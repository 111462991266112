import { Component, Input, OnChanges, OnInit, EventEmitter, SimpleChanges, Output, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IMultiSelectOption, IMultiSelectSettings } from 'angular-2-dropdown-multiselect';
// import { Customer } from '../models/customer.model';
import { Connection } from '../models/connection.model';
import { AuthService } from '../services/auth.service';
import { LocalStorageService } from '../services/local-storage.service';
import * as  moment from "moment";
import { NavigationHelperService } from '../services/navigation-helper.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MustMatch } from 'src/validators/must-match.validator';

declare var $: any;

@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.css']
})
export class AddCustomerComponent implements OnInit {
  @Input() public callBack: Function;
  @Input() public defaultCustomer?: any;
  @Output() submitForm = new EventEmitter<any>();
  customer_id: string;
  partnersList: any = [];
  invitedTeam: any = [];
  usersList: any = [];
  typeList: any = [];
  priorityList: any = [];
  plansList: any = [];
  solutionList: any = [];
  opportunitiesList: any = [];
  customerList: any = [];
  userSubscription: { type: any, data: any, create_partner: boolean, create_user: boolean } = { type: '', data: '', create_partner: true, create_user: true };
  selfPartners: any[] = [];
  userPartnerEmail = '';
  defaultPartnerID: string = '';
  customer: any = {};
  savedTeam: any[] = [];
  rawAssigneeList: any = [];
  constructor(
    private AuthService: AuthService,
    private formBuilder: FormBuilder,
    private localStorageService: LocalStorageService,
    private router: Router,
    private navHelper: NavigationHelperService,
    private activatedRoute: ActivatedRoute,
    private cdRef: ChangeDetectorRef
  ) {
    this.userData = this.localStorageService.get({ name: 'userData' })
    this.userData = this.userData ? JSON.parse(this.userData) : {}
    this.userPartnerId = this.userData.partner.partner_id;
    this.userPartnerEmail = this.userData.email;
    this.partnerOwners = this.localStorageService.get({ name: 'partnerOwners' })
    this.partnerOwners = this.partnerOwners ? JSON.parse(this.partnerOwners) : []
    if (this.userData.subscription_type_data != null) {
      this.userSubscription.type = 'subscriber'
      this.userSubscription.data = this.userData.subscription_type_data;
      // if (this.userData.first_name) {
      //   this.customer.approvers = `${this.userData.first_name} ${this.userData.last_name}`;
      //   this.customer.approver_id = this.userData.user_id;
      // } else {
      //   this.customer.approvers = '';
      // }
    } else {
      this.userSubscription.type = 'partner'
      this.userSubscription.data = this.userData.subscriber_subscription_type_data;
      if (this.userSubscription.data) {
        this.userSubscription.create_user = this.userSubscription.data.user_by_partner;
      }
    }
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.partnerId) {
        this.defaultPartnerID = params.partnerId;
      }
    })
    this.activatedRoute.params.subscribe((params) => {
      if (params.customerId) {
        this.customer_id = params.customerId;
        this.getCustomerData();
      }
    })
    this.customerData = localStorage.getItem('customerData')
    this.customerData = this.customerData ? JSON.parse(this.customerData) : {};
    this.customer = { ...this.customer, ...this.customerData };
    // this.savedTeam = this.customer.assignee;
    localStorage.removeItem('customerData');
    let teamData = localStorage.getItem('customerInvitedTeam')
    this.invitedTeam = teamData ? JSON.parse(teamData) : [];
    localStorage.removeItem('customerInvitedTeam')
  }
  isUpdate: boolean = false;
  userData: any = {};
  userPartnerId: string = '';
  partnerOwners: any = [];
  ngOnInit(): void {
    this.createForm();
  }
  customerForm: FormGroup;
  formControls: any;
  createForm(): any {
    this.customerForm = this.formBuilder.group({
      // weburl: ['', Validators.compose([Validators.required])],
      name: [''],
      // order_webhook: [''],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      confirm_email: ['', Validators.compose([Validators.required])],
      phone: [''],
      organization: [''],
      type: ['']
    }, { validators: MustMatch('email', 'confirm_email') });
    this.formControls = this.customerForm.controls;
  }
  customerData: any = {};
  newPartner() {
    if (this.userSubscription.create_partner) {
      localStorage.setItem('customerData', JSON.stringify(this.customer));
      localStorage.setItem('customerInvitedTeam', JSON.stringify(this.invitedTeam));
      localStorage.setItem('redirect', this.router.url)
      this.router.navigate(["/create-partner"]);
    } else {
      $('#alertModalX').modal('show');
    }
  }
  getAccess(type): boolean {
    if (type == 'partner') {
      if (this.userSubscription.type == 'subscriber') {
        if (this.userSubscription.data.no_partner == null) {
          return true;
        } else if (this.partnersList.length < this.userSubscription.data.no_partner) {
          return true;
        } else {
          return false;
        }
      } else if (type == 'partner') {
        if (this.userSubscription.data && this.userSubscription.data.partner_by_partner) {
          return true;
        }
        return false;
      }
    } else if (type == 'user') {
      if (this.userSubscription.type == 'subscriber') {
        if (this.userSubscription.data.no_user == null) {
          return true;
        } else if (this.userSubscription.data.no_user < this.partnersList.length) {
          return true;
        } else {
          return false;
        }
      } else if (type == 'partner') {
        if (this.userSubscription.data && this.userSubscription.data.user_by_partner) {
          return true;
        }
        return false;
      }
    }
  }
  getCustomerData() {
    this.AuthService.get('customer/' + this.customer_id + '/getCustomer').subscribe(
      (result) => {
        this.customer = result.data;
      },
      (error) => {

      }
    )
  }

  getCustomers() {
    return new Promise((resolve, reject) => {
      this.AuthService.get("customers?limit=100").subscribe(
        (result: any) => {
          let sortedList = (result.data || []).map((a) => {
            a.name = a.name.toLowerCase();
            return a
          });
          resolve(sortedList);
        }, (error) => {
          resolve([]);
        });
    })
  }
  openConfirmPopup() {
    if (this.customerForm.valid) {
      $('#confirmPopup').modal('show');
    } else {
      this.formError = true;
    }
  }
  goBack() {
    $('.modal').modal('hide')
    let previousUrl = this.navHelper.getUrl()
    // let redirect = localStorage.getItem('redirect2');
    // if ((previousUrl && previousUrl != this.router.url) || redirect) {
    if (previousUrl && (previousUrl != this.router.url)) {
      // this.router.navigate([redirect || previousUrl])
      if (previousUrl == '/dashboard') {
        this.router.navigate([previousUrl, 'solutions']);
      } else {
        this.router.navigate([previousUrl])
      }
    } else {
      this.router.navigate(['/customer-dashboard'])
    }
  }
  closeModal() {
    $('.modal').modal('hide');
  }
  submitInProgress: any = false;
  formError: any = false;
  displayResult: any = ''
  errorMessage: any = ''
  submit() {
    this.displayResult = '';
    this.submitInProgress = false;
    if (this.customerForm.valid) {
      this.submitInProgress = true;
      let final_data = JSON.parse(JSON.stringify(this.customer));


      // if (this.rawAssigneeList.includes(this.userPartnerEmail)) {
      //   final_data.assignee.push(this.userPartnerEmail);
      // }
      let alertReq = ''
      $('.modal').modal('hide');
      $('#successModal').modal('show');
      //if form is valid submit to backend
      this.AuthService[this.customer_id ? 'update' : 'add']('customer' + (this.customer_id ? '/' + this.customer_id : '') + alertReq, final_data).subscribe(
        (result: any) => {
          this.submitInProgress = false
          this.displayResult = 'success';
          if (alertReq != '') {
            this.AuthService.add('alerts/custom_alerts', {
              users: result.data.users,
              moduleName: "customer",
              message: `${this.userData.first_name} ${this.userData.last_name} of Partner ${this.userData.partner.partner_name} has submitted Customer ${result.data.customer} for approval`,
              partners: result.data.partners
            }).subscribe((result) => {
              console.log(result);
            })
          }
        },
        (error) => {
          this.submitInProgress = false
          this.displayResult = "failure"
          this.errorMessage = error.error.meta.message
        },
        () => { }
      )
    } else {
      this.formError = true
    }
  }
}
