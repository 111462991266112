<header class="header">
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid d-flex align-items-center justify-content-between">
      <div class="navbar-header">
        <!-- Navbar Header-->
        <a class="navbar-brand" style="margin-right: 0.2rem;">
          <div class="brand-text brand-big visible text-uppercase float-left" routerLink="/dashboard">
            <img src="./assets/ICONS/flyerologo1.png" style="width: 33px;" />
          </div>
          <div class="brand-text brand-sm float-left" routerLink="/dashboard">
            <img src="./assets/ICONS/flyerologo1.png" style="width: 33px;" />
          </div>
          <span>{{ title }}</span>
        </a>
      </div>
      <div class="left_icons">
        <!-- <div class="icon">
          <img src="./assets/ICONS/activities.png" style="cursor: pointer;" />
        </div>
        <div class="icon">
          <i class="fa fa-search" aria-hidden="true"></i>
        </div>-->

        <div class="icon">
          <span class="brand1">{{brandData.brand}}</span>
          <img class="" *ngIf="brandData.logo" style="max-height: 40px;width:auto;" [src]="initPath + brandData.logo" />
          <!-- <span class="brand">{{brandData.brand}}</span> -->
        </div>
        <div class="icon" style="position: relative;">
          <a style="cursor: pointer;" data-toggle="collapse" data-target="#collapseExample2"
            aria-controls="collapseExample2" aria-expanded="false">
            <span [matBadge]="allAlerts.length || ''" matBadgeOverlap="true" matBadgePosition="after"
              matBadgeColor="warn">
              <span class="material-icons" style="font-size: 35px;">
                notifications
              </span>
            </span>
          </a>
          <div class="collapse notifications-collapse" id="collapseExample2">
            <ul class="nav nav-tabs" style="padding-top: 2px; margin-top: 2px;" id="myTab" role="tablist">
              <li class="nav-item notifications-nav-item">
                <a class="nav-link notifications-nav-link active" id="home-tab" data-toggle="tab" href="#home"
                  role="tab" aria-controls="home" aria-selected="true" aria-expanded="true">Your Alerts</a>
              </li>
              <li class="nav-item notifications-nav-item">
                <a class="nav-link notifications-nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab"
                  aria-controls="profile" aria-selected="false">All Notifications</a>
              </li>
              <li class="close-notifications">
                <div data-toggle="collapse" data-target="#collapseExample2" aria-controls="collapseExample2">
                  <span class="material-icons">
                    close
                  </span>
                </div>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade show notifications-tab active" id="home" role="tabpanel"
                aria-labelledby="home-tab">
                <div style="display: flex; justify-content: space-evenly;">
                  <div (click)="actionAll('read')" class="all-actions">
                    Mark All as Read
                  </div>
                  <div (click)="actionAll('clear')" class="all-actions">
                    Clear All
                  </div>
                </div>
                <ul class="notifications-list" *ngIf="allAlerts.length > 0">
                  <li class="notifications-each" *ngFor="let alert of allAlerts">
                    <div>{{ alert.message }}</div>
                    <div class="notifications-action">
                      <div (click)="viewItem(alert.alert_id, alert.alert_type)" style="cursor: pointer;">
                        View
                      </div>
                      <div>
                        <span (click)="readItem(alert.alert_id)" class="material-icons">
                          delete
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="tab-pane fade show notifications-tab" id="profile" role="tabpanel"
                aria-labelledby="profile-tab">
                <ul class="notifications-list" *ngIf="notifications.length > 0">
                  <li class="notifications-each" *ngFor="let notification of notifications">
                    <div *ngIf="notification.notification_type == 'Partner' && notification.custom_data">
                      {{notification.custom_data.user_name}}
                      <span *ngIf="notification.custom_data && user">
                        <span *ngIf="notification.custom_data.partner_name && user.partner ">
                          <span *ngIf="notification.custom_data.partner_name != user.partner.partner_name">
                            from {{notification.custom_data.partner_name}}
                          </span>
                        </span>
                      </span> has {{notification.custom_data.action}} , {{notification.custom_data.created_partner}}.
                    </div>
                    <div *ngIf="notification.notification_type == 'User' && notification.custom_data">
                      {{notification.custom_data.user_name}}
                      <!-- <span
                        *ngIf="notification.custom_data.partner_name != user.partner.partner_name">
                        from {{notification.custom_data.partner_name}}
                      </span> -->
                      has {{notification.custom_data.action}}.
                    </div>
                  </li>
                </ul>
                <div class="notifications-list" *ngIf="notifications.length <= 0">
                  <div class="notifications-each">
                    No notifications to show.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <mat-icon matBadge="15" matBadgeColor="warn" [matBadgeHidden]="false">notifications</mat-icon> -->
        </div>
        <div class="dropdown">
          <div href="" aria-expanded="false" class="dropdown-toggle" data-toggle="dropdown"
            data-target="dropdownMenuButton">
            <img class="profile profile_pic" *ngIf="user.profile_pic" [src]="baseUrl + user.profile_pic" />
            <div *ngIf="!user.profile_pic" class="profile profile_icon">
              {{(user.first_name ? user.first_name.charAt(0) : '' )+ (user.first_name ? user.last_name.charAt(0) : '' ) | uppercase}}
            </div>
          </div>
          <div id="dropdownMenuButton" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <button class="dropdown-item" style="pointer-events: none;cursor: none;">
              <p style="
                  font-family: 'Work Sans', sans-serif;
                  color: #44a2f8;
                  font-weight: bold;
                ">
                {{ user.first_name | titlecase }}
                {{ user.last_name | titlecase }}
              </p>
            </button>
            <button class="dropdown-item" [routerLink]="['/profile']">
              Profile
            </button>
            <button class="dropdown-item" [routerLink]="['/change-password']" href="#">
              Change Password
            </button>
            <button href="javascript:;" class="dropdown-item" (click)="logout()">
              Logout
            </button>
          </div>

        </div>
        <div class="spinner-border" style="position: absolute;width: 3rem;height: 3rem;right: 25px;top: 8px;"
          [ngStyle]="{display: loading?'block':'none'}"></div>
      </div>
    </div>
  </nav>
</header>

<div *ngIf="adminAccess && showCompany" id="company">
  <button class="btn btn-block custom-btn" type="button" data-toggle="collapse" data-target="#collapseExample"
    aria-expanded="false" aria-controls="collapseExample">
    {{ user.partner.partner_name }}
  </button>
  <div class="collapse" id="collapseExample">
    <div class="card card-body">
      <div style="font-size: 16px; margin-bottom: 10px; margin-top: 8px;">
        <strong>Type: </strong>{{ subscription.name }}
      </div>
    </div>
    <div class="card card-body">
      <div style="font-size: 16px; margin-bottom: 20px;">
        <strong>Valid Till:
        </strong>{{  subscription_dates ? (subscription_dates.end_date | date: "dd MMMM yyyy") : 'No subscription details' }}
      </div>
    </div>
  </div>
</div>
<div *ngIf="showBanner && isDashboard" id="newData" style="background: white;">
  <div style="padding: 8px;">
    <span style="color: black;">
      This board has some updates. Click <span (click)="updatePage()"
        style="color: #44a2f8 !important;cursor:pointer">'refresh'</span>
    </span>
    <span (click)="showBanner = false" style="font-size: 17px;padding-left: 15px;color: red;cursor:pointer">x</span>
  </div>
</div>
<div id="each_alert" class="modal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row mrgbtmn" style="margin-top: 20px;text-align: center;">
          <div class="col fz-16">
            Alert
          </div>
        </div>
        <div class="row mrgbtmn">
          <div class="col fz-14 fc-black-1" style="padding: 0 40px">
            <span *ngIf="messageParts.length > 0">
              {{messageParts[0]}}
              <a style="color: #44a2f8;text-decoration: underline;cursor: pointer;"
                (click)="redirectToObject(selectedAlert.alert_id)">
                {{messageParts[1]}}
              </a>
              {{messageParts[2]}}
            </span>
          </div>
        </div>
        <div *ngIf="differences.length > 1" class="row mrgbtmn">
          <div class="col fz-14 fc-black-1" style="padding: 0 40px">
            Changes:
            <ul>
              <li *ngFor="let diff of differences">{{diff | titlecase}}</li>
            </ul>
          </div>
        </div>
        <div class="row mrgbtmn" style="text-align: center;">
          <div class="col">
            <button class="btn addbtn" style="min-width: 100px;" (click)="closeItem(selectedAlert.alert_id)">
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>