<div style="height: 100%;">
  <div class="row" style="height: 100%; padding-top: 100px;">
    <div class="col-3 bdr-r">
      <div class="h-100 d-f d-fd-c j-c-c">
        <div class="d-f d-fd-c a-i-b pd-30">
          <span (click)="showPage = 'profile'" class="fz-18 cursor-p fc-black-2">Profile</span>
        </div>
        <div class="d-f d-fd-c a-i-b pd-30">
          <p (click)="showPage = 'organization'" class="fz-18 cursor-p fc-black-2">
            Organization
          </p>
          <p (click)="showPage = 'users'" class="fz-14 cursor-p fc-black-2">
            Users
          </p>
          <p (click)="showPage = 'partners'" class="fz-14 cursor-p fc-black-2">
            Partners
          </p>
        </div>
      </div>
    </div>
    <div class="col-9 d-f j-c-c">
      <div style="width: 100%;" class="fz-18 d-f j-c-c fc-black-2 a-i-c">
        <div *ngIf="showPage == 'profile'" style="width: 70%;">
          <div class="row">
            <div class="col-3"></div>
            <div class="col-9">
              <div class="bdr" style="height: 185px; width: 185px; padding: 5px;">
                <div style="position: relative;">
                  <img style="height: 173px; width: 173px;" [src]="initPath + picPath" id="profile_pic"
                    (click)="openUpload()" alt="profile-pic" />
                  <div *ngIf="imageError.error" class="errorMessage">
                    {{ imageError.message }}
                  </div>
                  <div (click)="openUpload()" class="editOption">
                    <span class="material-icons">
                      create
                    </span>
                  </div>
                </div>
                <input style="display: none;" id="profile_pic_upload" (change)="handleFileSelect($event)" type="file" />
              </div>
            </div>
          </div>
          <form [formGroup]="updatedUserDetails">
            <div class="row">
              <div class="col-3 t-align-r custom-label">Email:</div>
              <div class="col-9 pd-l-60">
                <span [style.display]="isAdmin ? 'none' : 'block'">{{
                  this.currentUserDetails.email
                }}</span>
                <span [style.display]="isAdmin ? 'block' : 'none'">
                  <input matInput type="text" formControlName="email" placeholder="Enter Email" />
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-3 t-align-r custom-label">First Name:</div>
              <div class="col-9 pd-l-60">
                <input matInput type="text" formControlName="first_name" placeholder="Enter First Name" />
                <div *ngIf="
                    (formControls['first_name'].dirty ||
                      formControls['first_name'].touched) &&
                    formControls['first_name'].errors
                  ">
                  <div class="errorMessage" *ngIf="formControls['first_name'].errors.required">
                    First name is required
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-3 t-align-r custom-label">Last Name:</div>
              <div class="col-9 pd-l-60">
                <input matInput type="text" formControlName="last_name" placeholder="Enter Last Name" />
                <div *ngIf="
                    (formControls['last_name'].dirty ||
                      formControls['last_name'].touched) &&
                    formControls['last_name'].errors
                  ">
                  <div class="errorMessage" *ngIf="formControls['last_name'].errors.required">
                    Last name is required
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-3 t-align-r custom-label">Phone No:</div>
              <div class="col-9 pd-l-60">
                <input matInput type="text" formControlName="phone_number" placeholder="Enter Phone No." />
                <div *ngIf="
                    (formControls['phone_number'].dirty ||
                      formControls['phone_number'].touched) &&
                    formControls['phone_number'].errors
                  ">
                  <div class="errorMessage" *ngIf="formControls['phone_number'].errors.required">
                    Phone Number is required
                  </div>

                  <div class="errorMessage" *ngIf="formControls['phone_number'].errors.pattern">
                    Please Enter a Valid Mobile Number
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-3 t-align-r custom-label">Role:</div>
              <div class="col-9 pd-l-60">
                <input matInput type="text" name="role" placeholder="Enter Role" formControlName="role" />
                <div *ngIf="
                    (formControls['role'].dirty ||
                      formControls['role'].touched) &&
                    formControls['role'].errors
                  ">
                  <div class="errorMessage" *ngIf="formControls['role'].errors.required">
                    Role is required
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-3 t-align-r custom-label">Country:</div>
              <div class="col-9 pd-l-60">
                <span [style.display]="isAdmin ? 'none' : 'block'">{{
                  countries[currentUserDetails.country_code]
                    ? countries[currentUserDetails.country_code].country_name
                    : ""
                }}</span>
                <span class="select_wrapper" [style.display]="isAdmin ? 'block' : 'none'">
                  <select class="bdr" formControlName="country_code">
                    <option *ngFor="let country of countryArray" [value]="country.country_code">
                      {{ country.country_name }}</option>
                  </select>
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-3 t-align-r custom-label">Password:</div>
              <div class="col-9 pd-l-60">
                <a class="ts-u fc-blue" [routerLink]="['/change-password']">Change Password</a>
              </div>
            </div>
            <div *ngIf="formError.status" class="row">
              <div class="col-3 t-align-r"></div>
              <div class="col-9 pd-l-60">
                <div class="errorMessage">
                  {{ formError.message }}
                </div>
              </div>
            </div>
            <div class="row pd-b-20">
              <div class="col-3 t-align-r d-f j-c-c">
                <button type="button" (click)="goBack()" class="btn fz-18 fw-4 bd-0 cnclbtn">
                  CANCEL
                </button>
              </div>
              <div class="col-9 pd-l-60">
                <button type="submit" (click)="update()" class="btn fz-18 fw-4 addbtn">
                  SAVE CHANGES
                </button>
              </div>
            </div>
          </form>
        </div>
        <div *ngIf="showPage == 'organization'" class="form" style="width: 100%;">
          <div class="row mg-b-20">
            <div class="col-4 d-f d-fd-c a-i-b pd-r-30">Organization Name:</div>
            <div class="col-8">
              {{ this.currentUserDetails.partner.partner_name }}
            </div>
          </div>
          <div class="row mg-b-20">
            <div class="col-4 d-f d-fd-c a-i-b pd-r-30">Admin:</div>
            <div class="col-8">
              <div *ngFor="let user of adminUsers; index as i">
                <p [style.display]="i < 4 ? 'block' : 'none'">
                  {{ user.email }}
                </p>
              </div>
              <div *ngIf="adminUsers.length <= 0">
                No admin users
              </div>
            </div>
          </div>
          <div class="row mg-b-20">
            <div class="col-4 d-f d-fd-c a-i-b pd-r-30">Subscription Type:</div>
            <div class="col-8">
              {{
                (this.subscription
                  ? this.subscription.name
                  : "No Subscription"
                ) | titlecase
              }}
            </div>
          </div>
        </div>
        <div *ngIf="showPage == 'users'" style="width: 70%;">
          <div class="table_wrapper mt-2">
            <table class="table table-condensed table-bordered">
              <thead>
                <th scope="col" class="table-secondary">
                  <div class="table-header">
                    <div>User</div>
                  </div>
                </th>
                <th scope="col" class="table-secondary">
                  <div class="table-header">
                    <div>Email</div>
                  </div>
                </th>
              </thead>
              <tbody>
                <tr *ngIf="this.allUsers.length == 0">
                  <td align="center">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </td>
                  <td>
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </td>
                </tr>
                <tr class="table-content" *ngFor="let user of ourUsers; index as i">
                  <!-- <td>{{user.}}</td> -->
                  <td>
                    <label class="dark_gray">{{ user.first_name | titlecase }}
                      {{ user.last_name | titlecase }}</label>
                  </td>
                  <td>
                    <label class="dark_gray">{{ user.email }}</label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div *ngIf="showPage == 'partners'" style="width: 100%;">
          <div class="table_wrapper mt-2">
            <table class="table table-condensed">
              <thead>
                <th scope="col" class="table-secondary">
                  <div class="table-header">
                    <div>Organization Name</div>
                  </div>
                </th>
                <th scope="col" class="table-secondary">
                  <div class="table-header">
                    <div>Country</div>
                  </div>
                </th>
                <th scope="col" class="table-secondary">
                  <div class="table-header">
                    <div>Partner Owner</div>
                  </div>
                </th>
              </thead>
              <tbody>
                <tr *ngIf="this.allConnections.length == 0">
                  <td align="center">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </td>
                  <td align="center">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </td>
                  <td>
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </td>
                </tr>
                <tr class="table-content" *ngFor="let connection of allConnections; index as i">
                  <!-- <td>{{user.}}</td> -->
                  <td style="text-align: center !important;">
                    <label>{{ connection.partner_name | titlecase }}</label>
                  </td>
                  <td style="text-align: center !important;">
                    <label>{{
                      this.countries[connection.country_code].country_name
                    }}</label>
                  </td>
                  <td style="text-align: center !important;">
                    <label>{{ connection.email }}</label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="success_modal" class="modal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body" style="text-align: center;">
        <div class="row mrgbtmn" style="margin-top: 20px;">
          <div class="col fz-18">
            Congrats, your changes have been saved.
          </div>
        </div>
        <div class="row mrgbtmn">
          <div class="col">
            <button data-dismiss="modal" class="btn addbtn" style="min-width: 100px;" data-target="success_modal">
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>