<form [formGroup]="customerForm">
  <div style="
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
    ">
    <div class="form-container">
      <div class="row mrgbtm">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 section-border" style="padding-top: 20px">
          <div class="form-section">
            <div class="row mrgbtmn">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                <p class="head_type">
                  Customer Name<sub class="required">*</sub>:
                </p>
              </div>
              <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9" style="padding-top: 10px">
                <input matInput formControlName="name" type="text" [(ngModel)]="customer.name" name="name"
                  style="width: 80%" placeholder="Customer Name" />
                <div class="mrgbtm" *ngIf="
                    (formControls['name'].dirty ||
                      formControls['name'].touched ||
                      formError) &&
                    formControls['name'].errors
                  ">
                  <div class="errorMessage" *ngIf="formControls['name'].errors.required">
                    Customer Name is required.
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div class="row mrgbtmn">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                <p class="head_type">
                  Customer Email<sub class="required">*</sub>:
                </p>
              </div>
              <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9" style="padding-top: 10px">
                <input matInput formControlName="email" type="text" [(ngModel)]="customer.email" name="email"
                  [readonly]="customer_id" style="width: 80%" placeholder="Customer Email" />
                <div class="mrgbtm" *ngIf="
                    (formControls['email'].dirty ||
                      formControls['email'].touched ||
                      formError) &&
                    formControls['email'].errors
                  ">
                  <div class="errorMessage" *ngIf="formControls['email'].errors.required">
                    Customer Email is required.
                  </div>
                  <div class="errorMessage" *ngIf="formControls['email'].errors.email">
                    Invalid Email.
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div class="row mrgbtmn">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                <p class="head_type">
                  Confirm Customer Email<sub class="required">*</sub>:
                </p>
              </div>
              <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9" style="padding-top: 10px">
                <input matInput formControlName="confirm_email" type="text" name="confirm_email" style="width: 80%"
                  placeholder="Confirm Customer Email" />
                <div class="mrgbtm" *ngIf="
                    (formControls['confirm_email'].dirty ||
                      formControls['confirm_email'].touched ||
                      formError) &&
                    formControls['confirm_email'].errors
                  ">
                  <div class="errorMessage" *ngIf="formControls['confirm_email'].errors.required">
                    Confirm Customer Email is required.
                  </div>
                  <div class="errorMessage" *ngIf="formControls['confirm_email'].errors.mustMatch">
                    Email Id you have entered do not match.
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div class="row mrgbtmn">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                <p class="head_type">Customer Mobile:</p>
              </div>
              <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9" style="padding-top: 10px">
                <input matInput formControlName="phone" type="text" [(ngModel)]="customer.phone" name="phone"
                  style="width: 80%" placeholder="Customer Mobile" />
                <div class="mrgbtm" *ngIf="
                    (formControls['phone'].dirty ||
                      formControls['phone'].touched ||
                      formError) &&
                    formControls['phone'].errors
                  ">
                  <div class="errorMessage" *ngIf="formControls['phone'].errors.required">
                    Customer Mobile is required.
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div class="row mrgbtmn">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                <p class="head_type">Associated Organization:</p>
              </div>
              <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9" style="padding-top: 10px">
                <input matInput formControlName="organization" type="text" [(ngModel)]="customer.organization"
                  name="organisation" style="width: 80%" placeholder="Associated Organization" />
                <div class="mrgbtm" *ngIf="
                    (formControls['organization'].dirty ||
                      formControls['organization'].touched ||
                      formError) &&
                    formControls['organization'].errors
                  ">
                  <div class="errorMessage" *ngIf="formControls['organization'].errors.required">
                    Associated Organization is required.
                  </div>
                </div>
              </div>
            </div>
            <br />
            <!-- <div class="row mrgbtmn">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                <p class="head_type">
                  App Url:
                </p>
              </div>
              <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9" style="padding-top: 10px">
                <input matInput formControlName="weburl" type="text" [(ngModel)]="customer.weburl" name="weburl"
                  style="width: 80%" placeholder="App Url" />
                <div class="mrgbtm" *ngIf="
                    (formControls['weburl'].dirty ||
                      formControls['weburl'].touched ||
                      formError) &&
                    formControls['weburl'].errors
                  ">
                  <div class="errorMessage" *ngIf="formControls['weburl'].errors.required">
                    Web Url is required.
                  </div>
                </div>
              </div>
            </div> -->
            <!-- <div class="row mrgbtmn">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                <p class="head_type">
                  Order Update webhook:
                </p>
              </div>
              <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9" style="padding-top: 10px">
                <input matInput formControlName="order_webhook" type="text" [(ngModel)]="customer.order_webhook"
                  name="weburl" style="width: 80%" placeholder="Webhook Url" />
                <div class="mrgbtm" *ngIf="
                    (formControls['order_webhook'].dirty ||
                      formControls['order_webhook'].touched ||
                      formError) &&
                    formControls['order_webhook'].errors
                  ">
                  <div class="errorMessage" *ngIf="formControls['order_webhook'].errors.required">
                    Webhook is required.
                  </div>
                </div>
              </div>
            </div>
            <br> -->
            <!-- <div class="row mrgbtmn">
              <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                <p class="head_type">Customer Type:</p>
              </div>
              <div class="col-lg-5 col-md-5 col-sm-4 col-xs-4 col-4">
                <ng-select class="border-none owner" name="type" [(ngModel)]="customer.type" formControlName="type"
                  placeholder="Select">
                  <ng-option [value]="item.name" *ngFor="let item of typeList">
                    {{ item.name}}
                  </ng-option>
                </ng-select>
              </div>
            </div> -->
          </div>
        </div>
      </div>

      <div class="row mrgbtm">
        <div class="col-md-12">
          <div align="center">
            <a type="button" (click)="goBack()" class="btn cnclbtn">CANCEL</a>
            <button *ngIf="!customer_id" type="button" (click)="openConfirmPopup()" [disabled]="submitInProgress"
              class="btn addbtn">
              CREATE CUSTOMER
            </button>
            <button type="button" *ngIf="customer_id" (click)="openConfirmPopup()" [disabled]="submitInProgress"
              class="btn addbtn">
              UPDATE CUSTOMER
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
<!-----------Document model popup-------->

<div class="modal" id="confirmPopup">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal body -->
      <div class="modal-body" style="text-align: center; display: inline">
        <h5 class="modal-title mrgbtm">Are You Sure?</h5>
        <p>Customer will be saved and marked as completed, on confirmation.</p>
      </div>
      <!-- Modal footer -->
      <div class="modal-footer" style="text-align: center; display: inline">
        <button type="button" (click)="closeModal()" class="btn cnclbtn">
          CANCEL
        </button>
        <button [disabled]="submitInProgress" type="button" class="btn addbtn" (click)="submit()">
          CONFIRM
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="successModal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content modal-custom">
      <!-- Modal body -->
      <div *ngIf="displayResult == 'success'" class="modal-body" style="text-align: center; display: inline">
        <div class="row mrgbtmn">
          <div class="col-md-12">
            <h5 *ngIf="!customer_id" class="modal-title mrgbtm">
              Customer Created!
            </h5>
            <h5 *ngIf="customer_id" class="modal-title mrgbtm">
              Customer Updated!
            </h5>
            <p *ngIf="invitedTeam.length > 0">
              Invites will be sent to
              <span *ngFor="let member of invitedTeam; index as i">
                {{ member.email }}{{ i < invitedTeam.length - 1 ? "," : "." }}
              </span>
            </p>
          </div>
        </div>
        <div class="row mrgbtm">
          <div class="col-md-12">
            <div align="center">
              <button (click)="this.goBack()" type="button" class="btn addbtn">
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="displayResult == 'failure'" class="modal-body" style="text-align: center; display: inline">
        <div>
          <div class="row mrgbtm">
            <div class="col-md-12">
              <div align="center" class="errorMessage">
                {{ errorMessage }}
              </div>
            </div>
          </div>
          <div class="row mrgbtm">
            <div class="col-md-12">
              <div align="center">
                <button (click)="closeModal()" type="button" class="btn addbtn">
                  CLOSE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="displayResult == ''" class="modal-body" style="text-align: center; display: inline">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <p>{{ customer_id ? "Updating the" : "Creating a" }} Customer...</p>
      </div>
    </div>
  </div>
</div>