import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { CommonEmitterService } from './services/common-emitter';
import { AuthService } from './services/auth.service';
import { LocalStorageService } from './services/local-storage.service';
declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  showLogOutPage = false;
  currentPath = "";
  constructor(private router: Router, private CommonEmitter: CommonEmitterService, private AuthService: AuthService, private LocalStorageService: LocalStorageService) {
    this.getAppVersion()
    router.events.subscribe((val) => {
      // see also 
      if (val instanceof NavigationEnd) {
        this.currentPath = window.location.pathname;

      }
    });
    this.CommonEmitter.getMessage().subscribe(
      (data) => {
        this.currentPath = window.location.pathname;
        if (data.saveAccessToken) {
          this.LocalStorageService.put({ name: 'accessToken', value: data.saveAccessToken });
        }
        if (data.saveUserData) {
          this.LocalStorageService.put({ name: 'userData', value: data.saveUserData });
        }
        if (data.savePartnerInfo) {
          this.LocalStorageService.put({ name: 'partnerOwners', value: data.savePartnerInfo });
        }
        if (data.showLogOutPage == true) {
          console.log('timeout', this.currentPath);
          this.showLogOutPage = true;
          this.LocalStorageService.clear();
          this.LocalStorageService.remove({ name: 'accessToken' });
          this.LocalStorageService.remove({ name: 'isAdmin' });
          localStorage.removeItem('planData')
          $('.modal').modal('hide');
          if (!this.currentPath.includes('/register') && !this.currentPath.includes('/set-password')) {
            console.log('redirect on clear session')
            this.router.navigate(['/sign-in']);
          }
        } else if (data.showLogOutPage == false) {
          this.showLogOutPage = false;
        }
      }
    );
    window.addEventListener("focus", (event) => {
      // console.log('focus', this.router.url.split('?')[0], this)
      if (!this.LocalStorageService.get({ name: 'accessToken' })) {
        if (!this.router.url.includes('/sign-in') && this.router.url.includes('/set-password') && this.router.url.includes('/forget-password') && !this.router.url.includes('/register')) {
          console.log('redirect on focus')
          this.router.navigate(['/sign-in'])
        }
      }
    });
  }
  ngOnInit(): void {
    // (<any>window).analytics.page();
  }
  getAppVersion() {
    this.AuthService.get('app_version').subscribe((result) => {
      this.CommonEmitter.sendMessage('app_version', 'v ' + result.data.app_version)
    }, (err) => {
      console.log(err);
    })
  }
}
