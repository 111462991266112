import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { IMultiSelectSettings } from 'angular-2-dropdown-multiselect';

declare var $: any;
@Component({
  selector: 'app-connections',
  templateUrl: './connections.component.html',
  styleUrls: ['./connections.component.css']
})
export class ConnectionsComponent implements OnInit {
  selectedUserName: string = '';
  // newOwner: string = '';
  selectedOwner: string = '';
  selectedUserID: any;
  selectedUserIndex: number;
  newOwner: FormControl;
  newName: FormControl;
  showError: boolean = false;
  loadedConnections: boolean = false;
  multiSelectionSettings: IMultiSelectSettings = {
    enableSearch: true,
    checkedStyle: "checkboxes",
    buttonClasses: "btn btn-default",
    dynamicTitleMaxItems: 0
  };
  constructor(private AuthService: AuthService, private fb: FormBuilder) {
    this.newOwner = new FormControl('', [Validators.required, Validators.pattern(/\S+@\S+\.\S+/)])
    this.newName = new FormControl('', [Validators.required])
    this.subscribeChanges()
  }
  subscribeChanges() {
    this.newOwner.valueChanges.subscribe(val => {
      this.showError = false;
      this.checkIfPartner(val);
      this.checkDomain(val);
    })
    this.newName.valueChanges.subscribe(val => {
      this.showError = false;
      this.checkIfExists(val);
      this.checkIfCompany(val);
    })
  }
  checkIfExists(value) {
    if (this.newName.errors && !this.newName.errors.exists) {
      return;
    }
    let temp = () => { return this.allConnections.filter(connection => value.trim().toLowerCase() == connection.name.trim().toLowerCase()) };
    if (temp.length > 1) {
      this.newName.setErrors({ exists: true })
    } else {
      this.newName.setErrors(null);
    }
  }
  checkIfCompany(value) {
    if (this.newName.errors && !this.newName.errors.ourCompany) {
      return;
    }
    if (value.trim().toLowerCase() == this.user.partner.partner_name.trim().toLowerCase()) {
      this.newName.setErrors({ ourCompany: true })
    } else {
      this.newName.setErrors(null);
    }
  }
  checkIfPartner(value) {
    if (this.newOwner.errors && !this.newOwner.errors.isPartner) {
      return;
    }
    if (value.trim().toLowerCase() == this.selectedOwner.trim().toLowerCase()) {
      this.newOwner.setErrors({ isPartner: true })
    } else {
      this.newOwner.setErrors(null)
    }
  }
  checkDomain(value) {
    if (this.newOwner.errors && !this.newOwner.errors.invalidDomain) {
      return;
    }
    let validDomain = this.selectedOwner.split('@')[1].trim().toLowerCase();
    let inputDomain = value.split('@')[1].trim().toLowerCase();
    if (inputDomain != validDomain) {
      this.newOwner.setErrors({ invalidDomain: true })
    } else {
      this.newOwner.setErrors(null)
    }
  }
  ngOnInit(): void {
    this.getUserDetails();
    this.getConnections();
  }
  user: any;
  getUserDetails() {
    this.AuthService.get("user/me", () => { }).subscribe(
      (result: any) => {
        console.log("header says", result);
        this.user = result.data[0];
      },
      err => {
        console.log(err);
      }
    );
  }
  connectionList: any = []
  filter: any = {};
  filterList() {
    console.log(this.filter)
    this.allConnections = this.connectionList.filter((a) => {
      let status = true;
      if (this.filter.partner_name && this.filter.partner_name.length && this.filter.partner_name.indexOf(a.partner_name) == -1) {
        status = false
      }
      return status;
    }).sort((a, b) => {
      if (this.filter.sortOrg) {
        if (this.filter.sortOrg == 'ASC') {
          if (a.partner_name < b.partner_name) { return -1; }
          if (a.partner_name > b.partner_name) { return 1; }
        } else {
          if (a.partner_name < b.partner_name) { return 1; }
          if (a.partner_name > b.partner_name) { return -1; }
        }
      }
      if (this.filter.sortEmail) {
        if (this.filter.sortEmail == 'ASC') {
          if (a.email < b.email) { return -1; }
          if (a.email > b.email) { return 1; }
        } else {
          if (a.email < b.email) { return 1; }
          if (a.email > b.email) { return -1; }
        }
      }
      return 0;
    })
  }
  allConnections: any[] = [];
  organisations: any = [];
  getConnections() {
    this.AuthService.get('admin/partners').subscribe(
      (result) => {
        console.log(result.data)
        this.allConnections = this.connectionList = result.data.map((a) => {
          if (this.organisations.filter((aa) => aa.name == a.partner_name).length == 0) {
            this.organisations.push({ name: a.partner_name, id: a.partner_name });
          }
          return a;
        });
        this.loadedConnections = true;
      },
      (error) => {
        console.log(error.error);
        this.allConnections = [];
        this.loadedConnections = true;
      },
    )
  }

  editUser(i: number, user: any) {
    this.finalUser = user;
    this.selectedUserIndex = i;
    this.selectedUserID = user.partner_id;
    this.selectedOwner = user.email;
    this.selectedUserName = user.partner_name;
    this.newName.patchValue(this.selectedUserName)
    $('#editModal').modal('show')
  }
  openDeleteModal(user) {
    this.finalUser = user;
    this.selectedUserID = user.partner_id;
    this.selectedOwner = user.email;
    this.selectedUserName = user.partner_name;
    $('#deleteModal').modal('show');
  }
  getDeletePayload() {
    return {
      partner_id: this.finalUser.partner_id,
      partner_owner: this.finalUser.email,
      connection_id: this.finalUser.connection_id
    }

  }
  deleteUser() {
    this.AuthService.delete(`admin/partners/${this.selectedUserID}`, this.getDeletePayload()).subscribe(
      (result: any) => {
        this.getConnections()
        this.closeDeleteModal();
      },
      (error) => {
        console.log(error);
      },
      () => { }
    )
  }

  closeDeleteModal() {
    $('#deleteModal').modal('hide');
  }
  closeEdit() {
    this.newOwner.reset('');
    $('#editModal').modal('hide')
  }
  finalUser: any;
  getEditPayload() {
    return {
      partner_id: this.finalUser.partner_id,
      partner_owner: this.newOwner.value,
      connection_id: this.finalUser.connection_id
    }

  }
  submitEdit() {
    if (this.newOwner.valid) {
      console.log(this.allConnections)
      this.AuthService.update(`admin/partners/${this.selectedUserID}`, this.getEditPayload()).subscribe(
        (result) => {
          console.log(result);
          this.newOwner.reset();
          this.closeEdit();
          this.getConnections();
        },
        (error) => { console.log(error) },
        () => { }
      )
    } else {
      this.showError = true;
    }
  }
}