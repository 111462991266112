<form [formGroup]="createSolutionForm">
  <div class="d-flex" style="justify-content: center;align-content: center;">
    <!-- Sidebar Navigation-->
    <div style="width:85%">
      <div class="row mrgbtm" [ngClass]="solutionId && !isEdit ? 'disabled-form' : ''">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 bdrritr" style="padding-top: 20px;">
          <div class="pad_div">
            <!-- <div class="row mrgbtm" *ngIf="solutionId">
              <div class="col-lg-4 col-md-6 col-sm-6 col-xs-6 col-6">
                <p class="head_type">
                  Solution ID:
                </p>
                <span class="head_type">(Auto generated)</span>
              </div>
              <div class="col-lg-8 col-md-6 col-sm-6 col-xs-6 col-6">
                <p class="data_field">{{ solutionId }}</p>
              </div>
            </div>
            <hr *ngIf="solutionId" style="margin-right: 25%;" /> -->
            <div class="row mrgbtmn">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                <p style="margin-left: -12px;" class="head_type">
                  Solution Name<sub class="required">*</sub>:
                </p>
              </div>
              <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9">
                <input matInput type="text" class="no-border" [readonly]="solutionId != ''" placeholder="Solution Name"
                  formControlName="solution_name" [(ngModel)]="solution.solution_name" />
                <div class="mrgbtm" *ngIf="
                    (formControls['solution_name'].dirty ||
                      formControls['solution_name'].touched ||
                      formError) &&
                    formControls['solution_name'].errors
                  ">
                  <div class="errorMessage" *ngIf="formControls['solution_name'].errors.required">
                    Solution Name is required.
                  </div>
                </div>
              </div>
            </div>
            <div class="row mrgbtmn">
              <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 head_type"
                style="display: flex; align-items: flex-end;">
                Partners:
              </div>
              <div class="col-lg-5 col-md-5 col-sm-4 col-xs-4 col-4 partnerDrop" style="
                  display: flex;
                  flex-direction: column;
                ">
                <p class="sub_title">Choose From:</p>
                <ng-select class="border-none owner" [multiple]="true" formControlName="partners"
                  [(ngModel)]="solution.partners" (ngModelChange)="onPartnerSelect($event)" [items]="partnerOptions"
                  bindLabel="name" bindValue="id" placeholder="Select"></ng-select>
                <!-- <ss-multiselect-dropdown [disabled]="!partnerOptions" [options]="partnerOptions"
                  [(ngModel)]="solution.partners" [settings]="multiSelectionSettings"
                  (ngModelChange)="onPartnerSelect($event)" formControlName="partners">
                </ss-multiselect-dropdown> -->
              </div>
              <!-- <div *ngIf="isEdit || !solutionId" class="col-lg-1 col-md-1 col-sm-4 col-xs-4 col-4"
                style="display: flex; align-items: flex-end;">
                <h5 class="data_field" style="margin-top: 0.5rem;">OR</h5>
              </div> -->

              <div class="col-lg-3 col-md-3 col-sm-4 col-xs-4 col-4 partnerDrop" style="
                  display: flex;
                  align-content: center;
                ">
                <div class="tbl_in_icon" style="display: flex;margin-top: auto;margin-bottom:auto">
                  <a style="cursor: pointer;font-size: 10px;" (click)="newPartner()" class="data_field_a"><i
                      class="fa fa-plus fc-blue" id="partnerButton_1" aria-hidden="true" style="font-size: 24px;"></i>
                    <span (click)="newPartner()" id="partnerButton_2" style="position: relative;
                    bottom: 24px;
                    right: 8px;width:fit-content">Add
                      Partner</span>
                  </a>
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-4" style="display: flex; align-items: flex-end;">
                <!-- <p class="head_type">Add Partner</p>
                <div class="tbl_in_icon">
                <i
                class="fa fa-plus-circle"
                aria-hidden="true"
                style="color: #44a2f8;"
              ></i>
                </div> -->
                <!-- <button (click)="newPartner()" *ngIf="isEdit || !solutionId" style="margin: 0;" type="button"
                  class="btn cnclbtn">
                  New Partner
                </button> -->
              </div>
              <div class="mrgbtm col-12  text-center" *ngIf="partner_error">
                <div class="errorMessage col-11" *ngIf="partner_error">
                  Partner is required
                </div>
              </div>
            </div>
            <div class="row mrgbtmn">
              <div class="col-lg-4 col-md-6 col-sm-6 col-xs-6 col-6 head_type">
                Solution Stage<sub class="required">*</sub>:
              </div>
              <div class="col-lg-5 col-md-5 col-sm-6 col-xs-6 col-6">
                <ng-select class="border-none owner" formControlName="solution_stage"
                  [(ngModel)]="solution.solution_stage" [items]="solutionStages" bindLabel="name" bindValue="name"
                  placeholder="Select"></ng-select>
                <!--<select class="ui search dropdown stage stage-1 fc-blue" formControlName="solution_stage"
                  [(ngModel)]="solution.solution_stage">
                  <option value="" [selected]="!solution.solution_stage">Select</option>
                  <option *ngFor="let stage of solutionStages" [value]="stage">{{ stage }}</option>
                </select>-->
                <div class="mrgbtm" *ngIf="
                    (formControls['solution_stage'].dirty ||
                      formControls['solution_stage'].touched ||
                      formError) &&
                    formControls['solution_stage'].errors
                  ">
                  <div class="errorMessage" *ngIf="formControls['solution_stage'].errors.required">
                    Select a stage.
                  </div>
                </div>
              </div>
            </div>
            <div class="row mrgbtmn">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 head_type">
                Description<sub class="required">*</sub>:
              </div>
              <div style="margin-bottom: 40px;" class="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9">
                <textarea style="margin: 0px 0px 1px -14px; margin-bottom: 0px;" formControlName="description"
                  [(ngModel)]="solution.description" placeholder="Solution description"></textarea>
                <div class="mrgbtm" *ngIf="
                    (formControls['description'].dirty ||
                      formControls['description'].touched ||
                      formError) &&
                    formControls['description'].errors
                  ">
                  <div class="errorMessage" *ngIf="formControls['description'].errors.required">
                    Description is required.
                  </div>
                </div>
              </div>
            </div>
            <div class="row mrgbtmn">
              <div class="col-lg-4 col-md-4 col-sm-6 col-xs-6 col-6 head_type">
                Estimated Start Date<sub class="required">*</sub>:
              </div>
              <div style="position: relative;" class="col-lg-5 col-md-5 col-sm-6 col-xs-6 col-6">
                <input style="border: none;" type="text" id="date1" [(ngModel)]="solution.start_date"
                  formControlName="start_date" placeholder="Start Date" autocomplete="off" />
                <!-- <span style="position: absolute; left: 85%; z-index: 99; top: 0px;" class="material-icons">
                  event_note
                </span> -->

                <div class="mrgbtm" *ngIf="
                    (formControls['start_date'].dirty ||
                      formControls['start_date'].touched ||
                      formError) &&
                    formControls['start_date'].errors
                  ">
                  <div class="errorMessage" *ngIf="formControls['start_date'].errors.required">
                    Start Date is required.
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="row mrgbtmn">
              <div class="col-lg-4 col-md-6 col-sm-6 col-xs-6 col-6">
                <h4 class="head_type">
                  Total Revenues<sub class="required">*</sub>:
                </h4>
              </div>
              <div class="col-lg-8 col-md-6 col-sm-6 col-xs-6 col-6">
                <input type="text" placeholder="Enter Total Revenue" formControlName="revenue"
                  [(ngModel)]="solution.revenue" />
                <div class="mrgbtm" *ngIf="
                    (formControls['revenue'].dirty ||
                      formControls['revenue'].touched ||
                      formError) &&
                    formControls['revenue'].errors
                  ">
                  <div class="errorMessage" *ngIf="formControls['revenue'].errors.required">
                    Revenue is required
                  </div>
                  <div class="errorMessage" *ngIf="formControls['revenue'].errors.pattern">
                    Revenue must be a valid number or decimal
                  </div>
                </div>
              </div>
            </div> -->
            <div class="row mrgbtmn">
              <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 head_type">
                Team Members:
              </div>
              <div class="col-lg-5 col-md-5 col-sm-3 col-xs-3 col-3">
                <ng-select class="border-none owner" [multiple]="true" formControlName="teams"
                  [(ngModel)]="partnerUsersModel" (ngModelChange)="onChange($event)" [items]="partnerTeamOptions"
                  bindLabel="name" bindValue="id" placeholder="Select"></ng-select>
              </div>
              <div style="margin: 7px 0 0 0;display: flex;align-content: center;"
                class="col-lg-2 col-md-2 col-sm-4 col-xs-4 col-4  partnerDrop">
                <div class="tbl_in_icon" style="display: flex;margin-top: auto;margin-bottom:auto">
                  <a style="cursor: pointer;font-size: 10px;" class="data_field_a"><i class="fa fa-plus fc-blue"
                      (click)="openTeam()" aria-hidden="true" style="font-size: 24px;"></i>
                    <span (click)="openTeam()"
                      style="position: relative;
                    bottom: 24px;
                    right: 8px;width:fit-content">{{this.invitedTeam.length < 1 ? "Invite" : this.invitedTeam.length + " invited"}}</span>
                  </a>
                </div>
              </div>
            </div>
            <div class="row mrgbtmn">
              <div class="col-lg-4 col-md-6 col-sm-6 col-xs-6 col-6">
                <p class="head_type">
                  Target Revenues<sub class="required">*</sub>:
                </p>
              </div>
              <div class="col-lg-5 col-md-5 col-sm-6 col-xs-6 col-6">
                <div style="display:flex;">
                  <!-- <h4 class="fz-16 fc-blue" style="margin: auto;">
                    $
                  </h4> -->
                  <input matInput type="text" placeholder="Enter Target Revenue" formControlName="revenue"
                    [(ngModel)]="solution.revenue" />
                </div>
                <div class="mrgbtm" *ngIf="
                    (formControls['revenue'].dirty ||
                      formControls['revenue'].touched ||
                      formError) &&
                    formControls['revenue'].errors
                  ">
                  <div class="errorMessage" *ngIf="formControls['revenue'].errors.required">
                    Revenue is required
                  </div>
                  <div class="errorMessage" *ngIf="formControls['revenue'].errors.pattern">
                    Revenue must be a valid number or decimal
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 " style="padding-top: 20px;">
          <div class="pad_div">
            <div class="row mrgbtmn">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <input type="checkbox" id="additionalInfo" [(ngModel)]="solution.additional"
                  formControlName="additional" style="top: 5px;" (click)="applyDropdown()" />
                <label class="head_type fz-14 check-box" for="additionalInfo" for="additionalInfo">
                  Additional Solution Details (Optional)</label>
              </div>
            </div>

            <div class="additiona-box" [ngClass]="{'d-none': !solution.additional}">
              <div class="row mrgbtmn">
                <div class="col-lg-4 col-md-4 col-sm-5 col-xs-5 col-5">
                  <p style="margin-top: 5px;" class="head_type">
                    Tag to a Plan:
                  </p>
                </div>
                <div class="col-lg-5 col-md-5 col-sm-3 col-xs-3 col-3">
                  <ng-select class="border-none owner" formControlName="plan_id" [multiple]="true"
                    [(ngModel)]="solution.plan_id" #plan (ngModelChange)="setPlanName($event)" [items]="plans"
                    bindLabel="plan_name" bindValue="id" placeholder="Select"></ng-select>
                  <!--<select class="ui search dropdown stage fc-blue" #plan formControlName="plan_id"
                    [(ngModel)]="solution.plan_id" (change)="setPlanName(plan.value)">
                    <option>Select</option>
                    <option [value]="plan.id" *ngFor="let plan of plans">{{
                      plan.plan_name
                    }}</option>
                  </select>-->
                  <div class="mrgbtm" *ngIf="
                      (formControls['solution_stage'].dirty ||
                        formControls['solution_stage'].touched ||
                        formError) &&
                      formControls['solution_stage'].errors
                    ">
                    <div class="errorMessage" *ngIf="formControls['solution_stage'].errors.required">
                      Select a plan.
                    </div>
                  </div>
                </div>

                <!-- <div class="col-lg-3 col-md-3 col-sm-4 col-xs-4 col-4 partnerDrop" style="
              display: flex;
              flex-direction: column;
              align-items: flex-start;
            ">
              <p class="sub_title">Add Product</p>
               
                <div class="tbl_in_icon">
                  <i class="fa fa-plus" data-toggle="modal" data-target="#myModal"
                    style="color: #44a2f8;"></i>
                </div>
            </div> -->
              </div>
              <div class="row mrgbtmn">
                <div class="col-lg-4 col-md-4 col-sm-5 col-xs-5 col-5">
                  <p class="head_type">
                    Product Category:
                  </p>
                </div>
                <!-- <div class="col-lg-8 col-md-8 col-sm-7 col-xs-7 col-7"> -->

                <!--</div> -->
                <div class="col-lg-5 col-md-5 col-sm-3 col-xs-3 col-3">
                  <ng-select class="border-none owner" [multiple]="true" formControlName="product_category"
                    [(ngModel)]="solution.product_category" (ngModelChange)="getProducts($event)"
                    [items]="productCategories" bindLabel="category_name" bindValue="category_name"
                    placeholder="Select"></ng-select>

                  <!--<select multiple #product_category formControlName="product_category"
                    [(ngModel)]="solution.product_category" (change)="getProducts(product_category.value)"
                    class="ui search dropdown stage fc-blue">
                    <option value="" [selected]="!solution.product_category">Select</option>
                    <option *ngFor="let cat of productCategories" [value]="cat.category_name">
                      {{ cat.category_name }}
                    </option>
                  </select>-->
                  <!-- <p *ngFor="let cat of productCategories">{{ cat.category_name }}</p> -->
                  <!-- <select class="ui search dropdown stage" #product_category formControlName="product_category" [(ngModel)]="solution.product_category" (change)="getProducts(product_category.value)">
                  <option value="" [selected]="!solution.product_category">Select</option>
                  <option *ngFor="let cat of productCategories" [value]="cat.category_name">  {{ cat.category_name }}</option>
                </select> -->
                  <div class="mrgbtm" *ngIf="
                      (formControls['solution_stage'].dirty ||
                        formControls['solution_stage'].touched ||
                        formError) &&
                      formControls['solution_stage'].errors
                    ">
                    <div class="errorMessage" *ngIf="formControls['solution_stage'].errors.required">
                      Select a product category.
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="row mrgbtm">
              <div class="col-lg-4 col-md-4 col-sm-6 col-xs-6 col-12">
                <h4 class="head_type">
                  Product:
                </h4>
              </div>
              <div class="col-lg-4 col-md-3 col-sm-3 col-xs-3 col-3">
               
                <select class="ui search dropdown stage" #product formControlName="product" [(ngModel)]="solution.product">
                  <option value="" [selected]="!solution.product">Select</option>
                  <option *ngFor="let prod of products" [value]="prod.product_name">  {{ prod.product_name }}</option>
                </select>
                <button
                  type="button"
                  class="btn cnclbtn col-6"
                  style=" float:right;"
                  data-toggle="modal"
                  data-target="#myModal"
                  *ngIf="isEdit || !solutionId"
                >
                  Add Product
                </button>
              </div>
            </div> -->

              <div class="row mrgbtmn">
                <div class="col-lg-4 col-md-4 col-sm-5 col-xs-5 col-5">
                  <p style="margin-top: 5px;" class="head_type">
                    Products:
                  </p>
                </div>

                <div class="col-lg-5 col-md-5 col-sm-3 col-xs-3 col-3">
                  <ng-select class="border-none owner" [multiple]="true" formControlName="product"
                    [(ngModel)]="solution.product" #product [items]="products" bindLabel="product_name"
                    bindValue="product_name" placeholder="Select"></ng-select>

                  <!--<select multiple class="ui search dropdown stage fc-blue" #product formControlName="product"
                    [(ngModel)]="solution.product">
                    <option value="" [selected]="!solution.product">Select</option>
                    <option *ngFor="let prod of products" [value]="prod.product_name">
                      {{ prod.product_name }}</option>
                  </select>-->
                  <div class="mrgbtm" *ngIf="
                      (formControls['solution_stage'].dirty ||
                        formControls['solution_stage'].touched ||
                        formError) &&
                      formControls['solution_stage'].errors
                    ">
                    <div class="errorMessage" *ngIf="formControls['solution_stage'].errors.required">
                      Select a product.
                    </div>
                  </div>
                </div>

                <!-- <div class="col-lg-3 col-md-3 col-sm-4 col-xs-4 col-4 partnerDrop" style="
              display: flex;
              flex-direction: column;
              align-items: flex-start;
            ">
                  <p class="sub_title">Add Product</p>
                  <!-- <ss-multiselect-dropdown [disabled]="!partnerOptions" [options]="partnerOptions"
              [(ngModel)]="solution.partners" [settings]="multiSelectionSettings"
              (ngModelChange)="onPartnerSelect($event)" formControlName="partners">
            </ss-multiselect-dropdown> --
                  <div class="tbl_in_icon">
                    <i class="fa fa-plus" data-toggle="modal" data-target="#myModal" style="color: #44a2f8;"></i>
                  </div>
                </div> -->
              </div>
            </div>
            <div class="row" style="margin-top: 40px;">
              <div class="col-md-8 col-12">
                <p class="head_type">
                  Solutions Documents:
                </p>
              </div>
              <div class="col-md-4 col-12">
                <div class="tbl_in_icon">
                  <!--<a href="#" data-toggle="modal" data-target="#myModaladd">
                    <i
                      class="fa fa-download"
                      aria-hidden="true"
                      style="color: #44a2f8;"
                    ></i>
                  </a>-->
                  <a href="javascript:;" data-toggle="modal" data-target="#docModal">
                    <i class="fa fa-paperclip" aria-hidden="true" style="color: #44a2f8;"></i>
                  </a>
                </div>
              </div>
            </div>

            <div class="row mrgbtm">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12"></div>
            </div>

            <div class="row mrgbtm">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 table-responsive">
                <table class="table solntblnew border border-dark">
                  <thead class="thead-dark">
                    <tr>
                      <th style="background-color: black;" scope="col">
                        File Name
                      </th>
                      <th scope="col">Type</th>
                      <th scope="col">Date Modified</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style="display: none;">
                      <td>
                        <!-- <input type="checkbox" name="" />
                        <span style="padding-left: 3px; font-weight: bold;">
                          Select All</span> -->
                      </td>

                      <td colspan="2" style="text-align: right; font-size: 15px;">
                        <!-- <a href="javascript:;" data-toggle="modal" data-target="#docModal"
                          style="font-size: 20px; color: #44A2F8;"><i class="fa fa-paperclip"
                            aria-hidden="true"></i></a> -->
                      </td>
                    </tr>
                    <tr *ngFor="let f of solution.documents; let i = index">
                      <td>
                        <!-- <input type="checkbox" name="" /> -->
                        <span style="padding-left: 3px;">
                          {{ f.fileName }}</span>
                      </td>
                      <td>{{ f.type }}</td>
                      <td>
                        {{ f.createdDate | date: "MMM dd, yyyy" }}
                        <label class="float-right">
                          <a href="javascript:;" class="text-primary pr-3" (click)="downloadFile(f)"><i
                              class="fa fa-download ml-2"></i></a>
                          <a href="javascript:;" class="text-primary " (click)="removeDoc(i)"><i
                              class="fa fa-trash"></i></a>

                        </label>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="domainMatchError" class="row mrgbtm">
        <div class="col-md-12">
          <div align="center" class="errorMessage">
            Partner Owner(s) and Team members domains does not match.
          </div>
        </div>
      </div>
      <div *ngIf="formError" class="row mrgbtm">
        <div class="col-md-12">
          <div align="center" class="errorMessage">
            Please fill all required(*) details to create solution
          </div>
        </div>
      </div>
      <div class="row mrgbtm">
        <div class="col-md-12">
          <div align="center">
            <a type="button" (click)="goBack()" class="btn cnclbtn">CANCEL</a>
            <button type="button" (click)="openConfirmPopup()" [disabled]="isProgress" *ngIf="!solutionId"
              class="btn addbtn">
              CREATE SOLUTION
            </button>
            <button type="button" (click)="isEdit = true" *ngIf="solutionId && !isEdit" class="btn addbtn">
              EDIT SOLUTION
            </button>
            <button type="button" [disabled]="isProgress" (click)="openConfirmPopup()" *ngIf="solutionId && isEdit"
              class="btn addbtn">
              UPDATE SOLUTION
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
<!-----------model popup-------->
<!-- The Modal -->
<div class="modal" id="myModal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header" style="text-align: center; display: inline;">
        <h4 class="modal-title">Add Product</h4>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <div class="mrgbtm row">
          <label for="inputPassword" class="col-sm-4 col-form-label">Product Category:</label>
          <div class="col-sm-8">
            <input type="text" #prod_cat class="form-control" placeholder="Notes" />
            <div class="mrgbtm" *ngIf="errors.category">
              <div class="errorMessage" *ngIf="errors.category">
                {{ errors.category }}
              </div>
            </div>
          </div>
        </div>
        <div class="mrgbtm row">
          <label for="inputPassword" class="col-sm-4 col-form-label">Product:</label>
          <div class="col-sm-8">
            <input type="text" #prod class="form-control" placeholder="Notes." />
            <div class="mrgbtm" *ngIf="errors.product">
              <div class="errorMessage" *ngIf="errors.product">
                {{ errors.product }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer" style="text-align: center; display: inline;">
        <a type="button" class="btn cnclbtn" data-dismiss="modal">CANCEL</a>

        <button type="button" class="btn addbtn" (click)="openConfirmModal(prod_cat.value, prod.value)">
          CONFIRM
        </button>
      </div>
    </div>
  </div>
</div>
<!-----------Document model popup-------->
<!-- The Modal -->
<div class="modal" id="docModal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal body -->
      <div class="modal-body">
        <div class="row">
          <div style="text-align: center" class="col">
            <h5 class="modal-title mrgbtmn">Choose file to attach:</h5>
          </div>
        </div>
        <div class="mrgbtm row">
          <label for="inputPassword" class="col-sm-4 col-form-label">
            <p class="head_type">Document</p>
          </label>
          <div class="col-sm-8">
            <input type="file" #file name="file_upload" (change)="handleFileSelect($event)" class=""
              placeholder="Notes" />
            <div class="mrgbtm" *ngIf="errors.document">
              <div class="errorMessage" *ngIf="errors.document">
                {{ errors.document }}
              </div>
            </div>
          </div>
        </div>
        <div class="mrgbtm row">
          <label for="inputPassword" class="col-sm-4 col-form-label">
            <p class="head_type">Type:</p>
          </label>
          <div class="col-sm-8">
            <input type="text" #type name="file_name" class="form-control" placeholder="Notes." />
            <div class="mrgbtm" *ngIf="errors.type">
              <div class="errorMessage" *ngIf="errors.type">
                {{ errors.type }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer" style="text-align: center; display: inline;">
        <a type="button" class="btn cnclbtn" data-dismiss="modal">CANCEL</a>

        <button type="button" [disabled]="isProgress" class="btn addbtn" (click)="addDocument(type.value)">
          CONFIRM
        </button>
      </div>
    </div>
  </div>
</div>
<!-----------model popup confirm-------->
<!-- The Modal -->
<div class="modal" id="myModalcnfrm">
  <div class="modal-dialog">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header" style="text-align: center; display: inline;">
        <h4 class="modal-title">Are You Sure?</h4>
      </div>

      <!-- Modal body -->
      <div class="modal-body" style="text-align: center; display: inline;">
        <p>You want to add product category and product.</p>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer" style="text-align: center; display: inline;">
        <button type="button" class="btn cnclbtn" data-dismiss="modal">
          CANCEL
        </button>

        <button type="button" [disabled]="isProgress" (click)="confirmProduct(prod_cat.value, prod.value)"
          class="btn addbtn">
          CONFIRM
        </button>
      </div>
    </div>
  </div>
</div>
<!-- The Modal -->
<div class="modal" id="myModalcnfrmDelete">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal body -->
      <div class="modal-body" style="text-align: center; display: inline;color:red;">
        <h5 class="modal-title mrgbtmn">Are You Sure?</h5>
        <p>Do you want to delete this document permanently?</p>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer" style="text-align: center; display: inline;">
        <button type="button" class="btn cnclbtn" data-dismiss="modal">
          CANCEL
        </button>

        <button type="button" [disabled]="isProgress" (click)="removeDoc(selectedDocument, true)" class="btn addbtn">
          CONFIRM
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal" id="teamModal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal body -->
      <div class="modal-body" style="text-align: center; display: inline; padding: 0;">
        <app-add-team [onlySuperUsers]="false" [teamList]="partnerTeamOptions" [meData]="userDataPartners"
          [selfPartners]="selfPartners" [domainList]="this.domainList" [partner_owner_email]="this.userPartnerEmail"
          (submitForm)="saveTeam($event)" (closeModal)="closeTeam()" (domainMismatch)="domainChange($event)">
        </app-add-team>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="successModal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content modal-custom">
      <!-- Modal body -->
      <div *ngIf="displayResult == 'success'" class="modal-body" style="text-align: center; display: inline;">
        <div class="row mrgbtmn">
          <div class="col-md-12">
            <h5 *ngIf="!solutionId" class="modal-title mrgbtm">Solution Created!</h5>
            <h5 *ngIf="solutionId" class="modal-title mrgbtm">Solution Updated!</h5>
            <p *ngIf="invitedTeam.length > 0">
              Invites will be sent to
              <span *ngFor="let member of invitedTeam; index as i">
                {{ member.email
            }}{{ i < invitedTeam.length - 1 ? "," : "." }}
              </span></p>
          </div>
        </div>
        <div class="row mrgbtm">
          <div class="col-md-12">
            <div align="center">
              <button (click)="this.goBack()" type="button" class="btn addbtn">
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="displayResult == 'failure'" class="modal-body" style="text-align: center; display: inline;">
        <div>
          <div class="row mrgbtm">
            <div class="col-md-12">
              <div align="center" class="errorMessage">
                {{ errorMessage }}
              </div>
            </div>
          </div>
          <div class="row mrgbtm">
            <div class="col-md-12">
              <div align="center">
                <button (click)="closeModal()" type="button" class="btn addbtn">
                  CLOSE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="displayResult == ''" class="modal-body" style="text-align: center; display: inline;">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <p>{{ solutionId ? "Updating the" : "Creating a" }} Solution...</p>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="confirmPopup">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal body -->
      <div class="modal-body" style="text-align: center; display: inline;">
        <h5 class="modal-title mrgbtm">Are You Sure?</h5>
        <p *ngIf="!solutionId">
          All invitees can access Solution, on confirmation
        </p>
        <p *ngIf="solutionId && isEdit">
          All changes will be saved, on confirmation
        </p>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer" style="text-align: center; display: inline;">
        <button type="button" (click)="closeConfirmPopup()" class="btn cnclbtn">
          CANCEL
        </button>

        <button type="button" class="btn addbtn" (click)="submit()">
          CONFIRM
        </button>
      </div>
    </div>
  </div>
</div>