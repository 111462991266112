import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

declare var $: any;
@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {
  forgot: any = {};
  email: string;
  errMessage: string = '';
  displayError: boolean = false
  forgotPasswordForm: FormGroup;
  formControls: any;
  page: any = 'forget';

  constructor(private AuthService: AuthService, private router: Router, private route: ActivatedRoute, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    if (this.AuthService.isLoggedIn())
      this.router.navigate(['/dashboard']);
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern(/\S+@\S+\.\S+/)])],
    })
    this.formControls = this.forgotPasswordForm.controls
  }
  respStatus: string = 'none'
  respMessage: string = ''
  submit() {
    if (this.forgotPasswordForm.valid) {
      this.AuthService.add('user/forgotpassword', this.forgotPasswordForm.value).subscribe((result: any) => {
        this.respStatus = 'success'
        $('#success_modal').modal('show');
      }, (err: any) => {
        this.respStatus = 'error'
        this.respMessage = err.error.meta.message
        $('#success_modal').modal('show');
      });
    } else {
      this.displayError = true;
    }

  }

}
