<div style="
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 60px;
  ">
  <!-- *ngIf="connections.length" -->
  <div style="
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    ">
    <div class="row text-center justify-content-center">
      <!-- <ngx-daterangepicker-material (choosedDate)="choosedDate($event)">
      </ngx-daterangepicker-material> -->
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 d-f f-d-c text-center mt-4">
        <h4 class="head_type fz-14 fc-gray-1">Date Range:</h4>
      </div>
      <div class="form-group col-md-12 mt-2 mb-4 justify-content-center d-flex">
        <input type="text" class="form-control col-5 text-center" style="border: none !important" ngxDaterangepickerMd
          [(ngModel)]="selected" [showCustomRangeLabel]="true" [alwaysShowCalendars]="true" [ranges]="ranges"
          [linkedCalendars]="true" [isInvalidDate]="isInvalidDate" [showClearButton]="true" placeholder="Select dates"
          (ngModelChange)="dateChaged($event)" />
      </div>
    </div>
    <div class="row" style="height: 150px">
      <div class="col-md card-wrapper">
        <p class="card-title-custom fc-gray-1">Customers</p>
        <div class="custom-card-1 plan">
          <p class="card-number-custom mt-5">
            Customers Invited <br /><br /><strong><a href="javascript:;" (click)="
                  goToList('invitedCustomer', counterData.invitedCustomer)
                ">{{ counterData.invitedCustomer }}</a></strong>
          </p>
          <p class="card-number-custom">
            Customers Registered <br /><br /><strong><a href="javascript:;" (click)="
                  goToList('registeredCustomer', counterData.registeredCustomer)
                ">{{ counterData.registeredCustomer }}</a></strong>
          </p>
          <p class="card-number-custom">
            Customers Purchased <br /><br /><strong><a href="javascript:;" (click)="
                  goToList('purchasesCustomers', counterData.purchasesCustomers)
                ">{{ counterData.purchasesCustomers }}</a></strong>
          </p>
        </div>
      </div>
      <div class="col-md card-wrapper">
        <p class="card-title-custom fc-gray-1">Purchases</p>
        <div class="custom-card-1 solution">
          <p class="card-number-custom mt-5">
            New Customer Purchases <br /><br /><strong><a href="javascript:;" (click)="
                  goToList('newUserPurchases', counterData.newUserPurchases)
                ">{{ counterData.newUserPurchases | number: "1.2-2" }} GBP</a></strong>
          </p>
          <p class="card-number-custom">
            Total Orders <br /><br /><strong><a href="javascript:;" (click)="
                  goToList(
                    'newUserPurchasesOrders',
                    counterData.newUserPurchasesOrders
                  )
                ">{{ counterData.newUserPurchasesOrders }}</a></strong>
          </p>

          <p class="card-number-custom">
            Total Purchases <br /><br /><strong><a href="javascript:;"
                (click)="goToList('purchases', counterData.purchases)">{{ counterData.purchases | number: "1.2-2" }}
                GBP</a></strong>
          </p>
        </div>
      </div>
      <div class="col-md card-wrapper">
        <p class="card-title-custom fc-gray-1">Performance</p>
        <div class="custom-card-1 opportunity pt-5">
          <p class="card-number-custom" *ngIf="userData.email && !is_subscriber">
            Your Commission <br /><br /><strong><a href="javascript:;"
                (click)="goToList('commission', counterData.purchases)">{{
                  ((counterData.purchases *
                    (userData &&
                      (userData.email == userData.partner.partner_owner
                        ? 2
                        : 10))) /
                    100 )| number: "1.2-2"
                }}
                GBP</a></strong>
          </p>
          <p class="card-number-custom" *ngIf="
          userData.email &&
              !is_subscriber &&
              userData.email == userData.partner.partner_owner
            ">
            Your Team Commission <br /><br /><strong><a href="javascript:;"
                (click)="goToList('commission', counterData.purchases,'team')">{{
                 ( (counterData.purchases * 10) / 100) | number: "1.2-2"
                }}
                GBP</a></strong>
          </p>
          <p class="card-number-custom" *ngIf="userData.email && is_subscriber">
            Total Commission Paid <br /><br /><strong><a href="javascript:;"
                (click)="goToList('commission', counterData.purchases)">{{
                 ( (counterData.purchases * 12) / 100) | number: "1.2-2"
                }}
                GBP</a></strong>
          </p>
          <p class="card-number-custom" *ngIf="userData.email">
            Members <br /><br /><strong><a href="javascript:;"
                (click)="goToList('commission', counterData.partners)">{{ counterData.partners }}</a></strong>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>