<div class="d-flex align-items-stretch">
  <div class="container-fluid">
    <div style="
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      ">


      <div class="col-9" style="margin-top: 80px; text-align: center; height: 100%;">
        <form [formGroup]="userForm">
          <div>
            <div>
              <div class="row">
                <div class="col">
                  <div class="row">
                    <!-- <div class="col col-centered">
                      <h5>User Email</h5>
                    </div> -->


                    <div class="col">
                      <div class="row">
                        <div class="col-6 col-centered">
                          <h5>User Email <sub class="required" style="line-height: 0.5;">*</sub></h5>
                        </div>
                        <div class="col-3 col-centered">
                          <h5>Super User(Y/N)</h5>
                        </div>
                        <div class="col-3 col-centered">
                          <h5>Admin User(Y/N)</h5>
                        </div>
                      </div>
                    </div>


                  </div>
                  <!-- <div class="row">
                    <div class="col"></div>
                    <div class="col"></div>
                    <div class="col"></div>
                  </div> -->


                </div>
              </div>
            </div>
          </div>
          <div formArrayName="users">
            <div style="margin: 15px 0;" class="row" *ngFor="let userGroup of usersArray.controls; let i = index"
              [formGroupName]="i">
              <div class="col">
                <div class="row">
                  <!-- <div class="col col-centered">
                    <span class="material-icons">
                      email
                    </span>
                    <input
                      type="text"
                      placeholder="Email"
                      formControlName="email"
                    />
                  </div> -->

                  <div class="col">
                    <div class="row">

                      <div class="col-6 col-centered">
                        <span class="material-icons">
                          email
                        </span>
                        <input type="text" style="width: 100%;" placeholder="Email" formControlName="email" />
                      </div>

                      <div class="col-3 col-centered">
                        <label class="pkj-checkbox">
                          <input type="checkbox" [checked]="userGroup.get('super_user').value"
                            (click)="changeSuperAccess($event, userGroup)" type="checkbox" id="super{{ i }}"
                            formControlName="super_user">
                          <span class="checkmark"></span>
                        </label>
                        <!-- <input [checked]="userGroup.get('super_user').value" style="border-color: #44A2F8 ;border-width: 2px;height:20px;width: 20px;border-radius: 4px;-webkit-appearance:auto;"
                          (click)="changeSuperAccess($event, userGroup)"
                          type="checkbox"
                          id="super{{ i }}"
                          formControlName="super_user"
                        /> -->
                      </div>
                      <div class="col-3 col-centered">
                        <label class="pkj-checkbox">
                          <input type="checkbox" [checked]="userGroup.get('admin_user').value"
                            (click)="changeAdminAccess($event, userGroup)" type="checkbox" id="admin{{ i }}"
                            formControlName="admin_user">
                          <span class="checkmark"></span>
                        </label>
                        <!-- <input  [checked]="userGroup.get('admin_user').value"  style="border-color: #44A2F8 ;border-width: 2px;height:20px;width: 20px;border-radius: 4px;-webkit-appearance:auto;"
                          type="checkbox"
                          id="admin{{ i }}"
                          formControlName="admin_user"
                          (click)="changeAdminAccess($event, userGroup)"
                        /> -->
                      </div>

                    </div>
                  </div>


                </div>
                <div class="row" *ngIf="
                    (userGroup.get('email').dirty ||
                      userGroup.get('email').touched) &&
                    userGroup.get('email').errors
                  ">
                  <div class="col col-centered">
                    <span class="errorMessage" *ngIf="userGroup.get('email').errors.pattern">
                      Enter valid email id
                    </span>
                    <span class="errorMessage" *ngIf="userGroup.get('email').errors.invalidDomain">
                      Enter user with valid domain
                    </span>
                    <span class="errorMessage" *ngIf="userGroup.get('email').errors.unique">
                      Email already entered
                    </span>
                    <span class="errorMessage" *ngIf="userGroup.get('email').errors.exists">
                      User already exists
                    </span>
                  </div>
                  <div class="col">
                    <div class="row">
                      <div class="col"></div>
                      <div class="col"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div class="row" *ngIf="showError">
          <div class="col-12 errorMessage">
            Please fill proper details to add users.
          </div>
        </div>

        <div style="
            display: flex;
            flex-direction: row;
            margin-top: 30px;
            align-items: center;
            justify-content: flex-end;
          ">
          <a href="javascript:;" data-target="#addMoreModal" data-toggle="modal"
            style="text-decoration: underline; color: #1e98ea;">Add More</a>
        </div>
        <div class="col-12 custom-row" style="
            display: flex;
            flex-direction: row;
            margin-top: 30px;
            align-items: center;
            justify-content: flex-end;
            padding-right: 150px;
            margin-bottom: 10px;
          ">
          <button class="btn" (click)="confirmModal()" style="
              background-color: #1e98ea;
              color: white;
              padding-top: 8px;
              padding-bottom: 8px;
              padding-left: 50px;
              padding-right: 50px;
            ">
            ADD USERS
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal" id="getYouSetup">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <!-- Modal body -->
        <div class="modal-body" style="text-align: left; display: inline;">
          <div class="row mrgbtm">
            <div class="col-md-12 mt-4">
              <h5 style="color: black;">Let's get you set up!</h5>
            </div>
            <div class="col-md-12 mt-3">
              <p style="color: black;">
                1.
                <a href="#" style="text-decoration: underline; color: #1e98ea;">Add users</a>
              </p>
            </div>
            <div class="col-md-12 mt-3 mb-4">
              <p style="color: black;">
                2.
                <a href="#" style="text-decoration: underline; color: #1e98ea;">Customize Flyero to your
                  organization</a>
              </p>
            </div>
          </div>
          <!-- Modal footer -->
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal" id="successModal">
  <div class="modal-dialog modal-centered">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row">
          <div class="col">
            <h5>Successfully added users</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal" id="confirmModal">
  <div class="modal-dialog modal-centered">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row mrgbtmn">
          <div class="col">
            <h4>Are you sure?</h4>
          </div>
        </div>
        <div class="row mrgbtmn">
          <div class="col">
            <p>Invites to join Flyero will be sent to all Users</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="btn-group-1">
              <button class="btn cnclbtn1" data-target="#confirmModal" data-dismiss="modal">
                CLOSE
              </button>
              <button class="btn addbtn" (click)="adduser()">
                CONFIRM
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="errorModal">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row">
          <div class="col errorMessage" *ngIf="errorId == 1">
            Enter valid email id to give access.
          </div>
          <div class="col errorMessage" *ngIf="errorId == 2">
            Cannot make provide admin access to other partner users.
          </div>
          <div class="col errorMessage" *ngIf="errorId == 3">
            Cannot delete your own account
          </div>
          <div class="col" *ngIf="errorId == 4"></div>
          <div class="col" *ngIf="errorId == 5"></div>
        </div>
      </div>
      <div class="modal-footer" style="text-align: center; display: inline;">
        <button type="button" (click)="closeErrorModal()" class="btn cnclbtn">
          CLOSE
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal" id="addMoreModal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row">
          <div class="col">
            No. of Users to add:
          </div>
          <div class="col">
            <input placeholder="Enter Number" type="number" [(ngModel)]="noOfAdditionalUsers">
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="btn-group-1">
              <button class="btn cnclbtn1" data-target="#addMoreModal" data-dismiss="modal">
                CANCEL
              </button>
              <button class="btn addbtn" (click)="addmore(false)">
                ADD
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>