import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { MustMatch } from 'src/validators/must-match.validator';
import { LocalStorageService } from '../services/local-storage.service';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { NavigationHelperService } from '../services/navigation-helper.service';
declare var $: any;
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  errMessage: any = '';
  password: any;
  formControls: any;
  formError: boolean = false;
  passwordPattern: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~])[A-Za-z\d `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]{8,}$/;
  changeStatus = 'none';
  initPath = this.AuthService.baseUrl;
  picPath = 'file/2020-08-07/1596807539174-Image.png';
  constructor(private fb: FormBuilder,
    private LocalStorageService: LocalStorageService,
    private AuthService: AuthService,
    private router: Router,
    private navHelper: NavigationHelperService) {
    if (this.navHelper.navigationTracker.length == 0) {
      this.navHelper.setUrl(this.router.url)
    }
    this.getUserData();
    this.changePasswordForm = this.fb.group({
      password: ['', Validators.compose([Validators.required])],
      new_password: ['', Validators.compose([Validators.required, Validators.pattern(this.passwordPattern)])],
      confirm_new_password: ['', Validators.compose([Validators.required])],
    }, { validators: MustMatch('new_password', 'confirm_new_password') });
    this.formControls = this.changePasswordForm.controls;
    this.subscribeChanges();
  }
  userData: any;
  subscribeChanges() {
    this.changePasswordForm.valueChanges.subscribe((val) => {
      this.changeStatus = 'none';
      this.formError = false;
      this.oldNewMatch();
    })
  }
  oldNewMatch() {
    let npControl: AbstractControl = this.changePasswordForm.get('new_password');
    if (npControl.errors && !npControl.errors.samePassword) {
      return;
    }
    if (this.changePasswordForm.value.password == this.changePasswordForm.value.new_password) {
      npControl.setErrors({ samePassword: true });
    } else {
      npControl.setErrors(null);
    }
  }
  getUserData() {
    this.userData = JSON.parse(this.LocalStorageService.get({ name: 'userData' }));
    this.picPath = this.userData.profile_pic;
  }
  goBack() {
    $('.modal').modal('hide')
    let previousUrl = this.navHelper.getUrl()
    if (previousUrl) {
      this.router.navigate([previousUrl])
    } else {
      this.router.navigate(['/dashboard'])
    }
  }
  ngOnInit(): void {
  }
  submit() {

    if (this.changePasswordForm.valid) {
      this.changeStatus = 'loading';
      this.AuthService.add('user/changepassword', this.changePasswordForm.value).subscribe(
        (result) => {
          this.changeStatus = 'none';
          $('#success_modal').modal('show');
        },
        (error) => {
          this.errMessage = error.error.meta.message;
          this.changeStatus = 'error';
        })
    } else {
      this.formError = true;
    }
  }
}
