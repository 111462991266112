import { Component, Input, OnInit, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
declare var $: any;
@Component({
  selector: 'app-add-team',
  templateUrl: './add-team.component.html',
  styleUrls: ['./add-team.component.css']
})
export class AddTeamComponent implements OnInit, OnChanges {
  @Input() partner_owner_email: string;
  @Input() onlySuperUsers: boolean;
  @Input() domainList: any[];
  @Input() teamList: any[];
  @Input() selfPartners: any[];
  @Input() meData: any;
  @Input() existingTeam?: any[];
  @Input() validateDomain?: boolean = false;
  @Output() submitForm = new EventEmitter<any>();
  @Output() domainMismatch = new EventEmitter<any>();
  @Output() partnerMatch = new EventEmitter<any>();
  @Output() closeModal = new EventEmitter<any>();

  teamForm: FormGroup
  team: FormArray
  partnerEmail: string;
  completedFirstEmit: boolean = false;
  constructor(private fb: FormBuilder) {
    this.partnerEmail = '';

  }
  invalidDomains: any[];
  preValues = [{ email: 'abhi@logicshore.com', user_role: '1' }];
  ngOnInit(): void {
    this.teamForm = this.fb.group({
      team: this.fb.array([this.teamMember()])
    })
    // if (this.existingTeam) {
    //   this.insertTeam();
    // } else {
    //   this.addTeamMember()
    // }
    this.checkEmail()
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.completedFirstEmit) {
      // if (!changes.existingTeam.firstChange) {
      //   if (changes.existingTeam.previousValue.length < changes.existingTeam.currentValue.length) {
      //     this.teamArray.setValue(changes.existingTeam.currentValue)
      //   }
      // }
      this.teamArray.controls.forEach(control => {
        control.updateValueAndValidity();
      })
    }
  }
  get teamArray() {
    return <FormArray>this.teamForm.get('team');
  }
  // insertTeam() {
  //   this.existingTeam.forEach((member => {
  //     let control = this.teamMember();
  //     control.patchValue({ email: member });
  //     this.team = this.teamForm.get('team') as FormArray
  //     this.team.push(control);
  //   }))
  // }
  checkEmail() {
    this.teamArray.controls.forEach((control) => {
      this.invalidDomains = [];
      control.valueChanges.subscribe((val) => {
        let partner: string = this.partner_owner_email.trim().toLowerCase();
        let partnerDomain: string = partner.split('@')[1]
        let inputValue: string = control.get('email').value.trim().toLowerCase();
        let inputDomain: string = inputValue.split('@')[1];
        console.log(inputValue);
        if (inputValue != '') {
          this.checkDomain(control, inputDomain, partnerDomain);
          this.checkIfPartner(control, inputValue, partner);
          this.checkAccess(control, inputDomain, partnerDomain);
        }
        this.invalidDomains = this.teamArray.controls.filter(control => control.get('email').errors && control.get('email').errors.validDomain)
        if (this.invalidDomains.length > 0) {
          this.domainMismatch.emit(true)
        } else {
          this.domainMismatch.emit(false)
        }
      })
    })
  }
  checkIfPartner(control: AbstractControl, inputValue: string, partner) {
    if (control.get('email').errors && !control.get('email').errors.isPartner) {
      return;
    }
    if (inputValue == partner) {
      this.partnerMatch.emit(true);
      control.get('email').setErrors({ isPartner: true })
    } else {
      this.partnerMatch.emit(false);
      control.get('email').setErrors(null)
    }
  }
  checkDomain(control: AbstractControl, inputDomain: string, partnerDomain: string) {
    if (control.get('email').errors && !control.get('email').errors.validDomain) {
      return;
    }
    console.log(this.domainList)
    if (this.domainList) {
      if (this.domainList.length > 0) {
        if (this.domainList.includes(inputDomain) || !this.validateDomain) {
          control.get('email').setErrors(null)
        } else {
          control.get('email').setErrors({ validDomain: true })
          this.invalidDomains.push(inputDomain)
        }
        return;
      }
    }
    if (inputDomain != partnerDomain && this.validateDomain) {
      control.get('email').setErrors({ validDomain: true })
    } else {
      control.get('email').setErrors(null)
      this.invalidDomains.push(inputDomain)
    }
  }
  checkAccess(control: AbstractControl, inputDomain: string, partnerDomain: string) {
    if (control.get('email').errors && !control.get('email').errors.notAllowed) {
      return;
    }
    let subsData: any = ''
    if (Array.isArray(this.meData)) {
      let data1 = this.meData.find(a => a.subscription_type_data);
      let data2 = this.meData.find(a => a.subscriber_subscription_type_data);
      subsData = (data1 && data1 != null) ? data1.subscription_type_data : data2.subscriber_subscription_type_data
    } else {
      if (this.meData) {
        let data1 = this.meData.subscription_type_data
        let data2 = this.meData.subscriber_subscription_type_data;
        subsData = (data1 && data1 != null) ? data1 : data2
      }
    }
    if (subsData) {
      let totalAllowed = subsData ? (subsData.no_user != null ? Number(subsData.no_user) : -1) : 0;
      if (totalAllowed && totalAllowed != -1) {
        let totalUser = this.teamList.filter(a => {
          let status = true;
          let domain = a.email.split('@')[1].toLowerCase()
          if (domain != inputDomain.toLowerCase()) {
            status = false;
          }
          return status
        }).length
        if (totalAllowed <= totalUser) {
          control.get('email').setErrors({ notAllowed: true })
        } else {
          control.get('email').setErrors(null);
        }
      }
    }
  }
  isValidAll() {
    let err = false;
    this.teamArray.controls.map(a => {
      if (a.get('email').errors) {
        if (a.get('email').errors.required) {
          err = true;
        }
        if (a.get('email').errors.pattern) {
          err = true;
        }
        if (a.get('email').errors.unique) {
          err = true;
        }
        if (a.get('email').errors.isPartner) {
          err = true;
        }
        if (a.get('email').errors.validDomain) {
          err = true;
        }
        if (a.get('email').errors.notAllowed) {
          err = true;
        }
      }
    })
    return !err
  }
  teamMember() {
    return this.fb.group({
      email: ['', Validators.compose([RxwebValidators.unique(), Validators.pattern(/\S+@\S+\.\S+/)])],
      user_role: ['3']
    })
  }
  addTeamMember() {
    this.team = this.teamForm.get('team') as FormArray
    this.team.push(this.teamMember());
    setTimeout(() => {
      $("#teamList").scrollTop($("#teamList")[0].scrollHeight);
    }, 50);
    this.team.updateValueAndValidity()
    this.checkEmail()
  }
  removeTeamMember(index) {
    this.team = this.teamForm.get('team') as FormArray
    this.team.removeAt(index)
    if (this.team.length == 0) {
      this.addTeamMember();
    }
    this.teamArray.controls.forEach(control => {
      control.updateValueAndValidity();
    })
  }
  getFinal() {
    return this.teamArray.value.filter(element => element.email != '');
  }
  submit() {
    if (this.isValidAll()) {
      this.completedFirstEmit = true;
      this.submitForm.emit(this.getFinal());
      this.domainMismatch.emit(false);
    }
  }
  closeModalEmit() {
    this.closeModal.emit();
  }
  //******* Domain check has two conditions to satisfy ********//
  // 1. domain should match with current user domain
  // 2. domain should match with connection partner domains
  //*********** Duplicate email check  ***********//
  //if found duplicate mark current email id as duplicate
  //*********** Invite TeamMember modal flow  ***********//
  //to add team member
  //check if last entered email is valid
  //to remove team member
  //validate emails to check if user removed duplicates
  //if user removed duplicates, then to remove error this function is called
  //************* Validating all TeamMembers ************/
  // this function is called when we need to close team members modal.
  // checks for duplicates, 
  //if one member is added and  last teamMember entry is null
  // removes last add member field
  //validate all emails entered and mark duplicates
  //if any errors found don't close modal, filtering errors
  // close modal if
  // 1.no errors
  // 2.no connection is selected
  // 3. close == true, 
}
