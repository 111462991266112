<div style="padding: 0 60px;">
  <div class="fixed">
    <div class="row mrgbtmn" style="margin-top: 40px;">
      <div class="col-md-8 col-12">
        <p style="color: #606060; opacity: 0.87; font-size: 18px;">
          SALES PIPELINE
        </p>
      </div>
      <div class="col-md-4 col-12">
        <div class="tbl_in_icon">
          <!-- <a href="#" data-toggle="modal" data-target="#myModaladd">
          <i class="fa fa-plus-circle fc-blue" aria-hidden="true"></i>
        </a> -->
          <i class="fa fa-filter fc-blue" aria-hidden="true"></i>
          <!-- data-toggle="modal" data-target="#filterModal" -->
          <i class="fa fa-cog" aria-hidden="true"></i>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="opportunity_stages.length">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="arrow-steps clearfix" style="overflow: hidden; padding: 0 20px;">
          <div class="step" [id]="st.id" *ngFor="let st of opportunity_stages; let i = index">
            <span> {{ st.name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mrgbtmn" style="margin-top: 20px; padding: 0 20px;overflow-y: auto;
  height: calc(100vh - 300px);" [ngStyle]="{ 'pointer-events': isProgress ? 'none' : 'auto' }"
    *ngIf="opportunity_stages.length">
    <div class="mrgdiv center" [id]="st.id" (drop)="dropped($event)"
      *ngFor="let st of opportunity_stages; let i = index">
      <div class="overviewCard" [id]="st.id + '~' + op.id" *ngFor="let op of st.opportunties; let j = index">
        <a [id]="'anc'+st.id + '~' + op.id">
          <!-- (click)="editOrder(op)" -->
          <p class="card-title-custom" [id]="'pt'+st.id + '~' + op.id">
            {{ op.customer_name }}
          </p>
          <p class="card-text-custom" [id]="'pt1'+st.id + '~' + op.id">{{ op.name }}</p>
          <p class="card-text-custom" [id]="'pt2'+st.id + '~' + op.id">GBP {{ op.fixed_revenue | number }} </p>
          <p class="card-text-custom" [id]="'pt2'+st.id + '~' + op.id">{{ op.created_at | date:'MMM dd, yyyy' }}</p>
        </a>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="!opportunity_stages.length" style="height: auto">
  <div class="col-12" style="height: auto;">
    <div style="height: auto;" class="d-flex justify-content-center align-items-center">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</div>
<div class="modal" id="myModaladd" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal body -->
      <div class="modal-body">
        <div class="row mrgbtmn">
          <div class="col-xs-12 col-md-12" style="text-align: center;">
            <h3 class="modal-title">Share Prospects</h3>
          </div>
        </div>
        <div class="row mrgbtmn">
          <div class="col-xs-12 col-md-12">
            <a href="javascript:;" (click)="gotoCreate()" class="data_field_a fc-blue">Add
              Order</a>
          </div>
        </div>
        <!-- <div class="row mrgbtmn">
          <div class="col-xs-12 col-md-12">
            <a class="data_field_a" style="color: grey !important;">Import Order from CRM</a>
          </div>
        </div> -->
        <!-- <div class="row mrgbtmn">
          <div class="col-xs-12 col-md-12">
            <a href="javascript:;" class="data_field_a fc-blue" (click)="uploadFileModal()">Import Order from CSV
              file</a>
          </div>
        </div> -->
      </div>

      <!-- Modal footer -->
      <div class="modal-footer" style="text-align: center; display: inline;">
        <button _ngcontent-odb-c155="" type="button" class="btn cnclbtn1" data-dismiss="modal">
          CANCEL
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Upload opportunity -->
<div class="modal" id="uploadModal" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal body -->
      <div class="modal-body">
        <form #form>
          <div class="row mrgbtmn">
            <div class="col-xs-12 col-md-12" style="text-align: center;">
              <h3 class="modal-title">Import Opportunities</h3>
            </div>
          </div>
          <div class="col-12 mrgbtmn">
            <div class="form-row mb-4">
              <label for="inputPassword" class="col-6 col-form-label">Upload File:</label>
              <div class="col-6 text-center">
                <input type="file" #myInput (change)="handleFileSelect($event)"
                  style="color: #929292; background:none; border: none;" class="" [(ngModel)]="selectedOrder.name" />
                <div class="mrgbtm text-left" *ngIf="errors.file">
                  <div class="errorMessage" *ngIf="errors.file">
                    {{ errors.file }}
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row mb-4">
              <label for="inputPassword" class="col-6 col-form-label">Template (CSV file):</label>
              <div class="col-6 text-center">
                <a href="assets/opportunity.csv" target="__blank"><i style="font-size: 34px;"
                    class="fa fa-file"></i></a>
              </div>
            </div>
            <div class="form-row mb-4" style="color: #929292;">
              <p>You can upload opportunities in Bulk using our CSV template Requirements:</p>
              <ol>
                <li>Attached CSV format only</li>
                <li>Please check the file for validation</li>
              </ol>
            </div>
          </div>
        </form>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer" style="text-align: center; display: inline;">
        <button _ngcontent-odb-c155="" (click)="resetForm()" type="button" class="btn cnclbtn1" data-dismiss="modal">
          CANCEL
        </button>
        <button type="button" (click)="confirmUpload()" class="btn addbtn">
          CONFIRM
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Upload opportunity preview -->
<div class="modal" id="uploadModalPreview" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content">
      <!-- Modal body -->
      <div class="modal-body">
        <div class="row mrgbtmn">
          <div class="col-xs-12 col-md-12">
            <p class="modal-title">{{rows.length-errorCount}} Opportunities are error-free and ready to upload. </p>
            <p class="modal-title" style="color:red">Please correct missing / erroneous fields in the following
              opportunities: </p>
          </div>
        </div>
        <div class="mrgbtmn">
          <p class="modal-title">{{errorCount}} Opportunities has error are listed below. </p>
          <div class="table-responsive" style="height: 415px;">
            <table class="mat-table cdk-table">
              <thead class="rowgroup">
                <tr class="mat-header-row cdk-header-row ">
                  <th class="mat-header-cell cdk-header-cell cdk-column-id mat-column-id" style="width: 130px;">Customer
                    Name</th>
                  <th class="mat-header-cell cdk-header-cell cdk-column-id mat-column-id" style="width: 130px;">Owner
                  </th>
                  <th class="mat-header-cell cdk-header-cell cdk-column-id mat-column-id">Order Name</th>
                  <th class="mat-header-cell cdk-header-cell cdk-column-id mat-column-id" style="width: 130px;">Stage
                  </th>
                  <th class="mat-header-cell cdk-header-cell cdk-column-id mat-column-id" style="width: 130px;">Deal
                    Status</th>
                  <th class="mat-header-cell cdk-header-cell cdk-column-id mat-column-id">Fixed Revenues</th>
                  <th class="mat-header-cell cdk-header-cell cdk-column-id mat-column-id">Probability</th>
                  <th class="mat-header-cell cdk-header-cell cdk-column-id mat-column-id">Estimated Close Date</th>
                  <th class="mat-header-cell cdk-header-cell cdk-column-id mat-column-id" style="width: 130px;">Partners
                  </th>
                  <th class="mat-header-cell cdk-header-cell cdk-column-id mat-column-id" style="width: 130px;">Partner
                    Owners</th>
                  <th class="mat-header-cell cdk-header-cell cdk-column-id mat-column-id" style="width: 130px;">Team
                    Members</th>
                  <th class="mat-header-cell cdk-header-cell cdk-column-id mat-column-id">Recurring Revenues Value</th>
                  <th class="mat-header-cell cdk-header-cell cdk-column-id mat-column-id">Recurring Revenues per</th>
                  <th class="mat-header-cell cdk-header-cell cdk-column-id mat-column-id">Term Number</th>
                  <th class="mat-header-cell cdk-header-cell cdk-column-id mat-column-id">Term in</th>
                  <th class="mat-header-cell cdk-header-cell cdk-column-id mat-column-id" style="width: 130px;">Product
                    Category</th>
                  <th class="mat-header-cell cdk-header-cell cdk-column-id mat-column-id" style="width: 130px;">Product
                  </th>
                  <th class="mat-header-cell cdk-header-cell cdk-column-id mat-column-id">Quote Date</th>
                  <th class="mat-header-cell cdk-header-cell cdk-column-id mat-column-id">Quote Value</th>
                  <th class="mat-header-cell cdk-header-cell cdk-column-id mat-column-id">Include in Upload(Y/N)</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let r of rows;let i=index;" [ngClass]="!r.error && r.is_upload?'d-none':''">
                  <td class="mat-cell cdk-cell cdk-column-id mat-column-id text-center">
                    <input matInput type="text" [ngClass]="r.errors.customer_name?'error-border':''"
                      class="fc-blue border-none" placeholder="Enter Customer Name" name="customer_name"
                      [(ngModel)]="r.customer_name" (ngModelChange)="validateOpportunities()" />
                  </td>
                  <td class="mat-cell cdk-cell cdk-column-id mat-column-id">
                    <ng-select class="border-none owner" [ngClass]="r.errors.owner?'error-border':''"
                      style="width: 130px;" name="owner" [(ngModel)]="r.owner" [items]="owners" bindLabel="name"
                      bindValue="name" placeholder="Select Owner" (ngModelChange)="validateOpportunities()"></ng-select>
                  </td>
                  <td class="mat-cell cdk-cell cdk-column-id mat-column-id">
                    <input matInput type="text" [ngClass]="r.errors.name?'error-border':''" class="fc-blue border-none"
                      placeholder="Enter Order Name" name="name" [(ngModel)]="r.name"
                      (keyup)="validateOpportunities()" />
                  </td>
                  <td class="mat-cell cdk-cell cdk-column-id mat-column-id">
                    <ng-select class="border-none stage" [ngClass]="r.errors.stage?'error-border':''"
                      style="width: 130px;" placeholder="Select Stage" name="stage" [(ngModel)]="r.stage"
                      [items]="stages" bindLabel="label" bindValue="label" (ngModelChange)="validateOpportunities()">
                    </ng-select>
                  </td>
                  <td class="mat-cell cdk-cell cdk-column-id mat-column-id">
                    <ng-select class="border-none stage" [ngClass]="r.errors.deal_status?'error-border':''"
                      style="width: 130px;" placeholder="Select deal status" name="deal_status"
                      [(ngModel)]="r.deal_status" [items]="dealStatuses" bindLabel="label" bindValue="label"
                      (ngModelChange)="validateOpportunities()">
                    </ng-select>
                  </td>
                  <td class="mat-cell cdk-cell cdk-column-id mat-column-id">
                    <div class="d-flex">
                      <!-- <h4 class="fz-16 fc-blue" style="margin: auto;">
                        $
                      </h4> -->
                      <input matInput type="text" [ngClass]="r.errors.fixed_revenue?'error-border':''"
                        class="fc-blue border-none" style="margin: 15px 0;" placeholder="Enter Fixed Revenues"
                        name="fixed_revenue" [(ngModel)]="r.fixed_revenue" (keyup)="validateOpportunities()" />
                    </div>
                  </td>
                  <td class="mat-cell cdk-cell cdk-column-id mat-column-id fc-blue">
                    <input matInput type="text" [ngClass]="r.errors.probability?'error-border':''" style="width: 70%;"
                      class="fc-blue border-none" [id]="'probability-'+i" placeholder="Probability" name="probability"
                      [(ngModel)]="r.probability" (keyup)="validateOpportunities()" />%
                  </td>
                  <td class="mat-cell cdk-cell cdk-column-id mat-column-id"
                    [ngClass]="r.errors.close_date?'error-border-div':''">
                    <input type="text" class="datepicker" [ngClass]="r.errors.close_date?'error-border':''"
                      style="width: 130px;" [ngClass]="'date-'+i" [(ngModel)]="r.close_date" name="close_date"
                      placeholder="MM/DD/YYYY" autocomplete="off" (keyup)="validateOpportunities()" />
                  </td>
                  <td class="mat-cell cdk-cell cdk-column-id mat-column-id">
                    <ng-select class="border-none owner" [ngClass]="r.errors.partners?'error-border':''"
                      style="width: 130px;" [multiple]="true" name="partners" [(ngModel)]="r.partners"
                      (ngModelChange)="onPartnerSelect($event,i)" [items]="partnerOptions" bindLabel="name"
                      bindValue="id" placeholder="Select Partner"></ng-select>
                  </td>
                  <td class="mat-cell cdk-cell cdk-column-id mat-column-id">
                    <ng-select class="border-none owner" [ngClass]="r.errors.partner_owner?'error-border':''"
                      style="width: 150px;" [multiple]="true" name="partner_owner" [(ngModel)]="r.partner_owner"
                      (ngModelChange)="onPartnerSelect($event,i)" [items]="r.party_owners" bindLabel="name"
                      bindValue="id" placeholder="Select Partner Owner">
                    </ng-select>
                  </td>

                  <td class="mat-cell cdk-cell cdk-column-id mat-column-id">
                    <ng-select class="border-none owner w-mc" [ngClass]="r.errors.team?'error-border':''"
                      style="width: 150px;" [multiple]="true" name="teams" [(ngModel)]="r.team"
                      [items]="r.partnerTeamOptions" bindLabel="name" bindValue="id" placeholder="Select"
                      (ngModelChange)="validateOpportunities()">
                    </ng-select>
                  </td>
                  <td class="mat-cell cdk-cell cdk-column-id mat-column-id">
                    <div class="d-flex">
                      <!-- <h4 class="fz-16 fc-blue" style="margin: auto;">
                        $
                      </h4> -->
                      <input matInput type="text" [ngClass]="r.errors.recurring_revenue?'error-border':''"
                        class="fc-blue border-none" style="margin: 15px 0;" placeholder="Enter Recurring Revenues"
                        name="recurring_revenue" [(ngModel)]="r.recurring_revenue" (keyup)="validateOpportunities()" />
                    </div>
                  </td>
                  <td class="mat-cell cdk-cell cdk-column-id mat-column-id">
                    <ng-select class="border-none stage w-mc" [ngClass]="r.errors.recurring_frequency?'error-border':''"
                      style="margin: 15px 0;min-width: 150px;" name="recurring_frequency"
                      [(ngModel)]="r.recurring_frequency" [items]="customTerms" bindLabel="name" bindValue="id"
                      placeholder="Select Term" (ngModelChange)="validateOpportunities()"></ng-select>
                  </td>
                  <td class="mat-cell cdk-cell cdk-column-id mat-column-id">
                    <input matInput type="text" [ngClass]="r.errors.terms?'error-border':''" class="fc-blue border-none"
                      placeholder="Enter terms" name="terms" [(ngModel)]="r.terms" (keyup)="validateOpportunities()" />
                  </td>
                  <td class="mat-cell cdk-cell cdk-column-id mat-column-id">
                    <ng-select class="border-none stage w-mc" [ngClass]="r.errors.term_frequency?'error-border':''"
                      style="margin: 15px 0;min-width: 150px;" name="term_frequency" [(ngModel)]="r.term_frequency"
                      [items]="customTerms" bindLabel="name" bindValue="id" placeholder="Select Term"
                      (ngModelChange)="validateOpportunities()"></ng-select>
                  </td>
                  <td class="mat-cell cdk-cell cdk-column-id mat-column-id">
                    <ng-select [multiple]="true" [ngClass]="r.errors.product_category?'error-border':''"
                      class="border-none product_category" name="product_category" [(ngModel)]="r.product_category"
                      style="width: 150px;" (change)="getFilterProducts(r.product_category,i)"
                      [items]="r.productCategories" bindLabel="category_name" bindValue="category_name"
                      placeholder="Select Category"></ng-select>
                  </td>
                  <td class="mat-cell cdk-cell cdk-column-id mat-column-id">
                    <ng-select [multiple]="true" [ngClass]="r.errors.product?'error-border':''" style="width: 150px;"
                      class="border-none product" name="product" [(ngModel)]="r.product" [items]="r.products"
                      bindLabel="product_name" bindValue="product_name" placeholder="Select Product"
                      (ngModelChange)="validateOpportunities()"></ng-select>
                  </td>
                  <td class="mat-cell cdk-cell cdk-column-id mat-column-id">
                    <input type="text" class="datepicker1" [ngClass]="r.errors.quote_date?'error-border':''"
                      style="width: 130px;" [ngClass]="'date2-'+i" [(ngModel)]="r.quote_date" name="quote_date"
                      placeholder="MM/DD/YYYY" autocomplete="off" (keyup)="validateOpportunities()" />
                  </td>
                  <td class="mat-cell cdk-cell cdk-column-id mat-column-id">
                    <div class="d-flex">
                      <!-- <h4 class="fz-16 fc-blue" style="margin: auto;">
                        $
                      </h4> -->
                      <input matInput type="text" [ngClass]="r.errors.quote_value?'error-border':''"
                        class="fc-blue border-none" style="margin: 15px 0;" placeholder="Enter Quote Value"
                        name="quote_value" [(ngModel)]="r.quote_value" (keyup)="validateOpportunities()" />
                    </div>
                  </td>
                  <td class="mat-cell cdk-cell cdk-column-id mat-column-id">
                    <input type="checkbox" [(ngModel)]="r.is_upload" name="is_upload" />
                  </td>
                </tr>
              </tbody>
              <tfoot></tfoot>
            </table>
          </div>

        </div>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer" style="text-align: center; display: inline;">
        <div class="row mrgbtm" *ngIf="noData">
          <div class="col-md-12">
            <div align="center" class="errorMessage">
              No data selected to upload.
            </div>
          </div>
        </div>
        <button _ngcontent-odb-c155="" type="button" class="btn cnclbtn1" data-dismiss="modal">
          CANCEL
        </button>
        <button type="button" (click)="uploadData()" class="btn addbtn">
          CONFIRM
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="successModal" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content modal-custom">
      <!-- Modal body -->
      <div *ngIf="displayResult == 'success'" class="modal-body" style="text-align: center; display: inline;">
        <div class="row mrgbtm">
          <div class="col-md-12">
            <h5 *ngIf="!opportunityId" class="modal-title mrgbtm">Order Created!</h5>
            <h5 *ngIf="opportunityId" class="modal-title mrgbtm">Order Updated!</h5>
          </div>
        </div>
      </div>
      <div *ngIf="displayResult == 'failure'" class="modal-body" style="text-align: center; display: inline;">
        <div>
          <div class="row mrgbtm">
            <div class="col-md-12">
              <div align="center" class="errorMessage">
                {{ errorMessage }}
              </div>
            </div>
          </div>
          <div class="row mrgbtm">
            <div class="col-md-12">
              <div align="center">
                <button (click)="closeModal()" type="button" class="btn addbtn">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="displayResult == ''" class="modal-body" style="text-align: center; display: inline;">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <p>
          Updating the Order...
        </p>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="closeStageModal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal Header -->

      <!-- Modal body -->
      <div class="modal-body">
        <h4 class="modal-title mrgbtmn ml-4">
          {{ selectedOrder.customer_name }}
          <a href="javascript:;" class="float-right" (click)="editOrder(selectedOrder)"><i class="fa fa-edit"
              style="font-size: 25px;"></i></a>
        </h4>
        <div style="padding: 0 20px;">
          <div class="mrgbtm row mb-4">
            <label for="inputPassword" class="col-12 col-form-label">Name:</label>
            <div class="col-12">
              <input type="text" class="form-control" [(ngModel)]="selectedOrder.name" />
            </div>
          </div>
          <div class="mrgbtm row mb-4">
            <label for="inputPassword" class="col-4 col-form-label">Deal Status:</label>
            <div class="col-4" style="width: 10rem; padding: 0;">
              <div class="dropdown-menu show" style="position: initial; border: none; float: none;">
                <a class="dropdown-item" style="font-size: 14px; padding: 5px 10px;" [ngClass]="
                    (selectedOrder.deal_status_id == d.id ? 'active' : '')
                  " [class.d-none]="d.displayNone" *ngFor="let d of deal_statuses" href="javascript:;"
                  (click)="setDealStatus(d)">{{ d.label }}</a>
              </div>
              <div class="mrgbtm text-left" *ngIf="errors_deal_status">
                <div class="errorMessage" *ngIf="errors_deal_status">
                  {{ errors_deal_status }}
                </div>
              </div>
              <!-- <select class="form-control" [(ngModel)]="selectedOrder.deal_status" id="exampleFormControlSelect4" style=" border:none !important">
                <option>Commit</option>
                <option>won</option>
                <option>lost </option>
                <option>upside</option>
              </select> -->
            </div>
            <hr class="col-12" />
          </div>
          <div class="mrgbtm row mb-4">
            <label for="inputPassword" class="col-4 col-form-label">Stage:</label>
            <div class="col-4" style="width: 10rem; padding: 0;">
              <input type="text" value="Closed" disabled class="form-control" style="
                  background-color: #44a2f8;
                  color: white;
                  border: none !important;
                " />
            </div>
            <hr class="col-12" />
          </div>
          <div class="mrgbtm row mb-4">
            <label for="inputPassword" class="col-sm-4 col-form-label">Value:</label>
            <div class="col-6">
              <span class="col-form-label">
                {{ selectedOrder.value }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal footer -->
      <div class="mb-4" style="text-align: center; display: inline;">
        <button href="#" (click)="getOpportunities()" type="button" class="btn cnclbtn1 mr-4"
          data-dismiss="modal">CANCEL</button>

        <button type="button" (click)="updateOrder()" class="btn addbtn">
          SAVE CHANGES?
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="evaluateStageModal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal Header -->

      <!-- Modal body -->
      <div class="modal-body">
        <h4 class="modal-title mrgbtmn ml-4">
          {{ selectedOrder.customer_name }}

          <a href="javascript:;" class="float-right" (click)="editOrder(selectedOrder)"><i class="fa fa-edit"
              style="font-size: 25px;"></i></a>
        </h4>
        <div style="padding: 0 20px;">
          <div class="mrgbtm row mb-4">
            <label for="inputPassword" class="col-12 col-form-label">Name:</label>
            <div class="col-12" style="padding-right: 0;">
              <input type="text" readonly class="form-control" [(ngModel)]="selectedOrder.name" />
            </div>
          </div>
          <div class="mrgbtm row mb-4">
            <label for="inputPassword" class="col-4 col-form-label">Deal Status:</label>
            <div class="col-4" style="width: 10rem; padding: 0;">
              <div class="dropdown-menu show" style="position: initial; border: none; float: none;">
                <a class="dropdown-item" style="font-size: 14px; padding: 5px 10px;" [ngClass]="
                    selectedOrder.deal_status == d ? 'active' : ''
                  " *ngFor="let d of deal_statuses" href="javascript:;"
                  (click)="selectedOrder.deal_status = d">{{ d }}</a>
              </div>
              <!-- <select class="form-control" [(ngModel)]="selectedOrder.deal_status" id="exampleFormControlSelect4" style=" border:none !important">
                <option>Commit</option>
                <option>won</option>
                <option>lost </option>
                <option>upside</option>
              </select> -->
            </div>
            <hr class="col-12" />
          </div>
          <div class="mrgbtm row mb-4">
            <label for="inputPassword" class="col-4 col-form-label">Stage:</label>
            <div class="col-4" style="width: 10rem; padding: 0;">
              <input type="text" value="Evaluation" disabled class="form-control" style="
                  background-color: #44a2f8;
                  color: white;
                  border: none !important;
                " />
            </div>
            <hr class="col-12" />
          </div>
          <div class="mrgbtm row mb-4">
            <label for="inputPassword" class="col-sm-4 col-form-label">Value:</label>
            <div class="col-6">
              <span class="col-form-label">
                {{ selectedOrder.value }}</span>
            </div>
          </div>
          <div class="mrgbtm row mb-4">
            <label for="inputPassword" class="col-4 col-form-label">Latest Quote:</label>
            <div class="col-4" style="width: 10rem; padding: 0;">
              <!-- <input type="text" value="Closed" disabled class="form-control"
                style="background-color: #44a2f8; color: white; border:none !important" /> -->
            </div>
            <hr class="col-12" />
          </div>
          <div class="mrgbtm row mb-4">
            <label for="inputPassword" class="col-4 col-form-label">
              Quote Dt:</label>
            <div class="col-4" style="width: 10rem; padding: 0;">
              <input type="text" id="date2" style="border: 1px solid #44a2f8; padding-left: 5px;"
                placeholder="Quote Date" autocomplete="off" />
            </div>
            <hr class="col-12" />
          </div>
          <div class="mrgbtm row mb-4">
            <label for="inputPassword" class="col-4 col-form-label">Quote Value:</label>
            <div class="col-4" style="width: 10rem; padding: 0;">
              <div class="d-flex">
                <!-- <h4 class="fz-16 fc-blue" style="
                    font-size: 20px !important;
                    align-self: flex-end;
                    margin-bottom: 20px;
                  ">
                  $
                </h4> -->
                <input type="text" class="fz-16 fc-blue border-none form-control" style="margin: 15px 0;"
                  placeholder="Quote Value" name="selectedOrder" [(ngModel)]="selectedOrder.quote_value" />
              </div>
            </div>
            <hr class="col-12" />
          </div>
        </div>
      </div>
      <!-- Modal footer -->
      <div class="mb-4" style="text-align: center; display: inline;">
        <a href="#" (click)="getOpportunities()" type="button" class="btn cnclbtn mr-4" data-dismiss="modal">CANCEL</a>

        <button type="button" (click)="updateOrder()" class="btn addbtn">
          SAVE CHANGES?
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal" id="filterModal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal body -->
      <div class="modal-body">
        <form action="">
          <div class="form-group">
            <label for="recipient-name" class="col-form-label">Filter By:</label>
            <a href="javascript:;" class="float-right" (click)="resetFilter()">Reset</a>
          </div>
          <div class="form-row mb-2">
            <label for="recipient-name" class="col-4">Order Name:</label>
            <ng-select [multiple]="true" class="col-8" [(ngModel)]="filterData.plan" name="plan"
              [items]="filterOption.items" bindLabel="name" bindValue="id" placeholder="Select"
              style="border: none !important;"></ng-select>
          </div>
          <div class="form-row mb-2">
            <label for="recipient-name" class="col-4">Partner Name:</label>
            <ng-select [multiple]="true" class="col-8" [(ngModel)]="filterData.partner" name="partner"
              [items]="filterOption.partners" bindLabel="name" bindValue="id" placeholder="Select"
              style="border: none !important;"></ng-select>
          </div>
          <div class="form-row mb-2">
            <label for="recipient-name" class="col-4">Created By:</label>
            <ng-select [multiple]="true" class="col-8" [(ngModel)]="filterData.createdBy" name="createdBy"
              [items]="filterOption.createdBy" bindLabel="name" bindValue="id" placeholder="Select"
              style="border: none !important;"></ng-select>

          </div>
          <div class="form-row mb-2">
            <label for="recipient-name" class="">Opportunities closing between:</label>
            <div class="row col-12">
              <div class="col-5">
                <input type="text" matInput class="form-control" id="from_date"
                  style="background:#e5e5e5;padding: 8px 12px;">
              </div>
              <div class="col-2" style="padding: 0;text-align: center;"><span
                  style="color:#929292; font-size:14px;">and</span></div>
              <div class="col-5" style="padding-right: 0;">
                <input type="text" matInput class="form-control" id="to_date"
                  style="background:#e5e5e5;padding: 8px 12px;">
              </div>
            </div>
          </div>
          <div class="form-row mb-2">
            <label for="recipient-name" class="col-4">Team Members:</label>
            <ng-select [multiple]="true" class="col-8" [(ngModel)]="filterData.team" name="team"
              [items]="filterOption.users" bindLabel="name" bindValue="id" placeholder="Select"
              style="border: none !important;"></ng-select>
            <!-- <ss-multiselect-dropdown class="col-8" [(ngModel)]="filterData.team" name="team"
              [options]="selectedTable=='plans'?users.plan:selectedTable=='solutions'?users.solution:users.opportunity"
              [settings]="multiSelectionSettings" style="border: none !important;">
            </ss-multiselect-dropdown> -->
          </div>
        </form>
      </div>
      <!-- Modal footer -->
      <div class="modal-footer" style="text-align: center; display: inline;">
        <button type="button" data-dismiss="modal" class="btn cnclbtn">
          CANCEL
        </button>
        <button type="button" class="btn addbtn" (click)="filter()">
          OK
        </button>
      </div>
    </div>
  </div>
</div>