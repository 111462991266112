<div class="d-flex align-items-stretch">
  <div class="container-fluid">
    <div style="
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      ">


      <div class="col-8" style="margin-top: 80px; ">
        <form>
          <div class="form-group">
            <label for="recipient-name" class="col-form-label col-12">Your chosen trading name (Please enter the
              right
              font):</label>
            <input type="text" class="col-12" name="brand" [(ngModel)]="brandData.brand" placeholder="Brand Name"
              class="form-control" id="recipient-name">
          </div>
          <div class="form-group">
            <label for="recipient-name" class="col-form-label col-12">Your Company Name:</label>
            <input type="text" class="col-12" name="company" [(ngModel)]="brandData.company" placeholder="Company Name"
              class="form-control">
          </div>
          <div class="form-group">
            <label for="recipient-name" class="col-form-label col-12">Term and condition text:</label>
            <input type="text" class="col-12" name="term_condition_text" [(ngModel)]="brandData.term_condition_text"
              placeholder="Term & condition text" class="form-control">
          </div>
          <div class="form-group">
            <label for="message-text" class="col-form-label col-12">Your Company Logo:</label>
            <div class="bdr" style="height: 185px; width: 185px; padding: 5px;">
              <div style="position: relative;">
                <img style="height: 173px; width: 173px;" *ngIf="brandData.brand" [src]="initPath + brandData.logo"
                  id="profile_pic" (click)="openUpload()" alt="profile-pic" />
                <div *ngIf="imageError.error" class="errorMessage">
                  {{ imageError.message }}
                </div>
                <div (click)="openUpload()" class="editOption text-right">
                  <span class="material-icons">
                    create
                  </span>
                </div>
              </div>
              <input style="display: none;" id="profile_pic_upload" (change)="handleFileSelect($event)" type="file" />
            </div>
          </div>
        </form>
        <!-- <div class="row" *ngIf="showError">
          <div class="col-12 errorMessage">
            Please fill proper details to add users.
          </div>
        </div> -->

        <div class="col-12 custom-row text-center mb-4">
          <button class="btn" (click)="updateBrand()" style="
              background-color: #1e98ea;
              color: white;
              padding-top: 8px;
              padding-bottom: 8px;
              padding-left: 50px;
              padding-right: 50px;
            ">
            UPDATE
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal" id="getYouSetup">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <!-- Modal body -->
        <div class="modal-body" style="text-align: left; display: inline;">
          <div class="row mrgbtm">
            <div class="col-md-12 mt-4">
              <h5 style="color: black;">Let's get you set up!</h5>
            </div>
            <div class="col-md-12 mt-3">
              <p style="color: black;">
                1.
                <a href="#" style="text-decoration: underline; color: #1e98ea;">Add users</a>
              </p>
            </div>
            <div class="col-md-12 mt-3 mb-4">
              <p style="color: black;">
                2.
                <a href="#" style="text-decoration: underline; color: #1e98ea;">Customize Flyero to your
                  organization</a>
              </p>
            </div>
          </div>
          <!-- Modal footer -->
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal" id="successModal">
  <div class="modal-dialog modal-centered">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row">
          <div class="col">
            <h5>Successfully added users</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>