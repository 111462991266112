import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import lodash from 'lodash';
import * as moment from "moment";
import { filter, map } from "rxjs/operators";
import { User } from "src/app/models/user.model";
import { AuthService } from "src/app/services/auth.service";
import { CommonEmitterService } from "src/app/services/common-emitter";
import { LocalStorageService } from 'src/app/services/local-storage.service';
declare var $: any;
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"]
})
export class HeaderComponent implements OnInit {
  user: User;
  userName: string;
  adminAccess: boolean;
  title: any = 'Flyero'
  baseUrl = this.AuthService.baseUrl;
  showCompany: boolean = false;
  loading: any = false;
  initPath = this.AuthService.baseUrl

  constructor(
    private AuthService: AuthService,
    private CommonEmitter: CommonEmitterService,
    private LocalStorageService: LocalStorageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.getUsers();
  }

  ngOnInit(): void {
    this.user = new User();
    this.getUserDetails();
    this.getAlerts();
    this.getNotifications();
    this.getDefaultData();
    this.CommonEmitter.updateTitle.subscribe(data => {
      if (data) {
        this.title = data
      }
    })
    this.CommonEmitter.updateLoading.subscribe(data => {
      console.log(data)
      this.loading = data;
    })
    this.CommonEmitter.updateUserData.subscribe(data => {
      this.getUserDetails();
    })
    this.CommonEmitter.updateBranding.subscribe(data => {
      this.getDefaultData();
    })
    this.router
      .events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let route = this.router.url.split('/')[1]
          if (route.split('/')[0] == 'admin') {
            this.showCompany = true
          } else {
            this.showCompany = false
          }
          this.showBanner = false;
          const child = this.activatedRoute.firstChild;
          setTimeout(() => {
            let height = $(document).height();
            console.log(height + ' ---- ' + (height - 142))
            // $('nav#sidebar').css({ height: (height - 100) + 'px' })
          }, 200)
          return child;
        })
      ).subscribe((ttl: any) => {
        this.title = ttl.snapshot.data['title']
        $('#sidebar').addClass('shrinked')
      });
    $(document).click(function (event) {
      var clickover = $(event.target);
      var _opened = $("#company #collapseExample").hasClass("show");
      if (_opened === true && !clickover.hasClass("collapse")) {
        $("#company .custom-btn").click();
      }
    });
  }
  brandData: any = {};
  getDefaultData(partner_id?: string) {
    let apiString = 'custom_data'
    if (partner_id && partner_id != '') {
      apiString = apiString + '?partner_id=' + partner_id
    }
    this.AuthService.get(`${apiString}`, (err) => {
      console.log(err);
    }).subscribe((result: any) => {
      this.brandData = {
        brand: result.data.brand,
        logo: result.data.logo
      }
    }, (err: any) => {
      // this.solutionStages = [];
      console.log(err);
    });
  }
  get isDashboard(): boolean {
    // console.log(this.router.url.split('/'));
    return (this.router.url.split('/')[1] == 'dashboard');
  }
  subscription: any;
  subscription_dates: any;
  getUserDetails() {
    this.AuthService.get("user/me", () => { }).subscribe(
      (result: any) => {
        console.log("header says", result);
        this.user = result.data[0];
        this.adminAccess = this.user.admin_user;
        this.subscription_dates = this.user.subscription_data;
        if (this.user.subscription_type_data) {
          this.subscription = this.user.subscription_type_data;
        } else if (this.user.subscriber_subscription_type_data) {
          this.subscription = this.user.subscriber_subscription_type_data
        } else {
          this.subscription = { name: 'No subscription details' }
        }
        this.CommonEmitter.sendMessage("saveUserData", JSON.stringify(this.user));
        this.CommonEmitter.changeUserData.next();
      },
      err => {
        console.log(err);
      }
    );
  }
  openNotifications() {
    $('#dropdownMenuButton12').dropdown('show');
  }
  showBanner: boolean = false;
  allAlerts: any[] = [];
  alertMessages: any[] = [];
  getAlerts() {
    this.AuthService.get('alerts').subscribe(
      (result) => {
        console.log(result)
        this.allAlerts = result.data;
        if (this.allAlerts && this.allAlerts && this.allAlerts.length) {
          var data = this.allAlerts.filter(alert => alert.alert_type.toLowerCase() != 'activity'
            // && !alert.oldData && alert.oldData == null // code to filter newly created objects for banner
            && alert.is_new)
          if (data && data.length) {
            this.showBanner = true;
          }
        }
        this.resetNew();
        setTimeout(() => {
          this.getAlerts();
        }, 120000);
      },
      (error) => { console.log(error) }
    )
  }
  notifications: any[] = [];
  getNotifications() {
    this.AuthService.get('notifications').subscribe(
      (result) => {
        console.log(result)
        this.notifications = result.data;
      },
      (error) => { console.log(error) }
    )
  }
  getDetails(id) {
    return {
      req: this.allUsers.find((user) => {
        if (user.user_id == id) {
          return true;
        }
      })
    }
  }
  allUsers: any[] = [];
  getUsers() {
    this.AuthService.get('admin/users').subscribe(
      (result) => {
        console.log(result);
        this.allUsers = result.data
      },
      (err) => {
        console.log(err.error);
        this.allUsers = []
      }, () => {
      }
    )
  }
  selectedAlert: { alert_id: string, alert_type: string, message: string, old_data: any, new_data: any } = { alert_id: '', alert_type: '', message: '', old_data: {}, new_data: {} };
  object_id: string = '';
  differences: any[] = [];
  messageParts: any = [];
  viewItem(id, type) {
    this.AuthService.get('alerts/' + id).subscribe(
      (result) => {
        console.log(result.data);
        this.selectedAlert = result.data[0];
        let alertMessage = this.selectedAlert.message.slice();
        this.object_id = this.selectedAlert.new_data.id;
        let object_name = ''
        let object_length = 0
        let object_index = 0
        let final_part_index = 0
        if (this.selectedAlert.alert_type == 'Plan') {
          object_name = this.selectedAlert.new_data.plan_name;
        } else if (this.selectedAlert.alert_type == 'Solution') {
          object_name = this.selectedAlert.new_data.solution_name;
        } else if (this.selectedAlert.alert_type == 'Opportunity') {
          if (this.selectedAlert.new_data.opportunity_name) {
            object_name = this.selectedAlert.new_data.opportunity_name;
          } else {
            object_name = this.selectedAlert.new_data.name;
          }
        } else if (this.selectedAlert.alert_type == 'Activity') {
          object_name = this.selectedAlert.new_data.activity;
        } else {
          object_name = this.selectedAlert.new_data.name;
        }
        object_length = object_name.length
        object_index = alertMessage.indexOf(object_name);
        final_part_index = object_index + object_length
        this.messageParts = [
          alertMessage.slice(0, object_index),
          alertMessage.slice(object_index, object_index + object_length),
          alertMessage.slice(final_part_index)
        ]
        $('#each_alert').modal('show');
        if (this.selectedAlert.old_data != null && this.selectedAlert.new_data != null) {
          this.diffArr = [];
          this.differences = this.recurseObjectToFindDiff(this.selectedAlert.old_data, this.selectedAlert.new_data)
        }
      },
      (error) => {
        console.log(error);
      })
  }
  closeItem(id) {
    this.readItem(id);
    $('#each_alert').modal('hide');
  }
  readItem(id) {
    this.AuthService.update('alerts/' + id + '/clear', '').subscribe(
      (result) => {
        console.log(result.data);
        this.getAlerts();
      },
      (error) => {
        console.log(error);
      })
  }
  actionAll(action: string, stop?: boolean) {
    this.AuthService.update('alerts/' + action, '').subscribe(
      (result) => {
        console.log(result.data);
        this.getAlerts();
      },
      (error) => {
        console.log(error);
      })
  }
  resetNew() {
    this.AuthService.update('alerts/refresh', '').subscribe(
      (result) => {
      },
      (error) => {
        console.log(error);
      })
  }
  getPartners() {
    let partners = this.LocalStorageService.get({ name: 'isFirstLogin' })
  }
  logout() {
    this.AuthService.get("user/logout", (error) => {
    }).subscribe(
      () => {
        this.CommonEmitter.sendMessage("showLogOutPage", true);
      },
      err => {
        this.CommonEmitter.sendMessage("showLogOutPage", true);
      }
    );
  }
  updatePage() {
    this.showBanner = false;
    this.CommonEmitter.updatePage(true);
  }
  datesList = ['start_date', 'end_date', 'close_date', 'quote_date', 'completion_date'];
  ignoreKeys = ["updatedAt", 'value', 'approval_status', 'approval_id', 'approvers', "updatedBy", 'solution_name', 'plan_name', 'partner', 'partners', "isActive", 'created_data', "createdAt", "createdBy", "created_at", "updated_at", "is_active", "created_by", "updated_by", "users"];
  mapper = {
    "team": "Team member",
    "end_date": "End date",
    "start_date": "Start date",
    "partners": "Partner",
    'partnerDetails': 'Partner',
    'deal_status': 'Deal Status',
    'partner_owner': 'Partner Owner',
    'close_date': 'Close Date',
    'recurring_revenue': 'Recurring Revenue',
    'fixed_revenue': 'Fixed Revenue',
    'quote_value': 'Quote Value',
    'customer_name': 'Customer Name',
    'quote_date': 'Quote Date',
    'completion_date': 'Completion Date',
    'type': 'Activity Type',
    'recurring_frequency': 'Recurring Frequency',
    'plan_id': 'Plan',
    'solution_id': 'Solution',
    'product_category': 'Product Category'
  }
  formAlertsMessage(oldData, newData, moduleName, req, entityName) {
    let action = oldData ? 'updated' : 'created';
    if (!newData) {
      action = 'deleted';
    }
    let message = '';
    let userName = '';
    if (req.firstName && req.lastName) {
      userName = req.firstName + ' ' + req.lastName;
    }
    if (req.first_name) {
      userName += ' ' + req.first_name;
    }
    if (req.last_name) {
      userName += ' ' + req.last_name;
    }
    if (oldData) {
      var data = this.recurseObjectToFindDiff(oldData, newData);
      if (data) {
        //multiple
        message += `${userName} has made multiple changes to ${moduleName}, ${entityName}`;
      } else {
        message += `${userName} ${data} in ${entityName}`;
      }
    } else {
      message += `${userName} has ${action} ${moduleName}, ${entityName}`;
    }
    return message;
  }
  diffArr: any[] = [];
  recurseObjectToFindDiff(obj1, obj2) {
    var diffData = this.getObjectDiff(obj1, obj2);
    diffData = diffData.filter(diff => this.ignoreKeys.indexOf(diff) == -1)
    diffData.forEach(key => {
      let diffKey = key;
      if (typeof obj1[diffKey] == 'string' || typeof obj2[diffKey] == 'string') {
        //we will get the data here
        // ${this.datesList.includes(diffKey) ? moment(obj1[diffKey]).format('MM/DD/YYYY') : obj1[diffKey]} 
        if (typeof obj1[diffKey] != undefined && typeof obj2[diffKey] != undefined) {
          if (obj2[diffKey] == '' && obj1[diffKey] == '') {
            return;
          } else if (obj2[diffKey] == '') {
            this.diffArr.push(` removed ${(typeof this.mapper[diffKey] != 'undefined' ? this.mapper[diffKey] : diffKey)}`)
          } else if (obj1[diffKey] == '') {
            this.diffArr.push(`added ${(typeof this.mapper[diffKey] != 'undefined' ? this.mapper[diffKey] : diffKey)}, 
            ${this.datesList.includes(diffKey) ? moment(obj2[diffKey]).format('MM/DD/YYYY') : obj2[diffKey]}`)
          } else {
            this.diffArr.push(`${(typeof this.mapper[diffKey] != 'undefined' ? this.mapper[diffKey] : diffKey)} to 
              ${this.datesList.includes(diffKey) ? moment(obj2[diffKey]).format('MM/DD/YYYY') : obj2[diffKey]}`);
          }
        }
        if (typeof obj1[diffKey] == undefined) {
          this.diffArr.push(` added ${(typeof this.mapper[diffKey] != 'undefined' ? this.mapper[diffKey] : diffKey)}, ${obj2[diffKey]}`);
        }
        if (typeof obj2[diffKey] == undefined) {
          this.diffArr.push(`removed ${(typeof this.mapper[diffKey] != 'undefined' ? this.mapper[diffKey] : diffKey)}, ${obj1[diffKey]}`);
          console.log(this.diffArr);
        }
      } else {
        if (typeof obj1 == 'object' && Array.isArray(obj1[diffKey]) == true && typeof obj1[diffKey][0] != 'object') {
          var arrDiff = this.getArrDiff(obj1[diffKey], obj2[diffKey]);
          arrDiff.forEach(element => {
            let realKey: string = diffKey.slice();
            let diffKey1 = typeof this.mapper[diffKey] != 'undefined' ? this.mapper[diffKey] : diffKey;
            //#change1
            if (obj1[realKey].includes(element) && !obj2[realKey].includes(element)) {
              let message = `removed ${diffKey1}, ${element}`;
              if (!this.diffArr.includes(message)) {
                this.diffArr.push(message);
              }
            } else {
              let message = `added ${diffKey1}, ${element}`;
              if (!this.diffArr.includes(message)) {
                this.diffArr.push(message);
              }
            }
          });
        } else {
          if ((obj1[diffKey] != null) && (obj2[diffKey] != null)) {
            this.recurseObjectToFindDiff(obj1[diffKey], obj2[diffKey]);
          }
        }
      }
    })
    return this.diffArr
  }
  getObjectDiff(obj1, obj2) {
    const diff = Object.keys(obj1).reduce((result, key) => {
      if (!obj2.hasOwnProperty(key)) {
        result.push(key);
      } else if (lodash.isEqual(obj1[key], obj2[key])) {
        const resultKeyIndex = result.indexOf(key);
        result.splice(resultKeyIndex, 1);
      }
      return result;
    }, Object.keys(obj2));

    return diff;
  }
  getArrDiff(arr1, arr2) {
    return arr1
      .filter(x => !arr2.includes(x))
      .concat(arr2.filter(x => !arr1.includes(x)));
  }
  redirectToObject(id) {
    this.closeItem(id);
    if (this.selectedAlert.alert_type == 'Plan') {
      this.router.navigate(['/plan-detail', this.object_id]);
    } else if (this.selectedAlert.alert_type == 'Solution') {
      this.router.navigate(['/edit-solution', this.object_id]);
    } else if (this.selectedAlert.alert_type == 'Opportunity') {
      this.router.navigate(['/edit-opportunity', this.object_id]);
    } else {
      this.router.navigate(['/edit-activity', this.object_id]);
    }
  }
}
