<div class="row loader-rp" style="height: 100%;">
    <div class="col-12" style="height: 100%;">
        <div style="height: 100%;" class="d-flex justify-content-center align-items-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
</div>

<div class="row hide report-div">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="">
        <div class="pad_div">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 bdrritrbtn bdrritr">
                    <div class="row mb-4">
                        <label class="filter-by">Filter By: </label>
                        <div class="col-md-3">
                            <select class="ui search dropdown stage" #opstage1
                                (change)="filter.stage=opstage1.value;loadCharts(1)">
                                <option value=" ">Stage</option>
                                <option [value]="st" *ngFor="let st of opportunity_stages;">{{st}}</option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <select class="ui search dropdown partner " #partner1
                                (change)="filter.partner=partner1.value;loadCharts(1)">
                                <option value=" ">Partner</option>
                                <option [value]="p.partner_id" *ngFor="let p of partners;">{{p.partner_name}}</option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <select class="ui search dropdown partner " #year1
                                (change)="filter.year=year1.value;loadCharts(1)">
                                <option value=" ">Years</option>
                                <option [value]="y" *ngFor="let y of years;">{{y}}</option>
                            </select>
                        </div>
                    </div>
                    <div id="bar-1" class="mb-4 pr-4"></div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 bdrritrbtn">
                    <div class="row mb-4">
                        <label class="filter-by">Filter By: </label>
                        <div class="col-md-3">
                            <select class="ui search dropdown partner " #partner2
                                (change)="filter.partner2=partner2.value;loadCharts(2)">
                                <option value=" ">Partner</option>
                                <option [value]="p.partner_id" *ngFor="let p of partners;">{{p.partner_name}}</option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <select class="ui search dropdown stage " #time2
                                (change)="filter.time2=time2.value;loadCharts(2)">
                                <option value=" ">Time</option>
                                <option value="W">Week</option>
                                <option value="M">Month</option>
                                <option value="Q">Quarter Year</option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <select class="ui search dropdown partner" #year2
                                (change)="filter.year2=year2.value;loadCharts(2)">
                                <option value=" ">Years</option>
                                <option [value]="y" *ngFor="let y of years;">{{y}}</option>
                            </select>
                        </div>
                    </div>
                    <div id="bar-2" class="mb-4"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12  bdrritr">
                    <div class="row mb-4 mt-4">
                        <label class="filter-by">Filter By: </label>
                        <div class="col-md-3">
                            <select class="ui search dropdown stage" #stage3
                                (change)="filter.stage3=stage3.value;loadCharts(3)">
                                <option value=" ">Stage</option>
                                <option [value]="st" *ngFor="let st of opportunity_stages;">{{st}}</option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <select class="ui search dropdown partner " #partnertype3
                                (change)="filter.partner_type3=partnertype3.value;loadCharts(3)">
                                <option value=" ">Partner Type</option>
                                <option [value]="t" *ngFor="let t of partner_types;">{{t}}</option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <select class="ui search dropdown partner " #year3
                                (change)="filter.year3=year3.value;loadCharts(3)">
                                <option value=" ">Years</option>
                                <option [value]="y" *ngFor="let y of years;">{{y}}</option>
                            </select>
                        </div>
                    </div>
                    <div id="pieghraph" class="mb-4"></div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                    <div class="row mb-4 mt-4">
                        <label class="filter-by">Filter By: </label>
                        <div class="col-md-2">
                            <select class="ui search dropdown stage" #stage4
                                (change)="filter.stage4=stage4.value;loadCharts(4)">
                                <option value=" ">Stage</option>
                                <option [value]="st" *ngFor="let st of opportunity_stages;">{{st}}</option>
                            </select>
                        </div>
                        <div class="col-md-3 solotion-stage">
                            <select class="ui search dropdown stage" #slstage4 style="padding:10px 10px"
                                (change)="filter.solution_stage4=slstage4.value;loadCharts(4)">
                                <option value=" ">Solution Stage</option>
                                <option [value]="st" *ngFor="let st of sulution_stages;">{{st}}</option>
                            </select>
                        </div>
                        <div class="col">
                            <select class="ui search dropdown partner " #partner4
                                (change)="filter.partner4=partner4.value;loadCharts(4)">
                                <option value=" ">Partner</option>
                                <option [value]="p.partner_id" *ngFor="let p of partners;">{{p.partner_name}}</option>
                            </select>
                        </div>
                        <div class="col">
                            <select class="ui search dropdown partner " #year4
                                (change)="filter.year4=year4.value;loadCharts(4)">
                                <option value=" ">Years</option>
                                <option [value]="y" *ngFor="let y of years;">{{y}}</option>
                            </select>
                        </div>
                    </div>
                    <div id="pyramid" class="mb-4"></div>
                </div>
            </div>
        </div>
    </div>

</div>