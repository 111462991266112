import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IMultiSelectOption, IMultiSelectSettings } from 'angular-2-dropdown-multiselect';
import { NavigationHelperService } from '../services/navigation-helper.service';
import { LocalStorageService } from '../services/local-storage.service';
import * as  moment from "moment";
import { CommonEmitterService } from '../services/common-emitter';

declare var $: any;
@Component({
  selector: 'app-create-opportunity',
  templateUrl: './create-opportunity.component.html',
  styleUrls: ['./create-opportunity.component.css']
})
export class CreateOpportunityComponent implements OnInit {
  solutions: any = []
  owners: any = []
  partners: any = []
  party_owners: any = []
  dealStatuses: any = []
  allDealStatuses: any = []
  dealStages: any = [];
  domainList: any = []
  partnerOptions: IMultiSelectOption[] = [];
  defaultPartnerID: string = '';
  defaultPartnerEmail: string;
  userPartnerEmail: any;
  userPartnerDomain: any;
  createOpportunityForm: FormGroup;
  opportunity: any = {
    partner_id: '',
    deal_status: null,
    stage: null,
    solution: '',
    product_category: '',
    product: '',
    last_activity: '',
    team: [],
    recurring_frequency: 'month',
  };
  formControls: any;
  multiSelectionSettings: IMultiSelectSettings = {
    enableSearch: true,
    checkedStyle: "checkboxes",
    buttonClasses: "btn btn-default",
    dynamicTitleMaxItems: 0
  };
  multiCustomerSettings: IMultiSelectSettings = {
    enableSearch: true,
    checkedStyle: "fontawesome",
    buttonClasses: "btn btn-default fz-16 h-42",
    // dynamicTitleMaxItems: 0,
    selectionLimit: 1,
    autoUnselect: true,
  };
  formError: boolean = false;
  productCategories: any[] = []
  products: any[] = []
  isEdit: any = true;
  opportunityId: any = ''
  userData: any = {};
  selectedConnection: any = {}
  customers: any = []
  plans: any = [];
  isCustomer: any = false;
  partnerOwner: any = '';
  owner: any = '';
  userSubscription: { type: any, data: any, create_partner: boolean, create_user: boolean } = { type: '', data: '', create_partner: true, create_user: true };
  customTerms: { id: string, name: string }[] = [
    { id: 'month', name: 'Month' },
    { id: 'quarter', name: 'Quarter' },
    { id: 'year', name: 'Year' }
  ];
  stageUrl: any = '';
  partner_error: any = false;
  userDataPartners: any = [];
  selfPartners: any = [];
  @ViewChild('prod') prod: ElementRef
  @ViewChild('product') product: ElementRef
  @ViewChild('product_category1') product_category1: ElementRef
  @ViewChild('product_category') product_category: ElementRef
  @ViewChild('plan_id') plan_id: ElementRef
  @ViewChild('solution_id') solution_id: ElementRef
  @ViewChild('stage') stage: ElementRef
  @ViewChild('deal_status') deal_status: ElementRef
  constructor(
    private formBuilder: FormBuilder,
    private AuthService: AuthService,
    private router: Router,
    private CommonEmitterService: CommonEmitterService,
    private navHelper: NavigationHelperService,
    private activeRoute: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private cdRef: ChangeDetectorRef
  ) {
    this.userData = this.localStorageService.get({ name: 'userData' })
    this.userData = this.userData ? JSON.parse(this.userData) : {}
    if (this.userData.email) {
      this.userPartnerEmail = this.userData.email;
    }

    if (this.userData.partner) {
      this.userPartnerDomain = this.userData.partner.email_domain;
    }
    if (this.userData.subscription_type_data != null) {
      this.userSubscription.type = 'subscriber'
      this.userSubscription.data = this.userData.subscription_type_data;
    } else {
      this.userSubscription.type = 'partner'
      this.userSubscription.data = this.userData.subscriber_subscription_type_data;
      if (this.userSubscription.data) {
        this.userSubscription.create_user = this.userSubscription.data.user_by_partner;
      }
    }
    if (this.navHelper.navigationTracker.length == 0) {
      this.navHelper.setUrl(this.router.url)
    }
    this.activeRoute.params.subscribe((a) => {
      if (a.opportunityId) {
        this.opportunityId = a.opportunityId;
      }
    })
    this.activeRoute.queryParams.subscribe((a) => {
      if (a.partnerId) {
        this.defaultPartnerID = a.partnerId;
      }
      if (a.stage) {
        this.stageUrl = a.stage;
      }
    })
    this.createOpportunityForm = this.formBuilder.group({
      customer_name: ['', Validators.compose([Validators.required])],
      customer_id: [''],
      partners: [[]],
      owner: [[], Validators.compose([Validators.required])],
      product_category: [[]],
      product: [[]],
      partner_owner: [''],
      name: ['', Validators.compose([Validators.required])],
      stage: [, Validators.compose([Validators.required])],
      stage_id: [, Validators.compose([Validators.required])],
      deal_status: [, Validators.compose([Validators.required])],
      deal_status_id: [, Validators.compose([Validators.required])],
      order_status: [, Validators.compose([Validators.required])],
      order_status_id: [, Validators.compose([Validators.required])],
      // value: ['', Validators.compose([Validators.required, Validators.pattern("^[0-9]*$")])],
      probability: ['', Validators.compose([Validators.required, Validators.max(100)])],
      close_date: ['', Validators.compose([Validators.required])],
      solution_name: [[]],
      solution_id: [[]],
      plan_name: [[]],
      plan_id: [[]],
      last_activity: [''],
      quote_date: [''],
      quote_value: ['', Validators.compose([Validators.pattern("^[0-9]*$")])],
      teams: [[]],
      fixed_revenue: ['', Validators.compose([Validators.required, Validators.pattern("^[0-9]*$")])],
      recurring_revenue: ['', Validators.compose([Validators.pattern("^[0-9]*$")])],
      recurring_frequency: [''],
      terms: ['', Validators.compose([Validators.pattern("^[0-9]*$")])]
    })
    this.subscribeChanges();
    this.getPartyOwner(this.userData.partner.partner_id, ['team_members', 'owners', 'party_owners'], true);
  }
  subscribeChanges() {
    let probabilityControl = this.createOpportunityForm.get('probability');
    probabilityControl.valueChanges.subscribe(val => {
      if (probabilityControl.errors && !probabilityControl.errors.pattern) {
        return;
      }
      let percentage: string = val.substring(0, val.length - 1)
      if (!(percentage.match(/^[0-9]*$/))) {
        probabilityControl.setErrors({ pattern: true });
      } else {
        probabilityControl.setErrors(null);
      }
    })
    let recurringRevenueControl = this.createOpportunityForm.get('recurring_revenue');
    recurringRevenueControl.valueChanges.subscribe(val => {
      if (!val || val == '') {
        this.createOpportunityForm.get('recurring_frequency').setValidators([]);
        this.createOpportunityForm.get('recurring_frequency').updateValueAndValidity();
        this.createOpportunityForm.get('terms').setValidators([]);
        this.createOpportunityForm.get('terms').updateValueAndValidity();
      } else {
        this.createOpportunityForm.get('recurring_frequency').setValidators([Validators.required]);
        this.createOpportunityForm.get('recurring_frequency').updateValueAndValidity();
        this.createOpportunityForm.get('terms').setValidators([Validators.required]);
        this.createOpportunityForm.get('terms').updateValueAndValidity();
      }
    })
    let partnersControl = this.createOpportunityForm.get('partners')
    partnersControl.valueChanges.subscribe(value => {
      if (value && value.length > 0) {
        this.createOpportunityForm.get('partner_owner').setValidators([Validators.required]);
        this.createOpportunityForm.get('partner_owner').updateValueAndValidity();
      } else {
        this.createOpportunityForm.get('partner_owner').setValidators([]);
        this.createOpportunityForm.get('partner_owner').updateValueAndValidity();
      }
    })
  }
  getOpportunityDetail() {
    this.AuthService.get('opportunities/' + this.opportunityId, (err) => {
      console.log(err);
    }).subscribe((result: any) => {
      this.opportunity = result.data;
      this.processOpportunityData()
    }, (err: any) => {
      this.opportunity = {};
      console.log(err);
    }, () => {
      this.setSolution();
    });
  }
  processOpportunityData() {
    this.opportunity.close_date = moment(new Date(this.opportunity.close_date)).format('MM/DD/YYYY');
    if (this.opportunity.quote_date) {
      this.opportunity.quote_date = moment(new Date(this.opportunity.quote_date)).format('MM/DD/YYYY');
    }
    if (Array.isArray(this.opportunity.partners) && this.opportunity.partners.length > 1) {
      this.getPartyOwner(this.opportunity.partners.join(), ['team_members', 'owners', 'party_owners']);
    }
    this.opportunity.partners = this.opportunity.partners.filter(val => val != this.userData.partner.partner_id);
    if (this.defaultPartnerID) {
      this.opportunity.partners.push(this.defaultPartnerID);
    }
    this.getConnection((this.opportunity.partners || []).join());
    this.getSolutions((this.opportunity.solution_id || []).join());
    this.getPlans((this.opportunity.plan_id || []).join());
    if (Array.isArray(this.opportunity.team)) {
      this.opportunity.team = this.opportunity.team
        // .filter(val => {
        //   if (val && val.email) {
        //     return val.email != this.userPartnerEmail
        //   } else if (val) {
        //     return val != this.userPartnerEmail
        //   }
        // })
        .map(val => {
          if (val && val.email) {
            return val.email
          } else if (val) {
            return val
          }
        });
    }
    this.partnerOwner = this.opportunity.partner_owner;
    this.owner = this.opportunity.owner;
    this.opportunity.stage_id = this.stageUrl ? this.stageUrl : this.opportunity.stage_id;
    this.changeStage({})
    this.changeStatus({ id: this.opportunity.deal_status_id });
    this.getProductCategories()
    if (this.opportunity.product_category) {
      this.opportunity.product_category = Array.isArray(this.opportunity.product_category) ? this.opportunity.product_category : [this.opportunity.product_category]
      this.getProducts(this.opportunity.product_category)
    }
  }
  solutionOptions: any[] = [];
  getSolutions(solution_id?) {
    let apiString = 'solutions'
    if (solution_id && solution_id != '') {
      apiString = apiString + '?solution_id=' + solution_id
    }
    this.AuthService.get(apiString, (err) => {
    }).subscribe((result: any) => {
      if (this.opportunityId && solution_id && solution_id != '') {
        this.solutions = [...this.solutions, ...result.data]
      } else {
        this.solutions = result.data;
      }
      this.solutions = this.solutions.filter((v, i, a) => a.findIndex(t => (t.solution_id === v.solution_id)) === i);
      this.solutionOptions = this.solutions.map((a) => { return { name: a.solution_name, id: a.id } });
      if (this.opportunity.id) {
        this.setSolution()
      }
    }, (err: any) => {
      this.solutions = [];
      console.log(err);
    });
  }
  planOptions: any[] = [];
  getPlans(plan_id?) {
    let apiString = 'plans'
    if (plan_id && plan_id != '') {
      apiString = apiString + '?plan_id=' + plan_id
    }
    this.AuthService.get(apiString, (err) => {
    }).subscribe((result: any) => {
      if (this.opportunityId && plan_id && plan_id != '') {
        this.plans = [...this.plans, ...result.data]
      } else {
        this.plans = result.data;
      }
      this.plans = this.plans.filter((v, i, a) => a.findIndex(t => (t.plan_id === v.plan_id)) === i);
      this.planOptions = this.plans.map((a) => { return { name: a.plan_name, id: a.id } });
      if (this.opportunity.id) {
        this.setPlan()
      }
    }, (err: any) => {
      this.solutions = [];
      console.log(err);
    });
  }
  setPlanName(planIds) {
    let planName = []
    if (planIds && (planIds.length > 0)) {
      planIds.forEach(planId => {
        if (planId && planId != '') {
          let plan = this.plans.find((a) => a.id == planId);
          if (plan) {
            planName.push(plan.plan_name)
          }
        }
      });
      this.createOpportunityForm.patchValue({ plan_name: planName });
      this.opportunity.plan_name = planName;
    }
  }
  setSolutionName(solutionIds) {
    let solutionName = []
    if (solutionIds && (solutionIds.length > 0)) {
      solutionIds.forEach(solutionId => {
        if (solutionId && solutionId != '') {
          let solution = this.solutions.find((a) => a.id == solutionId);
          if (solution) {
            solutionName.push(solution.solution_name)
          }
        }
      });
      this.createOpportunityForm.patchValue({ solution_name: solutionName });
      this.opportunity.solution_name = solutionName;
    }
  }
  getConnection(partner_id?) {
    let apiString = '';
    if (this.opportunityId && partner_id) {
      apiString = 'partners?partner_id=' + partner_id
    } else {
      apiString = 'partners'
    }
    this.AuthService.get(`${apiString}`).subscribe(
      (result: any) => {
        if (this.opportunityId && partner_id) {
          this.partners = [...this.partners, ...result.data]
        } else {
          this.selfPartners = result.data;
          this.partners = result.data
          this.userSubscription.create_partner = this.getAccess('partner');
          if (!this.userSubscription.create_partner) {
            $('#partnerButton_1').addClass('no-partner');
            $('#partnerButton_2').addClass('no-partner');
          }
        }
        this.partners = this.partners.filter((v, i, a) => {
          if (v.partner_id === this.userData.partner.partner_id) {
            return false;
          } else {
            return (a.findIndex(t => { return (t.partner_id === v.partner_id) }) === i)
          }
        });
        this.partnerOptions = this.partners.map(
          (partner) => { return { id: partner.partner_id, name: partner.partner_name } });
        setTimeout(() => {
          this.partnerOwner = this.opportunity.partner_owner;
          this.owner = this.opportunity.owner;
          this.opportunity = this.opportunity;
        }, 100)
        if (!this.opportunityId) {
          let owner_partner_ids = this.userDataPartners.map((a) => a.partner.owner_partner_id).filter(a => a && a != this.userData.partner.partner_id);
          console.log(owner_partner_ids);
          if (owner_partner_ids.length) {
            if (owner_partner_ids.length == 1) {
              this.opportunity.partners = [owner_partner_ids[0]]
              this.createOpportunityForm.patchValue({ partners: this.opportunity.partners })
              this.getProductCategories()
            }
          }
        }
      },
      (err: any) => {
        this.partnerOptions = [];
      })
  }
  getFilteredCustomers(e) {
    if (this.createOpportunityForm.value['customer_name'] != '' && this.createOpportunityForm.value['customer_name'] != undefined) {
      this.filteredCustmers = this.customers.filter((a) => a.name.toLowerCase().indexOf(this.createOpportunityForm.value['customer_name'].toLowerCase()) != -1).sort((a: any, b: any) => {
        var keyA = a.name,
          keyB = b.name;
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });
    } else {
      this.filteredCustmers = this.customers.sort((a: any, b: any) => {
        var keyA = a.name,
          keyB = b.name;

        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });
    }
    console.log(e, this.customers, this.filteredCustmers)
  }
  filteredCustmers: any = [];
  getCustomers() {
    // console.log(e);
    this.isCustomer == false;
    this.AuthService.get("customers?limit=100").subscribe(
      (result: any) => {
        // console.log(result.data)
        this.customers = (result.data || []).map((a) => {
          a.name = a.name.toLowerCase();
          return a
        });
      });
  }
  setCustomer(c) {
    console.log(c);
    this.isCustomer = true;
    this.opportunity.customer_name = c.name;
    this.opportunity.customer_id = c.customer_id;
    this.createOpportunityForm.patchValue({ customer_name: c.name });
    this.createOpportunityForm.patchValue({ customer_id: c.customer_id });
    this.filteredCustmers = []
  }
  blurCustomer() {
    setTimeout(() => {
      this.filteredCustmers = [];
    }, 500)

  }
  setSolution(value?: any) {
    if (value) {
      let f = this.solutions.find((a) => a.id == value)
      if (f) {
        // this.createOpportunityForm.patchValue({ solution_name: f.solution_name, solution_id: value });
        console.log("this.createOpportunityForm:", this.createOpportunityForm);
        if (f.product_category) {
          this.createOpportunityForm.patchValue({ product_category: f.product_category });
          this.product_category.nativeElement.value = f.product_category;
        }
        if (f.product) {
          this.createOpportunityForm.patchValue({ product: f.product });
          this.product.nativeElement.value = f.product;
        }
      }
    }
  }
  setPlan(value?: any) {
    if (value) {
      let f = this.plans.find((a) => a.id == value)
      // this.createOpportunityForm.patchValue({ plan_name: f.solution_name, plan_id: value });
      console.log("this.createOpportunityForm:", this.createOpportunityForm);
    }
  }
  allUser: any = []
  rawTeamList: any[] = [];
  getPartyOwner(id, key?, firstLoad?) {
    let apiString = 'users'
    if (id && id != '') {
      apiString = 'partners/users?partner_id=' + id
    }
    this.AuthService.get(apiString).subscribe(
      (result: any) => {
        let filteredList = [];
        this.allUser = [...this.allUser, ...result.data]
        if (key.includes('team_members')) {
          // let filteredList = result.data.filter(
          //   (user: any) => { return user.email != this.userPartnerEmail })
          this.partnerTeamOptions = this.rawTeamList = result.data.map(
            (partnerUser) => {
              return { id: partnerUser.email, name: partnerUser.email };
            }
          );
          if (!firstLoad && this.opportunity.team && (this.opportunity.team.length > 0)) {
            console.log(this.opportunity.team)
            this.opportunity.team = this.opportunity.team.filter(val => this.partnerTeamOptions.find(team => team.id == (typeof val == 'string' ? val : val.email)));
          }
          this.domainList = result.data.map(partnerUser => { return partnerUser.email_domain });
        }
        if (key.includes('owners')) {
          this.owners = result.data.map(
            (partnerUser) => {
              return { id: partnerUser.email, name: partnerUser.email };
            }
          );
        }
        if (key.includes('party_owners')) {
          if (this.opportunityId) {
            this.party_owners = result.data
              // .filter((partnerUser) => partnerUser.email_domain != this.userPartnerDomain)
              .map(
                (partnerUser) => {
                  return { id: partnerUser.email, name: partnerUser.email };
                }
              );
          } else {
            this.party_owners = result.data.filter((partnerUser) => partnerUser.email_domain != this.userPartnerDomain).map(
              (partnerUser) => {
                return { id: partnerUser.email, name: partnerUser.email };
              }
            );
          }
          if (!firstLoad && this.opportunity.partner_owner && (this.opportunity.partner_owner.length > 0)) {
            this.opportunity.partner_owner = this.opportunity.partner_owner.filter(owner => this.party_owners.find(member => member.id == owner));
          }
        }
      },
      (err: any) => {
        this.party_owners = [];
      }
    );
  }
  orderStatuses: any = [];
  getDefaultData(partner_id?: string) {
    let apiString = 'custom_data'
    if (partner_id && partner_id != '') {
      apiString = apiString + '?partner_id=' + partner_id
    }
    this.AuthService.get(`${apiString}`, (err) => {
      console.log(err);
    }).subscribe((result: any) => {
      this.dealStages = result.data.opportunity_stages.sort((a: any, b: any) => {
        var keyA = a.order,
          keyB = b.order;
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });
      this.orderStatuses = result.data.opportunity_order_statuses.sort((a: any, b: any) => {
        var keyA = a.order,
          keyB = b.order;
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });
      // this.dealStages = result.data.opportunity_stages.filter((a) => { return (typeof a == 'string') || (typeof a == 'object' && a.show) }).map((a) => { return typeof a == 'string' ? { label: a, show: true, stage: [] } : a });
      this.dealStatuses = this.allDealStatuses = result.data.opportunity_deals.filter((a) => { return (typeof a == 'string') || (typeof a == 'object' && a.status) }).map((a) => { return typeof a == 'string' ? { label: a, status: true, stage: [] } : a });
      if (this.opportunity.stage_id)
        this.dealStatuses = this.allDealStatuses.filter((a) => a.stage.length && a.stage.indexOf(this.opportunity.stage_id) != -1);

    }, (err: any) => {
      // this.solutionStages = [];
      console.log(err);
    });
  }
  getProductCategories(defaultCat: any = '') {
    let vars = '';
    if (this.opportunity.partners && this.opportunity.partners.length) {
      vars = '?partner_id=' + this.opportunity.partners.join()
      vars = vars + `,${this.userData.partner.partner_id}`
    }
    this.AuthService.get('product_categories' + vars, (err) => {
      console.log(err);
    }).subscribe((result: any) => {
      this.productCategories = result.data;
      console.log(this.opportunity.product_category, this.productCategories, defaultCat);
      if (defaultCat) {
        setTimeout(() => {
          this.createOpportunityForm.patchValue({ product_category: defaultCat });
          this.product_category.nativeElement.value = defaultCat;
        }, 100)
        if (this.opportunity.product_category) {
          let cat = this.productCategories.find((a) => this.opportunity.product_category.indexOf(a.category_name) != -1)
          this.getProducts(cat)
        }
      }
    }, (err: any) => {
      this.productCategories = [];
      console.log(err);
    });
  }
  getProducts(cat) {
    console.log(cat)
    if (this.opportunity.product_category) {
      let cats = []
      if (typeof cat == 'string') {
        let cat1 = this.productCategories.find((a) => a.category_name == cat)
        console.log(cat1, cat)
        cat = cat1;
      }
      if (Array.isArray(this.opportunity.product_category)) {
        this.opportunity.product_category.map((a1) => {
          let cat1 = this.productCategories.find((a) => a.category_name == a1)
          if (cat1 && cat1.category_id) {
            cats.push(cat1.category_id)
          }
        })
      }
      this.AuthService.get('products?category_id=' + cats.join(), (err) => {
        console.log(err);
      }).subscribe((result: any) => {
        this.products = result.data;
      }, (err: any) => {
        this.products = [];
        console.log(err);
      });
    }
  }
  openConfirmPopup() {
    this.errors_deal_status = '';
    this.formError = false;
    if (this.createOpportunityForm.valid) {
      let form_data = this.createOpportunityForm.value;
      console.log(this.dealStatuses, form_data.deal_status, this.dealStatuses.filter((a) => a.label == form_data.deal_status))
      // return;
      if (this.dealStatuses.filter((a) => a.label == form_data.deal_status).length) {
        let owner_partner_ids = this.userDataPartners.map((a) => a.partner.owner_partner_id).filter(a => a && a != this.userData.partner.partner_id);
        console.log(owner_partner_ids);
        if (owner_partner_ids.length && !this.createOpportunityForm.value.partners.length) {
          this.formError = true;
          this.partner_error = true;
        } else {
          this.formError = false;
          $('#confirmPopup').modal('show');
        }

        // this.formError = false;
        // $('#confirmPopup').modal('show');
      } else {
        this.errors_deal_status = 'Invalid Deal status.'
        this.formError = true;
      }
    } else {
      this.formError = true;
    }
  }
  closeConfirmPopup() {
    $('#confirmPopup').modal('hide');
  }
  sortTeamMembers() {
    let final_team: any[] = []
    final_team = this.invitedTeam.filter(member => { return member.email != '' })
    console.log(final_team, this.invitedTeam, this.selectedTeamMembers, this.final_data)
    this.selectedTeamMembers.map((eachMember) => {
      final_team.push({ email: eachMember.email, user_role: '3' })
    })
    this.final_data.team = final_team
  }
  errorMessage: any = ''
  displayResult: any = ''
  displayId: any = ''
  isProgress: any = false;
  final_data: any;
  errors_deal_status: any = '';
  submit() {
    console.log(this.isProgress);
    if (!this.isProgress) {
      this.isProgress = true
      if (this.createOpportunityForm.valid) {
        this.formError = false;
        this.final_data = this.createOpportunityForm.value
        console.log(this.final_data)

        let date = this.final_data.close_date.split('/')
        this.final_data.close_date = new Date(date[2] + '-' + date[0] + '-' + date[1])
        if (this.final_data.quote_date && this.final_data.quote_date.indexOf('/') !== -1) {
          date = this.final_data.quote_date.split('/')
          this.final_data.quote_date = new Date(date[2] + '-' + date[0] + '-' + date[1])
        }
        this.final_data.probability = this.final_data.probability.replace('%', '');
        let partners = this.final_data.partners || [];
        if (partners && partners.length >= 1) {
          if (this.final_data.owner) {
            let owner = this.allUser.find((a) => a.email == this.final_data.owner)
            partners.push(owner.partner_id)
          }
          if (this.final_data.partner_owner && this.final_data.partner_owner.length) {
            this.final_data.partner_owner.map(val => {
              let owner = this.allUser.find((a) => a.email == val)
              console.log(owner, val, partners);
              if (owner && !partners.find((a) => a == owner.partner_id)) {
                partners.push(owner.partner_id)
              }
            })
          }
          this.final_data.partners = partners;
        }

        this.sortTeamMembers();
        delete this.final_data.teams
        delete this.final_data.selectedTeam
        console.log(this.final_data)
        if (this.opportunityId) {
          this.final_data.id = this.opportunityId
        }
        this.final_data.isCustomer = this.isCustomer
        if (!this.opportunity.id) {
          this.final_data.created_date = new Date()
        } else {
          if (!this.opportunity.created_date) {
            this.final_data.created_date = new Date()
          }
          this.final_data.updated_date = new Date()
        }
        if (!this.final_data.partner_owner) {
          this.final_data.partner_owner = this.userData.email;
        }
        this.final_data.recurring_revenue = this.final_data.recurring_revenue || 0
        this.final_data.terms = this.final_data.terms || 0
        this.final_data.value = Number(this.final_data.fixed_revenue) + (Number(this.final_data.recurring_revenue) * Number(this.final_data.terms));
        console.log(this.final_data.value);
        this.final_data.solution_id = this.final_data.solution_id || []
        this.final_data.plan_id = this.final_data.plan_id || []
        this.final_data.product = this.final_data.product || []
        this.final_data.product_category = this.final_data.product_category || []
        if (this.final_data.partners && this.final_data.partners.length > 0) {
          this.final_data.partners = [...[this.userData.partner.partner_id], ...this.final_data.partners];
        } else {
          this.final_data.partners = [...[this.userData.partner.partner_id]];
        }
        if (!this.opportunity.id) {
          this.final_data.created_user = `${this.userData.first_name} ${this.userData.last_name}`
        }
        this.closeConfirmPopup();
        $('#successModal').modal('show');
        this.AuthService[this.opportunity.id ? 'update' : 'add']('opportunities' + (this.opportunity.id ? '/' + this.opportunityId : ''), this.final_data).subscribe((result: any) => {
          this.isProgress = false
          this.displayId = result.data.id
          this.displayResult = 'success';
          this.CommonEmitterService.updateObjectCount.next();
        }, (err: any) => {
          this.isProgress = false
          this.errorMessage = err.error.meta.message
          this.displayResult = 'failure'
          console.log(err);
        });
      } else {
        this.isProgress = false
        console.log(this.createOpportunityForm)
        this.formError = true;
      }
    }
  }
  openConfirmModal(cat, prod) {
    this.errors = {}
    console.log(cat, prod)
    if (cat && prod) {
      $('#myModal').modal('hide');
      $('#myModalcnfrm').modal('show');
    } else {
      if (!cat) {
        this.errors.category = 'Category is required.'
      }
      if (!prod) {
        this.errors.product = 'Product is required.'
      }
    }
  }
  errors: any = {}
  setIsCat: any = false
  confirmProduct(cat, prod) {
    this.errors = {}
    if (!this.isProgress) {
      this.isProgress = true;
      if (cat && prod) {
        if (!this.setIsCat) {
          this.AuthService.add(`product_categories`, { category_name: cat }).subscribe(
            (resultCat: any) => {

              this.AuthService.add(`products`, { product_name: prod, category_id: resultCat.data.category_id }).subscribe(
                (result: any) => {
                  this.isProgress = false;
                  this.opportunity.product_category = resultCat.data.category_name;
                  this.getProductCategories(resultCat.data.category_name)
                  this.opportunity.product = result.data.product_name;
                  this.createOpportunityForm.patchValue({ product_category: resultCat.data.category_name });
                  this.createOpportunityForm.patchValue({ product: result.data.product_name });
                  this.product.nativeElement.value = result.data.product_name;
                  this.product_category.nativeElement.value = resultCat.data.category_name;
                  this.prod.nativeElement.value = ''
                  this.product_category1.nativeElement.value = ''
                  $('#myModalcnfrm').modal('hide')
                },
                (err: any) => {
                  this.isProgress = false;
                }
              );
            },
            (err: any) => {
              this.isProgress = false;
            }
          );
        } else {
          this.AuthService.add(`products`, { product_name: prod, category_id: cat }).subscribe(
            (result: any) => {
              this.isProgress = false;
              let cat1 = this.productCategories.find((a) => a.category_id == cat)
              console.log(cat1)
              this.opportunity.product_category = cat1.category_name;
              this.getProductCategories(cat1.category_name)
              this.setIsCat = false;
              this.opportunity.product = result.data.product_name;
              this.getProducts(cat1)
              this.createOpportunityForm.patchValue({ product_category: cat1.category_name });
              this.createOpportunityForm.patchValue({ product: result.data.product_name });

              this.product.nativeElement.value = result.data.product_name;
              this.product_category.nativeElement.value = cat1.category_name;

              this.prod.nativeElement.value = ''
              this.product_category1.nativeElement.value = ''
              console.log(this.opportunity, this.createOpportunityForm.value)
              $('#myModalcnfrm').modal('hide')
            },
            (err: any) => {
              this.isProgress = false;
            }
          );
        }
      } else {
        this.isProgress = false;
        if (!cat) {
          this.errors.category = 'Category is required.'
        }
        if (!prod) {
          this.errors.product = 'Product is required.'
        }
      }
    }
  }
  goBack() {
    $('.modal').modal('hide')
    let previousUrl = this.navHelper.getUrl()
    // let redirect = localStorage.getItem('redirect2')
    console.log(previousUrl)
    // if ((previousUrl && previousUrl != this.router.url) || redirect) {
    if ((previousUrl && previousUrl != this.router.url)) {
      // this.router.navigate([redirect || previousUrl])
      if (previousUrl == '/dashboard') {
        this.router.navigate([previousUrl, 'opportunities'])
      } else {
        this.router.navigate([previousUrl])
      }
    } else {
      this.router.navigate(['/opportunity-dashboard'])
    }
  }
  loadUserDomain() {
    this.AuthService.get('user/me').subscribe(
      (result: any) => {
        this.userDataPartners = result.data;
        // this.userPartnerDomain = result.data[0].partner.email_domain
        // this.userPartnerEmail = result.data[0].email;
        this.getConnection();
      },
      () => { }
    )
  }
  ngOnInit(): void {
    this.formControls = this.createOpportunityForm.controls;
    this.getSolutions();
    this.getPlans();
    this.loadUserDomain();
    this.getDefaultData();
    this.getProductCategories();
    this.getCustomers();
    var d = new Date();
    d.setDate(d.getDate() - 1);
    let load = true;
    $('document').ready(() => {
      $('#probability').on("keyup", function (e) {
        console.log(e.keyCode);

        var value: string = $(this).val();
        if (value != '' && value != '%') {
          value = value.replace('%', '');
          if (value.length > 1) {
            var output = value + "%";
            var cursorPosition = output.length - 1;
          } else {
            var output = value + "%";
            var cursorPosition = output.length - 1;
          }
          if (e.keyCode != 39 && e.keyCode != 37) {
            $(this).val(output);
            $(this)[0].selectionStart = $(this)[0].selectionEnd = cursorPosition;
          }
        } else if (value == "%") {
          $(this).val('');
        }
      });
      $('#date-closed').datepicker({
        format: 'mm/dd/yyyy',
        minDate: d,
        close: (e) => {
          this.createOpportunityForm.patchValue({ close_date: $('#date-closed').val() });
        }
      })
      $('#date2').datepicker({
        format: 'mm/dd/yyyy',
        minDate: d,
        close: (e) => {
          this.createOpportunityForm.patchValue({ quote_date: $('#date2').val() });
        }
      })
      // $('.step').click(($event) => {
      //   this.opportunity.stage = $event.target.innerText;
      //   this.changeStage({ target: { value: $event.target.innerText } });
      // })
      let opportunityData = localStorage.getItem('opportunityData')
      if (opportunityData != null || opportunityData != undefined) {
        load = false
        this.opportunity = opportunityData ? JSON.parse(opportunityData) : {};
      }
      localStorage.removeItem('opportunityData');
    });
    setTimeout(() => {
      if (load && this.opportunityId != '') {
        this.getOpportunityDetail();
      } else if (!load && this.opportunity.id) {
        this.processOpportunityData();
      }
    }, 500);
  }
  closeModal() {
    this.displayResult = ''
    $('#successModal').modal('hide');
  }
  addActivity() {
    localStorage.setItem('opportunityData', JSON.stringify(this.opportunity))
    localStorage.setItem('redirect', this.router.url)
    this.router.navigate(['/create-activity']);
  }
  addedActivity(data: any) {
    if (data) {
      this.opportunity.last_name = data.activity;
    }
    $('#addActivity').modal('hide');
  }
  selectedStatus: any = {};
  changeStatus(event) {
    this.selectedStatus = event;
    this.createOpportunityForm.patchValue({ deal_status_id: this.selectedStatus.id });
  }
  selectedOrderStatus: any = {}
  changeOrderStatus(event) {
    console.log(event)
    this.selectedOrderStatus = event;
    this.createOpportunityForm.patchValue({ order_status: this.selectedOrderStatus.label });
  }
  selectedStage: any = {};
  datePickerImplemented: boolean = false;
  changeStage(event?: any) {
    this.selectedStage = this.dealStages.find((a) => a.id == this.opportunity.stage_id);
    this.createOpportunityForm.patchValue({ stage: this.selectedStage.label });
    this.dealStatuses = this.allDealStatuses.filter((a) => a.stage.length && a.stage.indexOf(this.opportunity.stage_id) != -1);
    console.log(this.dealStatuses);
    setTimeout(() => {
      if (this.selectedStage.quoteDt) {
        if (!this.datePickerImplemented) {
          var d = new Date();
          $('#date2').datepicker({
            dateFormat: 'mm/dd/yyyy',
            maxDate: d,
            close: (e) => {
              this.createOpportunityForm.patchValue({ quote_date: $('#date2').val() });
            }
          })
          this.datePickerImplemented = true;
        }
        this.opportunity.quote_date = this.opportunity.quote_date;
        this.createOpportunityForm.patchValue({ quote_date: this.opportunity.quote_date });
      }
    }, 100)
  }
  //************** load teams *************//
  onPartnerSelect($event?) {
    if ($event != undefined) {
      let partner_ids: any[] = [];
      if (this.formControls['partners'].value && this.formControls['partners'].value != '') {
        partner_ids = this.formControls['partners'].value;
        partner_ids.push(this.userData.partner.partner_id);
      }
      this.getPartyOwner(partner_ids.join(), ['team_members', 'party_owners']);
      this.getDefaultData(partner_ids.join());
      this.getProductCategories();
    }
  }
  //******** team members logic *************//
  partnerTeamOptions: { id: string, name: string }[] = []
  partnerUsers = [];
  // loadUsers(partners_list: string) {
  //   partners_list = this.userData.partner.partner_id + (partners_list ? ',' + partners_list : partners_list);
  //   this.AuthService.get(`partners/users?partner_id=${partners_list}`).subscribe(
  //     (result: any) => {

  //       this.domainList = filteredList.map(partnerUser => { return partnerUser.email_domain });
  //       console.log(this)
  //     },
  //     (err: any) => {
  //       this.partnerUsers = [];
  //     }
  //   );
  // }
  selectedTeamMembers = []
  partnerUsersModel = [];
  onChange(event) {
    this.selectedTeamMembers = []
    if (Array.isArray(this.opportunity.team)) {
      console.log(this.opportunity.team);
      this.opportunity.team.map(t => {
        let values = (typeof t == 'string' ? t : t.email).split('~')
        if (values[0] != '') {
          this.selectedTeamMembers.push({ email: values[0], user_role: '3' })
        }
      });
    }
  }
  onPartnerOwnerSelect(event) {
    if (this.formControls['partner_owner'].value) {
      this.partnerTeamOptions = this.partnerTeamOptions.filter(val => { return !this.formControls['partner_owner'].value.includes(val.id.split('~')[0]) });
    }
  }
  //************** Invite teams *************//
  invitedTeam: any[] = [];
  //********** save team *************//
  saveTeam($event) {
    if ($event) {
      this.invitedTeam = $event;
    }
    $('#teamModal').modal('hide');
  }
  domainMatchError: boolean = false;
  domainChange($event) {
    this.domainMatchError = $event;
    this.cdRef.detectChanges();
  }
  closeTeam() {
    $('#teamModal').modal('hide');
  }
  partnerMatchError: boolean = false;
  getPartnerMatch($event) {
    this.partnerMatchError = $event;
    this.cdRef.detectChanges();
  }
  openTeam() {
    $('#teamModal').modal('show');
  }
  //************** Invite teams end *************//
  newPartner() {
    if (this.userSubscription.create_partner) {
      localStorage.setItem('opportunityData', JSON.stringify(this.opportunity))
      localStorage.setItem('redirect', this.router.url)
      this.router.navigate(["/create-partner"]);
    } else {
      $('#alertModalX').modal('show');
    }
  }
  getAccess(type): boolean {
    if (type == 'partner') {
      if (this.userSubscription.type == 'subscriber') {
        if (this.userSubscription.data.no_partner == null) {
          return true;
        } else if (this.partners.length < this.userSubscription.data.no_partner) {
          return true;
        } else {
          return false;
        }
      } else if (type == 'partner') {
        if (this.userSubscription.data && this.userSubscription.data.partner_by_partner) {
          return true;
        }
        return false;
      }
    } else if (type == 'user') {
      if (this.userSubscription.type == 'subscriber') {
        if (this.userSubscription.data.no_user == null) {
          return true;
        } else if (this.userSubscription.data.no_user < this.partners.length) {
          return true;
        } else {
          return false;
        }
      } else if (type == 'partner') {
        if (this.userSubscription.data && this.userSubscription.data.user_by_partner) {
          return true;
        }
        return false;
      }
    }
  }
}
