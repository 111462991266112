<div style="height: 100%; padding: 0; margin: 0;"
  class="d-flex flex-column justify-content-between align-items-between">
  <div class="main-container">
    <div style="height: 100%;" class="row">
      <div class="col-lg-3" style="margin-bottom: 10px;">
        <img width="240" src="./assets/ICONS/logo@2x.png" />
      </div>
      <div style="display: flex; justify-content: center; align-content: center;" class="col-lg-6">
        <div class="d-flex justify-content-center align-items-center">
          <div class="middle-container">
            <div style="padding: 10px; text-align: center;">
              <div class="col-12" *ngIf="!message">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>

              <h4 class="mrngbtm" *ngIf="message && !error"><strong>Congratulations!</strong></h4>
              <h6 class="mrngbtm" *ngIf="message && !error">
                User <strong>{{ this.key }}</strong> is activated. Press OK to
                start connecting.
              </h6>
              <h6 class="mrngbtm text-danger" *ngIf="message && error">
                {{message}}.
              </h6>
              <button type="button" class="btn addbtn" (click)="redirect()">
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <!-- dummy div for Alignment here -->
      </div>
    </div>
  </div>
  <footer class="footer" style="width: 100%; height: 60px; background-color: #44A2F8;"></footer>
</div>