<form class="form-centered" [formGroup]="createPlanForm">
  <div style="width: 45%;margin-left: 10%;">
    <div class="row mg-b-20">
      <div class="col-3 fc-black-2 custom-label head_type">
        Plan Name<sub class="required">*</sub>:
      </div>
      <div class="col-9" style="text-align: left;">
        <input [ngClass]="{ 'np-none': planId }" [readonly]="planId" type="text" class="form-control fz-16 fc-blue"
          formControlName="plan_name" placeholder="Enter plan name" [(ngModel)]="plans.plan_name" />
        <div class="mrgbtm" *ngIf="
            (formControls['plan_name'].dirty ||
              formControls['plan_name'].touched ||
              formError) &&
            formControls['plan_name'].errors
          ">
          <div class="errorMessage" *ngIf="formControls['plan_name'].errors.required">
            Plan name is required
          </div>
        </div>
      </div>
    </div>
    <div class="row mg-b-20">
      <div class="col-3 custom-label fc-black-2 head_type">
        Choose Partners:
      </div>
      <div class="col-9" style="text-align: left;display: flex;">
        <div class="CP_div">
          <ng-select id="partnerName" class="border-none owner" [multiple]="true" formControlName="partners"
            [(ngModel)]="selectedConnections" (ngModelChange)="onConnectionSelect($event)" [items]="partnersList"
            bindLabel="name" bindValue="id" placeholder="Select"></ng-select>
          <!-- <ss-multiselect-dropdown id="partnerName" class="form-control" formControlName="partners"
            [(ngModel)]="selectedConnections" (ngModelChange)="onConnectionSelect($event)" [options]="partnersList"
            [settings]="multiSelectionSettings" style="border: none !important;">
          </ss-multiselect-dropdown> -->
        </div>
        <div class="OK_div">
          <h5 class="data_field fz-12">OR</h5>
        </div>
        <div class="NP_div">
          <button type="button" id="partnerButton" (click)="newConnection()" class="btn new_partner_btn addbtn">
            NEW PARTNER
          </button>
        </div>
      </div>
      <div class="mrgbtm col-12  text-center" *ngIf="partner_error">
        <div class="errorMessage col-9" *ngIf="partner_error">
          Partner is required
        </div>
      </div>
    </div>
    <div class="row mg-b-20">
      <div class="col-3 custom-label fc-black-2 head_type">
        Target<sub class="required">*</sub>:
      </div>
      <div class="col-9" style="text-align: left;">
        <div class="d-flex">
          <!-- <h4 class="fz-16 fc-blue" style="margin-top: 6px;">$</h4> -->
          <input type="text" class="form-control fz-16 fc-blue" formControlName="target"
            placeholder="Enter Target Amount...." [(ngModel)]="plans.target" />
        </div>
        <div class="mrgbtm" *ngIf="
            (formControls['target'].dirty ||
              formControls['target'].touched ||
              formError) &&
            formControls['target'].errors
          ">
          <div class="errorMessage" *ngIf="formControls['target'].errors.required">
            Target is required
          </div>
          <div class="errorMessage" *ngIf="formControls['target'].errors.pattern">
            Target must be a valid number or decimal
          </div>
        </div>
      </div>
    </div>
    <div class="row mg-b-20">
      <div class="col-3 custom-label fc-black-2 head_type">
        Budget:
      </div>
      <div class="col-9" style="text-align: left;">
        <div class="d-flex">
          <!-- <h4 class="fz-16 fc-blue" style="margin-top: 6px;">$</h4> -->
          <input type="text" class="form-control fz-16 fc-blue" formControlName="budget"
            placeholder="Enter Budget Amount...." [(ngModel)]="plans.budget" />
        </div>
        <div class="mrgbtm" *ngIf="
            (formControls['budget'].dirty ||
              formControls['budget'].touched ||
              formError) &&
            formControls['budget'].errors
          ">
          <div class="errorMessage" *ngIf="formControls['budget'].errors.required">
            Budget is required
          </div>
          <div class="errorMessage" *ngIf="formControls['budget'].errors.pattern">
            Budget must be a valid number or decimal
          </div>
        </div>
      </div>
    </div>
    <div class="row mg-b-20">
      <div class="col-3 custom-label fc-black-2 head_type">
        Description<sub class="required">*</sub>:
      </div>
      <div class="col-9" style="text-align: left;">
        <textarea class="form-control fz-16 fc-blue" formControlName="description" placeholder="Enter Description...."
          [(ngModel)]="plans.description"></textarea>
        <div class="mrgbtm" *ngIf="
            (formControls['description'].dirty ||
              formControls['description'].touched ||
              formError) &&
            formControls['description'].errors
          ">
          <div class="errorMessage" *ngIf="formControls['description'].errors.required">
            Plan Description is required
          </div>
        </div>
      </div>
    </div>
    <div class="row mg-b-20">
      <div class="col-3 custom-label fc-black-2 head_type">
        Start Date<sub class="required">*</sub>:
      </div>
      <div class="col-9" style="text-align: left;">
        <div style="width:200px;">
          <input type="text" autocomplete="off" class="form-control fz-16 fc-blue" id="date1"
            placeholder="Plan Start date" style="height: 38px;" formControlName="start_date"
            [(ngModel)]="plans.start_date" /></div>
        <div class="mrgbtm" *ngIf="
          (formControls['start_date'].dirty ||
            formControls['start_date'].touched ||
            formError) &&
          formControls['start_date'].errors
        ">
          <div class="errorMessage" *ngIf="formControls['start_date'].errors.required">
            Start date is required
          </div>
        </div>
      </div>
    </div>
    <div class="row mg-b-20">
      <div class="col-3 custom-label fc-black-2 head_type">
        Duration:
      </div>
      <div class="col-9" style="text-align: left;">
        <div class="row" style="width: 450px;">
          <div class="col">
            <input type="radio" formControlName="duration" id="30" name="duration" value="30" />
            <label class="label fz-12" for="30">30 Days</label>
          </div>
          <div class="col">
            <input type="radio" formControlName="duration" id="90" name="duration" value="90"
              [(ngModel)]="plans.duration" />
            <label class="label fz-12" for="90">90 Days</label>
          </div>
          <div class="col">
            <input type="radio" formControlName="duration" id="183" name="duration" value="183"
              [(ngModel)]="plans.duration" />
            <label class="label fz-12" for="183">6 Months</label>
          </div>
          <div class="col">
            <input type="radio" formControlName="duration" id="365" name="duration" value="365"
              [(ngModel)]="plans.duration" />
            <label class="label fz-12" for="365">1 Year</label>
          </div>
        </div>
        <div class="mrgbtm" *ngIf="
            (formControls['duration'].dirty ||
              formControls['duration'].touched ||
              formError) &&
            formControls['duration'].errors
          ">
          <div class="errorMessage" *ngIf="formControls['duration'].errors.min">
            Plan Duration is required
          </div>
        </div>
      </div>
    </div>
    <div class="row mg-b-20">
      <div class="col-3 custom-label fc-black-2 head_type">
        Team Members<sub class="required">*</sub>:
      </div>
      <div class="col-9" style="text-align: left;display:flex;">
        <div style="margin-right: 10px;">
          <ng-select id="teams" class="border-none owner" [multiple]="true" formControlName="teams"
            [(ngModel)]="selectedTeamMembers" [items]="teamList" bindLabel="name" bindValue="id"
            placeholder="Display Name"></ng-select>
          <!-- <ss-multiselect-dropdown id="teams" [options]="teamList" [texts]="multiSelectionTexts"
            [(ngModel)]="selectedTeamMembers" [settings]="multiSelectionSettings" formControlName="teams">
          </ss-multiselect-dropdown> -->
        </div>
        <div>
          <a style="cursor: pointer;font-size: 10px;" (click)="openTeam()" class="data_field_a"><i class="fa fa-plus"
              aria-hidden="true" style="color: #44a2f8;font-size: 24px;"></i>
            <span
              style="position: absolute;bottom:32px;width:fit-content">{{this.invitedTeam.length < 1 ? "Invite" : this.invitedTeam.length + " invited"}}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="row mrgbtm">
    <div class="col-md-12">
      <div align="center">
        <button (click)="goBack()" type="button" class="btn cnclbtn">
          CANCEL
        </button>
        <button *ngIf="!planId" (click)="openConfirmPopup()" type="button" class="btn addbtn">
          CREATE PLAN
        </button>
        <button *ngIf="planId" (click)="openConfirmPopup()" type="button" class="btn addbtn">
          UPDATE PLAN
        </button>
      </div>
    </div>
  </div>
</form>
<div class="modal" id="teamModal">
  <div class="modal-dialog  teams-modal modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal body -->
      <div class="modal-body" style="text-align: center; display: inline; padding: 0;">
        <app-add-team [onlySuperUsers]="false" [teamList]="teamList" [meData]="userDataPartners"
          [selfPartners]="selfPartners" [domainList]="this.domainList" [partner_owner_email]="this.userPartnerEmail"
          (submitForm)="saveTeam($event)" (closeModal)="closeTeam()" (domainMismatch)="domainChange($event)">
        </app-add-team>
      </div>
    </div>
  </div>
</div>
<div class="modal" id="confirmPopup">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal body -->
      <div class="modal-body" style="text-align: center; display: inline;">
        <h5 class="modal-title mrgbtm">Are You Sure?</h5>
        <p *ngIf="!planId">All invitees can access Plan, on confirmation</p>
        <p *ngIf="planId">All changes will be saved, on confirmation</p>
      </div>
      <!-- Modal footer -->
      <div class="modal-footer" style="text-align: center; display: inline;">
        <button type="button" (click)="closeConfirmPopup()" class="btn cnclbtn">
          CANCEL
        </button>

        <button type="button" class="btn addbtn" (click)="submit()">
          CONFIRM
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal" id="successModal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal body -->
      <div *ngIf="displayResult == 'success'" class="modal-body" style="text-align: center; display: inline;">
        <div class="row mrgbtmn">
          <div class="col-md-12">
            <h5 *ngIf="!planId" class="modal-title mrgbtm">Plan Created!</h5>
            <h5 *ngIf="planId" class="modal-title mrgbtm">Plan Updated!</h5>
            <p *ngIf="invitedMembers.length > 0">
              Invites will be sent to
              <span *ngFor="let member of invitedMembers; index as i">
                {{ member.email
                }}{{ i < invitedMembers.length - 1 ? "," : "." }}
              </span>
            </p>
          </div>
        </div>
        <div class="row mrgbtm">
          <div class="col-md-12">
            <div align="center">
              <button (click)="this.goBack()" type="button" class="btn addbtn">
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="displayResult == 'failure'" class="modal-body" style="text-align: center; display: inline;">
        <div>
          <div class="row mrgbtm">
            <div class="col-md-12">
              <div align="center" class="errorMessage">
                {{ errorMessage }}
              </div>
            </div>
          </div>
          <div class="row mrgbtm">
            <div class="col-md-12">
              <div align="center">
                <button (click)="closeModal()" type="button" class="btn addbtn">
                  CLOSE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="displayResult == ''" class="modal-body" style="text-align: center; display: inline;">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <p *ngIf="!planId">Creating a Plan...</p>
        <p *ngIf="planId">Updating a Plan...</p>
      </div>
    </div>
  </div>
</div>