<!-- //********** show loader till table data is fetched *********// -->
<div class="row" *ngIf="!teamsList || teamsList.length == 0" style="height: 100%">
  <div class="col-12" style="height: 100%">
    <div style="height: 100%" class="d-flex justify-content-center align-items-center">
      <div *ngIf="!teamsList" class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div *ngIf="teamsList && teamsList.length == 0">
        <div class="row mrgbtm">
          <div class="col-md-12">
            <div align="center" class="errorMessage" style="color: #4292c6">
              No Team Members to Display. Add Team Member.
            </div>
          </div>
        </div>
        <div class="row mrgbtm">
          <div class="col-md-12">
            <div align="center">
              <button
                *ngIf="!userData.subscription_data && userData.partner && userData.email==userData.partner.partner_owner"
                [routerLink]="['/create-team']" type="button" class="btn addbtn">
                Add New Team Member
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- //********** Show table after table data is fetched *********// -->

<div class="table_margin_top" *ngIf="teamsList && teamsList.length > 0">
  <div class="row">
    <div class="col-md-8 col-12">
      <h3></h3>
    </div>
    <div class="col-md-4 col-12">
      <div class="tbl_in_icon">
        <a href=""
          *ngIf="!userData.subscription_data && userData.partner && userData.email==userData.partner.partner_owner"
          [routerLink]="['/create-team']">
          <i class="fa fa-plus-circle fc-blue" aria-hidden="true" style="color: gray"></i>
        </a>
        <i class="fa fa-filter" aria-hidden="true"></i>
        <i class="fa fa-cog" aria-hidden="true"></i>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="pad_div">
        <div class="maintbl">
          <table class="table solntbl">
            <thead class="thead-dark">
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Partner Owner</th>
                <th scope="col">Partner</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let eachCustomer of teamsList; index as i">
                <!--(click)="enableUpdate(eachCustomer)"-->
                <td>
                  <span class="fc-blue fz-16">{{
                    eachCustomer.name || "None"
                  }}</span>
                </td>
                <td>
                  <span class="fc-blue fz-16">{{
                    eachCustomer.email || "None"
                  }}</span>
                </td>
                <td>
                  <span class="fc-blue fz-16">{{
                    eachCustomer.partner_owner
                  }}</span>
                </td>
                <td>
                  <span class="fc-blue fz-16">{{
                    eachCustomer.partner_name
                  }}</span>
                </td>
                <!--  -->
                <td>
                  <a *ngIf="(userData.subscription_data || userData.email==userData.partner.partner_owner)&& userData.email!=eachCustomer.email"
                    [ngClass]="" href="javascript:;" (click)="openDeleteModal(eachCustomer)"><span
                      _ngcontent-bcv-c147="" class="material-icons">
                      delete
                    </span></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div class="modal" id="AddCustomer" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-dialog-centered" style="width: 38%;">
    <div class="modal-content">
      <div class="modal-body">
        <app-add-team [defaultCustomer]="selectedCustomer" [callBack]="closeModal.bind(this)"></app-add-team>
      </div>
    </div>
  </div>
</div> -->

<div id="deleteModal" class="modal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row mrgbtmn" style="color: #e61610">
          <div class="col">
            <h4>
              <span class="material-icons" style="color: #e61610; position: relative; bottom: -3px">
                report_problem
              </span>
              Are you sure?
            </h4>
          </div>
        </div>

        <div class="row mrgbtmn" style="color: #e61610">
          <div class="col">
            <h6>
              {{ this.selectedUserEmail | titlecase }} will be deactivated and
              this user will no longer be able to access plans, solutions,
              opportunities or activities shared with them.
            </h6>
          </div>
        </div>
        <div></div>
      </div>
      <!-- Modal footer -->
      <div class="modal-footer" style="text-align: center; display: inline">
        <button type="button" (click)="closeDeleteModal()" class="btn cnclbtn">
          CANCEL
        </button>

        <button type="button" class="btn addbtn" (click)="deleteUser()">
          DELETE USER
        </button>
      </div>
    </div>
  </div>
</div>