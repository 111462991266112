import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
// import { Customer } from '../models/customer.model';
import { Connection } from '../models/connection.model';
import { AuthService } from '../services/auth.service';
import { LocalStorageService } from '../services/local-storage.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import * as moment from 'moment';

declare var $: any;
@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})
export class CustomerComponent implements OnInit {
  @Input() onlyAddForm: boolean;
  customersList: any;
  userData: any = {};
  defaultCustomer: any;
  constructor(
    private formBuilder: FormBuilder,
    private AuthService: AuthService,
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute,
    private location: Location
  ) {
    this.userData = this.localStorageService.get({ name: 'userData' })
    this.userData = this.userData ? JSON.parse(this.userData) : {};
  }
  //********** fetch connections ********//
  connections: any;
  getConnections() {
    return new Promise((resolve, reject) => {
      this.AuthService.get('partners').subscribe((result) => {
        resolve(result.data);
      },
        (error) => {
          console.log(error.error.meta.message)
          resolve((<any>[]));
        });
    })
  }
  //*********** fetch table data ***********//
  getCustomers() {
    return new Promise((resolve, reject) => {
      this.AuthService.get('customer').subscribe(
        (result) => {
          let list = [];
          if (result.data) {
            list = result.data;
          }
          resolve(list)
          setTimeout(() => {
            $('.ui').dropdown();
          }, 100)
        },
        (error) => {
          console.log(error);
          resolve([]);
        })
    })
  }
  ngOnInit(): void {
    this.initializePage();
  }
  getPartnerIndexes(arr, val) {
    var indexes = [], i;
    for (i = 0; i < arr.length; i++)
      if (arr[i].partner_id === val)
        indexes.push(i);
    return indexes;
  }
  getUserIndexes(arr, val) {
    var indexes = [], i;
    for (i = 0; i < arr.length; i++)
      if (arr[i] === val)
        indexes.push(i);
    return indexes;
  }
  async initializePage() {
    // this.connections = await this.getConnections();
    await this.getCustomers().then((result: any) => {

      this.customersList = result;
    })
    this.route.params.subscribe((a) => {
      if (a.customerId) {
        this.defaultCustomer = a.customerId;
        this.location.replaceState('customer');
      }
    })
  }
  selectedCustomer: any = {};
  enableUpdate(updateData) {
    this.selectedCustomer = updateData;
    setTimeout(() => {
      $('#AddCustomer').modal('show');
    }, 100)
  }
}
