import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { CommonEmitterService } from "./common-emitter";
import { LocalStorageService } from "./local-storage.service";


const uploadHttpOptions = {
  withCredentials: false
};

@Injectable({ providedIn: "root" })
export class AuthService {
  private httpOptions = {};

  baseUrl = "/api/v1/"; // URL to web api for dev and stage environments
  showLoginOnSessionOut = false;
  constructor(
    private http: HttpClient,
    private LocalStorageService: LocalStorageService,
    private Router: Router,
    private CommonEmitterService: CommonEmitterService
  ) {
    if (window.location.href.indexOf('localhost:4300') != -1) {
      this.baseUrl = 'http://localhost:8000/api/v1/'
      // this.baseUrl = 'https://dev.flyero.com/api/v1/';
    }
    if (this.LocalStorageService.get({ name: 'partnerOwners' })) {
      this.savePartners(JSON.parse(this.LocalStorageService.get({ name: 'partnerOwners' })))
    }
  }

  getHeaders() {
    return {
      headers: new HttpHeaders({
        // "Content-Type": "application/json",
        Authorization:
          "Bearer " + this.LocalStorageService.get({ name: "accessToken" })
      }),
      withCredentials: true
    };
  }
  getUploadHeaders() {
    return {
      headers: new HttpHeaders({
        "Content-Type": 'multipart/form-data',
        Authorization:
          "Bearer " + this.LocalStorageService.get({ name: "accessToken" })
      }),
      withCredentials: true
    };
  }
  /** GET data from the server */
  get(url, errCallback = null) {
    return this.http.get(this.baseUrl + url).pipe(
      tap(data => data),
      catchError(errCallback || this.handleError("getdata", []))
    );
  }
  getThirdPartyData(url, errCallback = null) {
    this.CommonEmitterService.sendMessage("loading", "show");
    return this.http.get(url).pipe(
      catchError(errCallback || this.handleError("getdata", []))
    );
  }

  /** POST: add a new data to the server */
  add(url, data, errCallback = null) {

    return this.http.post(this.baseUrl + url, data);
  }
  upload(url, data, errCallback = null) {

    return this.http.post(this.baseUrl + url, data, this.getUploadHeaders());
  }
  /** DELETE: delete the data from the server */
  delete(url, data) {
    return this.http.request('delete', this.baseUrl + url, { body: data });
  }

  /** PUT: update the data on the server */
  update(url, data): Observable<any> {
    delete data["createdAt"];
    delete data["updatedAt"];

    return this.http.put(this.baseUrl + url, data);
  }

  /** GET data from the server */
  raw(method, url, data): Observable<any> {
    return this.http[method](url, data);
  }

  // /** GET multiple data from the server */
  // forkJoin(callList) {
  //     let requestCallList = [];
  //     callList.forEach(element => {
  //         let method = element.method;
  //         requestCallList.push(this.http[element.method](this.baseUrl + element.url, element.data, httpOptions).pipe(
  //             tap(data => this.log(`fetched data`)),
  //             catchError(this.handleError('getdata', []))
  //         ));
  //     });
  //     return forkJoin(requestCallList);
  // }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = "operation", result?: T) {
    console.log(operation, result)
    return (error: any): Observable<T> => {
      this.CommonEmitterService.sendMessage("loading", "hide");
      if (error.status == 401 || error.status == 403) {
        //set popup here..
        this.CommonEmitterService.sendMessage("showLogOutPage", true);
        this.Router.navigate(["/sign-in"]);
      } else
        // TODO: send the error to remote logging infrastructure
        if ((error.status != 404 || error.status != 409) && error.error.meta) {
          // alert(error.error.meta.message);
          // this.LocalStorageService.remove({ name: 'accessToken' });
          // this.Router.navigate(['/sign-in'])
        }
      // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return null; // of(error as T) // of(error as T);
    };
  }

  isLoggedIn() {
    if (this.LocalStorageService.get({ name: "accessToken" }) != null) {
      return true;
    } else {
      return false;
    }
  }
  partner_owners: any[];
  savePartners(partnerOwners) {
    return new Promise((resolve, reject) => {
      this.partner_owners = partnerOwners;
      resolve(true);
    })
  }
  isAdmin() {
    return new Promise(resolve => {
      this.get('user/me').subscribe((result) => {
        resolve(result.data[0].admin_user)
      }, (error) => {
        resolve(false)
      })
    })
  }
  getPartnerOwners() {
    return this.partner_owners;
  }
  /** Log a dataService message with the MessageService */
  private log(message: string) {
    console.log("LOG: " + message);
  }
}
