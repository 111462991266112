import { AfterViewInit, Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { NavigationHelperService } from '../services/navigation-helper.service';
import * as moment from "moment";
import { LocalStorageService } from '../services/local-storage.service';
import { IMultiSelectTexts, IMultiSelectSettings } from 'angular-2-dropdown-multiselect';
import { Location } from '@angular/common';
import { CommonEmitterService } from '../services/common-emitter';
import { Subscription } from 'rxjs';
declare var $: any
declare var Highcharts: any;
@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.css']
})
export class DefaultComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('planSort', { static: false }) planSort: MatSort;
  @ViewChild('planPaginator', { static: false }) planPaginator: MatPaginator;
  @ViewChild('solutionSort', { static: false }) solutionSort: MatSort;
  @ViewChild('solutionPaginator', { static: false }) solutionPaginator: MatPaginator;
  @ViewChild('opportunitySort', { static: false }) opportunitySort: MatSort;
  @ViewChild('opportunityPaginator', { static: false }) opportunityPaginator: MatPaginator;
  plans: any[];
  solutions: any[];
  opportunities: any[]
  barSeries: any = [];
  categories: any = [];
  Dates: any = [];
  milisecondInDay: any = 86400000
  userData: any = {};
  userPartnerId: any;
  filterData: any = {}
  users: any = {
    plan: [],
    solution: [],
    opportunity: [],
  };
  items: any = {
    plan: [],
    solution: [],
    opportunity: [],
  };
  partners: any = {
    plan: [],
    solution: [],
    opportunity: [],
  };
  createdBy: any = {
    plan: [],
    solution: [],
    opportunity: [],
  };
  multiSelectionSettings: IMultiSelectSettings = {
    enableSearch: true,
    checkedStyle: "checkboxes",
    buttonClasses: "btn btn-default fz-14 fc-blue mw-200",
    dynamicTitleMaxItems: 0
  };
  defaultTable: any;
  loadedData = { plans: false, solutions: false, opportunities: false };
  refreshSubscription: Subscription;
  constructor(private router: Router,
    private route: ActivatedRoute,
    private AuthService: AuthService,
    private location: Location,
    private navHelper: NavigationHelperService,
    private CommonEmitterService: CommonEmitterService,
    private localStorageService: LocalStorageService) {
    if (this.navHelper.navigationTracker.length == 0) {
      this.navHelper.setUrl(this.router.url)
    }
    this.userData = this.localStorageService.get({ name: 'userData' })
    this.userData = this.userData ? JSON.parse(this.userData) : {}
    if (this.userData.partner) {
      this.userPartnerId = this.userData.partner.partner_id;
    }
    this.route.params.subscribe((a) => {
      if (a.selectedTable) {
        this.defaultTable = a.selectedTable;
        this.location.replaceState('dashboard');
      }
    })
    let today = new Date()
    let q1 = this.getQuarterStartEndDate(today);
    this.Dates.push({
      name: 'Q' + moment(q1.firstDate).quarter() + 'FY' + moment(q1.firstDate).format('YY'),
      dates: [q1.firstDate, q1.endDate]
    })
    q1 = this.getQuarterStartEndDate(new Date(q1.endDate.getTime() + (85 * this.milisecondInDay)));
    this.Dates.push({
      name: 'Q' + moment(q1.firstDate).quarter() + 'FY' + moment(q1.firstDate).format('YY'),
      dates: [q1.firstDate, q1.endDate]
    })
    q1 = this.getQuarterStartEndDate(new Date(q1.endDate.getTime() + (85 * this.milisecondInDay)));
    this.Dates.push({
      name: 'Q' + moment(q1.firstDate).quarter() + 'FY' + moment(q1.firstDate).format('YY'),
      dates: [q1.firstDate, q1.endDate]
    })
    this.getPartners();
    this.getUsers();
    this.refreshSubscription = this.CommonEmitterService.pageRefresh.subscribe((val) => {
      this.reload();
    })
  }
  totalTargetValue = { plan: '', solution: '', opportunity: '' }
  public planData = new MatTableDataSource<any>();
  public planColumns = ['id', 'plan_name', 'partners', 'target', 'achievement', 'oppy'];
  public solutionData = new MatTableDataSource<any>();
  public solutionColumns = ['id', 'solution_name', 'solution_stage', 'revenue', 'archivement', 'oppy'];
  public opportunityData = new MatTableDataSource<any>();
  public opportunityColumns = ['id', 'name', 'users', 'value', 'stage', 'deal_status'];
  selectedTable: string = 'plans'
  graph: any = {};
  timer: any;
  ngOnInit(): void {
    if (!this.AuthService.isLoggedIn()) {
      this.router.navigate(['/sign-in']);
    }
    this.plans = [];
    // this.jointPlan()
    this.solutions = [];
    // this.jointSolution();
    this.opportunities = [];
    this.getStagesData();
    this.getOpportunities();
    // this.loadedData.plans = true;
    // this.loadedData.solutions = true;
    // this.loadedData.opportunities = true;
    // this.timer = setInterval(() => { this.getOpportunities(); }, 30000)
    this.datePickerApply();
  }
  sortData(e) {
    console.log(e);
    if (e.active == 'achievement1') {
      let data = this.solutionData.data.sort((a: any, b: any) => {
        var keyA = a.archivement,
          keyB = b.archivement;
        // console.log(keyA,keyB)
        // Compare the 2 dates
        if (e.direction != 'asc') {
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
        } else {
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
        }
        return 0;
      });
      console.log(data.map((a) => a.archivement))
      this.solutionData.data = data;
    }
  }
  filter() {
    if (this.selectedTable == 'plans') {
      this.processGraphData(this.plans.filter((aa) => {
        let status = true;
        if (this.filterData.start_date) {
          // if ((new Date(aa.start_date).getTime() < new Date(this.filterData.start_date).getTime()) || (new Date(aa.end_date).getTime() < new Date(this.filterData.start_date).getTime())) {
          if (new Date(aa.end_date).getTime() <= new Date(moment(this.filterData.start_date).startOf('day').format('YYYY-MM-DD h:mm:ss')).getTime()) {
            status = false;
          }
        }
        if (this.filterData.end_date) {
          // if ((new Date(aa.start_date).getTime() > new Date(this.filterData.end_date).getTime()) || (new Date(aa.end_date).getTime() > new Date(this.filterData.end_date).getTime())) {
          if (new Date(aa.start_date).getTime() >= new Date(moment(this.filterData.end_date).endOf('day').format('YYYY-MM-DD h:mm:ss')).getTime()) {
            status = false;
          }
        }
        if (this.filterData.team && this.filterData.team.length) {
          let stat = false;
          aa.team.map((aaa) => {
            if (this.filterData.team.indexOf(aaa.email) != -1) {
              stat = true;
            }
          })
          if (!stat) {
            status = false;
          }
        }
        if (this.filterData.createdBy && this.filterData.createdBy.length) {
          let stat = false;
          if (this.filterData.createdBy.indexOf(aa.created_by) != -1) {
            stat = true;
          }
          if (!stat) {
            status = false;
          }
        }
        if (this.filterData.partner && this.filterData.partner.length) {
          let stat = false;
          aa.partners.map((aaa) => {
            if ((this.filterData.partner.length == 1) && (this.filterData.partner.indexOf(this.userPartnerId) != -1)) {
              if ((aa.partners.length == 1) && (aa.partners.indexOf(this.userPartnerId) != -1)) {
                stat = true;
              }
            } else {
              if (this.filterData.partner.indexOf(aaa) != -1) {
                stat = true;
              }
            }
          })
          if (!stat) {
            status = false;
          }
        }
        if (this.filterData.plan && this.filterData.plan.length) {
          let stat = false;
          if (this.filterData.plan.indexOf(aa.id) != -1) {
            stat = true;
          }
          if (!stat) {
            status = false;
          }
        }
        return status;
      }), 'plan')
    } else if (this.selectedTable == 'solutions') {
      this.processGraphData(this.solutions.filter((aa) => {
        let status = true;
        if (this.filterData.start_date) {
          if (new Date(moment(aa.start_date).startOf('day').format('YYYY-MM-DD h:mm:ss')).getTime() < new Date(moment(this.filterData.start_date).startOf('day').format('YYYY-MM-DD h:mm:ss')).getTime()) {
            status = false;
          }
        }
        // console.log(this.filterData, moment(this.filterData.start_date).startOf('day').format('YYYY-MM-DD h:mm:ss'), moment(this.filterData.end_date).endOf('day').format('YYYY-MM-DD h:mm:ss'), moment(aa.start_date).format('YYYY-MM-DD h:mm:ss'), (new Date(aa.start_date).getTime() < new Date(moment(this.filterData.start_date).startOf('day').format('YYYY-MM-DD h:mm:ss')).getTime()), (new Date(aa.start_date).getTime() > new Date(moment(this.filterData.end_date).endOf('day').format('YYYY-MM-DD h:mm:ss')).getTime()));
        if (this.filterData.end_date) {
          if (new Date(moment(aa.start_date).endOf('day').format('YYYY-MM-DD h:mm:ss')).getTime() > new Date(moment(this.filterData.end_date).endOf('day').format('YYYY-MM-DD h:mm:ss')).getTime()) {
            status = false;
          }
        }
        if (this.filterData.team && this.filterData.team.length) {
          let stat = false;
          aa.team.map((aaa) => {
            if (this.filterData.team.indexOf(aaa.email) != -1) {
              stat = true;
            }
          })
          if (!stat) {
            status = false;
          }
        }
        if (this.filterData.plan && this.filterData.plan.length) {
          let stat = false;
          if (this.filterData.plan.indexOf(aa.id) != -1) {
            stat = true;
          }
          if (!stat) {
            status = false;
          }
        }
        if (this.filterData.createdBy && this.filterData.createdBy.length) {
          let stat = false;
          if (this.filterData.createdBy.indexOf(aa.created_by) != -1) {
            stat = true;
          }
          if (!stat) {
            status = false;
          }
        }
        if (this.filterData.partner && this.filterData.partner.length) {
          let stat = false;
          aa.partners.map((aaa) => {
            if ((this.filterData.partner.length == 1) && (this.filterData.partner.indexOf(this.userPartnerId) != -1)) {
              if ((aa.partners.length == 1) && (aa.partners.indexOf(this.userPartnerId) != -1)) {
                stat = true;
              }
            } else {
              if (this.filterData.partner.indexOf(aaa) != -1) {
                stat = true;
              }
            }
          })
          if (!stat) {
            status = false;
          }
        }
        return status;
      }), 'solution');
    } else {
      // console.log(this.opportunities);
      this.processGraphData(this.opportunities.filter((aa) => {
        let status = true;
        if (this.filterData.start_date) {
          if (new Date(moment(aa.close_date).startOf('day').format('YYYY-MM-DD h:mm:ss')).getTime() < new Date(moment(this.filterData.start_date).startOf('day').format('YYYY-MM-DD h:mm:ss')).getTime()) {
            status = false;
          }
        }
        if (this.filterData.end_date) {
          if (new Date(moment(aa.close_date).endOf('day').format('YYYY-MM-DD h:mm:ss')).getTime() > new Date(moment(this.filterData.end_date).endOf('day').format('YYYY-MM-DD h:mm:ss')).getTime()) {
            status = false;
          }
        }
        if (this.filterData.createdBy && this.filterData.createdBy.length) {
          let stat = false;
          if (this.filterData.createdBy.indexOf(aa.created_by) != -1) {
            stat = true;
          }
          if (!stat) {
            status = false;
          }
        }
        if (this.filterData.team && this.filterData.team.length) {
          let stat = false;
          aa.users.map((aaa) => {
            if (this.filterData.team.indexOf(aaa.email) != -1) {
              stat = true;
            }
          })
          if (!stat) {
            status = false;
          }
        }
        if (this.filterData.plan && this.filterData.plan.length) {
          let stat = false;
          if (this.filterData.plan.indexOf(aa.id) != -1) {
            stat = true;
          }
          if (!stat) {
            status = false;
          }
        }
        if (this.filterData.partner && this.filterData.partner.length) {
          let stat = false;
          console.log('#####', aa.partnerDetails, this.filterData.partner)
          aa.partnerDetails.map((aaa) => {
            if ((this.filterData.partner.length == 1) && (this.filterData.partner.indexOf(this.userPartnerId) != -1)) {
              if ((aa.partnerDetails.length == 1) && (aa.partnerDetails.filter((a11) => a11.partner_id == this.userPartnerId).length)) {
                stat = true;
              }
            } else {
              if (this.filterData.partner.indexOf(aaa.partner_id) != -1) {
                stat = true;
              }
            }
          })
          if (!stat) {
            status = false;
          }
        }
        return status;
      }), 'opportunity')
    }
    $('#filterModal').modal('hide')
  }
  resetFilter() {
    this.filterData = {};
    $('#date1').val('');
    $('#date2').val('');
    this.datePicker1.destroy();
    this.datePicker2.destroy();
    this.datePickerApply();
  }
  datePickerApply() {
    setTimeout(() => {
      this.datePicker1 = $('#date1').datepicker({
        format: 'mm/dd/yyyy',
        close: (e) => {
          let date = $('#date1').val()
          this.filterData.start_date = date;
        }
      });
      this.datePicker2 = $('#date2').datepicker({
        format: 'mm/dd/yyyy',
        close: (e) => {
          let date = $('#date2').val()
          this.filterData.end_date = date;
        }
      });
    }, 100);
  }
  datePicker1: any;
  datePicker2: any;
  ngAfterViewInit(): void {
    // this.genChart();
    this.planData.sort = this.planSort;
    this.planData.paginator = this.planPaginator;
    this.solutionData.sort = this.solutionSort;
    this.solutionData.paginator = this.solutionPaginator
    this.opportunityData.sort = this.opportunitySort;
    this.opportunityData.paginator = this.opportunityPaginator
  }
  connectionRedirect(path: string) {
    $('#zeroObjects').modal('hide');
    this.router.navigate([`/${path}`])
  }
  redirect() {
    this.router.navigate(['create-connection'])
  }
  planGraphData: any = [];
  planBarData: any = [{
    name: 'Target',
    data: [0, 0, 0]

  }, {
    name: 'Achievement',
    data: [0, 0, 0]

  }];
  jointPlan() {
    this.AuthService.get('plans', (err) => {
    }).subscribe(async (result: any) => {
      console.log("result is:", result.data)
      this.loadedData.plans = true;
      if (result.data) {
        this.plans = (result.data || []).filter(plan => (!plan.email || plan.email == "")).map((a) => {
          if (a.partnerDetails) {
            a.partnerName = a.partnerDetails.filter((aa) => aa.partner_id != this.userPartnerId).map((aa) => aa.partner_name)
          }
          return a;
        }).sort((a: any, b: any) => {
          var keyA = new Date(a.updated_at || a.created_at || 0),
            keyB = new Date(b.updated_at || b.created_at || 0);
          if (keyA > keyB) return -1;
          if (keyA < keyB) return 1;
          return 0;
        })
        this.plans.forEach((plan) => {
          this.items.plan.push({ id: plan.id, name: plan.plan_name })
          plan.partnerDetails.map((aa) => {
            if (!this.partners.plan.filter((a11) => a11.id == aa.partner_id).length) {
              // let partner = this.allPartners.find((a) => a.partner_id == aa)
              this.partners.plan.push({ id: aa.partner_id, name: aa.partner_id != this.userData.partner.partner_id ? aa.partner_name : 'Internal' })
            }
          })
          this.allUsers.forEach((user) => {
            if (!this.createdBy.plan.filter((a11) => a11.id == plan.created_by).length) {
              if (plan.created_by == user.user_id) {
                this.createdBy.plan.push({ id: user.user_id, name: user.email })
              }
            };
          })
          if (Array.isArray(plan.team)) {
            plan.team.map((aa) => {
              if (!this.users.plan.filter((a11) => a11.id == aa.email).length) {
                this.users.plan.push({ id: aa.email, name: aa.email })
              }
            })
          }
        })
        this.processGraphData(this.plans, 'plan');
        this.datePickerApply();
      } else {
        this.plans = [];
        this.datePickerApply();
      }
    }, (err: any) => {
      this.plans = [];
      this.loadedData.plans = true;
      this.changeTable();
      this.datePickerApply();
    }, () => {
      this.changeTable();
    });
  }
  dealStatus: any[] = [];
  dealStages: any[] = [];
  getStagesData() {
    this.AuthService.get('custom_data', () => { }).subscribe((result) => {
      this.dealStatus = result.data.opportunity_deals;
      this.dealStages = result.data.opportunity_stages;
    })
  }
  processGraphData(data, type) {
    if (type == 'plan') {
      let totalTarget: any = 0;
      let totalAchievement: any = 0;
      let remainingAchievement: any = 0;
      this.planBarData = [{
        name: 'Target',
        data: [0, 0, 0]
      }, {
        name: 'Achievement',
        data: [0, 0, 0]
      }];
      data = data.map((plan) => {
        totalTarget = totalTarget + Number(plan.target);
        let count = 0;
        let planAchievement = 0;
        let trarget = 0, archivement = 0, total = 0;
        this.opportunities.filter((a) => a.plan_id.indexOf(plan.id) != -1).map((aa) => {
          if (aa.deal_status != 'Lost') {
            count = count + 1;
          }
          let dt = new Date(aa.close_date)
          if (aa.stage == 'Closed' && aa.deal_status == 'Won') {
            totalAchievement = totalAchievement + Number(aa.value)
            planAchievement = planAchievement + Number(aa.value);
            archivement = archivement + Number(aa.value);
            let j = 0;
            this.Dates.map((aa1) => {
              if (dt.getTime() >= aa1.dates[0] && dt.getTime() <= aa1.dates[1]) {
                this.planBarData[1].data[j] = Number(this.planBarData[1].data[j]) + Number(aa.value);
              }
              j++;
            });
          }
          let i = 0;
          this.Dates.map((aa1) => {
            if (dt.getTime() >= aa1.dates[0] && dt.getTime() <= aa1.dates[1]) {
              this.planBarData[0].data[i] = Number(this.planBarData[0].data[i]) + Number(aa.value);
            }
            i++;
          });
        })
        plan.oppy = count;
        plan.achievement = planAchievement;
        plan.archivement = archivement;
        return plan;
      })
      this.planData.data = data;
      this.categories = this.Dates.map((a) => a.name)
      this.totalTargetValue.plan = totalTarget;
      remainingAchievement = totalTarget - totalAchievement
      remainingAchievement = remainingAchievement > 0 ? remainingAchievement : 0
      this.planGraphData = [];
      this.planGraphData.push(["Achieved Target", totalAchievement], ["Remaining Target", remainingAchievement])
      console.log('planData', this.planGraphData, this.planBarData);
      if (totalAchievement != 0 || remainingAchievement != 0) {
        this.graph.plan = true;
        setTimeout(() => {
          this.genChart('pie-1')
        }, 100)
      } else {
        this.graph.plan = false;
      }
      setTimeout(() => {
        console.log(this.planBarData);
        this.genChart('bar-1')
      }, 100)
    }
    if (type == "opportunity") {
      console.log(data)
      this.opportunityData.data = data.map((record) => {
        record.partners = record.partners.filter((a) => a != this.userData.partner.partner_id)
        return record;
      }).map((a) => {
        if (a.partnerDetails) {
          a.partnerName = a.partnerDetails.filter((aa) => aa.partner_id != this.userPartnerId).map((aa) => aa.partner_name)
        }
        return a;
      }).sort((a: any, b: any) => {
        var keyA = new Date(a.updated_at || a.created_at || 0),
          keyB = new Date(b.updated_at || b.created_at || 0);
        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;
        return 0;
      })
      let totalTarget: any = 0;
      let totalAchievement: any = 0;
      let remainingAchievement: any = 0;
      this.opportunityGraphData = [];
      this.opportunityBarData = [{
        name: 'Target',
        data: [0, 0, 0]
      }, {
        name: 'Achievement',
        data: [0, 0, 0]
      }];
      data.map((aa) => {
        let exist = this.opportunityGraphData.find((a) => a[0] == aa.stage)
        if (exist) {
          if (aa.deal_status != 'Lost') {
            this.opportunityGraphData = this.opportunityGraphData.map((a) => {
              if (a[0] == aa.stage) {
                a[1] = (a[1] || 0) + Number(aa.value)
              }
              return a;
            })
          }
        } else {
          if (aa.deal_status != 'Lost') {
            this.opportunityGraphData.push([aa.stage, Number(aa.value)])
          }
        }
        let dt = new Date(aa.close_date)
        if (aa.deal_status != 'Lost')
          totalTarget = totalTarget + Number(aa.value)
        if (aa.stage == 'Closed' && aa.deal_status == 'Won') {
          totalAchievement = totalAchievement + Number(aa.value)
          let j = 0;
          this.Dates.map((aa1) => {
            if (dt.getTime() >= aa1.dates[0] && dt.getTime() <= aa1.dates[1]) {
              this.opportunityBarData[1].data[j] = Number(this.opportunityBarData[1].data[j]) + Number(aa.value);
            }
            j++;
          });
        }
        let i = 0;
        this.Dates.map((aa1) => {
          if (dt.getTime() >= aa1.dates[0] && dt.getTime() <= aa1.dates[1]) {
            this.opportunityBarData[0].data[i] = Number(this.opportunityBarData[0].data[i]) + Number(aa.value);
          }
          i++;
        });
      })
      this.categories = this.Dates.map((a) => a.name)
      this.totalTargetValue.opportunity = totalTarget;
      remainingAchievement = totalTarget - totalAchievement
      let gdata = [];
      for (let i = 0; i < this.opportunityGraphData.length; i++) {
        let st = this.opportunityGraphData[i];
        let stage = this.dealStages.find(stage => stage.label == st[0])
        if (stage.level) {
          if (gdata[stage.level]) {
            gdata[stage.level][1] = gdata[stage.level][1] + Number(st[1])
          } else {
            gdata[stage.level] = [];
            if (stage.level == 1) {
              gdata[stage.level][0] = 'Prospect';
              gdata[stage.level][1] = Number(st[1]);
            } else if (stage.level == 2) {
              gdata[stage.level][0] = 'Qualified';
              gdata[stage.level][1] = Number(st[1]);
            } else {
              gdata[stage.level][0] = 'Closed';
              gdata[stage.level][1] = Number(st[1]);
            }
          }
        }
      }
      this.opportunityGraphData = gdata.filter((a) => a);
      // this.opportunityGraphData.push(["Achieved Target", totalAchievement], ["Remaining Target", remainingAchievement])
      console.log('opportunityData', this.opportunityGraphData, this.opportunityBarData, totalAchievement, remainingAchievement);
      if (this.opportunityGraphData.length) {
        this.graph.opportunity = true;
        setTimeout(() => {
          this.genChart('pie-3')
        }, 100)
      } else {
        this.graph.opportunity = false;

      }
      setTimeout(() => {
        this.genChart('pie-3')
      }, 100)

      setTimeout(() => {
        console.log(this.opportunityBarData);
        this.genChart('bar-3')
      }, 100)
    }
    if (type == "solution") {
      let totalTarget: any = 0;
      let totalAchievement: any = 0;
      let remainingAchievement: any = 0;
      this.solutionBarData = [{
        name: 'Target',
        data: [0, 0, 0]
      }, {
        name: 'Achievement',
        data: [0, 0, 0]
      }];
      data = data.map((sol) => {

        let count = 0;
        let solutionAchievement = 0;
        let trarget = 0, archivement = 0, total = 0;
        totalTarget = totalTarget + Number(sol.revenue);
        this.opportunities.filter((a) => (a.solution_id || []).indexOf(sol.id) != -1).map((aa) => {
          let dt = new Date(aa.close_date)
          if (aa.deal_status != 'Lost') {
            count = count + 1;
          }
          if (aa.stage == 'Closed' && aa.deal_status == 'Won') {
            archivement = archivement + Number(aa.value);
            totalAchievement = totalAchievement + Number(aa.value);
            let j = 0;
            this.Dates.map((aa1) => {
              if (dt.getTime() >= aa1.dates[0] && dt.getTime() <= aa1.dates[1]) {
                this.solutionBarData[1].data[j] = Number(this.solutionBarData[1].data[j]) + Number(aa.value);
              }
              j++;
            });
          }
          let i = 0;
          this.Dates.map((aa1) => {
            if (dt.getTime() >= aa1.dates[0] && dt.getTime() <= aa1.dates[1]) {
              this.solutionBarData[0].data[i] = Number(this.solutionBarData[0].data[i]) + Number(aa.value);
            }
            i++;
          });
        })
        sol.oppy = count;
        sol.achievement = solutionAchievement;
        sol.archivement = archivement;
        return sol;
      }).sort((a: any, b: any) => {
        var keyA = new Date(a.updated_at || a.created_at || 0),
          keyB = new Date(b.updated_at || b.created_at || 0);
        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;
        return 0;
      })
      this.solutionData.data = data;
      this.totalTargetValue.solution = totalTarget;
      remainingAchievement = totalTarget - totalAchievement
      remainingAchievement = remainingAchievement > 0 ? remainingAchievement : 0
      this.solutionGraphData = [];
      this.solutionGraphData.push(["Achieved Target", totalAchievement], ["Remaining Target", remainingAchievement])
      console.log('SolutioData', this.solutionGraphData);
      if (totalAchievement != 0 || remainingAchievement != 0) {
        this.graph.solution = true;
        setTimeout(() => {
          this.genChart('pie-2')
        }, 100)
      } else {
        this.graph.solution = false;
      }
      setTimeout(() => {
        console.log(this.solutionBarData);
        this.genChart('bar-2')
      }, 100)
    }

  }
  reload() {
    this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/dashboard', this.selectedTable]);
    });
  }
  changeTable() {
    // if (this.plans.length > 0) {
    if (this.defaultTable && this.defaultTable != '') {
      this.selectedTable = this.defaultTable;
      return;
    }
    if (this.selectedTable == 'plans') return;
    this.selectedTable = 'plans';
    // return;
    // }
    // if (this.solutions.length > 0) {
    if (this.selectedTable == 'solutions') return;
    this.selectedTable = 'solutions';
    // return;
    // }
    // if (this.opportunities.length > 0) {
    if (this.selectedTable == 'opportunities') return;
    this.selectedTable = 'opportunities';
    // return;
    // }
    if (this.plans.length < 1 && this.solutions.length < 1 && this.opportunities.length < 1) {
      this.selectedTable = 'none';
      $('#zeroObjects').modal('show');
    }
    setTimeout(() => {
      let height = $(document).height();
      console.log(height + ' ---- ' + (height - 142))
      // $('nav#sidebar').css({ height: (height - 100) + 'px' })
    }, 200);
  }
  solutionGraphData: any = []
  solutionBarData: any = [{
    name: 'Target',
    data: [0, 0, 0]

  }, {
    name: 'Achievement',
    data: [0, 0, 0]

  }];
  jointSolution() {
    this.AuthService.get('solutions', (err) => {
      this.solutions = [];
      console.log(err);
    }).subscribe((result: any) => {
      this.loadedData.solutions = true;
      if (result.data) {
        this.solutions = result.data;
        this.solutions.forEach((sol) => {
          this.items.solution.push({ id: sol.id, name: sol.solution_name })
          sol.partnerDetails.map((aa) => {
            if (!this.partners.solution.filter((a11) => a11.id == aa.partner_id).length) {
              // let partner = this.allPartners.find((a) => a.partner_id == aa)
              this.partners.solution.push({ id: aa.partner_id, name: aa.partner_id != this.userData.partner.partner_id ? aa.partner_name : 'Internal' })
            }

          })
          this.allUsers.forEach((user) => {
            if (!this.createdBy.solution.filter((a11) => a11.id == sol.created_by).length) {
              if (sol.created_by == user.user_id) {
                this.createdBy.solution.push({ id: user.user_id, name: user.email })
              }
            };
          })
          if (Array.isArray(sol.team)) {
            sol.team.map((aa) => {
              if (!this.users.solution.filter((a11) => a11.id == aa.email).length) {
                this.users.solution.push({ id: aa.email, name: aa.email })
              }
            })
          }
        })
        this.processGraphData(this.solutions, 'solution');
        this.datePickerApply();
      } else {
        this.solutions = [];
        this.datePickerApply();
      }
    }, (err: any) => {
      this.solutions = [];
      this.loadedData.solutions = true;
      this.changeTable();
      this.datePickerApply();
      console.log(err);
    }, () => {
      this.loadedData.solutions = true;
      this.changeTable();
    });
  }
  opportunityGraphData: any = [];
  opportunityBarData: any = [{
    name: 'Target',
    data: [0, 0, 0]
  }, {
    name: 'Achievement',
    data: [0, 0, 0]
  }];
  getOpportunities() {
    this.AuthService.get('opportunities', (err) => {
      this.loadedData.opportunities = true;
    }).subscribe((result: any) => {
      if (result.data) {
        this.loadedData.opportunities = true;
        console.log(result.data)
        this.opportunities = result.data;
        console.log(this.opportunities)
        this.opportunities.forEach((aa) => {
          this.items.opportunity.push({ id: aa.id, name: aa.name });
          aa.partnerDetails.map((aa) => {
            if (!this.partners.opportunity.filter((a11) => a11.id == aa.partner_id).length) {
              // let partner = this.allPartners.find((a) => a.partner_id == aa)
              this.partners.opportunity.push({ id: aa.partner_id, name: aa.partner_id != this.userData.partner.partner_id ? aa.partner_name : 'Internal' })
            }
          })

          this.allUsers.forEach((user) => {
            if (!this.createdBy.opportunity.filter((a11) => a11.id == aa.created_by).length) {
              if (aa.created_by == user.user_id) {
                this.createdBy.opportunity.push({ id: user.user_id, name: user.email })
              }
            };
          })
          if (Array.isArray(aa.users)) {
            aa.users.map((aa1) => {
              if (!this.users.opportunity.filter((a11) => a11.id == aa1).length) {
                this.users.opportunity.push({ id: aa1, name: aa1 })
              }
            })
          }
        })
        this.processGraphData(this.opportunities, 'opportunity')
        this.plans = [];
        this.solutions = [];
        this.datePickerApply();
        this.jointPlan()
        this.jointSolution()
      } else {
        this.opportunities = [];
        this.jointPlan()
        this.jointSolution()
        this.datePickerApply();
      }
    }, (err: any) => {
      this.opportunities = [];
      this.loadedData.opportunities = true;
      this.jointPlan()
      this.jointSolution()
      this.changeTable();
      this.datePickerApply();
      console.log(err);
    }, () => {
      this.changeTable();
    });
  }
  getPartnerName(array) {
    let listOfPartners: any = [];
    this.allPartners.forEach(partner => {
      array.forEach((element, i) => {
        if (partner.partner_id == element) {
          if (!listOfPartners.includes(partner.partner_name)) {
            listOfPartners.push(partner.partner_name);
          }
        }
      });
    })
    return listOfPartners
  }
  allUsers: any[] = [];
  getUsers() {
    this.AuthService.get('admin/users').subscribe(
      (result) => {
        console.log(result);
        this.allUsers = result.data
      },
      (err) => {
        console.log(err.error);
        this.allUsers = []
      }, () => {
      }
    )
  }
  allPartners: any[] = [];
  getPartners() {
    this.AuthService.get('partners').subscribe(
      (result) => {
        console.log(result);
        this.allPartners = result.data
      },
      (err) => {
        console.log(err.error);
        this.allPartners = []
      }, () => {
      }
    )
  }
  genChart(type = '') {
    Highcharts.setOptions({
      lang: {
        thousandsSep: ','
      }
    });
    let $this = this;
    if (type == 'bar-1') {
      Highcharts.chart('bar-1', {
        chart: {
          type: 'column'
        },
        xAxis: {
          categories: this.categories.length ? this.categories : [
            'Q4FY19',
            'Q1FY20',
            'Q2FY20',
          ],
          crosshair: true
        },
        title: {
          text: ''
        },
        colors: ['#43AFB1', '#8C4CC0', '#5BE1E8'],
        yAxis: {
          min: 0,
        },
        tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y:,.0f} </b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
        },
        plotOptions: {
          column: {
            pointPadding: 0,
            borderWidth: 0
          }
        },
        series: this.planBarData,
        legend: {
          align: 'center',
          verticalAlign: 'top',
          layout: 'horizontal',
          x: 0,
          y: 0
        },

      });
    }
    if (type == 'bar-2') {
      Highcharts.chart('bar-2', {
        chart: {
          type: 'column'
        },
        xAxis: {
          categories: this.categories.length ? this.categories : [
            'Q4FY19',
            'Q1FY20',
            'Q2FY20',
          ],
          crosshair: true
        },
        title: {
          text: ''
        },
        colors: ['#43AFB1', '#8C4CC0', '#5BE1E8'],
        yAxis: {
          min: 0,
        },
        tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y:,.0f} </b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
        },
        plotOptions: {
          column: {
            pointPadding: 0,
            borderWidth: 0
          }
        },
        series: this.solutionBarData,
        legend: {
          align: 'center',
          verticalAlign: 'top',
          layout: 'horizontal',
          x: 0,
          y: 0
        },

      });
    }
    if (type == 'bar-3') {
      Highcharts.chart('bar-3', {
        chart: {
          type: 'column'
        },
        xAxis: {
          categories: this.categories.length ? this.categories : [
            'Q4FY19',
            'Q1FY20',
            'Q2FY20',
          ],
          crosshair: true
        },
        title: {
          text: ''
        },
        colors: ['#43AFB1', '#8C4CC0', '#5BE1E8'],
        yAxis: {
          min: 0,
        },
        tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y:,.0f} </b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
        },
        plotOptions: {
          column: {
            pointPadding: 0,
            borderWidth: 0
          }
        },
        series: this.opportunityBarData,
        legend: {
          align: 'center',
          verticalAlign: 'top',
          layout: 'horizontal',
          x: 0,
          y: 0
        },

      });
    }
    if (type == 'pie-1' && this.graph.plan) {
      Highcharts.chart('pie-1', {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: 0,
          plotShadow: false,
          events: {
            load: function () {
              $this.addLabel(this, 'plan')
            },
            redraw: function () {
              $this.addLabel(this, 'plan')
            }
          }
        },
        title: {
          text: 'Achievement vs. Target',
          align: 'left',
          verticalAlign: 'top',
          x: 10,
          style: {
            color: '#929292'
          }
        },
        tooltip: {
          pointFormat: '<b>{point.y:,.0f}</b>'
        },
        accessibility: {
          point: {
            valueSuffix: '%'
          }
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: false,
              distance: -50,
              style: {
                fontWeight: 'bold',
                color: 'white'
              }
            },
            startAngle: -90,
            endAngle: 90,
            center: ['50%', '100%'],
            size: '150%'
          }
        },
        colors: [
          '#2fc2a8', '#f15424'
        ],
        series: [{
          type: 'pie',
          innerSize: '0%',
          data: this.planGraphData
        }]
      });
    }
    if (type == 'pie-2' && this.graph.solution) {
      Highcharts.chart('pie-2', {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: 0,
          plotShadow: false,
          events: {
            load: function () {
              $this.addLabel(this, 'solution')
            },
            redraw: function () {
              $this.addLabel(this, 'solution')
            }
          }
        },
        title: {
          text: 'Achievement vs. Target',
          align: 'left',
          verticalAlign: 'top',
          x: 10,
          style: {
            color: '#929292'
          }
        },
        tooltip: {
          pointFormat: '<b>{point.y:,.0f}</b>'
        },
        accessibility: {
          point: {
            valueSuffix: '%'
          }
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: false,
              distance: -50,
              style: {
                fontWeight: 'bold',
                color: 'white'
              }
            },
            startAngle: -90,
            endAngle: 90,
            center: ['50%', '100%'],
            size: '150%'
          }
        },
        colors: [
          '#2fc2a8', '#f15424'
        ],
        series: [{
          type: 'pie',
          innerSize: '0%',
          data: this.solutionGraphData
        }]
      });
    }
    if (type == 'pie-3' && this.graph.opportunity) {
      Highcharts.chart('pie-3', {
        chart: {
          type: 'funnel'
        },
        title: {
          text: ' ',
          x: -50
        },
        plotOptions: {
          series: {
            // reversed: true,
            dataLabels: {
              allowOverlap: false,
              enabled: true,
              inside: true,
              align: "center",
              verticalAlign: "middle",
              connectorWidth: '0',
              format: '<b>{point.name}</b> ({point.y:,.0f})'
            },
            center: ['60%', '40%'],
            width: '100%',
            neckWidth: '10%',
            neckHeight: '0%',
          }
        },
        pyramid: {
          reversed: true,
        },
        colors: ['#43afb1', '#8c4cc0', '#5be1e8'],
        // reversed: true,
        legend: {
          enabled: false,
          align: 'center',
          verticalAlign: 'top',
          layout: 'horizontal',
          x: 0,
          y: 0
        },
        series: [{
          name: 'Opportunity Value',
          data: this.opportunityGraphData
        }],

        responsive: {
          rules: [{
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              plotOptions: {
                series: {
                  dataLabels: {
                    inside: true
                  },
                  center: ['50%', '50%'],
                  width: '80%'
                }
              }
            }
          }]
        }
      });
    }
    // console.log(this)
  }
  getQuarterStartEndDate(date) {
    var now = new Date(date);
    var quarter = Math.floor((now.getMonth() / 3));
    var firstDate = new Date(now.getFullYear(), quarter * 3, 1);
    var endDate = new Date(firstDate.getFullYear(), firstDate.getMonth() + 3, 0);
    return {
      firstDate: new Date(moment(firstDate).format('YYYY-MM-DD') + ' 00:01'),
      endDate: new Date(moment(endDate).format('YYYY-MM-DD') + ' 23:59')
    }
  }
  goToOpportunity(action) {
    $('.modal').modal('hide');
    if (action == 'add') {
      this.router.navigate(['/create-opportunity'])
    } else if (action == 'import') {
      this.router.navigate(['/opportunity-dashboard', action]);
    }
  }
  copyToClipboard(element) {
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val(element).select();
    document.execCommand("copy");
    console.log('copied');
    $temp.remove();
  }
  addLabel(chart, key) {
    let series = chart.series[0]
    let point = series.points[0];
    let plotX = point.shapeArgs.x;
    let plotY = point.shapeArgs.y;
    let pieRadius = point.shapeArgs.r
    $(`#${key}-target-start`).remove();
    $(`#${key}-target-end`).remove();
    chart.renderer.html('0', plotX - pieRadius, plotY + 70).attr({ id: `${key}-target-start` }).css({
      fontSize: '15px',
      color: '#44a2f8'
    }).add();
    // let targetString = this.totalTargetValue[key].toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    // chart.renderer.html(`${targetString.substring(0, targetString.length() - 3)}`, plotX + pieRadius, plotY + 70)
    chart.renderer.html(`${this.totalTargetValue[key].toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`, plotX + pieRadius, plotY + 70)
      .attr({ id: `${key}-target-end` })
      .css({
        fontSize: '15px',
        color: '#44a2f8'
      }).add();
    let translateX = $(`#${key}-target-end`).width();
    $(`#${key}-target-end`).css({ "transform": `translate(-${translateX / 2}px,0)` });
  }
  ngOnDestroy(): void {
    this.refreshSubscription.unsubscribe();
  }
  // funnel graph levels old code
  // if (st[0] == 'Prospect') {
  //   gdata[0] = st;
  // } else if (st[0] != 'Closed') {
  //   if (gdata[1]) {
  //     gdata[1][1] = gdata[1][1] + Number(st[1])
  //   } else {
  //     gdata[1] = st;
  //     gdata[1][0] = 'Qualified Pipeline'
  //   }
  // } else {
  //   gdata[2] = st;
  // }
}
