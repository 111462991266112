import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-connection-dashboard',
  templateUrl: './connection-dashboard.component.html',
  styleUrls: ['./connection-dashboard.component.css']
})
export class ConnectionDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
