import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
// import { Team } from '../models/team.model';
import { Connection } from '../models/connection.model';
import { AuthService } from '../services/auth.service';
import { LocalStorageService } from '../services/local-storage.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import * as moment from 'moment';

declare var $: any;
@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TeamComponent implements OnInit {
  @Input() onlyAddForm: boolean;
  teamsList: any;
  userData: any = {};
  defaultTeam: any;
  constructor(
    private formBuilder: FormBuilder,
    private AuthService: AuthService,
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute,
    private location: Location
  ) {
    this.userData = this.localStorageService.get({ name: 'userData' })
    this.userData = this.userData ? JSON.parse(this.userData) : {};
  }

  //*********** fetch table data ***********//
  getTeams() {
    return new Promise((resolve, reject) => {
      let subscriber_admin = this.userData.subscription_data ? (this.userData.admin_user ? true : false) : false
      this.AuthService.get(subscriber_admin ? 'admin/users' : ('partners/users?partner_id=' + this.userData.partner.partner_id)).subscribe(
        (result) => {
          let list = [];
          if (result.data) {
            list = result.data.filter((a) => {
              let status = true;
              if (this.userData.subscription_data || this.userData.email == this.userData.partner.partner_owner) {
                status = true;
              } else {
                if (this.userData.partner.partner_owner == a.email) {
                  status = true;
                } else {
                  status = false;
                }
              }
              return status;
            });
          }
          resolve(list)
          setTimeout(() => {
            $('.ui').dropdown();
          }, 100)
        },
        (error) => {
          console.log(error);
          resolve([]);
        })
    })
  }
  ngOnInit(): void {
    this.initializePage();
  }
  getPartnerIndexes(arr, val) {
    var indexes = [], i;
    for (i = 0; i < arr.length; i++)
      if (arr[i].partner_id === val)
        indexes.push(i);
    return indexes;
  }
  getUserIndexes(arr, val) {
    var indexes = [], i;
    for (i = 0; i < arr.length; i++)
      if (arr[i] === val)
        indexes.push(i);
    return indexes;
  }
  async initializePage() {
    await this.getTeams().then((result: any) => {
      let subscriber_admin = this.userData.subscription_data ? (this.userData.admin_user ? true : false) : false
      this.teamsList = result.map((a) => {
        a.name = (a.first_name && a.first_name != 'undefined' ? a.first_name : '') + (a.last_name && a.last_name != 'undefined' ? ' ' + a.last_name : '')
        return a;
      }).filter((user) => {
        if (this.userData.email == user.email) return false;
        if (!subscriber_admin) {
          if (this.userData.partner.partner_id == user.partner_id) {
            return true;
          } else {
            return false;
          }
        }
        return true;
      })
    })
    this.route.params.subscribe((a) => {
      if (a.teamId) {
        this.defaultTeam = a.teamId;
        this.location.replaceState('team');
      }
    })
  }
  selectedTeam: any = {};
  enableUpdate(updateData) {
    this.selectedTeam = updateData;
    setTimeout(() => {
      $('#AddTeam').modal('show');
    }, 100)
  }
  selectedUserEmail: string = '';
  selectedUserSuperOwner: boolean = false;
  selectedUserAdminUser: boolean = false;
  selectedUserID: any;
  selectedUserIndex: number;
  selectedUserPartnerName: string = '';
  user: any;
  finalUser: any;
  errorId: number = 0;

  openDeleteModal(user) {
    if (this.userData.email != user.email) {
      this.finalUser = user;
      this.selectedUserID = user.user_id;
      this.selectedUserEmail = user.email;
      this.selectedUserPartnerName = user.partner_name;
      this.selectedUserSuperOwner = user.super_user;
      this.selectedUserAdminUser = user.admin_user;
      $('#deleteModal').modal('show');
    }
  }
  deleteUser() {
    if (this.userData.email != this.selectedUserEmail) {
      this.AuthService.delete(`admin/users/${this.selectedUserID}`, this.finalUser).subscribe(
        (result: any) => {
          console.log(result.meta);
          this.initializePage();
          this.closeDeleteModal();
        }, error => {
          console.log(error);

        })
    }
  }
  closeDeleteModal() {
    $('#deleteModal').modal('hide');
  }
}
