export class Partner {
  public _id;
  public partner_id;
  public partner_name;
  public country_code;
  public partner_type;
  public description;
  public partner_owner;
  public currency;
  public target_revenue;
  public email_domain?;
  public team: teamMember[];
  public app_url;
  public validate_domain;
}

export class PartnerOwner {
  public email;
  public user_role;
  public email_domain?;
}
export class teamMember {
  public email;
  public user_role;
}
