import { Component, OnInit, DoCheck, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from 'src/validators/must-match.validator';
import { User } from '../models/user.model';
import { AuthService } from '../services/auth.service';
import { CommonEmitterService } from '../services/common-emitter';
import { ActivatedRoute, Router } from '@angular/router';
import { Country } from '../models/country.model';
import { LocalStorageService } from '../services/local-storage.service';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';

declare var $: any;
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  user: User;
  countries: Country[];
  errMessage: string = '';
  termsError: boolean = false
  detailsError: boolean = false
  selected: string = ' '
  registerForm: FormGroup
  terms: boolean = false
  formControls: any
  displayError: boolean = false;
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";
  passwordPattern: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~])[A-Za-z\d `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]{8,}$/
  sanitizedPolicyUrl: SafeResourceUrl;
  sanitizedTermsUrl: SafeResourceUrl;
  code: any = '';
  tokenData: any = { customData: {} };
  constructor(
    private router: Router,
    private formbuilder:
      FormBuilder,
    private AuthService: AuthService,
    private CommonEmitter: CommonEmitterService,
    private route: ActivatedRoute,
    private LocalStorageService: LocalStorageService,
    private sanitizer: DomSanitizer
  ) { }
  getCOlor() {
    let color1: string
    if (this.registerForm.controls['country'].value === '') {
      color1 = '#a79490';
    } else {
      color1 = 'black'
    }
    return color1
  }
  logout() {
    if (this.LocalStorageService.get({ name: 'accessToken' })) {
      this.AuthService.get("user/logout", () => { }).subscribe(
        () => {
          this.CommonEmitter.sendMessage("showLogOutPage", true);
        },
        err => {
          this.CommonEmitter.sendMessage("showLogOutPage", true);
        }
      );
    }
  }
  ngOnInit(): void {
    $('.modal').modal('hide')
    this.route.queryParams.subscribe((a) => {
      if (a.validateToken) {
        this.logout();
        this.code = a.validateToken;
        this.AuthService.get('user/validateToken?validateToken=' + this.code, (err) => {
        }).subscribe((result: any) => {
          console.log(result);
          this.tokenData = result.data;
          this.tokenData.customData = this.tokenData.customData || {};
          this.user.email = this.tokenData.email.trim()
        }, (err: any) => {
          console.log(err)
        })
      } else if (!a.validateToken) {
        console.log('redirect on validate token')
        this.router.navigate(['/sign-in'])
      } else if (this.AuthService.isLoggedIn()) {
        this.router.navigate(['/dashboard']);
      }
    })

    this.user = new User();
    this.user.country_code = '';
    this.registerForm = this.formbuilder.group({
      firstName: ['', Validators.compose([Validators.required])],
      lastName: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.compose([Validators.required, Validators.pattern(this.passwordPattern)])],
      confirmPassword: ['', Validators.compose([Validators.required])],
      phone: ['', Validators.compose([Validators.required, Validators.pattern("^((\\+[0-9]{0,2}-?)|0)?[0-9]{10}$")])],
      country: ['', Validators.compose([Validators.required, Validators.minLength(2)])],
    }, { validators: MustMatch('password', 'confirmPassword') })
    this.formControls = (this.registerForm.controls)


    this.AuthService.get('countries?limit=all', (err) => {
      this.errMessage = err.error.meta.message
    })
      .subscribe((result: any) => {
        this.countries = result.data;
        console.log("this.countries:", this.countries);
      }, (err: any) => {
        console.log(err);
      });
  }
  modalShow(modal: string) {
    $(`#${modal}`).modal('show')
  }
  checkDetailErrors() {
    if (this.registerForm.status === "INVALID") {
      this.detailsError = true;
    } else {
      this.detailsError = false
    }
    return this.detailsError
  }
  submit() {
    if (this.checkDetailErrors()) {
      return;
    } else if (!this.terms) {
      this.termsError = true
      return;
    } else {
      this.AuthService.add('user/signup', this.user, (err) => {
        this.errMessage = err.error.meta.message
        this.displayError = true
      }).subscribe((result: any) => {
        //registration success
        console.log("registration success: result -", result);
        this.router.navigate([`/account-verify/${this.user.email}`])
      }, (err: any) => {
        console.log(err);
        this.displayError = true
        this.errMessage = err.error.meta.message
      });
    }
  }
}
