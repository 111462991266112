import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { LocalStorageService } from '../services/local-storage.service';
import { CommonEmitterService } from '../services/common-emitter';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare var $: any;
@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {

  email: string;
  password: string;
  errMessage: string = '';
  displayError: boolean = false
  signinForm: FormGroup;
  formControls: any;
  formError: boolean = false
  page: any = 'forget';

  constructor(private router: Router,
    private AuthService: AuthService, private LocalStorageService: LocalStorageService,
    private CommonEmitterService: CommonEmitterService, private route: ActivatedRoute, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    $('.modal').modal('hide')
    if (this.AuthService.isLoggedIn()) {
      this.router.navigate(['/dashboard']);
    }
    this.signinForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern(/\S+@\S+\.\S+/)])],
      password: ['', Validators.compose([Validators.required])]
    })
    this.formControls = this.signinForm.controls
    this.formChanges();
  }

  formChanges() {
    this.signinForm.valueChanges.subscribe(val => {
      this.displayError = false;
    })
  }
  changePage() {
    if (this.AuthService.isLoggedIn())
      this.router.navigate(['/dashboard']);
    console.log("forget")
    this.router.navigate(['/forget-password'])
  }
  submit() {
    if (this.AuthService.isLoggedIn())
      this.router.navigate(['/dashboard']);
    if (this.signinForm.valid) {
      this.email = this.email ? this.email.toLowerCase() : '';
      let param: any = { email: this.email, password: this.password };
      this.AuthService.add('user/login', param)
        .subscribe(async (result: any) => {
          if (result.data) {
            await this.AuthService.savePartners(result.data.moreInfo);
            let { isAdmin, isFirstLogin } = this.checkIfAdmin(result.data.moreInfo)
            this.CommonEmitterService.sendMessage("showLogOutPage", false);
            this.CommonEmitterService.sendMessage("saveAccessToken", result.data.accessToken);
            this.CommonEmitterService.sendMessage("savePartnerInfo", JSON.stringify(result.data.moreInfo));
            this.LocalStorageService.put({ name: 'isLogin', value: 'true' })
            if (isFirstLogin) {
              this.LocalStorageService.put({ name: 'isFirstLogin', value: 'true' })
            }
            setTimeout(() => {
              if (isAdmin) {
                this.router.navigate(['/admin/dashboard'])
              } else this.router.navigate(['/dashboard']);
            }, 500);
          }
        }, (err: any) => {
          console.log(err);
          if (err.error.meta.message == 'Invalid crdentials') {
            this.errMessage = "Invalid Credentials"
          } else {
            this.errMessage = err.error.meta.message
          }
          this.displayError = true
        });
    } else {
      this.errMessage = "Please enter email and password"
      this.formError = true
    }
  }
  checkIfAdmin(infoArray: any[]): { isAdmin: boolean, isFirstLogin: boolean } {
    let adminStatus = infoArray.filter(info => info.admin_user)
    let isFirstLogin = adminStatus.filter(admin => admin.is_first_login);
    return { isAdmin: adminStatus.length > 0, isFirstLogin: isFirstLogin.length > 0 }
  }
}
