<div *ngIf="!showContents; else firstTimeTemp" class="d-flex align-items-stretch">
  <div class="container-fluid">
    <div style="
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      ">
      <!-- *ngIf="connections.length" -->

      <div class="col-12 row">
        <div class="col-1 row" style="align-items: center;justify-content: center;">
        </div>
        <div class="col-11 row" style="align-items: center;justify-content:start;">
          <div class="col-6 row mt-4" style="height: 150px;">
            <div class="col-md card-wrapper mr-4">
              <div class="card" style="cursor: pointer;" routerLink="/admin/users">
                <label style="margin-top: 50px;">
                  <h5 class="sky_blue_dark">USERS</h5>
                </label>
                <label style="margin-bottom: 50px;">
                  <h5 class="light_blue">{{ allUser.length }}</h5>
                </label>
              </div>
            </div>
            <div class="col-md card-wrapper">
              <div class="card" style="cursor: pointer;" routerLink="/admin/connections">
                <label style="margin-top: 50px;">
                  <h5 class="sky_blue_dark">PARTNERS</h5>
                </label>
                <label style="margin-bottom: 50px;">
                  <h5 class="light_blue">{{ allConnections.length  }}</h5>
                </label>
              </div>
            </div>
          </div>
        </div>
        <hr class="col-12" style="padding: 0;">
      </div>

      <div class="col-12 custom-row">

        <div class="col-9">
          <label class="modal-title" style="color: black;font-size: 22px; ">
            Usage Stats(last 4 weeks):
          </label>
          <figure class="highcharts-figure">
            <div [hidden]="((internalUsersSum + externalUsersSum == 0) && loadedGraphData )" id="graph"></div>
            <div
              style="width: 100%;text-align:center;height:200px;display:flex;justify-content: center;align-items:center;"
              [hidden]="!((internalUsersSum + externalUsersSum == 0) && loadedGraphData )">
              No usage in last 4 weeks
            </div>
          </figure>
        </div>
        <div class="col-3" style="border-left: 1px solid rgba(0,0,0,.1);margin-top: -24px;padding-top: 25px;">
          <h6 class="modal-title" style="color: black;">ACTIONS:</h6>
          <a class="mt-4" href="javascript:void(0);" routerLink="/admin/add-user"
            style="text-decoration: underline; color: #1e98ea;">Add Users</a><br />
          <a class="mt-4" href="javascript:void(0);" data-toggle="modal" data-target="#customise-popup"
            style="text-decoration: underline; color: #1e98ea;">View/ Update
            Customization</a>
          <a class="mt-4" [routerLink]="['/admin/brand-customization']"
            style="text-decoration: underline; color: #1e98ea;">View/ Update
            Branding</a>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #firstTimeTemp>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="mt-4" style="text-align: center; display: inline;">
        <div class="brand-text brand-big visible text-uppercase">
          <img src="./assets/ICONS/logo@2x.png" style="width: 150px;" />
        </div>
      </div>
      <div class="modal-body" style="text-align: left; display: inline; margin: 10px;">
        <div class="row mrgbtm">
          <div class="col-md-12">
            <p style="color: #1e98ea;">Hi,</p>
          </div>
          <div class="col-md-12" style="align-items: center; justify-content: center;">
            <p style="color: #1e98ea;">
              Welcome to Flyero! You have been designated as Admin user for your
              company {{ user.partner.partner_name }}. As Admin, you will have a
              view of all the activity, data created and shared by your
              colleagues and partners. You will also be able to invite other
              users to join Flyero and start connecting and collaborating with
              partners.
            </p>
          </div>
        </div>
        <div class="row mrgbtm">
          <div class="col-md-12">
            <p style="color: #1e98ea;">
              Please reach out at
              <a href="mailto:support@flyero.com" target="_blank"
                style="text-decoration: underline; color: #6566a1;">support@flyero.com</a>
              for any queries, to share feedback or just give us a shout-out (we
              love them!)
            </p>
          </div>
        </div>
        <div class="row mrgbtm">
          <div class="col-md-12">
            <p style="color: #1e98ea;">Happy Selling!</p>
          </div>
          <div class="col-md-12">
            <p style="color: #1e98ea;">Team Flyero</p>
          </div>
        </div>
        <div class="row mrgbtm">
          <div class="col-md-12 text-center">
            <button class="btn" style="
                background-color: #1e98ea;
                color: white;
                padding-top: 8px;
                padding-bottom: 8px;
                padding-left: 50px;
                padding-right: 50px;
              " (click)="modalControl()">
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<div class="modal" id="getYouSetup">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body" style="text-align: left; display: inline;">
        <div class="row mrgbtm">
          <div class="col-md-12 mt-4">
            <h5 style="color: black;">Let's get you set up!</h5>
          </div>
          <div class="col-md-12 mt-3">
            <p style="color: #44a2f8; white-space: pre;">
              1.{{ whiteSpace }}
              <a href="javascript:void(0);" (click)="this.addUser()"
                style="text-decoration: underline; color: #1e98ea;">Add users</a>
            </p>
          </div>
          <div class="col-md-12 mt-3 mb-4">
            <p style="color: #44a2f8; white-space: pre;">
              2.{{ whiteSpace }}
              <a href="javascript:;" (click)="openCustomPopUp()"
                style="text-decoration: underline; color: #1e98ea;">Customize Flyero to your
                organization</a>
            </p>
          </div>
        </div>
        <!-- Modal footer -->
      </div>
    </div>
  </div>
</div>
<div class="modal" id="customise-popup">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body" style="text-align: left; display: inline;">
        <div class="row mrgbtm">
          <div class="col-md-12 mt-3 text-center">
            <p style="color: #44a2f8;/* white-space: pre; */padding: 30px 30px;">
              Flyero can be customized in multiple ways for you! Please email us at <a href="mailto:support@flyero.com"
                target="_blank" style="font-weight: bold;color: #44a2f8;">support@flyero.com</a> for any changes and our
              team will help you.
            </p>
            <hr>
            <button type="button" class="btn btn-primary" (click)="customiseBrand()"
              style="background: #44a2f8;color: #fff;padding: 5px 35px;border:none;" data-dismiss="modal">OK</button>
          </div>
        </div>
        <!-- Modal footer -->
      </div>
    </div>
  </div>
</div>

<div class="modal" id="brand-popup">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Brand Management</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="text-align: left; display: inline;">
        <div class="row mrgbtm">
          <div class="col-md-12 mt-3 text-center">
            <p style="color: #000;/* white-space: pre; */padding: 0 20px 20px 20px;">At Flyero, we take promoting our
              customer’s brand very seriously. For this, we request you to enter the following details
            </p>
            <form>
              <div class="form-group">
                <label for="recipient-name" class="col-form-label col-12">Your chosen trading name (Please enter the
                  right
                  font):</label>
                <input type="text" name="brand" [(ngModel)]="brand.brand" placeholder="Brand Name" class="form-control"
                  id="recipient-name">
              </div>
              <div class="form-group">
                <label for="message-text" class="col-form-label col-12">Your Company Logo:</label>
                <div class="bdr" style="height: 185px; width: 185px; padding: 5px;">
                  <div style="position: relative;">
                    <img style="height: 173px; width: 173px;" *ngIf="brand.brand" [src]="initPath + brand.logo"
                      id="profile_pic" (click)="openUpload()" alt="profile-pic" />
                    <div *ngIf="imageError.error" class="errorMessage">
                      {{ imageError.message }}
                    </div>
                    <div (click)="openUpload()" class="editOption text-right">
                      <span class="material-icons">
                        create
                      </span>
                    </div>
                  </div>
                  <input style="display: none;" id="profile_pic_upload" (change)="handleFileSelect($event)"
                    type="file" />
                </div>
              </div>
            </form>
            <hr>
            <button type="button" (click)="updateBrand()" class="btn btn-primary"
              style="background: #44a2f8;color: #fff;padding: 5px 35px;border:none;">Submit</button>
          </div>
        </div>
        <!-- Modal footer -->
      </div>
    </div>
  </div>
</div>