import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-account-confirm',
  templateUrl: './account-confirm.component.html',
  styleUrls: ['./account-confirm.component.css']
})
export class AccountConfirmComponent implements OnInit {
  key: any;
  code: any;
  error: any;
  message: any = ''
  constructor(private route: ActivatedRoute, private router: Router, private AuthService: AuthService) { }

  ngOnInit(): void {
    this.code = this.route.snapshot.paramMap.get('key');
    try {
      this.AuthService.get('user/verify?code=' + this.code, (err) => {
        this.error = true;
        this.message = err.error.meta.message;
        console.log(err)
      })
        .subscribe((result: any) => {
          this.error = false;
          this.key = result.data.email
          this.message = true;
        }, (err: any) => {
          this.error = true;
          this.message = err.error.meta.message;
          console.log(err)
        })
    } catch (e) {
      console.log(e)
    }
  }


  redirect() {
    this.router.navigate([''])
  }
}
