import { Component, OnInit } from '@angular/core';
import { Router, ActivationEnd } from '@angular/router';
import { CommonEmitterService } from '../services/common-emitter';
import { AuthService } from '../services/auth.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
declare var $: any
@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {
  sanitizedPolicyUrl: SafeResourceUrl;
  sanitizedTermsUrl: SafeResourceUrl;
  constructor(private router: Router, private AuthService: AuthService, private sanitizer: DomSanitizer, private CommonEmitterService: CommonEmitterService) {
    this.CommonEmitterService.getMessage().subscribe(data => {
      if (data.app_version) {
        this.app_version = data.app_version
      }
    })
    this.router.events.subscribe(val => {
      if (val instanceof ActivationEnd) {
        if (val.snapshot.data['title']) this.title = val.snapshot.data['title'];
        console.log(this.title);

      }
    })
  }
  title: string = ''
  register: boolean;
  app_version: string = '';
  ngOnInit(): void { }
  modalShow(modal: string) {
    $(`#${modal}`).modal('show')
  }
  isRegister(): boolean {
    return this.router.url.split('?')[0] == '/register'
  }
  redirect(path: string) {
    this.router.navigate([`${path}`])
  }


}
