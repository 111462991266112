import { Injectable, Output, EventEmitter } from '@angular/core';

import { Observable, Subscription, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CommonEmitterService {

    constructor() { }

    private subject = new Subject<any>();
    subscription: Subscription;
    rawObj: any = {}
    @Output() updateTitle: EventEmitter<string> = new EventEmitter();
    @Output() updateLoading: EventEmitter<any> = new EventEmitter();
    @Output() updateBranding: EventEmitter<any> = new EventEmitter();
    @Output() updateUserData: EventEmitter<string> = new EventEmitter();
    @Output() changeUserData: EventEmitter<string> = new EventEmitter();
    @Output() updateObjectCount: EventEmitter<string> = new EventEmitter();
    @Output() pageRefresh: EventEmitter<string> = new EventEmitter();
    sendMessage(key: string, value: any) {
        this.rawObj[key] = value;
        this.subject.next(this.rawObj);
    }
    changeObjectCount(value: boolean) {
        this.updateObjectCount.next(value.toString());
    }
    setTitle(title) {
        this.updateTitle.emit(title);
    }
    setLoading(status) {
        this.updateLoading.emit(status);
    }
    updateBrand() {
        this.updateBranding.emit(true);
    }
    updatePage(status) {
        this.pageRefresh.emit(status);
    }
    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
}