export class Activity {
    public Key: string;
    public activity: string;
    public assignee: string[];
    public customer_name: string;
    public customer: string;
    public id: string;
    public docType: string;
    public end_date: string;
    public completion_date: string;
    public priority: string;
    public partners: string[];
}