<div
  class="d-flex justify-content-center align-items-center"
  style="height: 100%;"
>
  <div class="container-fluid middle-container">
    <div class="row mrgbtm">
      <div
        class="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12"
        style="padding-top: 7px;"
      >
        <h6 class="col-form-labelin">
          Choose Partner<sub class="required">*</sub>:
        </h6>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3">
        <select
          id="partnerName"
          class="form-control"
          [(ngModel)]="connection.partner2_id"
          (change)="onPartnerSelect($event)"
        >
          <option disabled value="">Select</option>
          <option *ngFor="let partner of partners" [value]="partner.partner_id"
            >{{ partner.partner_name }}
          </option>
        </select>
      </div>
      <div
        class="col-lg-1 col-md-1 col-sm-1 col-xs-1 col-1"
        style="padding-top: 7px;"
      >
        <h5 class="data_field">OR</h5>
      </div>
      <div class="col-lg-5 col-md-5 col-sm-7 col-xs-7 col-7">
        <button type="button" (click)="newPartner()" class="btn cnclbtn">
          New Partner
        </button>
      </div>
    </div>
    <div
      class="row mrgbtm errorMessage"
      *ngIf="
        this.formErrors.partner2Owner != '' && this.connection.partner2_id == ''
      "
    >
      <div class="col" style="text-align: center;">
        {{ this.formErrors.partner2Owner }}
      </div>
    </div>
    <div class="row mrgbtm">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
        <hr style="border: 2px solid #44a2f8;" />
      </div>
    </div>
    <div class="row mrgbtm">
      <div
        class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12"
        style="padding-top: 7px;"
      >
        <h6 class="head_type">
          Choose From:
        </h6>
      </div>
    </div>
    <div class="row mrgbtm">
      <div
        class="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12"
        style="padding-top: 7px;"
      >
        <h6 class="col-form-labelin">Add Team<sub class="required">*</sub>:</h6>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3">
        <ss-multiselect-dropdown
          [disabled]="!connection.partner2_id"
          [options]="partnerUsersOptions"
          [(ngModel)]="partnerUsersModel"
          [settings]="multiSelectionSettings"
          (ngModelChange)="onChange($event)"
        >
        </ss-multiselect-dropdown>
      </div>
      <div
        class="col-lg-1 col-md-1 col-sm-1 col-xs-1 col-1"
        style="padding-top: 7px;"
      >
        <h5 class="data_field">OR</h5>
      </div>
      <div
        style="padding-top: 7px;"
        class="col-lg-5 col-md-5 col-sm-7 col-xs-7 col-7"
      >
        <a
          href="#"
          data-toggle="modal"
          data-target="#inviteModal"
          class="data_field_a"
          data-backdrop="static"
          data-keyboard="false"
          >{{
            this.teamMembers.length <= 1 && this.teamMembers[0].email == ""
              ? "Invite"
              : this.teamMembers.length + " member(s) added"
          }}</a
        >
      </div>
    </div>
    <div
      class="row mrgbtm errorMessage"
      *ngIf="
        this.formErrors.team != '' &&
        this.teamMembers.length == 1 &&
        this.teamMembers[0].email == '' &&
        this.connection.team.length < 1
      "
    >
      <div class="col" style="text-align: center;">
        {{ this.formErrors.team }}
      </div>
    </div>
    <div class="row mrgbtm">
      <div
        class="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12"
        style="padding-top: 7px;"
      >
        <h6 class="col-form-labelin">
          Connection Owner<sub class="required">*</sub>:
        </h6>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6">
        <input
          id="partnerOwner"
          type="text"
          class="form-control"
          [placeholder]="
            this.connection.partner2_id == ''
              ? 'Choose partner first'
              : 'Enter Owner Email Id'
          "
          (keypress)="validEmail(-1, false)"
          (blur)="validDomain(-1)"
          [(ngModel)]="connection.partner2_owner"
          [disabled]="this.connection.partner2_id == ''"
        />
        <div
          class="errorMessage"
          *ngIf="this.formErrors.partner2OwnerEmail.valid != ''"
        >
          {{ this.formErrors.partner2OwnerEmail.valid }}
        </div>
        <div
          class="errorMessage"
          *ngIf="this.formErrors.partner2OwnerEmail.domain != ''"
        >
          {{ this.formErrors.partner2OwnerEmail.domain }}
        </div>
        <div
          class="errorMessage"
          *ngIf="
            this.formErrors.partner2OwnerEmail.required != '' &&
            connection.partner2_owner == ''
          "
        >
          {{ this.formErrors.partner2OwnerEmail.required }}
        </div>
        <!-- <select class="form-control" [(ngModel)]="connection.partner2_owner">

        
        
      </div>
      <!-- <select class="form-control" [(ngModel)]="connection.partner2_owner">

          <option value="">Select</option>
          <option *ngFor="let owner of partnerOwners" [value]="owner.email"
            >{{ owner.email }}
          </option>
        </select> -->
      </div>
      <!-- <div
        class="col-lg-1 col-md-1 col-sm-1 col-xs-1 col-1"
        style="padding-top: 7px;"
      >
        <h5 class="data_field">OR</h5>
      </div>
      <div
        style="padding-top: 7px;"
        class="col-lg-5 col-md-5 col-sm-7 col-xs-7 col-7"
      >
        <a
          href="#"
          data-toggle="modal"
          data-target="#inviteModal"
          class="data_field_a"
          >Invite</a
        >
      </div> -->
    </div>
    <div *ngIf="formError" class="row mrgbtm">
      <div class="col-md-12">
        <div align="center" class="errorMessage">
          Please fill all required(*) fields to create connection
        </div>
      </div>
    </div>
    <div class="row mrgbtm">
      <div class="col-md-12">
        <div align="center">
          <button
            type="button"
            (click)="goBack()"
            class="btn cnclbtn"
            data-dismiss="modal"
          >
            Cancel
          </button>

          <button type="button" class="btn addbtn" (click)="validateForm()">
            Create Connection
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-----------model popup-------->
<div class="modal" id="myModal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal body -->
      <div class="modal-body" style="text-align: center; display: inline;">
        <h5 class="modal-title mrgbtm">Are You Sure?</h5>
        <p>
          New Connection with
          <span style="font-weight: bold;">{{ selectedPartner }}</span> will be
          created
        </p>
        <div class="errorMessage" *ngIf="responseError != ''">
          {{ responseError }}
        </div>
      </div>
      <!-- Modal footer -->
      <div class="modal-footer" style="text-align: center; display: inline;">
        <a type="button" class="btn cnclbtn" data-dismiss="modal">Cancel</a>

        <button type="button" class="btn addbtn" (click)="submit()">
          Confirm
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="inviteModal">
  <div class="modal-dialog">
    <div class="modal-content">
      <!-- Modal Header -->
      <!-- <div class="modal-header">
        <h4 class="modal-title"></h4>
        <button type="button" class="close" data-dismiss="modal">
          &times;
        </button>
      </div> -->

      <!-- Modal body -->

      <div
        *ngIf="!connection.partner2_id"
        class="no-partner-error modal-body mrgbtm"
        style="text-align: center;"
      >
        Choose a partner before inviting new team members.
      </div>
      <div *ngIf="connection.partner2_id" class="modal-body mrgbtm">
        <div class="mrgbtm row">
          <h4 class="col-sm-6 head_type" style="font-weight: bold;">
            Team Members:
          </h4>

          <div class="col-sm-3">
            <h4 class="head_type" style="font-weight: bold;">
              Set Access:
            </h4>
          </div>
        </div>
        <div
          class="member-row"
          *ngFor="let eachMember of teamMembers; index as i"
        >
          <div class="row" style="margin-bottom: 2px;">
            <div class="col-sm-6 data_field_a">
              <input
                class="form-control"
                type="text"
                (keypress)="validEmail(i, false)"
                [(ngModel)]="eachMember.email"
                placeholder="Enter Email Id"
              />
            </div>

            <div class="col-sm-3 perm-icons">
              <h4
                class="data_field_a"
                style="display: flex; justify-content: space-between;"
              >
                <i
                  class="fa fa-eye"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="View"
                  [ngStyle]="{
                    color: eachMember.user_role === 1 ? '#EF4E24' : '#e7dfd5'
                  }"
                  (click)="addPermission(i, 1)"
                  aria-hidden="true"
                ></i>
                <i
                  class="fa fa-pencil"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Edit"
                  [ngStyle]="{
                    color: eachMember.user_role === 2 ? '#EF4E24' : '#e7dfd5'
                  }"
                  (click)="addPermission(i, 2)"
                  aria-hidden="true"
                ></i>
                <i
                  class="fa fa-plus-circle"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Invite"
                  [ngStyle]="{
                    color: eachMember.user_role === 3 ? '#EF4E24' : '#e7dfd5'
                  }"
                  (click)="addPermission(i, 3)"
                  aria-hidden="true"
                ></i>
              </h4>
            </div>
            <div class="col-sm-3 remove-member-btn">
              <button
                *ngIf="i + 1 < teamMembers.length"
                class="btn-custom addbtn"
                (click)="removeMember(i)"
              >
                Remove
              </button>
              <button
                *ngIf="i + 1 === teamMembers.length"
                class="btn-custom addbtn"
                (click)="addMoreMembers(i)"
              >
                Add
              </button>
            </div>
          </div>
          <div
            class="errorMessage"
            *ngIf="this.teamErrors[i] && this.teamErrors[i].domain != ''"
          >
            {{ this.teamErrors[i].domain }}
          </div>
          <div
            class="errorMessage"
            *ngIf="this.teamErrors[i] && this.teamErrors[i].valid != ''"
          >
            {{ this.teamErrors[i].valid }}
          </div>
          <div
            class="errorMessage"
            *ngIf="this.teamErrors[i] && this.teamErrors[i].duplicate != ''"
          >
            {{ this.teamErrors[i].duplicate }}
          </div>
        </div>
      </div>
      <div *ngIf="connection.partner2_id" class="row mrgbtm">
        <div class="col-md-12">
          <div align="center">
            <!-- <button
              (click)="removeMember(-1)"

            <button

              type="button"
              class="btn cnclbtn"
              (click)="validateAllMembers()"
            >
              Cancel
            </button> -->

            <button
              (click)="validateAllMembers()"
              type="button"
              class="btn addbtn"
            >
              OK
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="!connection.partner2_id" class="row mrgbtm">
        <div class="col-md-12">
          <div align="center">
            <button type="button" class="btn cnclbtn" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
