import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { AuthService } from '../../services/auth.service';
import { NavigationHelperService } from '../../services/navigation-helper.service';
import { TitleCasePipe } from '@angular/common';
import { CommonEmitterService } from 'src/app/services/common-emitter';

declare var Highcharts: any
declare var $: any
@Component({
  selector: 'app-admindashboard',
  templateUrl: './admindashboard.component.html',
  styleUrls: ['./admindashboard.component.css']
})

export class AdminDashboardComponent implements OnInit {
  whiteSpace = '      '
  initPath = this.AuthService.baseUrl
  constructor(private router: Router,
    private route: ActivatedRoute, private AuthService: AuthService, private navHelper: NavigationHelperService, private LocalStorageService: LocalStorageService,
    private CommonEmitter: CommonEmitterService,
  ) {
    if (this.navHelper.navigationTracker.length == 0) {
      this.navHelper.setUrl(this.router.url)
    }
    let status: any = '';
    status = this.LocalStorageService.get({ name: 'isFirstLogin' })
    if ((status == true) || (status == 'true')) {
      this.showContents = true;
    }
  }
  showContents: boolean = false;
  seriesData = [
    {
      name: '',
      data: []
    },
    {
      name: '',
      data: []
    }];
  ngOnInit(): void {
    this.getUserDetails();
    if (!this.showContents) {
      this.getUsers();
    }
  }
  user: any;
  getUserDetails() {
    this.AuthService.get("user/me", () => { }).subscribe(
      (result: any) => {
        console.log("header says", result);
        this.user = result.data[0];
        this.getConnections();
        this.getDefaultData();
        setTimeout(() => {
          this.graphData();
        }, 300);
      },
      err => {
        console.log(err);
        this.getConnections();
        setTimeout(() => {
          this.graphData();
        }, 300);
      }
    );
  }
  internalUsersSum: number = 0;
  externalUsersSum: number = 0;
  loadedGraphData: boolean = false;
  graphData() {
    this.AuthService.get('admin/userusage', () => {
    }).subscribe((aa) => {
      this.internalUsersSum = 0;
      this.externalUsersSum = 0;
      aa.data.forEach((usage, i) => {
        if (usage.name == 'internal') {
          this.seriesData[i].name = this.user.partner.partner_name + ' Users'
        } else if (usage.name == 'external') {
          this.seriesData[i].name = 'Partner Users'
        } else {
          this.seriesData[i].name = usage.name;
        }
        this.seriesData[i].data = [];
        usage.data.forEach(element => {
          // let avgTime = element.count > 0 ? Number((element.interval / element.count).toFixed(2)) : Number(element.interval.toFixed(2));
          // let hours = Math.floor(avgTime)
          // let minutes = Number(((avgTime - hours) * 0.6).toFixed(2));
          this.seriesData[i].data.push({ y: element.count, avg: element.avg })
          if (usage.name == 'internal') {
            this.internalUsersSum = this.internalUsersSum + element.count
          } else if (usage.name == 'external') {
            this.externalUsersSum = this.externalUsersSum + element.count
          }
        })
      })
      this.seriesData.reverse();
      this.generateChart(this.seriesData);
    });
  }
  allUser: any[] = [];
  getUsers() {
    this.AuthService.get('admin/users').subscribe(
      (result) => {
        console.log(result);
        this.allUser = result.data
      },
      (err) => {
        console.log(err.error);
        this.allUser = []
      },
      () => {
        if (this.allUser.length <= 1) {
          $('#getYouSetup').modal('show');
        }
      }
    )
  }
  allConnections: any[] = [];
  getConnections() {
    this.AuthService.get('admin/partners').subscribe(
      (result) => {
        console.log(result.data)
        this.allConnections = result.data;
      },
      (error) => {
        console.log(error.error);
        this.allConnections = [];
      },
    )
  }
  generateChart(data) {
    let $this = this;
    Highcharts.chart('graph', {
      chart: {
        type: 'area',
        events: {
          load: function () {
            var chart = this;
            $this.loadedGraphData = true;
            chart.series.forEach((data, i) => {
              let point = data.points[(data.points.length - 1)];
              chart.renderer.text('Avg. time spent:' + '<br/>' + point.avg, point.plotX + chart.plotLeft + 5, point.plotY + chart.plotTop)
                .attr({ id: `usage-label-${i}` })
                .css({
                  fontSize: '12px',
                  color: '#666666'
                })
                .add();
              data.points.forEach((point, x) => {
                if (point.y > 0) {
                  let xOffset = 0
                  let yOffset = 0
                  if (x == (data.points.length - 1)) {
                    xOffset = 8
                  } else {
                    xOffset = -5
                  }
                  chart.renderer.html('<div>' + point.y + '</div>', point.plotX - xOffset, point.plotY + chart.plotTop + 20)
                    .attr({ id: `point-label-${x}` })
                    .css({
                      fontSize: '12px',
                      color: '#000000',
                      // background: 'linear-gradient(180deg, rgba(41,171,228,0.4) 1%, rgba(132,213,43,0.38139005602240894) 52%)'
                    })
                    .add();
                }
              })
            });
          },
          redraw: function () {
            var chart = this;
            $this.loadedGraphData = true;
            chart.series.forEach((data, i) => {
              let point = data.points[(data.points.length - 1)];
              $(`#usage-label-${i}`).remove();
              chart.renderer.text('Avg. time spent:' + '<br/>' + point.avg, point.plotX + chart.plotLeft + 5, point.plotY + chart.plotTop)
                .attr({ id: `usage-label-${i}` })
                .css({
                  fontSize: '12px',
                  color: '#666666'
                })
                .add();
              data.points.forEach((point, x) => {
                if (point.y > 0) {
                  let xOffset = 0
                  let yOffset = 0
                  if (x == (data.points.length - 1)) {
                    xOffset = 8
                  } else {
                    xOffset = -5
                  }
                  $(`#point-label-${x}`).remove();
                  chart.renderer.html('<div>' + point.y + '</div>', point.plotX - xOffset, point.plotY + chart.plotTop + 20)
                    .attr({ id: `point-label-${x}` })
                    .css({
                      fontSize: '12px',
                      color: '#000000',
                      // background: 'linear-gradient(180deg, rgba(41,171,228,0.4) 1%, rgba(132,213,43,0.38139005602240894) 52%)'
                    })
                    .add();
                }
              })
            });
          }
        },
      },
      colors: ['#003300', '#52BCC0'],
      title: {
        text: ''
      },
      xAxis: {
        categories: ['w1', 'w2', 'w3', 'w4'],
        gridLineColor: null,
        tickmarkPlacement: 'on',
        title: {
          enabled: false
        },
      },
      yAxis: {
        gridLineColor: null,

        title: {
          text: ''
        },
        labels: {
          formatter: function () {
            return null;
          }
        }
      },
      tooltip: {
        split: true,
        useHTML: true,
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.avg} </b></td></tr>',      // valueSuffix: ' millions'
      },
      legend: {
        align: 'center',
        verticalAlign: 'top',
        layout: 'horizontal',
        x: 0,
        y: 0
      },
      plotOptions: {
        area: {
          stacking: 'normal',
          lineColor: '#666666',
          lineWidth: 0,
          marker: {
            lineWidth: 0,
            lineColor: '#666666'
          }
        }
      },
      series: data
    });
  }
  addUser() {
    $('#getYouSetup').modal('hide')
    this.router.navigateByUrl('admin/add-user')
  }
  modalControl() {
    this.showContents = false;
    this.getUsers();
    this.getConnections();
    localStorage.removeItem('isFirstLogin');
    setTimeout(() => {
      this.graphData();
    }, 300);
  }
  openCustomPopUp() {
    $('#getYouSetup').modal('hide')
    $('#customise-popup').modal('show')
  }
  //Branding
  customiseBrand() {
    $('.modal').modal('hide')
    // $('#brand-popup').modal('show')
  }
  imageError: any = '';
  file: File;
  isProgress: any = false;
  brand: any = {};
  handleFileSelect(evt) {
    this.imageError = { error: false, message: '' };
    var _URL = window.URL || window.webkitURL;
    let files = evt.target.files;
    let file = files[0];
    let type = file.type.split('/')[0]
    if (type == 'image') {
      if (files && file) {
        if (file.size <= 5000000) {
          this.file = file;
          if (!this.isProgress) {
            this.isProgress = true;
            let formData = new FormData()
            formData.append('file', this.file)
            this.AuthService.upload('upload', formData, (err) => {
              this.isProgress = false
              console.log(err)
            }).subscribe((a: any) => {
              this.isProgress = false
              console.log(a)
              this.brand.logo = a.key;
              $('input[name="file_upload"').val('');
              $('input[name="file_name"').val('');
            })
          }
        } else {
          evt.target.value = ''
          this.imageError = { error: true, message: 'Image size should be less than 5mb (5000000 bytes)' };
        }
      }
    }
    else {
      evt.target.value = ''
      this.imageError = { error: true, message: 'Only image files are allowed' };
    }
  }
  openUpload() {
    $('#profile_pic_upload').click();
  }
  customerData: any = {};
  brandData: any = {};
  getDefaultData(partner_id?: string) {
    let apiString = 'custom_data'
    if (partner_id && partner_id != '') {
      apiString = apiString + '?partner_id=' + partner_id
    }
    this.AuthService.get(`${apiString}`, (err) => {
      console.log(err);
    }).subscribe((result: any) => {
      this.customerData = result.data;
      this.brand = {
        _id: this.customerData._id,
        partner_id: this.user.partner.partner_id,
        brand: this.customerData.brand,
        logo: this.customerData.logo
      }
    }, (err: any) => {
      // this.solutionStages = [];
      console.log(err);
    });
  }
  updateBrand() {
    this.AuthService.update('custom_data/' + this.brand._id + '/update', this.brand).subscribe((result: any) => {
      $('.modal').modal('hide');
      this.getDefaultData()
      this.CommonEmitter.updateBrand();
    })
  }
}
