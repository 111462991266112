import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonEmitterService } from 'src/app/services/common-emitter';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { AuthService } from 'src/app/services/auth.service';
declare var $: any;
@Component({
  selector: 'app-left-side-bar',
  templateUrl: './left-side-bar.component.html',
  styleUrls: ['./left-side-bar.component.css']
})
export class LeftSideBarComponent implements OnInit {
  href = this.router.url
  constructor(private router: Router, private AuthService: AuthService) {
    this.getAccess();
  }
  isAdmin: boolean = false;

  getAccess() {
    this.AuthService.get("user/me", () => { }).subscribe(
      (result: any) => {
        console.log("header says", result);
        this.isAdmin = result.data[0].admin_user;
      },
      err => {
        console.log(err);
      }
    );
  }
  ngOnInit(): void {
    console.log(this.href);
    // ------------------------------------------------------- //
    // Sidebar Functionality
    // ------------------------------------------------------ //
    $('.sidebar-toggle').on('click', function () {
      $(this).toggleClass('active');

      $('#sidebar').toggleClass('shrinked');
      $('.page-content').toggleClass('active');
      $(document).trigger('sidebarChanged');

      if ($('.sidebar-toggle').hasClass('active')) {
        $('.navbar-brand .brand-sm').addClass('visible');
        $('.navbar-brand .brand-big').removeClass('visible');
        // $(this).find('i').attr('class', 'fa fa-long-arrow-right');
      } else {
        $('.navbar-brand .brand-sm').removeClass('visible');
        $('.navbar-brand .brand-big').addClass('visible');
        // $(this).find('i').attr('class', 'fa fa-long-arrow-left');
      }
    });
  }
}
