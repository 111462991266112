<div style="height: 100vh; padding: 0; margin: 0"
    class="d-flex flex-column justify-content-between align-items-between">
    <div class="main-container">
        <!-- style="background-image: url(assets/background@3x.png);" -->
        <div style="height: 100%" class="row">
            <!-- <div class="col-lg-12 text-center" style="margin-top: 50px;">        
      </div> -->
            <div style="
          display: flex;
          justify-content: center;
          align-content: center;
          flex-direction: column;
          align-items: center;
        " class="col-lg-12">
                <img class="mb-4" width="240" src="./assets/ICONS/logo@2x.png" />
                <div class="d-flex justify-content-center align-items-center">
                    <div class="middle-container">
                        <div class="text-center" style="border-bottom: 2px solid black">
                            <h3 class="heading" style="color: black !important">
                                {{ title }}
                            </h3>
                        </div>
                        <div>
                            <router-outlet></router-outlet>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <!-- dummy div for Alignment -->
            </div>
        </div>
    </div>
    <div>
        <footer class="footer" style="width: 100%; height: 60px; background-color: #000">
            <div class="footNav">
                <a>&copy; Copyright Flyero 2020 {{ app_version }}</a>
                <a href="https://www.flyero.com/about" target="blank">About Flyero</a>
                <a href="javascript:void(0)" data-toggle="modal" data-target="#termsModal"><span>Terms and
                        Conditions</span></a>
                <!-- (click)="modalShow('termsModal')" -->
                <a href="javascript:void(0)"><span>Privacy Policy</span></a>
                <!-- (click)="modalShow('policyModal')" -->
            </div>
        </footer>
    </div>
</div>

<div class="modal" id="policyModal">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
                <div style="text-align: center; display: block">
                    <h6 class="modal-title">Privacy Guidelines</h6>
                </div>
                <button type="button" class="close" data-dismiss="modal">
                    &times;
                </button>
            </div>
            <div class="modal-body" style="text-align: left; display: inline">
                <div id="privacy_policy">
                    <!-- <iframe
            [src]="sanitizedPolicyUrl"
            style="border: none"
            height="400px"
            width="100%"
          ></iframe> -->
                </div>
            </div>
            <!-- Modal footer -->
            <div class="modal-footer" style="text-align: center; display: inline">
                <a type="button" class="btn mw-150 addbtn" data-dismiss="modal">OK</a>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="termsModal">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
                <div style="text-align: center; display: block">
                    <h6 class="modal-title">Terms and Conditions</h6>
                </div>
                <button type="button" class="close" data-dismiss="modal">
                    &times;
                </button>
            </div>
            <div class="modal-body" style="text-align: left; display: inline">
                <div id="terms_conditions">
                    <h1 style="text-align: center;"><strong>Introduction Agreement</strong><span
                            style="font-weight: 400;">&nbsp;</span></h1>
                    <h1><strong>1.Header Information</strong><span style="font-weight: 400;">&nbsp;</span></h1>
                    <p><span style="font-weight: 400;">The date of this agreement will be the date of the TGS account
                            creation
                            (the "Signing Date")</span><span style="font-weight: 400;">&nbsp;</span></p>
                    <p><span style="font-weight: 400;">This is an agreement between:</span><span
                            style="font-weight: 400;">&nbsp;</span></p>
                    <p><span style="font-weight: 400;">The Giving Stream Limited with its registered office at Soulbury
                            Road Farm,
                            Soulbury&nbsp;</span></p>
                    <p><span style="font-weight: 400;">Road, Burctt, LUti 0JP ("We/Us" in this agreement); &nbsp; &nbsp;
                            and</span><span style="font-weight: 400;">&nbsp;</span></p>
                    <p><span style="font-weight: 400;">YOU </span><span style="font-weight: 400;">&nbsp;("You" in this
                            agreement)</span><span style="font-weight: 400;">&nbsp;</span></p>
                    <h1><strong>2.The Background</strong><span style="font-weight: 400;">&nbsp;</span></h1>
                    <p><span style="font-weight: 400;">The background to this agreement is that You have various
                            contacts who may
                            be interested in buying Products from Us described in the Schedule ("the Product"). We would
                            like to be
                            introduced to the interested contacts and We are prepared to pay You commission, as
                            described in the
                            Schedule to this agreement, if those contacts do buy Products from Us. You are going to make
                            the
                            introductions to Us which are intended to produce sales.&nbsp;</span></p>
                    <h1><strong>3.Introductions</strong><span style="font-weight: 400;">&nbsp;</span></h1>
                    <p><span style="font-weight: 400;">3.1 We appoint You to identify potential clients for Us in the
                            Territory
                            set out in the Schedule to this Agreement, for the Term set out in the Schedule to this
                            Agreement, and to
                            make introductions of such potential clients on the terms of this agreement. You agree that
                            We may appoint
                            other introducers if We need to, but during the Term and in the Territory You will not
                            perform
                            introductions for anybody else who provides services similar to ours.</span><span
                            style="font-weight: 400;">&nbsp;</span></p>
                    <p><span style="font-weight: 400;">3.2 For an introduction to be valid and qualify under this
                            agreement three
                            things must happen;</span><span style="font-weight: 400;">&nbsp;</span></p>
                    <p><span style="font-weight: 400;">3.2.1 Firstly, the introduction must be to a person or business
                            resident or
                            having its main place of business in the Territory.</span><span
                            style="font-weight: 400;">&nbsp;</span>
                    </p>
                    <p><span style="font-weight: 400;">3.2.2 secondly, We must not at any time previously have provided
                            the
                            Services or any other goods or services to this person or business, and We must not have
                            been in genuine
                            negotiations to provide the Services in the six months before the date of your
                            introduction&nbsp;</span>
                    </p>
                    <p><span style="font-weight: 400;">3.2.3 all sales are to be conducted through the Your Farmer
                            APP&nbsp;&nbsp;</span></p>
                    <h1><strong>4.Your Promises</strong><span style="font-weight: 400;">&nbsp;</span></h1>
                    <p><span style="font-weight: 400;">4.1 You promise that:</span><span
                            style="font-weight: 400;">&nbsp;</span>
                    </p>
                    <p><span style="font-weight: 400;">4.1.1 You will not allow your own interests to conflict with your
                            responsibilities to Us under this Agreement;</span><span
                            style="font-weight: 400;">&nbsp;</span></p>
                    <p><span style="font-weight: 400;">4.1.2 You will do the best You possibly can to make introductions
                            to agreed
                            potential clients;</span><span style="font-weight: 400;">&nbsp;</span></p>
                    <p><span style="font-weight: 400;">4.1.3 You will upload real time sales information into the TGS
                            Sales
                            Portal; and You will comply with all instructions from Us as long as they are reasonable and
                            legal;</span><span style="font-weight: 400;">&nbsp;</span></p>
                    <p><span style="font-weight: 400;">4.1.4 You will not create the impression that You are authorised
                            to
                            actually enter into contracts or other commitments for Us, or incur any expense on our
                            behalf, and You
                            will not try to do that. Nor will You negotiate any terms for our Services or make any
                            promises about our
                            Services with potential clients, or produce any marketing materials about our Services
                            unless We have
                            approved them first.&nbsp;</span></p>
                    <h1><strong>5.Anti-bribery Compliance</strong><span style="font-weight: 400;">&nbsp;</span></h1>
                    <p><span style="font-weight: 400;">5.1 It's important to both of Us that You work ethically to
                            secure
                            introductions. So, You agree that You will:</span><span
                            style="font-weight: 400;">&nbsp;</span></p>
                    <p><span style="font-weight: 400;">5.1.1 comply with all laws, regulations and codes relating to
                            anti-bribery
                            and anti corruption which apply to your work for Us. This includes the piece of legislation
                            known as the
                            Bribery Act 2010. You will maintain throughout the Term of this Agreement any policies and
                            procedures
                            required to ensure this;</span><span style="font-weight: 400;">&nbsp;</span></p>
                    <p><span style="font-weight: 400;">5.1.2 quickly report to Us any request or demand for any undue
                            financial or
                            other advantage of any kind received by You in connection with the performance of this
                            Agreement;</span><span style="font-weight: 400;">&nbsp;</span></p>
                    <p><span style="font-weight: 400;">5.1.3 ensure that any person used by You in connection with
                            performing
                            introductions under this Agreement does so only on the basis of a written contract which
                            imposes on them
                            terms equivalent to those imposed on You in this Agreement. You will make sure that any such
                            contract is
                            complied with and be responsible to Us legally if it is not.&nbsp;</span></p>
                    <h1><strong>6.Data Protection</strong><span style="font-weight: 400;">&nbsp;</span></h1>
                    <p><span style="font-weight: 400;">6.1 The following definitions apply to this clause:</span><span
                            style="font-weight: 400;">&nbsp;</span></p>
                    <p><strong>Agreed Purposes:</strong><span style="font-weight: 400;"> The performance by each You and
                            Us of
                            obligations under this agreement.</span><span style="font-weight: 400;">&nbsp;</span></p>
                    <p><strong>Controller, data controller, processor, data processor, data subject, personal data,
                            processing and
                            appropriate technical and organisational measures:</strong><span style="font-weight: 400;">
                            have the
                            meanings set out in the Data Protection Legislation.</span><span
                            style="font-weight: 400;">&nbsp;</span>
                    </p>
                    <p><strong>Data Protection Legislation:</strong><span style="font-weight: 400;"> unless and until
                            the GDPR is
                            no longer directly applicable in the UK, the General Data Protection Regulation ((EU)
                            2016/6ti9) and any
                            national implementing laws, regulations and secondary legislation, as amended or updated
                            from time to
                            time, in the UK and then (ii) any successor legislation to the GDPR or the Data Protection
                            Act
                            1998.</span><span style="font-weight: 400;">&nbsp;</span></p>
                    <p><strong>Permitted Recipients:</strong><span style="font-weight: 400;"> You and Us and our
                            respective
                            employees, any third parties engaged to perform obligations in connection with this
                            agreement.&nbsp;</span></p>
                    <p><span style="font-weight: 400;">6.2 You and Us acknowledge that one (the Data Discloser) will
                            disclose to
                            the other (the Data Recipient) Shared Personal Data collected by the Data Discloser for the
                            Agreed
                            Purposes. You and Us both agree that each of us will:</span><span
                            style="font-weight: 400;">&nbsp;</span>
                    </p>
                    <p><span style="font-weight: 400;">6.2.1 ensure all necessary consents and notices to enable the
                            lawful
                            transfer of the Shared Personal Data to the Data Recipient for the Agreed Purposes have been
                            put in
                            place;</span><span style="font-weight: 400;">&nbsp;</span></p>
                    <p><span style="font-weight: 400;">6.2.2 give full information of the nature of any processing to
                            any data
                            subject whose personal data may be processed under this agreement;</span><span
                            style="font-weight: 400;">&nbsp;</span></p>
                    <p><span style="font-weight: 400;">6.2.3 process the Shared Personal Data only for the Agreed
                            Purposes;</span><span style="font-weight: 400;">&nbsp;</span></p>
                    <p><span style="font-weight: 400;">6.2.4 not disclose or allow access to the Shared Personal Date to
                            anyone
                            other than the PermiTTed Recipients;</span><span style="font-weight: 400;">&nbsp;</span></p>
                    <p><span style="font-weight: 400;">6.2.5 ensure that the PermiTTed Recipients are subject to
                            contractual
                            obligations regarding Shared Personal Data no less onerous than this agreement;</span><span
                            style="font-weight: 400;">&nbsp;</span></p>
                    <p><span style="font-weight: 400;">6.2.6 ensure that You and Us have in place appropriate technical
                            and
                            organisational measures to protect against unauthorised or unlawful processing of,
                            accidental loss,
                            destruction or damage of personal data;</span><span style="font-weight: 400;">&nbsp;</span>
                    </p>
                    <p><span style="font-weight: 400;">6.2.7 not transfer any personal data outside of the EEA without
                            the prior
                            written consent of the data subject unless they have i) complied with the provisions of
                            Article 26 of the
                            GDPR and ii) the transferring party complies with its obligations under Articles 45 and 46
                            of the GDPR or
                            one of the exceptions in Article 49 of the GDPR applies.</span><span
                            style="font-weight: 400;">&nbsp;</span></p>
                    <p><span style="font-weight: 400;">6.3 We and You agree that both of us will comply with the Data
                            Protection
                            Legislation. In the event that either You or Us commits a material breach of the Data
                            Protection
                            Legislation that is not remedied within 30 days of a written notice, the other party may
                            terminate this
                            agreement immediately.</span><span style="font-weight: 400;">&nbsp;</span></p>
                    <p><span style="font-weight: 400;">6.4 Both You and Us will assist the other in complying with all
                            applicable
                            requirements of the Data Protection Legislation. This includes:</span><span
                            style="font-weight: 400;">&nbsp;</span></p>
                    <p><span style="font-weight: 400;">6.4.1 consulting with the other about notices given to data
                            subjects
                            regarding Shared Personal Data;</span><span style="font-weight: 400;">&nbsp;</span></p>
                    <p><span style="font-weight: 400;">6.4.2 informing the other about the receipt of a data subject
                            request and
                            providing reasonable assistance;</span><span style="font-weight: 400;">&nbsp;</span></p>
                    <p><span style="font-weight: 400;">6.4.3 notifying the other without undue delay on becoming aware
                            of a breach
                            of the Data Protection Legislation;</span><span style="font-weight: 400;">&nbsp;</span></p>
                    <p><span style="font-weight: 400;">6.4.4 on the request of the Data Discloser deleting or returning
                            Shared
                            Personal Data including any copies, on termination unless required by law to continue to
                            store
                            it;</span><span style="font-weight: 400;">&nbsp;</span></p>
                    <p><span style="font-weight: 400;">6.4.5 maintain complete and accurate records and information to
                            demonstrate
                            compliance with this clause 6.</span><span style="font-weight: 400;">&nbsp;</span></p>
                    <p><span style="font-weight: 400;">6.5 You and Us agree to indemnify and keep indemnified and defend
                            at their
                            own expense the other against all costs, claims, damages or expenses incurred by the other
                            or for which
                            the other may become liable due to any failure by one of us to comply with its obligations
                            under this
                            clause 6.</span><span style="font-weight: 400;">&nbsp;</span></p>
                    <h1><strong>7.Commission and Payment</strong><span style="font-weight: 400;">&nbsp;</span></h1>
                    <p><span style="font-weight: 400;">7.1 You shall be entitled to commission ("Commission") if a
                            potential
                            client who is a qualifying introduction from You purchases product from Us ("A Completed
                            Purchase").</span><span style="font-weight: 400;">&nbsp;</span></p>
                    <p><span style="font-weight: 400;">7.2 The amount of Commission payable is set out in the Schedule
                            to this
                            Agreement, as is the period for which such Commission shall be due to You and the sums on
                            which such
                            Commission shall be calculated. We will promptly make the following information available to
                            you through
                            the TGS Sales Portal:&nbsp;</span></p>
                    <p><span style="font-weight: 400;">7.2.1 the date We enter into a Completed Purchase</span><span
                            style="font-weight: 400;">&nbsp;</span></p>
                    <p><span style="font-weight: 400;">7.2.2 the amount of the payments due for our Products under that
                            Completed
                            Purchase with that client;</span><span style="font-weight: 400;">&nbsp;</span></p>
                    <p><span style="font-weight: 400;">7.2.3 the dates on which payments for such Purchases are
                            payable.</span><span style="font-weight: 400;">&nbsp;</span></p>
                    <p><span style="font-weight: 400;">7.3 Any Commission payable will be due to You within 10 days of
                            the end of
                            the calendar month in which We received the corresponding payment for the Product (or 10
                            days aher We
                            receive your invoice if that is a later date). We will pay You Commission in the same
                            currency in which We
                            receive it.</span><span style="font-weight: 400;">&nbsp;</span></p>
                    <p><span style="font-weight: 400;">7.4 The TGS Sales Portal will provide all information on the
                            sales
                            received. You are responsible for accessing the TGS Sales Portal and keeping the information
                            up-to-date.
                            The TGS Sales Portal will also provide the following information:</span><span
                            style="font-weight: 400;">&nbsp;</span></p>
                    <p><span style="font-weight: 400;">7.4.1 the payments for Products received and details of any sums
                            due which
                            have not been received;</span><span style="font-weight: 400;">&nbsp;</span></p>
                    <p><span style="font-weight: 400;">7.4.2 how the Commission has been calculated, including details
                            of all
                            deductions made.</span><span style="font-weight: 400;">&nbsp;</span></p>
                    <p><span style="font-weight: 400;">7.5 You will invoice Us for the Commission payable shown on the
                            TGS Sales
                            Portal, together with any VAT which may be due and We will pay that invoice within 10 days
                            of receiving
                            it. Invoices are due to be submiTTed at the end of each calendar month. </span><span
                            style="font-weight: 400;">&nbsp;</span></p>
                    <p><span style="font-weight: 400;">7.6 Where a potential client is introduced by You and the
                            potential client
                            then introduces Us to a third party who buys Products from Us, You will not (because of the
                            initial
                            introduction) be responsible for the subsequent introduction and so no Commission shall
                            apply to that
                            subsequent introduction.</span><span style="font-weight: 400;">&nbsp;</span></p>
                    <h1><strong>8.Termination</strong><span style="font-weight: 400;">&nbsp;</span></h1>
                    <p><span style="font-weight: 400;">8.1 Either You or We may at any time end this agreement
                            immediately by
                            giving a written notice to the other, providing 14 days notice. We reserve the right to
                            Terminate the
                            agreement with immediate effect if You breach the terms of this agreement. </span><span
                            style="font-weight: 400;">&nbsp;</span></p>
                    <h1><strong>9.TGS Sales Portal </strong><span style="font-weight: 400;">&nbsp;</span></h1>
                    <p><span style="font-weight: 400;">9.1 We will provide access to the TGS Sales Portal so You have
                            visibility
                            of all sales generated by Your introductions. Access to the TGS Sales Portal will be removed
                            on
                            Termination of this Agreement and in the event You breach the conditions of this Agreement.
                        </span><span style="font-weight: 400;">&nbsp;</span></p>
                    <h1><strong>10.Independent Status</strong><span style="font-weight: 400;">&nbsp;</span></h1>
                    <p><span style="font-weight: 400;">10.1 You are a consultant called "an independent contractor", and
                            nothing
                            in this agreement involves Us creating a partnership, or a joint venture or makes one of Us
                            an employer or
                            employee of the other.</span><span style="font-weight: 400;">&nbsp;</span></p>
                    <h1><strong>11.General</strong><span style="font-weight: 400;">&nbsp;</span></h1>
                    <p><span style="font-weight: 400;">11.1 This agreement is the whole agreement between
                            Us.</span><span style="font-weight: 400;">&nbsp;</span></p>
                    <p><span style="font-weight: 400;">11.2 If either of Us doesn't exercise a right or remedy
                            straightaway, that
                            doesn't mean We can't exercise it later.</span><span style="font-weight: 400;">&nbsp;</span>
                    </p>
                    <p><span style="font-weight: 400;">11.3 Any changes to this agreement must be agreed in writing
                            between
                            Us.</span><span style="font-weight: 400;">&nbsp;</span></p>
                    <p><span style="font-weight: 400;">11.4 We can each assign this agreement to any subsidiary or
                            parent company,
                            or an affiliated company, or to any purchaser of all or most of our assets. Apart from that,
                            neither of Us
                            can assign the agreement, or assign or licence any of our rights to anybody
                            else.</span><span style="font-weight: 400;">&nbsp;</span></p>
                    <p><span style="font-weight: 400;">11.5 Nobody other than a signatory to this agreement has any
                            rights under
                            this agreement</span><span style="font-weight: 400;">&nbsp;</span></p>
                    <p><span style="font-weight: 400;">11.6 Separate copies of this agreement can be signed by each of
                            this and
                            together they will be taken as the whole agreement between Us.</span><span
                            style="font-weight: 400;">&nbsp;</span></p>
                    <h1><strong>12.No)ces</strong><span style="font-weight: 400;">&nbsp;</span></h1>
                    <p><span style="font-weight: 400;">12.1 Any notice under this agreement, must be in writing and must
                            be
                            delivered personally, or sent by pre-paid first class post or recorded delivery to the
                            other. Any notice
                            will be assumed to have been received;</span><span style="font-weight: 400;">&nbsp;</span>
                    </p>
                    <p><span style="font-weight: 400;">12.1.1 If delivered personally, when it is leh at the right
                            address and for
                            the right person;</span><span style="font-weight: 400;">&nbsp;</span></p>
                    <p><span style="font-weight: 400;">12.1.2 If sent by pre-paid post or recorded delivery, at 9am on
                            the second
                            business day aher posting.</span><span style="font-weight: 400;">&nbsp;</span></p>
                    <h1><strong>13.Law and Jurisdiction</strong><span style="font-weight: 400;">&nbsp;</span></h1>
                    <p><span style="font-weight: 400;">13.1 The Parties will use their best efforts to negotiate in good
                            faith and
                            seEle any dispute that may arise out of or relate to this Agreement or any breach of
                            it.</span><span style="font-weight: 400;">&nbsp;</span></p>
                    <p><span style="font-weight: 400;">13.2 If any such dispute cannot be seEled amicably through
                            ordinary
                            negotiations between the Parties, or either or both is or are unwilling to engage in this
                            process, either
                            Party may propose to the other in writing that structured negotiations be entered into with
                            the assistance
                            of a fully accredited mediator before resorting to litigation.</span><span
                            style="font-weight: 400;">&nbsp;</span></p>
                    <p><span style="font-weight: 400;">13.3 If the Parties are unable to agree upon a mediator, or if
                            the mediator
                            agreed upon is unable or unwilling to act and an alternative mediator cannot be agreed, any
                            party may
                            within 14 days of the date of knowledge of either event apply to LawBite to appoint a
                            mediator under the
                            LawBite Media On Procedure.</span><span style="font-weight: 400;">&nbsp;</span></p>
                    <p><span style="font-weight: 400;">13.4 Within 14 days of the appointment of the mediator (either by
                            mutual
                            agreement of the Parties or by LawBite in accordance with their mediation procedure), the
                            Parties will
                            meet with the mediator to agree the procedure to be adopted for the mediation, unless
                            otherwise agreed
                            between the parties and the mediator.</span><span style="font-weight: 400;">&nbsp;</span>
                    </p>
                    <p><span style="font-weight: 400;">13.5 All negotiations connected with the relevant dispute(s) will
                            be
                            conducted in confidence and without prejudice to the rights of the Parties in any further
                            proceedings.</span><span style="font-weight: 400;">&nbsp;</span></p>
                    <p><span style="font-weight: 400;">13.6 If the Parties agree on a resolution of the dispute at
                            mediation, the
                            agreement shall be reduced to writing and, once signed by the duly authorised
                            representatives of both
                            Parties, shall be final and binding on them.</span><span
                            style="font-weight: 400;">&nbsp;</span></p>
                    <p><span style="font-weight: 400;">13.7 If the Parties fail to resolve the dispute(s) within 60 days
                            (or such
                            longer term as may be agreed between the Parties) of the mediator being appointed, or if
                            either Party
                            withdraws from the mediation procedure, then either Party may exercise any right to seek a
                            remedy through
                            arbitration by an arbitrator to be appointed by LawBite under the Rules of the LawBite
                            Arbitration
                            Scheme.</span><span style="font-weight: 400;">&nbsp;</span></p>
                    <p><span style="font-weight: 400;">13.8 Any dispute shall not affect the Parties' ongoing
                            obligations under
                            the Agreement.</span><span style="font-weight: 400;">&nbsp;</span></p>
                    <p><span style="font-weight: 400;">13.9 This agreement and any dispute or claim arising out of it
                            will be
                            interpreted using English law. We both agree that the courts of England and Wales will be
                            the only place
                            where disputes or claims can be decided</span><span style="font-weight: 400;">&nbsp;</span>
                    </p>
                    <h1><strong>14.Signing</strong><span style="font-weight: 400;">&nbsp;</span></h1>
                    <p><span style="font-weight: 400;">By Clicking I Accept the Terms of the Introduction Agreement when
                            creating
                            your TGS Online Portal access, You confirm acceptance of the terms of this Introduction
                            Agreement.
                        </span><span style="font-weight: 400;">&nbsp;</span></p>
                    <p><span
                            style="font-weight: 400;">______________________________________________________________________</span><span
                            style="font-weight: 400;">&nbsp;</span></p>
                    <h1><span style="font-weight: 400;">&nbsp;</span> <strong>Schedule</strong><span
                            style="font-weight: 400;">&nbsp;</span></h1>
                    <p><span style="font-weight: 400;">This Schedule is part of the Introduction Agreement ("the
                            Agreement")
                            between [WE/ US] and [YOU]. This Schedule refers to some terms that We have agreed in
                            relation to the
                            services You will provide:&nbsp;</span></p>
                    <ol>
                        <li style="font-weight: 400;"><strong>"Territory":</strong> <span
                                style="font-weight: 400;">United
                                Kingdom&nbsp;&nbsp;</span></li>
                        <li style="font-weight: 400;"><strong>"Term":</strong><span
                                style="font-weight: 400;">&nbsp;</span></li>
                    </ol>
                    <p><span style="font-weight: 400;">12 Month Rolling Contract&nbsp;&nbsp;</span></p>
                    <ol>
                        <li style="font-weight: 400;"><strong>"Product":</strong><span
                                style="font-weight: 400;">&nbsp;</span></li>
                    </ol>
                    <p><span style="font-weight: 400;">The Product is Farm Finest food produced by the Your Farmer
                            brand. This
                            consists of meats, vegetables and oven ready meals.&nbsp;&nbsp;</span></p>
                    <ol>
                        <li style="font-weight: 400;"><strong>"Commission":</strong><span
                                style="font-weight: 400;">&nbsp;</span>
                        </li>
                    </ol>
                    <p><span style="font-weight: 400;">10% Commission of the Sales Revenue received from an introduced
                            contract on
                            completed sales. Commission is payable on all repeat orders generated from Your contacts for
                            the duration
                            of the Introduction Agreement.&nbsp; </span></p>
                    <!-- <iframe [src]="sanitizedPolicyUrl" style="border: none" height="400px" width="100%"></iframe> -->

                    <!-- <iframe [src]="sanitizedTermsUrl" style="border: none" height="400px" width="100%"></iframe> -->
                </div>
            </div>
            <!-- Modal footer -->
            <div class="modal-footer" style="text-align: center; display: inline">
                <a type="button" class="btn mw-150 addbtn" data-dismiss="modal">OK</a>
            </div>
        </div>
    </div>
</div>