<!-- //********** show loader till table data is fetched *********// -->
<div class="row" *ngIf="!activitiesList || activitiesList.length == 0" style="height: 100%;">
  <div class="col-12" style="height: 100%;">
    <div style="height: 100%;" class="d-flex justify-content-center align-items-center">
      <div *ngIf="!activitiesList" class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div *ngIf="activitiesList && activitiesList.length == 0">
        <div class="row mrgbtm">
          <div class="col-md-12">
            <div align="center" class="errorMessage" style="color:#4292C6 ">
              No Activities to Display. Add activity.
            </div>
          </div>
        </div>
        <div class="row mrgbtm">
          <div class="col-md-12">
            <div align="center">
              <button [routerLink]="['/create-activity']" type="button" class="btn addbtn">
                Create New Activity
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- //********** Show table after table data is fetched *********// -->

<div class="table_margin_top" *ngIf="activitiesList && activitiesList.length > 0">
  <div class="row">
    <div class="col-md-8 col-12">
      <h3></h3>
    </div>
    <div class="col-md-4 col-12">
      <div class="tbl_in_icon">
        <a href="" [routerLink]="['/create-activity']">
          <i class="fa fa-plus-circle fc-blue" aria-hidden="true" style="color: gray;"></i>
        </a>
        <i class="fa fa-filter" aria-hidden="true"></i>
        <i class="fa fa-cog" aria-hidden="true"></i>
      </div>
    </div>
  </div>


  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="pad_div">
        <div class="maintbl">
          <table class="table solntbl">
            <thead class="thead-dark">
              <tr>
                <th scope="col">Customer</th>
                <th scope="col">Partner</th>
                <th scope="col">Activity</th>
                <th scope="col" style="width: 10%;">Priority</th>
                <th scope="col" style="text-align: center;">Person</th>
                <th scope="col">Target End Date</th>
                <th scope="col">Completion Date</th>
                <th scope="col">Approval status</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let eachActivity of activitiesList; index as i">
                <!--(click)="enableUpdate(eachActivity)"-->
                <td>
                  <span class="fc-blue fz-16">{{ eachActivity.customer_name || "None" }}</span>
                </td>
                <td>
                  <span class="fc-blue fz-16" *ngIf="eachActivity.partners && eachActivity.partners.length">
                    <span
                      *ngFor="let partner of eachActivity.partners;index as i">{{ partner}}{{i == (eachActivity.partners.length -1) ? '': ','}}</span>
                  </span>
                  <span class="fc-blue fz-16" *ngIf="!(eachActivity.partners && eachActivity.partners.length)">
                    Internal
                  </span>
                </td>
                <td><span class="fc-blue fz-16">{{ eachActivity.activity }}</span></td>
                <td style="width: 10%;">
                  <div style="padding: 5px 10px;" class="fz-16" [ngClass]="['priority', eachActivity.priority]">
                    {{ eachActivity.priority | titlecase }}</div>
                </td>
                <td>
                  <select class="ui search dropdown stage fz-16">
                    <option *ngIf="eachActivity.assignee.length>1">Display List</option>
                    <option *ngFor="let u of  eachActivity.assignee"> {{u}}</option>
                    <option *ngIf="!eachActivity.assignee"> {{userData.email}}</option>
                  </select>
                </td>
                <td><span class="fc-blue fz-16">{{ eachActivity.end_date|date:'MM/dd/yyyy' }}</span></td>
                <td>
                  <span class="fc-blue fz-16"
                    *ngIf="eachActivity.completion_date">{{eachActivity.completion_date|date:'MM/dd/yyyy'}}</span>
                  <!-- <a class="fc-blue"  *ngIf="!eachActivity.completion_date" href="javascript:;" (click)="enableUpdate(eachActivity)"><span _ngcontent-bcv-c147="" class="material-icons"> create </span></a> -->
                </td>
                <td>
                  <span class="fc-blue fz-16">{{ eachActivity.approval_status }}</span>
                </td>
                <td>
                  <a [ngClass]="eachActivity.isEdit ?'fc-blue':'disabled'"
                    [routerLink]="['/edit-activity',eachActivity.id]"><span _ngcontent-bcv-c147=""
                      class="material-icons"> create
                    </span></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div class="modal" id="AddActivity" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-dialog-centered" style="width: 38%;">
    <div class="modal-content">
      <div class="modal-body">
        <app-add-activity [defaultActivity]="selectedActivity" [callBack]="closeModal.bind(this)"></app-add-activity>
      </div>
    </div>
  </div>
</div> -->