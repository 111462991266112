<!-- //********** show loader till table data is fetched *********// -->
<div class="row" *ngIf="!partnersList || partnersList.length == 0" style="height: 100%;">
  <div class="col-12" style="height: 100%;">
    <div style="height: 100%;" class="d-flex justify-content-center align-items-center">
      <div *ngIf="!partnersList" class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div *ngIf="partnersList && partnersList.length == 0">
        <div class="row mrgbtm">
          <div class="col-md-12">
            <div align="center" class="errorMessage" style="color:#4292C6 ">
              No Partners to Display. Add partner.
            </div>
          </div>
        </div>
        <div class="row mrgbtm">
          <div class="col-md-12">
            <div align="center">
              <button [routerLink]="['/create-partner']" type="button" class="btn addbtn">
                Create New Partner
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- //********** Show table after table data is fetched *********// -->

<div class="table_margin_top" *ngIf="partnersList && partnersList.length > 0">
  <div class="row">
    <div class="col-md-8 col-12">
      <h3></h3>
    </div>
    <div class="col-md-4 col-12">
      <div class="tbl_in_icon">
        <a href="" [routerLink]="['/create-partner']">
          <i class="fa fa-plus-circle fc-blue" aria-hidden="true" style="color: gray;"></i>
        </a>
        <i class="fa fa-filter" aria-hidden="true"></i>
        <i class="fa fa-cog" aria-hidden="true"></i>
      </div>
    </div>
  </div>


  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="pad_div">
        <div class="maintbl">
          <table class="table solntbl">
            <thead class="thead-dark">
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Owner</th>
                <th scope="col">Owner Partner</th>
                <!-- <th scope="col">Country</th> -->
                <!-- <th scope="col">Type</th> -->
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let eachPartner of partnersList; index as i">
                <!--(click)="enableUpdate(eachPartner)"-->
                <td>
                  <span class="fc-blue fz-16">{{ eachPartner.partner_name || "None" }}</span>
                </td>
                <td>
                  <span class="fc-blue fz-16">{{ eachPartner.email || "None" }}</span>
                </td>
                <td><span class="fc-blue fz-16">{{ eachPartner.owner_partner_name }}</span></td>
                <!-- <td>
                  {{ eachPartner.organization }}
                </td>
                <td>
                  {{ eachPartner.type }}
                </td> -->
                <!--  -->
                <td>
                  <a [ngClass]="" [routerLink]="['/edit-partner',eachPartner.partner_id]"><span _ngcontent-bcv-c147=""
                      class="material-icons"> create
                    </span></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div class="modal" id="AddPartner" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-dialog-centered" style="width: 38%;">
    <div class="modal-content">
      <div class="modal-body">
        <app-add-partner [defaultPartner]="selectedPartner" [callBack]="closeModal.bind(this)"></app-add-partner>
      </div>
    </div>
  </div>
</div> -->