export class User {
    public admin_user;
    public country_code;
    public email;
    public first_name;
    public last_name;
    public middle_name;
    public partner: partner;
    public phone_number;
    public subscription_data;
    public super_owner;
    public user_id;
    public password;
    public confirmPassword;
    public profile_pic;
    public subscription_type_data;
    public subscriber_subscription_type_data;
}
export class partner {
    public country_code;
    public email_domain;
    public partner_ext_id;
    public partner_id;
    public partner_name;
}

