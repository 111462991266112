<div *ngIf="
    (plans.length < 1  && loadedData.plans )&&
    (solutions.length < 1 && loadedData.solutions )&&
    (opportunities.length < 1 && loadedData.opportunities )
  " class="row" style="height: 100%;">
  <div *ngIf="!loadedData.plans && !loadedData.solutions && !loadedData.opportunities" class="col-12"
    style="height: 100%;">
    <div style="height: 100%;" class="d-flex justify-content-center align-items-center">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
  <div class="col-12" style="height: 100%;">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header" style="text-align: center; display: inline; border: 0;">
          <h4 class="modal-title fz-30">Start sharing with your Partners!</h4>
        </div>

        <!-- Modal body -->
        <div class="modal-body" style="text-align: left; display: inline;">
          <div class="row mrgbtm">
            <div class="col-md-12">
              <div class="each-route">
                <div class="icon-div" style="color: #ffa823 !important;">
                  <span (click)="connectionRedirect('create-plan')" class="material-icons work fz-34">
                    work
                  </span>
                </div>
                <div style="display: flex; flex-direction: column;">
                  <div>
                    <h5>
                      <a (click)="connectionRedirect('create-plan')" class="fz-24 fc-gray-1"
                        href="javascript:void(0)">Make Plans..</a>
                    </h5>
                  </div>
                  <div>
                    <p class="fz-18">
                      Create network specific goals and track together
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mrgbtm">
            <div class="col-md-12">
              <div class="each-route">
                <div class="icon-div" style="color: #ff3366 !important;">
                  <i (click)="connectionRedirect('create-solution')" class="fa fa-gears settings fz-34">
                  </i>
                </div>
                <div style="display: flex; flex-direction: column;">
                  <div>
                    <h5>
                      <a (click)="connectionRedirect('create-solution')" class="fz-24 fc-gray-1"
                        href="javascript:void(0)">Create Solutions..</a>
                    </h5>
                  </div>
                  <div>
                    <p class="fz-18">
                      Joint Solutions leveraging each partner's strengths
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mrgbtm">
            <div class="col-md-12">
              <div class="each-route">
                <div class="icon-div" style="color: #405094 !important;">
                  <span (click)="connectionRedirect('create-opportunity')" class="material-icons fz-34">
                    filter_alt
                  </span>
                </div>
                <div style="display: flex; flex-direction: column;">
                  <div>
                    <h5>
                      <a (click)="connectionRedirect('create-opportunity')" class="fz-24 fc-gray-1"
                        href="javascript:void(0)">Manage Pipelines</a>
                    </h5>
                  </div>
                  <div>
                    <p class="fz-18">
                      Opportunities, Deals, Forecasts, Activities
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div [hidden]="
    !(
      plans.length > 0 ||
      solutions.length > 0 ||
      opportunities.length > 0
    )
  " style="
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 60px;
  ">
  <!-- *ngIf="connections.length" -->
  <div style="
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    ">
    <div class="row" style="height: 150px;">
      <div class="col-md card-wrapper">
        <div class="custom-card-1 plan" style="cursor: pointer;" (click)="selectedTable = 'plans'">
          <p class="card-title-custom">PLANS</p>
          <p class="card-number-custom">{{ plans.length }}</p>
        </div>
      </div>
      <div class="col-md card-wrapper">
        <div class="custom-card-1 solution" style="cursor: pointer;" (click)="selectedTable = 'solutions'">
          <p class="card-title-custom">SOLUTIONS</p>
          <p class="card-number-custom">{{ solutions.length }}</p>
        </div>
      </div>
      <div class="col-md card-wrapper">
        <div class="custom-card-1 opportunity" style="cursor: pointer;" (click)="selectedTable = 'opportunities'">
          <p class="card-title-custom">OPPORTUNITIES</p>
          <p class="card-number-custom">{{ opportunities.length }}</p>
        </div>
      </div>
    </div>
    <div class="plan-container" [hidden]="selectedTable != 'plans'">
      <!-- && plans.length < 1 -->
      <div class="row graph-container">
        <div class="col" *ngIf="!graph.plan">
          <p class="text-center" style="margin: 25% 0;">
            No plans found.
          </p>
        </div>
        <div class="col" id="pie-1" *ngIf="graph.plan">
        </div>
        <div class="col" id="bar-1" style="padding: 0px 10%; overflow: hidden;"></div>
      </div>
      <div class="row mrgbtm">
        <div class="col-md-8 col-12">
          <h3></h3>
        </div>
        <div class="col-md-4 col-12">
          <div class="tbl_in_icon">
            <a routerLink="/create-plan">
              <i class="fa fa-plus-circle fc-blue" aria-hidden="true"></i>
            </a>
            <a href="javascript:;" data-toggle="modal" data-target="#filterModal">
              <i class="fa fa-filter fc-blue" aria-hidden="true"></i>
            </a>
            <i class="fa fa-cog" aria-hidden="true"></i>
          </div>
        </div>
      </div>
      <div class="table-wrapper">
        <table mat-table [dataSource]="planData" matSort #planSort="matSort">
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Plan Id</th>
            <td mat-cell *matCellDef="let element">
              <a [routerLink]="['/plan-detail', element.id]">{{
                element.id
              }}</a>
            </td>
          </ng-container>
          <ng-container matColumnDef="plan_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Plan Name
            </th>
            <td mat-cell *matCellDef="let element">{{ element.plan_name }}</td>
          </ng-container>
          <ng-container matColumnDef="partners">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Partners</th>
            <td mat-cell *matCellDef="let element">
              <span *ngFor="
                  let item of element.partnerName;let i=index">
                {{ item }}{{i<element.partnerName.length-1?', ':''}}
              </span>
              <span *ngIf="!element.partnerName.length">Internal</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="target">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Target
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.target | number }}
            </td>
          </ng-container>
          <ng-container matColumnDef="achievement">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Revenue
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.archivement | number }}
            </td>
          </ng-container>
          <ng-container matColumnDef="oppy">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              No of Opportunities
            </th>
            <td mat-cell *matCellDef="let element">{{ element.oppy }}</td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="planColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: planColumns"></tr>
        </table>
      </div>
      <mat-paginator hidden #planPaginator="matPaginator" [pageSize]="4">
      </mat-paginator>
      <div class="view-all mb-4">
        <a [routerLink]="['/plan-dashboard']"> View All</a>
      </div>
    </div>
    <div class="solution-container" [hidden]="selectedTable != 'solutions'">
      <div class="row graph-container">
        <div class="col" *ngIf="!graph.solution">
          <p class="text-center" *ngIf="!graph.solution || !solutionGraphData.length" style="margin: 25% 0;">
            No solutions found.
          </p>
        </div>
        <div class="col" id="pie-2" *ngIf="graph.solution">

        </div>
        <div class="col" style="padding: 0 10%;" id="bar-2"></div>
      </div>
      <div class="row mrgbtm">
        <div class="col-md-8 col-12">
          <h3></h3>
        </div>
        <div class="col-md-4 col-12">
          <div class="tbl_in_icon">
            <a routerLink="/create-solution">
              <i class="fa fa-plus-circle fc-blue" aria-hidden="true"></i>
            </a>
            <a href="javascript:;" data-toggle="modal" data-target="#filterModal">
              <i class="fa fa-filter fc-blue" aria-hidden="true"></i>
            </a>
            <i class="fa fa-cog" aria-hidden="true"></i>
          </div>
        </div>
      </div>
      <div class="table-wrapper">
        <table [hidden]="solutions.length < 1" mat-table [dataSource]="solutionData" #solutionSort="matSort" matSort
          (matSortChange)="sortData($event)">
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Solution Id
            </th>
            <td mat-cell *matCellDef="let element">
              <a [routerLink]="['/edit-solution', element.id]">{{
                element.id
              }}</a>
            </td>
          </ng-container>
          <ng-container matColumnDef="solution_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Solution Name
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.solution_name }}
            </td>
          </ng-container>
          <ng-container matColumnDef="solution_stage">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Solution Stage
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.solution_stage }}
            </td>
          </ng-container>
          <ng-container matColumnDef="revenue">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Target</th>
            <td mat-cell *matCellDef="let element">
              {{ element.revenue | number }}
            </td>
          </ng-container>
          <ng-container matColumnDef="archivement">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Revenue</th>
            <td mat-cell *matCellDef="let element">
              {{ element.archivement | number }}
            </td>
          </ng-container>
          <ng-container matColumnDef="oppy">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              No of Opportunities
            </th>
            <td mat-cell *matCellDef="let element">{{ element.oppy }}</td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="solutionColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: solutionColumns"></tr>
        </table>
      </div>
      <mat-paginator hidden #solutionPaginator="matPaginator" [pageSize]="4">
      </mat-paginator>
      <div class="view-all mb-4">
        <a [routerLink]="['/solution-dashboard']"> View All</a>
      </div>
    </div>
    <div class="opportunity-container" [hidden]="selectedTable != 'opportunities'">
      <div class="row graph-container">
        <div class="col" style="padding-bottom: 35px;" *ngIf="!graph.opportunity">
          <p class="text-center" *ngIf="!graph.opportunity" style="margin: 25% 0;">
            No opportunity found
          </p>
        </div>
        <div class="col" id="pie-3" style="padding-bottom: 35px;" *ngIf="graph.opportunity">
        </div>
        <div class="col" style="padding: 0 10%;" id="bar-3"></div>
      </div>
      <div class="row mrgbtm">
        <div class="col-md-8 col-12">
          <h3></h3>
        </div>
        <div class="col-md-4 col-12">
          <div class="tbl_in_icon">
            <a data-toggle="modal" data-target="#opportunityModal">
              <i class="fa fa-plus-circle fc-blue" aria-hidden="true"></i>
            </a>
            <a href="javascript:;" data-toggle="modal" data-target="#filterModal">
              <i class="fa fa-filter fc-blue" aria-hidden="true"></i>
            </a>

            <i class="fa fa-cog" aria-hidden="true"></i>
          </div>
        </div>
      </div>
      <div class="table-wrapper">
        <table [hidden]="opportunities.length < 1" mat-table [dataSource]="opportunityData" #opportunitySort="matSort"
          matSort>
          <ng-container matColumnDef="id">
            /edit-opportunity
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Oppty Id</th>
            <td mat-cell *matCellDef="let element">
              <a [routerLink]="['/edit-opportunity', element.id]">{{
                element.id
              }}</a>
            </td>
          </ng-container>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Opportunity Name
            </th>
            <td mat-cell *matCellDef="let element">{{ element.name }}</td>
          </ng-container>
          <ng-container matColumnDef="users">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Partners</th>
            <td mat-cell *matCellDef="let element">
              <span *ngFor="
                  let item of element.partnerName;let i=index">
                {{ item }}{{i<element.partnerName.length-1?', ':''}}
              </span>
              <span *ngIf="!element.partnerName.length">Internal</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Value
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.value | number }}
            </td>
          </ng-container>
          <ng-container matColumnDef="stage">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Stage</th>
            <td mat-cell *matCellDef="let element">{{ element.stage }}</td>
          </ng-container>
          <ng-container matColumnDef="deal_status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
            <td mat-cell *matCellDef="let element">
              {{ element.deal_status }}
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="opportunityColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: opportunityColumns"></tr>
        </table>
      </div>
      <mat-paginator hidden #opportunityPaginator="matPaginator" [pageSize]="4">
      </mat-paginator>
      <div class="view-all mb-4">
        <a [routerLink]="['/opportunity-dashboard']"> View All</a>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="filterModal" *ngIf="loadedData.plans && loadedData.solutions && loadedData.opportunities">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal body -->
      <div class="modal-body">
        <form action="">
          <div class="form-group">
            <label for="recipient-name" class="col-form-label">Filter By:</label>
            <a href="javascript:;" class="float-right" (click)="resetFilter()">Reset</a>
          </div>
          <div class="form-row mb-2">
            <label for="recipient-name" class="col-4">{{selectedTable|titlecase}} Name: </label>
            <ng-select [multiple]="true" class="col-8" [(ngModel)]="filterData.plan" name="plan"
              [items]="selectedTable=='plans'?items.plan:(selectedTable=='solutions'?items.solution:items.opportunity)"
              bindLabel="name" bindValue="id" placeholder="Select" style="border: none !important;"></ng-select>
            <!-- 
            <ss-multiselect-dropdown class="col-8" [(ngModel)]="filterData.plan" name="plan"
              [options]="selectedTable=='plans'?items.plan:selectedTable=='solutions'?items.solution:items.opportunity"
              [settings]="multiSelectionSettings" style="border: none !important;">
            </ss-multiselect-dropdown> -->
          </div>
          <div class="form-row mb-2">
            <label for="recipient-name" class="col-4">Partner Name:</label>
            <ng-select [multiple]="true" class="col-8" [(ngModel)]="filterData.partner" name="partner"
              [items]="selectedTable=='plans'?partners.plan:selectedTable=='solutions'?partners.solution:partners.opportunity"
              bindLabel="name" bindValue="id" placeholder="Select" style="border: none !important;"></ng-select>
            <!-- <ss-multiselect-dropdown class="col-8" [(ngModel)]="filterData.partner" name="partner"
              [options]="selectedTable=='plans'?partners.plan:selectedTable=='solutions'?partners.solution:partners.opportunity"
              [settings]="multiSelectionSettings" style="border: none !important;">
            </ss-multiselect-dropdown> -->
          </div>
          <div class="form-row mb-2">
            <label for="recipient-name" class="col-4">Created By:</label>
            <ng-select [multiple]="true" class="col-8" [(ngModel)]="filterData.createdBy" name="createdBy"
              [items]="selectedTable=='plans'?createdBy.plan:selectedTable=='solutions'?createdBy.solution:createdBy.opportunity"
              bindLabel="name" bindValue="id" placeholder="Select" style="border: none !important;"></ng-select>
            <!-- <ss-multiselect-dropdown class="col-8" [(ngModel)]="filterData.createdBy" name="createdBy"
              [options]="selectedTable=='plans'?createdBy.plan:selectedTable=='solutions'?createdBy.solution:createdBy.opportunity"
              [settings]="multiSelectionSettings" style="border: none !important;">
            </ss-multiselect-dropdown> -->
          </div>
          <div class="form-row mb-2">
            <label for="recipient-name" class="" *ngIf="selectedTable=='plans'">{{selectedTable|titlecase}} active
              during:</label>
            <label for="recipient-name" class="" *ngIf="selectedTable=='solutions'">Solutions starting between:</label>
            <label for="recipient-name" class="" *ngIf="selectedTable=='opportunities'">Opportunities closing
              between:</label>
            <div class="row col-12">
              <div class="col-5">
                <input type="text" matInput class="form-control" id="date1"
                  style="background:#e5e5e5;padding: 8px 12px;">
              </div>
              <div class="col-2" style="padding: 0;text-align: center;"><span
                  style="color:#929292; font-size:14px;">{{selectedTable=='plans'?'TO':'and'}}</span></div>
              <div class="col-5" style="padding-right: 0;">
                <input type="text" matInput class="form-control" id="date2"
                  style="background:#e5e5e5;padding: 8px 12px;">
              </div>
            </div>

          </div>
          <div class="form-row mb-2">
            <label for="recipient-name" class="col-4">Team Members:</label>
            <ng-select [multiple]="true" class="col-8" [(ngModel)]="filterData.team" name="team"
              [items]="selectedTable=='plans'?users.plan:selectedTable=='solutions'?users.solution:users.opportunity"
              bindLabel="name" bindValue="id" placeholder="Select" style="border: none !important;"></ng-select>
            <!-- <ss-multiselect-dropdown class="col-8" [(ngModel)]="filterData.team" name="team"
              [options]="selectedTable=='plans'?users.plan:selectedTable=='solutions'?users.solution:users.opportunity"
              [settings]="multiSelectionSettings" style="border: none !important;">
            </ss-multiselect-dropdown> -->
          </div>
        </form>
      </div>
      <!-- Modal footer -->
      <div class="modal-footer" style="text-align: center; display: inline;">
        <button type="button" data-dismiss="modal" class="btn cnclbtn">
          CANCEL
        </button>

        <button type="button" class="btn addbtn" (click)="filter()">
          OK
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal" id="opportunityModal" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal body -->
      <div class="modal-body">
        <div class="row mrgbtmn">
          <div class="col-xs-12 col-md-12" style="text-align: center;">
            <h3 class="modal-title">Share Prospects</h3>
          </div>
        </div>
        <div class="row mrgbtmn">
          <div class="col-xs-12 col-md-12">
            <a href="javascript:;" (click)="goToOpportunity('add')" class="data_field_a fc-blue">Add
              Opportunity</a>
          </div>
        </div>
        <div class="row mrgbtmn">
          <div class="col-xs-12 col-md-12">
            <a class="data_field_a" style="color: grey !important;">Import Opportunity from CRM</a>
          </div>
        </div>
        <div class="row mrgbtmn">
          <div class="col-xs-12 col-md-12">
            <a href="javascript:;" class="data_field_a fc-blue" (click)="goToOpportunity('import')">Import Opportunity
              from CSV
              file</a>
          </div>
        </div>
      </div>
      <!-- Modal footer -->
      <div class="modal-footer" style="text-align: center; display: inline;">
        <button _ngcontent-odb-c155="" type="button" class="btn cnclbtn1" data-toggle="modal"
          data-target="#opportunityModal">
          CANCEL
        </button>
      </div>
    </div>
  </div>
</div>