import { Injectable, NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
import { AddUserComponent } from '../app/admin/adduser/adduser.component';
import { AdminDashboardComponent } from '../app/admin/admindashboard/admindashboard.component';
import { AccountConfirmComponent } from './account-confirm/account-confirm.component';
import { AccountVerifyComponent } from './account-verify/account-verify.component';
import { ActivityComponent } from './activity/activity.component';
import { ConnectionsComponent } from './admin/connections/connections.component';
import { UsersComponent } from './admin/users/users.component';
import { ConnectionDashboardComponent } from './connection-dashboard/connection-dashboard.component';
import { CreateConnectionComponent } from './create-connection/create-connection.component';
import { CreateOpportunityComponent } from './create-opportunity/create-opportunity.component';
import { CreatePartnerComponent } from './create-partner/create-partner.component';
import { CreatePlanComponent } from './create-plan/create-plan.component';
import { CreateSolutionComponent } from './create-solution/create-solution.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DefaultComponent } from './default/default.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { LandingComponent } from './landing/landing.component';
import { OpportunityComponent } from './opportunity/opportunity.component';
import { PartnerDashboardComponent } from './partner-dashboard/partner-dashboard.component';
import { PartnerInviteComponent } from './partner-invite/partner-invite.component';
import { PlanDashboardComponent } from './plan-dashboard/plan-dashboard.component';
import { PlanDetailComponent } from './plan-detail/plan-detail.component';
import { ReportComponent } from './report/report.component';
import { AuthService } from './services/auth.service';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignupComponent } from './signup/signup.component';
import { SolutionDashboardComponent } from './solution-dashboard/solution-dashboard.component';
import { ChangePasswordComponent } from './change-password/change-password.component'
import { ProfileComponent } from './profile/profile.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { RefreshComponent } from './refresh/refresh.component';
import { AddActivityComponent } from './add-activity/add-activity.component';
import { CustomerComponent } from './customer/customer.component';
import { AddCustomerComponent } from './add-customer/add-customer.component';
import { SalesDashboardComponent } from './sales-dashboard/sales-dashboard.component';
import { SalesListComponent } from './sales-list/sales-list.component';
import { OrderComponent } from './order/order.component';
import { PartnerComponent } from './partners/partner.component';
import { CreateTeamComponent } from './create-team/create-team.component';
import { TeamComponent } from './team/team.component';
import { BrandComponent } from './admin/brand/brand.component';





@Injectable()
export class OnlyLoggedInUsersGuard implements CanActivate {
  constructor(private Router: Router, private AuthService: AuthService) {
  };

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.AuthService.isLoggedIn()) return true;
    this.Router.navigate(['/']);
    return false;
  }
}
@Injectable()
export class OnlyAdminsUsersGuard implements CanActivate {
  isAdmin() {
    return new Promise(resolve => {
      this.AuthService.get('user/me').subscribe((result) => {
        resolve(result.data[0].admin_user)
      }, (error) => {
        resolve(false)
      })
    })
  }
  constructor(private Router: Router,
    private AuthService: AuthService) { };
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (await this.isAdmin() == true) return true;
    this.Router.navigate(['/'])
    return false;
  }
}

const routes: Routes = [
  {
    path: '', component: LandingComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'sign-in' },
      { path: 'sign-in', component: SignInComponent, data: { title: 'Sign In' } },
      { path: 'register', component: SignupComponent, data: { title: 'Register' } },
      { path: 'forget-password', component: ForgetPasswordComponent, data: { title: 'Forgot Password' } },
      { path: 'set-password', component: SetPasswordComponent, data: { title: 'Reset Password' } },
    ]
  },
  {
    path: '', component: DashboardComponent, children: [
      { path: 'dashboard', component: SalesDashboardComponent, data: { title: 'Overview' } },
      { path: 'dashboard/:selectedTable', component: DefaultComponent, data: { title: 'Overview' } },
      { path: 'create-solution', component: CreateSolutionComponent, data: { title: 'Create Solution' } },
      { path: 'change-password', component: ChangePasswordComponent, data: { title: 'Change Password' } },
      { path: 'edit-solution/:solutionId', component: CreateSolutionComponent, data: { title: 'Edit Solution' } },


      { path: 'create-partner', component: CreatePartnerComponent, data: { title: 'Create Partner' } },
      { path: 'edit-partner/:partnerId', component: CreatePartnerComponent, data: { title: 'Edit Partner' } },
      { path: 'partner-dashboard', component: PartnerComponent, data: { title: 'Partners' } },

      { path: 'create-team', component: CreateTeamComponent, data: { title: 'Create Team member' } },
      { path: 'edit-team/:partnerId', component: CreateTeamComponent, data: { title: 'Edit Team member' } },
      { path: 'team-dashboard', component: TeamComponent, data: { title: 'Team members' } },

      { path: 'create-plan', component: CreatePlanComponent, data: { title: 'Create New Plan' } },
      { path: 'update-plan/:planId', component: CreatePlanComponent, data: { title: 'Update Plan' } },
      { path: 'plan-detail/:planId', component: PlanDetailComponent, data: { title: 'Track Plan' } },
      { path: 'solution-dashboard', component: SolutionDashboardComponent, data: { title: 'Joint Solution' } },
      { path: 'partner-dashboard', component: PartnerDashboardComponent, data: { title: 'Overview' } },
      { path: 'plan-dashboard', component: PlanDashboardComponent, data: { title: 'Plan Dashboard' } },
      { path: 'sales-list', component: SalesListComponent, data: { title: 'Sales Detail Dashboard' } },
      { path: 'connection-dashboard', component: ConnectionDashboardComponent, data: { title: 'Connection Dashboard' } },
      { path: 'opportunity-dashboard', component: OrderComponent, data: { title: 'Pipeline' } },
      { path: 'opportunity-dashboard/:actionType', component: OrderComponent, data: { title: 'Pipeline' } },
      { path: 'create-opportunity', component: CreateOpportunityComponent, data: { title: 'New Opportunity' } },
      { path: 'profile', component: ProfileComponent, data: { title: 'Profile' } },
      { path: 'edit-opportunity/:opportunityId', component: CreateOpportunityComponent, data: { title: 'Update Opportunity' } },
      { path: 'activity', component: ActivityComponent, data: { title: 'Activities' } },
      { path: 'create-activity', component: AddActivityComponent, data: { title: 'Create New Activity' } },
      { path: 'edit-activity/:activityId', component: AddActivityComponent, data: { title: 'Update Activity' } },
      { path: 'activity/:activityId', component: ActivityComponent, data: { title: 'Activities' } },

      { path: 'customer-dashboard', component: CustomerComponent, data: { title: 'Customers' } },
      { path: 'create-customer', component: AddCustomerComponent, data: { title: 'Create New Customer' } },
      { path: 'edit-customer/:customerId', component: AddCustomerComponent, data: { title: 'Update Customer' } },
      { path: 'customer-dashboard/:customerId', component: CustomerComponent, data: { title: 'Customers' } },

      { path: 'report', component: ReportComponent, data: { title: 'Reports' } },
      { path: 'refresh', component: RefreshComponent, data: { title: 'Loading' } },
      {
        path: 'admin', children: [
          { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
          { path: 'dashboard', component: AdminDashboardComponent, data: { title: 'Admin Dashboard' } },
          { path: 'add-user', component: AddUserComponent, data: { title: 'Admin Dashboard' } },
          { path: 'brand-customization', component: BrandComponent, data: { title: 'Branding Customization' } },
          { path: 'users', component: UsersComponent, data: { title: 'Admin Dashboard' } },
          { path: 'connections', component: ConnectionsComponent, data: { title: 'Admin Dashboard' } },
        ], canActivate: [OnlyAdminsUsersGuard], data: { title: 'Admin Dashboard' }
      }
    ], canActivate: [OnlyLoggedInUsersGuard]
  },
  { path: 'account-verify/:email', component: AccountVerifyComponent },
  { path: 'account-confirm/:key', component: AccountConfirmComponent },
  { path: 'partner-invite', component: PartnerInviteComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), BrowserAnimationsModule, MatIconModule],
  exports: [RouterModule, BrowserAnimationsModule, MatIconModule]
})
export class AppRoutingModule { }
