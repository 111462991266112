<div class="row" *ngIf="loader" style="height: 100%;">
  <div class="col-12" style="height: 100%;">
    <div style="height: 100%;" class="d-flex justify-content-center align-items-center">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</div>

<div style="padding: 30px 60px;" [hidden]="loader">
  <div class="row mrgbtmn" style="margin-top: 16px;">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <p style="color: #606060; opacity: 0.87; font-size: 18px;">
        ACHIEVEMENTS
      </p>
    </div>
  </div>
  <div class="row mrgbtm" style="height: 300px">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <!-- <div id="container"></div> -->
      <div id="container" *ngIf="!(solutionData.data.length == 0)"></div>
      <div *ngIf="!solutionData.data.length" style="text-align: center; margin: 15%; font-size: 20px" class="fc-blue">
        No Solutions Found
      </div>
    </div>
  </div>
  <div class="row mrgbtm">
    <div class="col-md-8 col-12">
      <h4></h4>
    </div>
    <div class="col-md-4 col-12">
      <div class="tbl_in_icon">
        <a routerLink="/create-solution">
          <i class="fa fa-plus-circle fc-blue" aria-hidden="true"></i>
        </a>
        <i class="fa fa-filter fc-blue" data-toggle="modal" data-target="#filterModal" aria-hidden="true"></i>
        <i class="fa fa-cog" aria-hidden="true"></i>
      </div>
    </div>
  </div>
  <div class="table-wrapper">
    <table mat-table [dataSource]="solutionData" #solutionSort="matSort" matSort>
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Solution Id</th>
        <td mat-cell *matCellDef="let element">
          <a [routerLink]="['/edit-solution', element.id]">{{ element.id }}</a>
        </td>
      </ng-container>
      <ng-container matColumnDef="solution_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Solution Name
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.solution_name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="solution_stage">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Solution Stage
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.solution_stage }}
        </td>
      </ng-container>
      <ng-container matColumnDef="revenue">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Target</th>
        <td mat-cell *matCellDef="let element">
          {{ element.revenue | number }}
        </td>
      </ng-container>
      <ng-container matColumnDef="achievement">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Revenue</th>
        <td mat-cell *matCellDef="let element">
          {{ element.achievement | number }}
        </td>
      </ng-container>
      <ng-container matColumnDef="oppy">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Opportunities
        </th>
        <td mat-cell *matCellDef="let element">{{ element.oppy }}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="solutionColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: solutionColumns"></tr>
    </table>
    <!-- <mat-paginator hidden #solutionPaginator="matPaginator" [pageSize]="3">
    </mat-paginator> -->
  </div>
</div>
<div class="modal" id="filterModal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal body -->
      <div class="modal-body">
        <form action="">
          <div class="form-group">
            <label for="recipient-name" class="col-form-label">Filter By:</label>
            <a href="javascript:;" class="float-right" (click)="resetFilter()">Reset</a>
          </div>
          <div class="form-row mb-2">
            <label for="recipient-name" class="col-4">Solution Name:</label>
            <ng-select [multiple]="true" class="col-8" [(ngModel)]="filterData.plan" name="plan"
              [items]="filterOption.items" bindLabel="name" bindValue="id" placeholder="Select"
              style="border: none !important;"></ng-select>

          </div>
          <div class="form-row mb-2">
            <label for="recipient-name" class="col-4">Partner Name:</label>
            <ng-select [multiple]="true" class="col-8" [(ngModel)]="filterData.partner" name="partner"
              [items]="filterOption.partners" bindLabel="name" bindValue="id" placeholder="Select"
              style="border: none !important;"></ng-select>
          </div>
          <div class="form-row mb-2">
            <label for="recipient-name" class="col-4">Created By:</label>
            <ng-select [multiple]="true" class="col-8" [(ngModel)]="filterData.createdBy" name="createdBy"
              [items]="filterOption.createdBy" bindLabel="name" bindValue="id" placeholder="Select"
              style="border: none !important;"></ng-select>

          </div>
          <div class="form-row mb-2">
            <label for="recipient-name" class="">Solutions starting between:</label>
            <div class="row col-12">
              <div class="col-5">
                <input type="text" matInput class="form-control" id="date1"
                  style="background:#e5e5e5;padding: 8px 12px;">
              </div>
              <div class="col-2" style="padding: 0;text-align: center;"><span
                  style="color:#929292; font-size:14px;">and</span></div>
              <div class="col-5" style="padding-right: 0;">
                <input type="text" matInput class="form-control" id="date2"
                  style="background:#e5e5e5;padding: 8px 12px;">
              </div>
            </div>
          </div>
          <div class="form-row mb-2">
            <label for="recipient-name" class="col-4">Team Members:</label>
            <ng-select [multiple]="true" class="col-8" [(ngModel)]="filterData.team" name="team"
              [items]="filterOption.users" bindLabel="name" bindValue="id" placeholder="Select"
              style="border: none !important;"></ng-select>
            <!-- <ss-multiselect-dropdown class="col-8" [(ngModel)]="filterData.team" name="team"
              [options]="selectedTable=='plans'?users.plan:selectedTable=='solutions'?users.solution:users.opportunity"
              [settings]="multiSelectionSettings" style="border: none !important;">
            </ss-multiselect-dropdown> -->
          </div>
        </form>
      </div>
      <!-- Modal footer -->
      <div class="modal-footer" style="text-align: center; display: inline;">
        <button type="button" data-dismiss="modal" class="btn cnclbtn">
          CANCEL
        </button>
        <button type="button" class="btn addbtn" (click)="filter()">
          OK
        </button>
      </div>
    </div>
  </div>
</div>