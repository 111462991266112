import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MustMatch } from 'src/validators/must-match.validator';
import { AuthService } from '../services/auth.service';
declare var $: any;
@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.css']
})
export class SetPasswordComponent implements OnInit {
  errMessage: string = '';
  signinForm: FormGroup;
  formControls: any;
  formError: boolean = false
  passwordPattern: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~])[A-Za-z\d `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]{8,}$/
  constructor(private router: Router,
    private AuthService: AuthService, private route: ActivatedRoute, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    $('.modal').modal('hide')
    this.signinForm = this.formBuilder.group({
      new_password: ['', Validators.compose([Validators.required, Validators.pattern(this.passwordPattern)])],
      confirmPassword: ['', Validators.compose([Validators.required])],
      token: ['']
    }, { validators: MustMatch('new_password', 'confirmPassword') })
    this.formControls = this.signinForm.controls
    this.route.queryParams.subscribe((a) => {
      if (a.key) {
        this.signinForm.patchValue({ token: a.key });
      }
    })
  }
  respStatus: string = 'none';
  respMessage: string = '';
  submit() {
    if (this.signinForm.valid) {
      this.AuthService.add('user/changepassword', this.signinForm.value)
        .subscribe(async (result: any) => {
          this.respStatus = 'success';
          $('#success_modal').modal('show');
        }, (err: any) => {
          this.respStatus = 'error';
          this.respMessage = err.error.meta.message;
        });
    } else {
      this.errMessage = "Please fill all fields"
      this.formError = true
      console.log(this.signinForm.value);

    }
  }
  goToLogin(){
    $('#success_modal').modal('hide');
    this.router.navigate(['/sign-in'])
  }
}
