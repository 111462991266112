<div
  style="
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  "
>
  <div class="form-container">
    <div class="row mrgbtm justify-content-center">
      <div
        class="col-lg-8 col-md-8 col-sm-12 col-xs-12 section-border"
        style="padding-top: 20px"
      >
        <div class="form-section">
          <h2>{{ userData.partner.partner_name }}</h2>
          <app-add-team
            [onlySuperUsers]="true"
            [partner_owner_email]="userData.partner.partner_owner"
            [domainList]="this.domainList"
            (submitForm)="saveTeam($event)"
            (domainMismatch)="domainChange($event)"
            (partnerMatch)="getPartnerMatch($event)"
            [validateDomain]="validateDomain"
          >
          </app-add-team>
        </div>
        <div *ngIf="getDomainError()" class="row mrgbtm">
          <div class="col-md-12">
            <div align="center" class="errorMessage">
              Partner Owner and Super users domains does not match.
            </div>
          </div>
        </div>
        <div *ngIf="partnerMatchError" class="row mrgbtm">
          <div class="col-md-12">
            <div align="center" class="errorMessage">
              Cannot enter same email for super users and partner owner.
            </div>
          </div>
        </div>
        <div *ngIf="formError" class="row mrgbtm">
          <div class="col-md-12">
            <div align="center" class="errorMessage">
              Please fill all required(*) fields to create partner
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mrgbtm">
      <div class="col-md-12">
        <div align="center">
          <a type="button" (click)="goBack()" class="btn cnclbtn">CANCEL</a>
          <button
            *ngIf="!team_id"
            type="button"
            (click)="openConfirmPopup()"
            [disabled]="submitInProgress"
            class="btn addbtn"
          >
            CREATE TEAM MEMBER
          </button>
          <button
            type="button"
            *ngIf="team_id"
            (click)="openConfirmPopup()"
            [disabled]="submitInProgress"
            class="btn addbtn"
          >
            UPDATE TEAM MEMBER
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-----------Document model popup-------->

<div class="modal" id="confirmPopup">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal body -->
      <div class="modal-body" style="text-align: center; display: inline">
        <h5 class="modal-title mrgbtm">Are You Sure?</h5>
        <p>Invitation will be sent to Email Id(s) you have entered</p>
      </div>
      <!-- Modal footer -->
      <div class="modal-footer" style="text-align: center; display: inline">
        <button type="button" (click)="closeModal()" class="btn cnclbtn">
          CANCEL
        </button>
        <button
          [disabled]="submitInProgress"
          type="button"
          class="btn addbtn"
          (click)="submit()"
        >
          CONFIRM
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="successModal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content modal-custom">
      <!-- Modal body -->
      <div
        *ngIf="displayResult == 'success'"
        class="modal-body"
        style="text-align: center; display: inline"
      >
        <div class="row mrgbtmn">
          <div class="col-md-12">
            <h5 *ngIf="!team_id" class="modal-title mrgbtm">
              Team member(s) Added!
            </h5>
            <h5 *ngIf="team_id" class="modal-title mrgbtm">
              Team member(s) Updated!
            </h5>
            <p *ngIf="invitedTeam.length > 0">
              Invites will be sent to
              <span *ngFor="let member of invitedTeam; index as i">
                {{ member.email }}{{ i < invitedTeam.length - 1 ? "," : "." }}
              </span>
            </p>
          </div>
        </div>
        <div class="row mrgbtm">
          <div class="col-md-12">
            <div align="center">
              <button (click)="this.goBack()" type="button" class="btn addbtn">
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="displayResult == 'failure'"
        class="modal-body"
        style="text-align: center; display: inline"
      >
        <div>
          <div class="row mrgbtm">
            <div class="col-md-12">
              <div align="center" class="errorMessage">
                {{ errorMessage }}
              </div>
            </div>
          </div>
          <div class="row mrgbtm">
            <div class="col-md-12">
              <div align="center">
                <button (click)="closeModal()" type="button" class="btn addbtn">
                  CLOSE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="displayResult == ''"
        class="modal-body"
        style="text-align: center; display: inline"
      >
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <p>{{ team_id ? "Updating " : "Creating " }} Team Member(s)...</p>
      </div>
    </div>
  </div>
</div>
