import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NavigationHelperService } from '../services/navigation-helper.service';
import { LocalStorageService } from '../services/local-storage.service';
import * as moment from 'moment';
declare var $: any;
@Component({
  selector: 'app-opportunity',
  templateUrl: './opportunity.component.html',
  styleUrls: ['./opportunity.component.css']
})
export class OpportunityComponent implements OnInit {
  opportunties: any = [];
  opportunity_stages: any = [];
  deal_statuses: any = [];
  dealStatuses: any = []
  stages: any = []
  fromStage: any = ''
  toStage: any = ''
  selectedOpportunity: any = {}
  filterData: any = {};
  filterOption: any = { items: [], partners: [], users: [], createdBy: [] };
  userPartnerEmail: any;
  userPartnerDomain: any;
  userPartnerId: any;
  userData: any = {};
  actionType: string = '';
  customTerms: { id: string, name: string }[] = [
    { id: '', name: 'Select' },
    { id: 'month', name: 'Month' },
    { id: 'quarter', name: 'Quarter' },
    { id: 'year', name: 'Year' }
  ];
  @ViewChild('form')
  form: ElementRef;
  userDataPartners: any = [];

  constructor(
    private AuthService: AuthService,
    private router: Router,
    private navHelper: NavigationHelperService,
    private activeRoute: ActivatedRoute,
    private localStorageService: LocalStorageService,
  ) {
    this.userData = this.localStorageService.get({ name: 'userData' })
    this.userData = this.userData ? JSON.parse(this.userData) : {}
    if (this.userData.email) {
      this.userPartnerEmail = this.userData.email;
      this.userPartnerId = this.userData.partner.partner_id;
    }
    if (this.userData.partner) {
      this.userPartnerDomain = this.userData.partner.email_domain;
    }
    if (this.navHelper.navigationTracker.length == 0) {
      this.navHelper.setUrl(this.router.url)
    }
    this.activeRoute.params.subscribe((a) => {
      if (a.actionType) {
        this.actionType = a.actionType;
      }
    })
    this.getUsers();
    this.getPartners();

  }
  rawStages: any;
  getDefaultData() {
    this.AuthService.get('custom_data', (err) => {
      console.log(err);
    }).subscribe((result: any) => {
      this.rawStages = result.data.opportunity_stages.filter((a) => { return (typeof a == 'string') || (typeof a == 'object' && a.show) }).map((a) => { return typeof a == 'string' ? { label: a, show: true } : a }).sort((a: any, b: any) => {
        var keyA = a.order,
          keyB = b.order;
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });
      this.stages = this.rawStages;
      this.groupOpportunitiesByStage(this.rawStages, this.opportunties);
      this.deal_statuses = result.data.opportunity_deals.filter((a) => { return (typeof a == 'string') || (typeof a == 'object' && a.status) }).map((a) => { return typeof a == 'string' ? { label: a, status: true, stage: [] } : a });
      this.dealStatuses = this.deal_statuses;
      setTimeout(() => { this.enableDrag() }, 100)
      console.log(this.opportunity_stages)
    }, (err: any) => {
      // this.solutionStages = [];
      console.log(err);
    });
  }
  groupOpportunitiesByStage(stagesData, opportuntiyData) {
    console.log(stagesData, opportuntiyData)
    this.opportunity_stages = stagesData.map((a) => {
      console.log(a)
      let b: any = { name: a.label, id: a.id }
      b.opportunties = opportuntiyData.filter((a1) => a1.stage_id.toLowerCase() == a.id.toLowerCase())
      return b;
    })
    console.log(this.opportunity_stages);
  }
  getOpportunities() {
    this.opportunties = [];
    this.opportunity_stages = [];
    this.AuthService.get('opportunities', (err) => {
    }).subscribe((result: any) => {
      console.log("sol result is:", result)
      if (result.data) {
        this.opportunties = result.data;
        this.opportunties.forEach((aa) => {
          this.filterOption.items = [...this.filterOption.items, { id: aa.id, name: aa.name }];
          aa.partnerDetails.map((aa1) => {
            if (!this.filterOption.partners.filter((a11) => a11.id == aa1.partner_id).length) {
              // let partner = this.allPartners.find((a) => a.partner_id == aa1)
              this.filterOption.partners = [...this.filterOption.partners, { id: aa1.partner_id, name: aa1.partner_id != this.userPartnerId ? aa1.partner_name : 'Internal' }];
            }
          })
          this.allUsers.forEach((user) => {
            if (!this.filterOption.createdBy.filter((a11) => a11.id == aa.created_by).length) {
              if (aa.created_by == user.user_id) {
                this.filterOption.createdBy = [...this.filterOption.createdBy, { id: user.user_id, name: user.email }];
              }
            };
          });
          if (Array.isArray(aa.users)) {
            aa.users.map((aa1) => {
              if (!this.filterOption.users.filter((a11) => a11.id == aa1).length) {
                this.filterOption.users = [...this.filterOption.users, { id: aa1, name: aa1 }]
              }
            })
          }
        })
      }
      this.getDefaultData()
    }, (err: any) => {
      this.opportunties = [];
      this.getDefaultData()
      console.log(err);
    });
  }
  datePicker1: any;
  datePicker2: any;
  ngOnInit(): void {
    this.loadUserDomain();
    this.getOpportunities();
    this.datePickerApply();
  }
  datePickerApply() {
    setTimeout(() => {
      this.datePicker1 = $('#from_date').datepicker({
        format: 'mm/dd/yyyy',
        close: (e) => {
          let date = $('#from_date').val()
          this.filterData.start_date = date;
        }
      });
      this.datePicker2 = $('#to_date').datepicker({
        format: 'mm/dd/yyyy',
        close: (e) => {
          let date = $('#to_date').val()
          this.filterData.end_date = date;
        }
      });
      if (this.actionType == "import") {
        this.uploadFileModal();
      }
    }, 100);
  }
  selectedTarget: any;
  enableDrag() {
    $(".overviewCard").draggable({
      scope: 'demoBox',
      revertDuration: 100,
      start: (event, ui) => {
        // console.log(event, $(event.target).attr('id'), ui)
        let fromStage = $(event.target).attr('id');
        this.fromStage = fromStage.split('~')[0];
        this.selectedTarget = event.target
        let selectedStage = this.opportunity_stages.find((a) => a.name.toLowerCase() == fromStage.split('~')[0].toLowerCase());
        this.selectedOpportunity = this.opportunties.find((a) => a.id == fromStage.split('~')[1]);
        // console.log(this.fromStage, this.selectedOpportunity)
        //Reset
        $(".overviewCard").draggable("option", "revert", true);
        $(".mrgdiv").addClass("classtobeadded");
        //$(".overviewCard").addClass("hidegrag");
        // $(".mystyle").addClass("hidegrag");
        // $(this).addClass("blockgrag");
      }
    });
    let $this = this;
    $(".mrgdiv").droppable({
      scope: 'demoBox',
      drop: function (event, ui) {
        // console.log(event, $(event.target).attr('id'), ui)
        $this.toStage = $(event.target).attr('id');
        $('#' + $(event.target).attr('id')).addClass('current')
        $($this.selectedTarget).attr('id', $this.toStage + '~' + $this.selectedOpportunity.id)
        //Realign item
        $(ui.draggable).detach().appendTo(this);
        $(".mrgdiv").removeClass("classtobeadded");
        let lastStage = $this.stages[$this.stages.length - 1];
        if ($this.fromStage == lastStage.id) {
          $this.gotPage(true);
        } else if ($this.fromStage != $this.toStage) {
          let stageDtl = $this.stages.find((a) => a.id == $this.toStage);
          console.log($this.fromStage, $this.toStage, stageDtl)
          if (!stageDtl.quoteDt && !stageDtl.quoteVal && !stageDtl.closeModal) {
            $this.selectedOpportunity.stage = stageDtl.label;
            $this.selectedOpportunity.stage_id = $this.toStage;
            $this.deal_statuses = $this.dealStatuses.filter(a => {
              let status = true;
              if (a.stage && a.stage.length && a.stage.indexOf($this.selectedOpportunity.stage_id) == -1) {
                status = false
              }
              return status;
            });
            $this.updateOpportunity();
          }
          else {
            if ((stageDtl.quoteDt || stageDtl.quoteVal) && !stageDtl.closeModal) {
              $this.gotPage(true);
              // $('#evaluateStageModal').modal('show');
            } else {
              let stageDtl = $this.stages.find((a) => a.id == $this.toStage);
              // console.log(this.selectedOpportunity, this.toStage)
              // return;
              $this.selectedOpportunity.stage = stageDtl.label;
              $this.selectedOpportunity.stage_id = $this.toStage;
              $this.selectedOpportunity = $this.selectedOpportunity;
              $this.deal_statuses = $this.dealStatuses.filter(a => {
                let status = true;
                if (a.stage && a.stage.length && a.stage.indexOf($this.selectedOpportunity.stage_id) == -1) {
                  status = false
                }
                return status;
              });
              console.log($this.selectedOpportunity, $this.deal_statuses)
              $this.openCloseModal($this.toStage);
            }
          }
        }
      }
    })
    let isDragging = false, startingPos = []
    $(".overviewCard")
      .mousedown(function (evt) {
        isDragging = false;
        startingPos = [evt.pageX, evt.pageY];
      })
      .mousemove(function (evt) {
        if (!(evt.pageX === startingPos[0] && evt.pageY === startingPos[1])) {
          isDragging = true;
        }
      })
      .mouseup((evt) => {
        if (isDragging) {
          console.log("Drag");
          this.deal_statuses = this.dealStatuses.filter(a => {
            let status = true;
            if (a.stage && a.stage.length && a.stage.indexOf(this.selectedOpportunity.stage_id) != -1) {
              status = false
            }
            return status;
          });
          // console.log(this.selectedOpportunity, this.deal_statuses)
        } else {
          let opId = $(evt.target).attr('id').split('~')[1];
          let op = this.opportunties.find((aa) => aa.id == opId);
          if (op && opId)
            this.editOpportunity(op)
          // console.log($(evt.target).attr('id'), opId, op)
          // console.log("Click", evt);
        }
        isDragging = false;
        startingPos = [];
      });
  }
  dropped(event) {
    // console.log('dropped====', event);
  }
  openCloseModal(toStage) {
    this.selectedOpportunity = this.selectedOpportunity;
    this.deal_statuses = this.deal_statuses.filter(a => {
      let status = true;
      if (a.stage && a.stage.length && a.stage.indexOf(this.selectedOpportunity.stage_id) == -1) {
        status = false
      }
      return status;
    });
    // console.log(this.selectedOpportunity, this.deal_statuses)
    setTimeout(() => {
      this.selectedOpportunity = this.selectedOpportunity;
      $('#closeStageModal').modal('show');
    }, 100)

  }
  setDealStatus(d) {
    // console.log(d)
    this.selectedOpportunity.deal_status = d.label;
    this.selectedOpportunity.deal_status_id = d.id
    // console.log(this.selectedOpportunity)
  }
  gotPage(go = false) {
    // this.router.navigateByUrl('/edit-opportunity/'+this.selectedOpportunity.id,{queryParams:{stage:this.toStage}})
    if ((go && this.selectedOpportunity.id) || (!go && !this.selectedOpportunity.id)) {
      // this.router.navigateByUrl('/edit-opportunity/' + this.selectedOpportunity.id + '?stage=' + this.toStage)
      location.href = '/edit-opportunity/' + this.selectedOpportunity.id + '?stage=' + this.toStage;
    }
  }
  setDate() {
    var d = new Date();
    // $('#myModalcnfrm').modal('show')
    d.setDate(d.getDate() - 1);
    $('#date2').datepicker({
      dateFormat: 'm-dd-yyyy',
      minDate: d,
      showOn: 'button',
      icons: {
        rightIcon: '<div class="calender-icon"><i class="material-icons pick">date_range</i></div>'
      },
      close: (e) => {
        this.selectedOpportunity.quote_date = $('#date2').val();
      }
    })
  }
  isProgress: any = false;
  displayId: any = ''
  displayResult: any = ''
  errorMessage: any = ''
  opportunityId: any = ''
  errors_deal_status: any = ''
  updateOpportunity() {
    this.errors_deal_status = ''
    this.opportunityId = this.selectedOpportunity.id;
    // let stageDtl = this.stages.find((a) => a.id == this.toStage);
    console.log(this.selectedOpportunity, this.deal_statuses, this.deal_statuses.filter(a => a.id == this.selectedOpportunity.deal_status_id).length)
    // // return;
    // this.selectedOpportunity.stage = stageDtl.label;
    // this.selectedOpportunity.stage_id = this.toStage;
    if (this.deal_statuses.filter(a => a.id == this.selectedOpportunity.deal_status_id).length) {
      // return;
      $('.modal').modal('hide')
      if (!this.isProgress) {
        this.isProgress = true
        $('#successModal').modal('show')
        this.AuthService[this.selectedOpportunity.id ? 'update' : 'add']('opportunities' + (this.selectedOpportunity.id ? '/' + this.selectedOpportunity.id : ''), this.selectedOpportunity).subscribe((result: any) => {
          this.isProgress = false
          this.displayId = result.data.id
          this.displayResult = 'success';
          $('.step').removeClass('current');
          this.fromStage = '';
          this.toStage = '';
          this.selectedOpportunity = {};
          // console.log(this)
          // this.getOpportunities();
          setTimeout(() => {
            $('.modal').modal('hide')
            this.displayId = ''
            this.displayResult = '';
            this.errorMessage = ''
          }, 100);
        }, (err: any) => {
          this.errorMessage = err.error.meta.message
          this.displayResult = 'failure';
          $('.step').removeClass('current');
          // this.getOpportunities();
          // console.log(err);
        });
      }
    } else {
      this.errors_deal_status = 'Deal status is required.'
    }
  }
  editOpportunity(op) {
    $('.modal').modal('hide');
    this.router.navigate(['/edit-opportunity', op.id])
  }
  gotoCreate() {
    $('.modal').modal('hide');
    this.router.navigate(['/create-opportunity'])
  }
  closeModal() {
    $('.modal').modal('hide');
    this.getOpportunities()
  }
  filteredOpportunities = [];
  filter() {
    this.groupOpportunitiesByStage(this.rawStages, this.opportunties.filter((aa) => {
      let status = true;
      if (this.filterData.start_date) {
        if (new Date(moment(aa.close_date).startOf('day').format('YYYY-MM-DD h:mm:ss')).getTime() < new Date(moment(this.filterData.start_date).startOf('day').format('YYYY-MM-DD h:mm:ss')).getTime()) {
          status = false;
        }
      }
      if (this.filterData.end_date) {
        if (new Date(moment(aa.close_date).endOf('day').format('YYYY-MM-DD h:mm:ss')).getTime() > new Date(moment(this.filterData.end_date).endOf('day').format('YYYY-MM-DD h:mm:ss')).getTime()) {
          status = false;
        }
      }
      if (this.filterData.createdBy && this.filterData.createdBy.length) {
        let stat = false;
        if (this.filterData.createdBy.indexOf(aa.created_by) != -1) {
          stat = true;
        }
        if (!stat) {
          status = false;
        }
      }
      if (this.filterData.team && this.filterData.team.length) {
        let stat = false;
        aa.team.map((aaa) => {
          if (this.filterData.team.indexOf(aaa.email) != -1) {
            stat = true;
          }
        })
        if (!stat) {
          status = false;
        }
      }
      if (this.filterData.partner && this.filterData.partner.length) {
        let stat = false;
        aa.partners.map((aaa) => {
          if ((this.filterData.partner.length == 1) && (this.filterData.partner.indexOf(this.userPartnerId) != -1)) {
            if ((aa.partners.length == 1) && (aa.partners.indexOf(this.userPartnerId) != -1)) {
              stat = true;
            }
          } else {
            if (this.filterData.partner.indexOf(aaa) != -1) {
              stat = true;
            }
          }
        })
        if (!stat) {
          status = false;
        }
      }
      if (this.filterData.plan && this.filterData.plan.length) {
        let stat = false;
        if (this.filterData.plan.indexOf(aa.id) != -1) {
          stat = true;
        }
        if (!stat) {
          status = false;
        }
      }
      return status;
    }));
    $('#filterModal').modal('hide');
    setTimeout(() => { this.enableDrag() }, 100)
  }
  resetFilter() {
    this.filterData = {};
    $('#from_date').val('');
    $('#to_date').val('');
    this.datePicker1.destroy();
    this.datePicker2.destroy();
    this.datePickerApply();
  }
  allPartners: any[] = [];
  getPartners() {
    this.AuthService.get('partners').subscribe(
      (result) => {
        // console.log(result);
        this.allPartners = result.data
        let ids = []
        this.partnerOptions = result.data.map(
          (partner) => {
            ids.push(partner.partner_id)
            return { id: partner.partner_id, name: partner.partner_name };
          }
        );
        ids.push(this.userData.partner.partner_id);
        console.log(ids);
        this.getPartyOwner(ids.join(), ['owners']);
        this.getPartyOwner(ids.join(), ['team_members']);
        this.getPartyOwner(ids.join(), ['party_owners']);
        this.getProductCategories(ids.join());
      },
      (err) => {
        console.log(err.error);
        this.allPartners = []
      }, () => {
      }
    )
  }
  allUsers: any[] = [];
  getUsers() {
    this.AuthService.get('admin/users').subscribe(
      (result) => {
        console.log(result);
        this.allUsers = result.data
      },
      (err) => {
        console.log(err.error);
        this.allPartners = []
      }, () => {
      }
    )
  }
  uploadFileModal() {
    $('.modal').modal('hide');
    this.records = [];
    $('#uploadModal').modal('show');
  }
  row: any = 0;
  rows: any = [];
  parseData(records) {
    this.form.nativeElement.reset();
    return records.map((a, i) => {

      let stage_id = this.stages.find(stage => stage.label == a['Stage*']) || {};
      let deal_status_id = this.dealStatuses.find(stage => stage.label == a['Deal Status*']) || {};
      let partners = (a['Partners'] ? (a['Partners'] || '').split('|') : []).map((aa) => {
        let p = this.partnerOptions.find((a) => a.name == aa);
        return p ? p.id : aa;
      });
      let cats = a['Product Category'] ? a['Product Category'].split('|') : [];
      let categories = this.productCategories.filter((a) => [...partners, ...[this.userPartnerId]].indexOf(a.partner_id) != -1);
      let catIds = this.productCategories.filter((a) => cats.indexOf(a.category_name) != -1).map((a) => a.category_id);
      let products = this.productsByCategories.filter((a) => catIds.indexOf(a.category_id) != -1)
      console.log(this.productsByCategories)
      // this.getProducts(a['Product Category'] ? a['Product Category'].split('|') : [], i);
      return {
        iid: i,
        customer_name: a['Customer Name*'],
        deal_status: a['Deal Status*'],
        deal_status_id: deal_status_id.id,
        close_date: a['Estimated Close Date*'],
        fixed_revenue: a['Fixed Revenues*'],
        name: a['Opportunity Name*'],
        owner: a['Owner*'],
        partner_owner: a['Partner Owners'] ? a['Partner Owners'].split('|') : [],
        partners: partners,
        probability: a['Probability*'],
        product: a['Product'] ? a['Product'].split('|') : [],
        product_category: cats,
        recurring_frequency: a['Recurring Revenues per'],
        recurring_revenue: a['Recurring Revenues Value'],
        stage: a['Stage*'],
        stage_id: stage_id.id,
        team: a['Team Members'] ? a['Team Members'].split('|') : [],
        term_frequency: a['Term in'],
        terms: a['Term Number'],
        quote_date: a['Quote Date'],
        quote_value: a['Quote Value'],
        errors: {},
        party_owners: this.allUser.filter((aa) => !this.partnerOptions.find((a) => a.email == aa.email)).map(
          (partnerUser) => {
            return { id: partnerUser.email, name: partnerUser.email };
          }
        ),
        partnerTeamOptions: this.allUser.map(
          (partnerUser) => {
            return { id: partnerUser.email, name: partnerUser.email };
          }
        ),
        created_user: `${this.userData.first_name} ${this.userData.last_name}`,
        is_upload: true,
        productCategories: categories,
        products: products
      }
    })
  }
  errorCount: any = 0
  validateOpportunities() {
    let err = false;
    let require = ["customer_name", "owner", "name", "stage", "deal_status", "probability", "close_date", "fixed_revenue"];
    console.log(this.rows)
    for (let i = 0; i < this.rows.length; i++) {
      let errors: any = {};
      let r = this.rows[i];
      if (r.is_upload) {
        console.log(errors, r)
        require.map((aa) => {
          if (!r[aa]) {
            errors[aa] = true
          }
          // return aa;
        });
        if (r.partners && r.partners.length) {
          r.partners.map(aa => {
            if (!this.partnerOptions.find((aa1) => aa1.id == aa)) {
              errors.partners = true
            }
          })

        } else {
          let owner_partner_ids = this.userDataPartners.map((a) => a.partner.owner_partner_id).filter(a => a && a != this.userData.partner.partner_id);
          console.log(owner_partner_ids);
          if (owner_partner_ids.length && !r.partners.length) {
            errors.partners = true
          }
        }
        if (r.owner) {
          if (!this.owners.find((aa1) => aa1.id == r.owner)) {
            errors.owner = true
          }
        }
        if (r.product_category && r.product_category.length) {
          if (Array.isArray(r.product_category)) {
            r.product_category.map(aa => {
              if (!r.productCategories.find((aa1) => aa1.category_name == aa)) {
                errors.product_category = true;
              }
            })
          }
        }
        if (r.product && r.product.length) {
          if (Array.isArray(r.product)) {
            r.product.map(aa => {
              if (!(r.products || []).find((aa1) => aa1.product_name == aa)) {
                errors.product = true;
              }
            })
          }
        }
        if (r.partner_owner && r.partner_owner.length) {
          if (Array.isArray(r.partner_owner)) {
            r.partner_owner.map(aa => {
              if (!(r.party_owners || []).find((aa1) => aa1.id == aa)) {
                errors.partner_owner = true;
              }
            })
          }
        } else {
          if (r.partners.length) {
            errors.partner_owner = true
          }
        }
        if (r.team && r.team.length) {
          if (Array.isArray(r.team)) {
            r.team.map(aa => {
              if (!(r.partnerTeamOptions || []).find((aa1) => aa1.id == aa)) {
                errors.team = true;
              }
            })
          }
        }
        if (r.stage) {
          if (!this.stages.find((aa1) => aa1.label == r.stage)) {
            errors.stage = true;
          }
          if (r.deal_status) {
            let stage_id = this.stages.find(stage => stage.label == r.stage) || {};
            let deals = this.dealStatuses.filter((a) => a.stage.indexOf(stage_id.id) != -1);
            console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@', r, deals.filter((a) => a.label == r.deal_status).length, deals.filter((a) => a.label == r.deal_status))
            if (!deals.filter((a) => a.label == r.deal_status).length)
              errors.deal_status = true;
          }
        }

        if (r.deal_status) {
          if (!this.dealStatuses.find((aa1) => aa1.label == r.deal_status)) {
            errors.deal_status = true;
          }
        }

        if (r.probability) {
          if (isNaN(r.probability) || (!isNaN(r.probability) && Number(r.probability) > 100)) {
            errors.probability = true;
          }
        }
        if (r.close_date) {
          if (!moment(r.close_date, "MM/DD/YYYY", true).isValid()) {
            errors.close_date = true;
          }
        }
        if (r.quote_date) {
          if (!moment(r.quote_date, "MM/DD/YYYY", true).isValid()) {
            errors.quote_date = true;
          }
        }
        if (r.quote_value) {
          if (isNaN(r.quote_value)) {
            errors.quote_value = true;
          }
        }
        if (r.fixed_revenue) {
          if (isNaN(r.fixed_revenue)) {
            errors.fixed_revenue = true;
          }
        }
        if (r.recurring_revenue) {
          if (isNaN(r.recurring_revenue)) {
            errors.recurring_revenue = true;
          }
          if (!r.recurring_frequency) {
            errors.recurring_frequency = true;
          }
          if (r.recurring_revenue == '0' && r.recurring_frequency) {
            errors.recurring_frequency = true;
          }
        } else if (r.recurring_frequency) {
          errors.recurring_frequency = true;
        }
        if (r.terms) {
          if (isNaN(r.terms)) {
            errors.terms = true;
          }
          if (!r.term_frequency) {
            errors.term_frequency = true;
          }
          if (r.terms == '0' && r.term_frequency) {
            errors.term_frequency = true;
          }
        } else if (r.term_frequency) {
          errors.term_frequency = true;
        }
        this.rows[i].errors = errors;
        if (Object.keys(errors).length) {
          this.rows[i].error = true;
          err = true
        } else {
          this.rows[i].error = false;
        }
      } else {
        this.rows[i].errors = {};
      }
    }
    console.log(this.rows)
    this.errorCount = this.rows.filter((a) => a.error).length
    return !err;
  }
  noData: any = false;
  uploadData() {
    this.noData = false;
    if (this.validateOpportunities()) {
      let data = this.rows.filter((a) => a.is_upload).map((aa) => {
        let date = aa.close_date.split('/')
        aa.close_date = new Date(date[2] + '-' + date[0] + '-' + date[1])
        if (aa.quote_date && aa.quote_date.indexOf('/') !== -1) {
          date = aa.quote_date.split('/')
          aa.quote_date = new Date(date[2] + '-' + date[0] + '-' + date[1])
        }
        let partners = aa.partners;
        if (aa.owner) {
          let owner = this.allUser.find((a) => a.email == aa.owner)
          partners.push(owner.partner_id)
        }
        if (aa.partner_owner && aa.partner_owner.length) {
          aa.partner_owner.map(val => {
            let owner = this.allUser.find((a) => a.email == val)
            console.log(owner, val, partners);
            if (owner && !partners.find((a) => a == owner.partner_id)) {
              partners.push(owner.partner_id)
            }
          })
        }
        aa.partners = partners;
        let teams = []
        aa.team = aa.team.map((a) => { return { email: a.split('~')[0], user_role: 3 } }).filter((a11) => {
          let status = true;
          if (teams.indexOf(a11.email.toLowerCase()) != -1) {
            status = false;
          } else {
            teams.push(a11.email.toLowerCase())
          }
          return status;
        });
        if (this.customers.find((a11) => { return a11.name == aa.customer_name }))
          aa.isCustomer = true
        aa.created_date = new Date()

        if (!aa.partner_owner) {
          aa.partner_owner = this.userData.email;
        }
        aa.recurring_revenue = aa.recurring_revenue || 0
        aa.terms = aa.terms || 0
        aa.value = Number(aa.fixed_revenue) + (Number(aa.recurring_revenue) * Number(aa.terms));
        console.log(aa.value);
        aa.solution_id = aa.solution_id || []
        aa.plan_id = aa.plan_id || []
        aa.product = aa.product || []
        aa.product_category = aa.product_category || [];
        let stage_id = this.stages.find(stage => stage.label == aa.stage) || {};
        let deal_status_id = this.dealStatuses.find(stage => stage.label == aa.deal_status) || {};
        aa.stage_id = stage_id.id;
        aa.deal_status_id = deal_status_id.id;
        return aa;
      })
      if (data.length) {

        $('#successModal').modal('show')
        this.AuthService.add('opportunity/create_multiple', data, () => { }).subscribe((aa) => {
          this.isProgress = false
          this.displayResult = 'success';
          $('.step').removeClass('current')

          this.fromStage = '';
          this.toStage = '';
          this.selectedOpportunity = {};
          console.log(this)
          setTimeout(() => {
            $('.modal').modal('hide')
            this.displayId = ''
            this.displayResult = '';
            this.errorMessage = ''
            // this.opportunity_stages = [];
            // this.opportunties = [];
            // this.getOpportunities();
          }, 100);
          this.getOpportunities()
        }, (err: any) => {
          this.errorMessage = err.error.meta.message
          this.displayResult = 'failure'
          // $('.step').removeClass('current')
          console.log(err);
        })
      } else {
        this.noData = true;
      }
    }
  }
  confirmUpload() {
    if (this.records.length) {
      $('.modal').modal('hide');
      this.rows = this.parseData(this.records)
      console.log(this.rows, this.records)
      this.validateOpportunities()
      $('#uploadModalPreview').modal('show');
      setTimeout(() => {
        // var d = new Date();
        // d.setDate(d.getDate() - 1);
        // $('.datepicker').datepicker({
        //   format: 'mm/dd/yyyy',
        //   minDate: d,
        //   close: (e) => {
        //     let classes = $(e.target).attr('class').split(' ');
        //     let date1 = classes.find((a) => a.indexOf('date-') != -1);
        //     let name = $(e.target).attr('name');
        //     let value = $('.'+date1).val();
        //     if (date1) {
        //       let spl = date1.split('-');
        //       // $('.'+date1).val(value);
        //       this.rows[spl[1]][name] = value
        //     }
        //   }
        // })
        // $('.datepicker1').datepicker({
        //   format: 'mm/dd/yyyy',
        //   minDate: d,
        //   close: (e) => {
        //     let classes = $(e.target).attr('class').split(' ');
        //     let date2 = classes.find((a) => a.indexOf('date2-') != -1);
        //     let name = $(e.target).attr('name');
        //     let value = $('.'+date2).val();
        //     if (date2) {
        //       let spl = date2.split('-');
        //       $(e.target).attr('name')
        //       // $('.'+date2).val(value)
        //       this.rows[spl[1]][name] = value
        //     }
        //     // this.createOpportunityForm.patchValue({ close_date: $('#date1').val() });
        //     // alert('Change is fired' + $('#date1').val());
        //   }
        // })
      }, 100)
    } else {
      if (!this.file) {
        this.errors.file = ('Please choose CSV file!');
      } else {
        if (this.isValidCSVFile(this.file.name))
          this.errors.file = ('Error is occured while reading file!');
        else
          this.errors.file = ('Please choose CSV file!');
      }
    }
  }
  errors: any = {};
  records: any = []
  file: File
  handleFileSelect(evt) {
    this.errors.file = '';
    let files = evt.target.files;
    let file = files[0];
    console.log(file)
    if (files && file) {
      this.file = file;
      if (file.size <= 5000000) {
        if (this.isValidCSVFile(file.name)) {
          let reader = new FileReader();
          reader.readAsText(file);

          reader.onload = () => {
            let csvData = reader.result;

            let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);

            let headersRow = this.getHeaderArray(csvRecordsArray);
            console.log(headersRow)
            this.records = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
            console.log(this.records)
          };

          reader.onerror = () => {
            this.errors.file = ('Error is occured while reading file!');
          };
        } else {
          this.errors.file = ('Please choose CSV file!');
        }
      } else {
        evt.target.value = ''
        this.errors.file = 'File should be less than 5mb';
      }
    }
  }
  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    let csvArr = [];
    console.log(csvRecordsArray)
    let arr = ["Customer Name*", "Owner*", "Opportunity Name*", "Stage*", "Deal Status*", "Fixed Revenues*", "Probability*", "Estimated Close Date*", "Partners", "Partner Owners", "Team Members", "Recurring Revenues Value", "Recurring Revenues per", "Term Number", "Term in", "Product Category", "Product", "Quote Date", "Quote Value"]
    for (let i = 1; i < csvRecordsArray.length; i++) {
      if (csvRecordsArray[i]) {
        let curruntRecord = (<string>csvRecordsArray[i]).split(',');
        console.log(curruntRecord)
        // if (curruntRecord.length == headerLength) {
        let csvRecord: any = {};
        for (let i = 0; i < arr.length; i++) {
          csvRecord[arr[i]] = curruntRecord[i].trim();
        }
        csvArr.push(csvRecord);
        // }
      }
    }
    return csvArr;
  }

  isValidCSVFile(file: any) {
    return file.endsWith(".csv");
  }

  getHeaderArray(csvRecordsArr: any) {
    let headers = (<string>csvRecordsArr[0]).split(',');
    let headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  //
  partnerOptions: any = []
  partners: any = [];
  customers: any = [];
  getConnection() {
    this.AuthService.get("partners").subscribe(
      (result: any) => {
        this.partners = result.data
        let ids = [], ids2 = []
        this.partnerOptions = result.data.map(
          (partner) => {
            ids.push(partner.partner_id)
            return { id: partner.partner_id, name: partner.partner_name };
          }
        ).filter((a) => {
          let status = true;
          if (ids2.indexOf(a.id) == -1) {
            ids2.push(a.id);
          } else {
            status = false;
          }
          return status;
        });
        ids.push(this.userData.partner.partner_id);
        console.log(ids);
        this.getPartyOwner(ids.join(), ['owners']);
        this.getPartyOwner(ids.join(), ['team_members']);
        this.getPartyOwner(ids.join(), ['party_owners']);
      },
      (err: any) => {
        this.partnerOptions = [];
      })
  }
  getCustomers() {
    // console.log(e);
    this.AuthService.get("customers").subscribe(
      (result: any) => {
        // console.log(result.data)
        this.customers = result.data || [];
      });
  }
  allUser: any = [];
  partnerTeamOptions: any = []
  domainList: any = []
  owners: any = []
  party_owners: any = []
  products: any = [];
  getPartyOwner(id, key?) {
    this.AuthService.get(`partners/users?partner_id=${id}`).subscribe(
      (result: any) => {
        let filteredList = [];
        this.allUser = [...this.allUser, ...result.data]
        // result.data.filter(
        //   (user: any) => { return user.email != this.userPartnerEmail }).map((a) => {
        //     let exist = filteredList.find((a1) => a.email.toLowerCase() == a1.email.toLowerCase())
        //     if (!exist) {
        //       filteredList.push(a)
        //     }
        //   })
        // this[key] = filteredList.map(
        //   (partnerUser) => {
        //     // if (this.opportunity.id && this.partnerOwner.toLowerCase() == partnerUser.email.toLowerCase()) {
        //     //   this.opportunity.partner_owner = []
        //     //   this.opportunity.partner_owner.push(partnerUser.email + '~' + partnerUser.user_role)
        //     // }
        //     return { id: partnerUser.email, name: partnerUser.email };
        //   }
        // );
        if (key.includes('team_members')) {
          let filteredList = result.data.filter(
            (user: any) => { return user.email != this.userPartnerEmail })
          if (this.rows[this.selectedIndex]) {
            let ids2 = [];
            this.rows[this.selectedIndex].partnerTeamOptions = filteredList.map(
              (partnerUser) => {
                return { id: partnerUser.email, name: partnerUser.email };
              }
            ).filter((a) => {
              let status = true;
              if (ids2.indexOf(a.id) == -1) {
                ids2.push(a.id);
              } else {
                status = false;
              }
              return status;
            });

            console.log('**********************', this.rows[this.selectedIndex]['partner_owner'], this.rows[this.selectedIndex].partnerTeamOptions, filteredList)
          }
          if (this.rows[this.selectedIndex] && this.rows[this.selectedIndex]['partner_owner']) {
            let ids2 = [];
            this.rows[this.selectedIndex].partnerTeamOptions = this.rows[this.selectedIndex].partnerTeamOptions.filter(val => { return !this.rows[this.selectedIndex]['partner_owner'].includes(val.id.split('~')[0]) }).filter((a) => {
              let status = true;
              if (ids2.indexOf(a.id) == -1) {
                ids2.push(a.id);
              } else {
                status = false;
              }
              return status;
            })
            this.domainList = filteredList.map(partnerUser => { return partnerUser.email_domain });

            console.log('**********************', this.rows[this.selectedIndex].partnerTeamOptions)
          }
        }
        if (key.includes('owners')) {
          this.owners = result.data.filter((a) => a.email.indexOf(this.userData.partner.email_domain) != -1).map(
            (partnerUser) => {
              return { id: partnerUser.email, name: partnerUser.email };
            }
          );
        }
        if (key.includes('party_owners') && this.rows[this.selectedIndex]) {
          let filteredList = result.data.filter(
            (user: any) => { return user.email.split('@')[1] != this.userData.partner.email_domain })
          this.rows[this.selectedIndex].party_owners = filteredList.map(
            (partnerUser) => {
              return { id: partnerUser.email, name: partnerUser.email };
            }
          );
        }
        // let index = this.owners.findIndex(owner => owner.name == this.userData.email);
        // if (index == -1) {
        //   this.owners.push({ id: this.userData.email, name: this.userData.email });
        // }
      },
      (err: any) => {
        this.party_owners = [];
      }
    );
  }
  productCategories: any = [];
  productsByCategories: any = [];
  getProductCategories(defaultCat: any = '') {
    let query = ''
    if (defaultCat) {
      query += '?partner_id=' + defaultCat;
    }
    this.AuthService.get('product_categories' + query, (err) => {
      console.log(err);
    }).subscribe((result: any) => {
      this.productCategories = result.data;
      let cats = this.productCategories.map((a) => a.category_id)
      if (cats.length) {
        this.AuthService.get('products?category_id=' + cats.join(), (err) => {
          console.log(err);
        }).subscribe((result: any) => {
          this.productsByCategories = [...this.productsByCategories, ...result.data];
        }, (err: any) => {
          // this.productsByCategories = [];
          console.log(err);
        });
      }
    }, (err: any) => {
      this.productCategories = [];
      console.log(err);
    });
  }
  getProducts(cat, i) {
    console.log(cat)
    let cats = []
    if (typeof cat == 'string') {
      let cat1 = this.productCategories.find((a) => a.category_name == cat)
      console.log(cat1, cat)
      cat = cat1;
    }
    if (Array.isArray(cat)) {
      cat.map((a1) => {
        let cat1 = this.productCategories.find((a) => a.category_name == a1)
        if (cat1)
          cats.push(cat1.category_id)
      })
    }
    this.AuthService.get('products?category_id=' + cats.join(), (err) => {
      console.log(err);
    }).subscribe((result: any) => {
      this.rows[i].products = result.data;
    }, (err: any) => {
      this.rows[i].products = [];
      console.log(err);
    });
    // }
  }
  getFilterProducts(cat, i) {
    console.log(cat)
    let cats = []
    if (typeof cat == 'string') {
      let cat1 = this.productCategories.find((a) => a.category_name == cat)
      console.log(cat1, cat)
      cat = cat1;
    }
    if (Array.isArray(cat)) {
      cat.map((a1) => {
        let cat1 = this.productCategories.find((a) => a.category_name == a1)
        if (cat1)
          cats.push(cat1.category_id)
      })
    }
    let products = this.productsByCategories.filter((a) => cats.indexOf(a.category_id) != -1)
    this.rows[i].products = products;
    this.validateOpportunities()
  }
  selectedIndex: any;
  onPartnerSelect($event?, i?) {
    this.selectedIndex = i;
    console.log(this.rows[i], i, this.productCategories)
    if (this.rows[i].partners && this.rows[i].partners != '') {
      let partner_ids: any = this.rows[i].partners;
      partner_ids = [...partner_ids, ...[this.userPartnerId]];
      this.getPartyOwner(partner_ids.join(), ['team_members']);
      this.getPartyOwner(partner_ids.join(), ['party_owners']);
      this.rows[i].productCategories = this.productCategories.filter((a) => partner_ids.indexOf(a.partner_id) != -1);
      this.getFilterProducts(this.rows[i].product_category, i)
    } else {
      // this.rows[i].productCategories = []; 
      this.rows[i].productCategories = this.productCategories.filter((a) => [this.userPartnerId].indexOf(a.partner_id) != -1);
      this.getFilterProducts(this.rows[i].product_category, i);
    }
    this.validateOpportunities();
  }
  resetForm() {
    this.form.nativeElement.reset()
  }
  loadUserDomain() {
    this.AuthService.get('user/me').subscribe(
      (result: any) => {
        this.userDataPartners = result.data;
      },
      () => { }
    )
  }
}
