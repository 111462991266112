<div class="row" *ngIf="!plan || plan.length == 0" style="height: 100%">
  <div class="col-12" style="height: 100%">
    <div style="height: 100%" class="d-flex justify-content-center align-items-center">
      <div *ngIf="!plan" class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div *ngIf="plan.length == 0 && loadedPlans">
        <div class="row mrgbtm">
          <div class="col-md-12">
            <div align="center" class="errorMessage">
              No data to display.
            </div>
          </div>
        </div>
        <!-- <div class="row mrgbtm">
          <div class="col-md-12">
            <div align="center">
              <button routerLink="/create-plan" type="button" class="btn addbtn">
                Create New Plan
              </button>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>

<div style="padding: 0 60px" [hidden]="!(loadedPlans && plan && plan.length > 0)">
  <div class="row mrgbtm">
    <div class="col-md-8 col-12">
      <h3></h3>
    </div>
    <div class="col-md-4 col-12">
      <div class="tbl_in_icon">
        <a routerLink="/create-plan">
          <i class="fa fa-plus-circle fc-blue" aria-hidden="true"></i>
        </a>
        <!-- <i class="fa fa-filter fc-blue" (click)="openFilterModal()" aria-hidden="true"></i> -->
        <i class="fa fa-cog" aria-hidden="true"></i>
      </div>
    </div>
  </div>
  <div class="row mb-4 col-12">
    <button class="btn btn-success mr-2" style="color: #fff" mat-raised-button
      (click)="exporter.exportTable('xlsx', { fileName: 'data' })">
      Excel
    </button>
    <button class="btn btn-success mr-2" style="color: #fff" mat-raised-button
      (click)="exporter.exportTable('csv', { fileName: 'data' })">
      CSV
    </button>
    <button class="btn btn-success mr-2" style="color: #fff" mat-raised-button (click)="SavePDF()">
      PDF
    </button>
    <!-- <button class="btn btn-success mr-2" style="color: #fff;" mat-raised-button
      (click)="exporter.exportTable('txt',{fileName:'data'})">TEXT</button> -->
  </div>
  <div class="table-wrapper" id="content-table" style="min-width: 500px">
    <table mat-table matTableExporter #exporter="matTableExporter" [dataSource]="planData" matSort #planSort="matSort"
      style="width: 100%">
      <ng-container matColumnDef="name" *ngIf="type == 'invitedCustomer' || type == 'registeredCustomer'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer Name</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>
      <ng-container matColumnDef="partner_name" *ngIf="type == 'invitedCustomer' || type == 'registeredCustomer'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Associate Reference
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.owner_partner.partner_name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="is_registered" *ngIf="type == 'invitedCustomer' || type == 'registeredCustomer'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let element">
          {{ element.is_registered ? "Registered" : "Invited" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="email" *ngIf="type == 'invitedCustomer' || type == 'registeredCustomer'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email Address</th>
        <td mat-cell *matCellDef="let element">
          {{ element.email }}
        </td>
      </ng-container>
      <ng-container matColumnDef="phone" *ngIf="type == 'invitedCustomer' || type == 'registeredCustomer'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone Number</th>
        <td mat-cell *matCellDef="let element">
          {{ element.phone }}
        </td>
      </ng-container>

      <ng-container matColumnDef="name" *ngIf="type != 'invitedCustomer' && type != 'registeredCustomer'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer Name</th>
        <td mat-cell *matCellDef="let element">{{ element.customer_name }}</td>
      </ng-container>

      <ng-container matColumnDef="status" *ngIf="type != 'invitedCustomer' && type != 'registeredCustomer'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let element">
          {{ element.order_status }}
        </td>
      </ng-container>

      <ng-container matColumnDef="partner" *ngIf="type == 'commission'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Salesmen</th>
        <td mat-cell *matCellDef="let element">
          {{ element.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="no_orders" *ngIf="type == 'commission'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>No. of Orders</th>
        <td mat-cell *matCellDef="let element">
          {{ element.orders }}
        </td>
      </ng-container>
      <ng-container matColumnDef="no_customer" *ngIf="type == 'commission'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          No. of Customers
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.customers }}
        </td>
      </ng-container>
      <ng-container matColumnDef="amount" *ngIf="type != 'invitedCustomer' && type != 'registeredCustomer'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
        <td mat-cell *matCellDef="let element">
          {{ element.fixed_revenue || element.totalAmount || element.amount }} GBP
        </td>
      </ng-container>
      <ng-container matColumnDef="commission" *ngIf="type == 'commission' && !type2">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Commission {{ userData.subscription_data ? "Paid" : "Earned" }}
        </th>
        <td mat-cell *matCellDef="let element">{{ ((userData &&
          (userData.email == userData.partner.partner_owner)
            ? element.commission
            : element.team_commission))|number:'1.2-2' }} GBP</td>
      </ng-container>
      <ng-container matColumnDef="team_commission" *ngIf="
          type == 'commission' &&
          this.userData.email == this.userData.partner.partner_owner
        ">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Team Commission
          {{ userData.subscription_data ? "Paid" : "Earned" }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.team_commission|number:'1.2-2' }} GBP
        </td>
      </ng-container>
      <ng-container matColumnDef="date" *ngIf="type != 'invitedCustomer' && type != 'registeredCustomer'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
        <td mat-cell *matCellDef="let element">
          {{ element.created_at | date: "MMM dd, yyyy" }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="planColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: planColumns"></tr>
    </table>
    <!-- <mat-paginator #planPaginator="matPaginator" [pageSize]="3">
    </mat-paginator> -->
  </div>
</div>
<div class="modal" id="filterModal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal body -->
      <div class="modal-body">
        <form action="">
          <div class="form-group">
            <label for="recipient-name" class="col-form-label">Filter By:</label>
            <a href="javascript:;" class="float-right" (click)="resetFilter()">Reset</a>
          </div>
          <div class="form-row mb-2">
            <label for="recipient-name" class="col-4">Plan Name:</label>
            <ng-select [multiple]="true" class="col-8" [(ngModel)]="filterData.plan" name="plan"
              [items]="filterOption.items" bindLabel="name" bindValue="id" placeholder="Select"
              style="border: none !important"></ng-select>
          </div>
          <div class="form-row mb-2">
            <label for="recipient-name" class="col-4">Partner Name:</label>
            <ng-select [multiple]="true" class="col-8" [(ngModel)]="filterData.partner" name="partner"
              [items]="filterOption.partners" bindLabel="name" bindValue="id" placeholder="Select"
              style="border: none !important"></ng-select>
          </div>
          <div class="form-row mb-2">
            <label for="recipient-name" class="col-4">Created By:</label>
            <ng-select [multiple]="true" class="col-8" [(ngModel)]="filterData.createdBy" name="createdBy"
              [items]="filterOption.createdBy" bindLabel="name" bindValue="id" placeholder="Select"
              style="border: none !important"></ng-select>
          </div>
          <div class="form-row mb-2">
            <label for="recipient-name" class="">Plans active during:</label>
            <div class="row col-12">
              <div class="col-5">
                <input type="text" matInput class="form-control" id="date1"
                  style="background: #e5e5e5; padding: 8px 12px" />
              </div>
              <div class="col-2" style="padding: 0; text-align: center">
                <span style="color: #929292; font-size: 14px">TO</span>
              </div>
              <div class="col-5" style="padding-right: 0">
                <input type="text" matInput class="form-control" id="date2"
                  style="background: #e5e5e5; padding: 8px 12px" />
              </div>
            </div>
          </div>
          <div class="form-row mb-2">
            <label for="recipient-name" class="col-4">Team Members:</label>
            <ng-select [multiple]="true" class="col-8" [(ngModel)]="filterData.team" name="team"
              [items]="filterOption.users" bindLabel="name" bindValue="id" placeholder="Select"
              style="border: none !important"></ng-select>
            <!-- <ss-multiselect-dropdown class="col-8" [(ngModel)]="filterData.team" name="team"
              [options]="selectedTable=='plans'?users.plan:selectedTable=='solutions'?users.solution:users.opportunity"
              [settings]="multiSelectionSettings" style="border: none !important;">
            </ss-multiselect-dropdown> -->
          </div>
        </form>
      </div>
      <!-- Modal footer -->
      <div class="modal-footer" style="text-align: center; display: inline">
        <button type="button" data-dismiss="modal" class="btn cnclbtn">
          CANCEL
        </button>
        <button type="button" class="btn addbtn" (click)="filter()">OK</button>
      </div>
    </div>
  </div>
</div>
<div id="editor"></div>