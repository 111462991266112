<div class="d-f d-fd-c j-c-c a-c-c" style="height: 100%;">
  <div class="row">
    <div class="col"></div>
    <div class="col">
      <div class="bdr" style="height: 185px; width: 185px; padding: 5px;">
        <img style="height: 173px; width: 173px;" [src]="initPath + picPath" alt="profile-pic" />
      </div>
    </div>
  </div>
  <div class="form fc-black-2" [formGroup]="changePasswordForm">
    <div *ngIf="userData.email" class="row">
      <div class="col custom-label t-align-r">
        Email:
      </div>
      <div class="col pd-l-60">
        <div>
          {{ userData != null ? userData.email : "" }}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col t-align-r custom-label fc-black-2">
        Old Password:
      </div>
      <div class="col pd-l-60">
        <div>
          <input matInput type="password" placeholder="Enter current password" formControlName="password" />
        </div>
        <div class="mw-50p" *ngIf="
            (formControls['password'].dirty ||
              formControls['password'].touched ||
              formError) &&
            formControls['password'].errors
          ">
          <div class="errorMessage" *ngIf="formControls['password'].errors.required">
            Password is required
          </div>
          <div class="errorMessage" *ngIf="formControls['password'].errors.pattern">
            Password should be Min *=8 digit alphanumeric, with a special
            character and atleast one capital alphabet
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col t-align-r custom-label fc-black-2">
        New Password:
      </div>
      <div class="col pd-l-60">
        <div>
          <input type="password" matInput placeholder="Enter new password" formControlName="new_password" />
        </div>
        <div class="mw-50p" *ngIf="
            (formControls['new_password'].dirty ||
              formControls['new_password'].touched ||
              formError) &&
            formControls['new_password'].errors
          ">
          <div class="errorMessage" *ngIf="formControls['new_password'].errors.required">
            New Password is required
          </div>
          <div class="errorMessage" *ngIf="formControls['new_password'].errors.samePassword">
            Old password cannot be new password.
          </div>
          <div class="errorMessage" *ngIf="formControls['new_password'].errors.pattern">
            New Password should be Min 8 digit alphanumeric, with a special
            character and at-least one capital alphabet
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col t-align-r custom-label fc-black-2">
        Confirm New Password:
      </div>
      <div class="col pd-l-60">
        <div class="mw-50p">
          <input type="password" matInput placeholder="Re-enter new password" formControlName="confirm_new_password" />
        </div>
        <div *ngIf="
            (formControls['confirm_new_password'].dirty ||
              formControls['confirm_new_password'].touched ||
              formError) &&
            formControls['confirm_new_password'].errors
          ">
          <div class="errorMessage" *ngIf="formControls['confirm_new_password'].errors.required">
            Confirm Password is required
          </div>
          <div class="errorMessage" *ngIf="formControls['confirm_new_password'].errors.mustMatch">
            Password is not matching
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="changeStatus != 'none' || formError" class="row" style="padding: 1px; margin: 0; padding-top: 10px;">
      <div class="col"></div>
      <div class="col pd-l-60">
        <div *ngIf="changeStatus == 'loading'">
          <div>
            <div class="spinner-border spinner-border-sm" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <span class="fz-14">Validating old password.</span>
          </div>
        </div>
        <div *ngIf="changeStatus == 'error'">
          <div class="errorMessage">
            {{ errMessage }}
          </div>
        </div>
        <div *ngIf="formError && changePasswordForm.invalid">
          <div class="errorMessage">
            Please fill in all fields to change password.
          </div>
        </div>
      </div>
    </div>
    <div class="row pd-b-20">
      <div class="col t-align-r pd-r-60 d-f j-c-b">
        <button (click)="goBack()" class="btn fz-18 fw-4 bd-0 cnclbtn">
          CANCEL
        </button>
      </div>
      <div class="col">
        <div>
          <button (click)="submit()" class="btn fz-18 fw-4 addbtn">
            SAVE CHANGES
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="success_modal" class="modal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body" style="text-align: center;">
        <div class="row mrgbtmn" style="margin-top: 20px;">
          <div class="col fz-18">
            Congrats, your changes have been saved.
          </div>
        </div>
        <div class="row mrgbtmn">
          <div class="col">
            <button class="btn addbtn" style="min-width: 100px;" (click)="goBack()">
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>