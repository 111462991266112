<div class="alerts">
  <h3 style="color: red;">ALERTS</h3>
  <div id="accordion" class="accordion">
    <div class="card mb-0">
      <div
        class="card-header collapsed"
        data-toggle="collapse"
        href="#collapseOne"
      >
        <a class="card-title">
          <img src="./assets/ICONS/general.png" style="width: 20px;" />
          General
        </a>
      </div>
      <div id="collapseOne" class="card-body collapse" data-parent="#accordion">
        <p>
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus
          terry richardson ad squid. 3 wolf moon officia
        </p>
      </div>
      <div
        class="card-header collapsed"
        data-toggle="collapse"
        data-parent="#accordion"
        href="#collapseTwo"
      >
        <a class="card-title">
          <img src="./assets/ICONS/ARCnet.png" style="width: 20px;" />
          ABC Consultant's Plan
        </a>
      </div>
      <div id="collapseTwo" class="card-body collapse" data-parent="#accordion">
        <p>
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus
          terry richardson ad squid.
        </p>
      </div>
      <div
        class="card-header collapsed"
        data-toggle="collapse"
        data-parent="#accordion"
        href="#collapseThree"
      >
        <a class="card-title">
          <img src="./assets/ICONS/ABconsultant.png" style="width: 20px;" />
          ARCNet
        </a>
      </div>
      <div id="collapseThree" class="collapse" data-parent="#accordion">
        <div class="card-body">
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus
          terry richardson ad squid.
        </div>
      </div>
    </div>
  </div>
</div>
