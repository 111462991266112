import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { LocalStorageService } from '../services/local-storage.service';

@Component({
  selector: 'app-partner-invite',
  templateUrl: './partner-invite.component.html',
  styleUrls: ['./partner-invite.component.css']
})
export class PartnerInviteComponent implements OnInit {
  code: any = '';
  tokenData: any = {}
  user: any = {};
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private LocalStorageService: LocalStorageService,
    private AuthService: AuthService
  ) {
    this.route.queryParams.subscribe((a) => {
      if (a.validateToken) {
        this.code = a.validateToken;
        this.AuthService.get('user/validateToken?validateToken=' + this.code, (err) => {
        }).subscribe((result: any) => {
          console.log(result);
          this.tokenData = result.data;
        }, (err: any) => {
          console.log(err)
        })
      } else {
        this.router.navigate(['/sign-in'])
      }
    })
  }

  ngOnInit(): void { }

  redirect() {
    if (this.LocalStorageService.get({ name: "accessToken" })) {
      this.AuthService.get("user/me", () => { }).subscribe(
        (result: any) => {
          console.log("header says", result);
          this.user = result.data[0];
          if (this.user.email == this.tokenData.email) {
            this.router.navigate(['/dashboard'])
          } else {
            this.AuthService.get("user/logout", () => { }).subscribe(
              () => {
                this.LocalStorageService.remove({ name: 'accessToken' });
                this.router.navigate(['/sign-in'])
              },
              err => {
                this.LocalStorageService.remove({ name: 'accessToken' });
                this.router.navigate(['/sign-in'])
              }
            );
          }
        },
        err => {
          this.LocalStorageService.remove({ name: 'accessToken' });
          this.router.navigate(['/sign-in'])
        }
      );
    } else {
      this.router.navigate(['/sign-in'])
    }
  }
}
