import { Component, Input, OnChanges, OnInit, EventEmitter, SimpleChanges, Output, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IMultiSelectOption, IMultiSelectSettings } from 'angular-2-dropdown-multiselect';
// import { Team } from '../models/team.model';
import { Connection } from '../models/connection.model';
import { AuthService } from '../services/auth.service';
import { LocalStorageService } from '../services/local-storage.service';
import * as  moment from "moment";
import { NavigationHelperService } from '../services/navigation-helper.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MustMatch } from 'src/validators/must-match.validator';

declare var $: any;

@Component({
  selector: 'app-create-team',
  templateUrl: './create-team.component.html',
  styleUrls: ['./create-team.component.css']
})
export class CreateTeamComponent implements OnInit {
  @Input() public callBack: Function;
  @Input() public defaultTeam?: any;
  @Output() submitForm = new EventEmitter<any>();
  team_id: string;
  partnersList: any = [];
  invitedTeam: any = [];
  usersList: any = [];
  typeList: any = [];
  priorityList: any = [];
  plansList: any = [];
  solutionList: any = [];
  opportunitiesList: any = [];
  teamList: any = [];
  userSubscription: { type: any, data: any, create_partner: boolean, create_user: boolean } = { type: '', data: '', create_partner: true, create_user: true };
  selfPartners: any[] = [];
  userPartnerEmail = '';
  defaultPartnerID: string = '';
  team: any = {};
  savedTeam: any[] = [];
  rawAssigneeList: any = [];
  validateDomain: boolean = false;
  constructor(
    private AuthService: AuthService,
    private formBuilder: FormBuilder,
    private localStorageService: LocalStorageService,
    private router: Router,
    private navHelper: NavigationHelperService,
    private activatedRoute: ActivatedRoute,
    private cdRef: ChangeDetectorRef
  ) {
    this.userData = this.localStorageService.get({ name: 'userData' })
    this.userData = this.userData ? JSON.parse(this.userData) : {}
    this.userPartnerId = this.userData.partner.partner_id;
    this.domainList.push(this.userData.partner.email_domain)
    this.validateDomain = this.userData.partner.validate_domain;
    this.userPartnerEmail = this.userData.email;
    this.partnerOwners = this.localStorageService.get({ name: 'partnerOwners' })
    this.partnerOwners = this.partnerOwners ? JSON.parse(this.partnerOwners) : []
    if (this.userData.subscription_type_data != null) {
      this.userSubscription.type = 'subscriber'
      this.userSubscription.data = this.userData.subscription_type_data;
      // if (this.userData.first_name) {
      //   this.team.approvers = `${this.userData.first_name} ${this.userData.last_name}`;
      //   this.team.approver_id = this.userData.user_id;
      // } else {
      //   this.team.approvers = '';
      // }
    } else {
      this.userSubscription.type = 'partner'
      this.userSubscription.data = this.userData.subscriber_subscription_type_data;
      if (this.userSubscription.data) {
        this.userSubscription.create_user = this.userSubscription.data.user_by_partner;
      }
    }
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.partnerId) {
        this.defaultPartnerID = params.partnerId;
      }
    })
    this.activatedRoute.params.subscribe((params) => {
      if (params.teamId) {
        this.team_id = params.teamId;
        this.getTeamData();
      }
    })
    this.teamData = localStorage.getItem('teamData')
    this.teamData = this.teamData ? JSON.parse(this.teamData) : {};
    this.team = { ...this.team, ...this.teamData };
    // this.savedTeam = this.team.assignee;
    localStorage.removeItem('teamData');
    let teamData = localStorage.getItem('teamInvitedTeam')
    this.invitedTeam = teamData ? JSON.parse(teamData) : [];
    localStorage.removeItem('teamInvitedTeam')
  }
  isUpdate: boolean = false;
  userData: any = {};
  userPartnerId: string = '';
  partnerOwners: any = [];
  domainList: any = [];
  partner: any = {};
  getPartner() {
    this.AuthService.get('partners/' + this.userPartnerId + '/getDetail')
      .subscribe((result: any) => {
        this.partner = { ...result.data, ...{ team: [] } };
        // this.getPartnerUsers();
      }, (err: any) => {
        console.log(err);
      });
  }
  ngOnInit(): void {
    this.getPartner()
  }
  teamForm: FormGroup;
  formControls: any;

  teamData: any = {};
  newPartner() {
    if (this.userSubscription.create_partner) {
      localStorage.setItem('teamData', JSON.stringify(this.team));
      localStorage.setItem('teamInvitedTeam', JSON.stringify(this.invitedTeam));
      localStorage.setItem('redirect', this.router.url)
      this.router.navigate(["/create-partner"]);
    } else {
      $('#alertModalX').modal('show');
    }
  }
  getAccess(type): boolean {
    if (type == 'partner') {
      if (this.userSubscription.type == 'subscriber') {
        if (this.userSubscription.data.no_partner == null) {
          return true;
        } else if (this.partnersList.length < this.userSubscription.data.no_partner) {
          return true;
        } else {
          return false;
        }
      } else if (type == 'partner') {
        if (this.userSubscription.data && this.userSubscription.data.partner_by_partner) {
          return true;
        }
        return false;
      }
    } else if (type == 'user') {
      if (this.userSubscription.type == 'subscriber') {
        if (this.userSubscription.data.no_user == null) {
          return true;
        } else if (this.userSubscription.data.no_user < this.partnersList.length) {
          return true;
        } else {
          return false;
        }
      } else if (type == 'partner') {
        if (this.userSubscription.data && this.userSubscription.data.user_by_partner) {
          return true;
        }
        return false;
      }
    }
  }
  getTeamData() {
    this.AuthService.get('team/' + this.team_id + '/getTeam').subscribe(
      (result) => {
        this.team = result.data;
      },
      (error) => {

      }
    )
  }

  getTeams() {
    return new Promise((resolve, reject) => {
      this.AuthService.get("teams?limit=100").subscribe(
        (result: any) => {
          let sortedList = (result.data || []).map((a) => {
            a.name = a.name.toLowerCase();
            return a
          });
          resolve(sortedList);
        }, (error) => {
          resolve([]);
        });
    })
  }
  validateTeam() {
    let error = false;
    if (this.team.length) {
      this.team.map((a) => {
        if (this.validateDomain && !a.email.includes(this.userData.partner.email_domain)) {
          error = true;
        }
      })
    } else {
      error = true;
    }
    return !error;
  }
  openConfirmPopup() {
    if (this.validateTeam()) {
      $('#confirmPopup').modal('show');
    } else {
      this.formError = true;
    }
  }
  goBack() {
    $('.modal').modal('hide')
    let previousUrl = this.navHelper.getUrl()
    // let redirect = localStorage.getItem('redirect2');
    // if ((previousUrl && previousUrl != this.router.url) || redirect) {
    if (previousUrl && (previousUrl != this.router.url)) {
      // this.router.navigate([redirect || previousUrl])
      if (previousUrl == '/dashboard') {
        this.router.navigate([previousUrl, 'solutions']);
      } else {
        this.router.navigate([previousUrl])
      }
    } else {
      this.router.navigate(['/team-dashboard'])
    }
  }
  closeModal() {
    $('.modal').modal('hide');
  }
  submitInProgress: any = false;
  formError: any = false;
  displayResult: any = ''
  errorMessage: any = ''
  submit() {
    this.displayResult = '';
    this.submitInProgress = false;
    if (this.validateTeam()) {
      this.submitInProgress = true;
      let final_data = this.partner;
      final_data = JSON.parse(JSON.stringify(this.team));


      // if (this.rawAssigneeList.includes(this.userPartnerEmail)) {
      //   final_data.assignee.push(this.userPartnerEmail);
      // }
      let alertReq = ''
      $('.modal').modal('hide');
      $('#successModal').modal('show');
      //if form is valid submit to backend
      this.AuthService.raw(this.userPartnerId ? 'post' : 'post', this.AuthService.baseUrl + (this.userPartnerId ? 'admin/createUsers' : 'partners'), final_data).subscribe((result: any) => {
        this.submitInProgress = false
        this.displayResult = 'success';
        // if (alertReq != '') {
        //   this.AuthService.add('alerts/custom_alerts', {
        //     users: result.data.users,
        //     moduleName: "team",
        //     message: `${this.userData.first_name} ${this.userData.last_name} of Partner ${this.userData.partner.partner_name} has submitted Team ${final_data} for approval`,
        //     partners: result.data.partners
        //   }).subscribe((result) => {
        //     console.log(result);
        //   })
        // }
      },
        (error) => {
          this.submitInProgress = false
          this.displayResult = "failure"
          this.errorMessage = error.error.meta.message
        },
        () => { }
      )
    } else {
      this.formError = true
    }
  }
  partnerMatchError: boolean = false;
  getPartnerMatch($event) {
    this.partnerMatchError = $event;
  }
  domainMatchError: boolean = false;
  domainChange($event) {
    this.domainMatchError = $event;
    this.cdRef.detectChanges();
  }

  saveTeam($event) {
    if ($event) {
      this.team = $event.map((a) => {
        a.partner_id = this.userPartnerId
        return a;
      });
      console.log($event);
    }
  }
  getDomainError() {
    return this.domainMatchError;
  }
}
