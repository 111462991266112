import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatBadgeModule } from '@angular/material/badge';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { AddUserComponent } from '../app/admin/adduser/adduser.component';
import { AdminDashboardComponent } from '../app/admin/admindashboard/admindashboard.component';
import { AccountConfirmComponent } from './account-confirm/account-confirm.component';
import { AccountVerifyComponent } from './account-verify/account-verify.component';
import { ActivityComponent } from './activity/activity.component';
import { AddActivityComponent } from './add-activity/add-activity.component';
import { UsersComponent } from './admin/users/users.component';
import { AppRoutingModule, OnlyAdminsUsersGuard, OnlyLoggedInUsersGuard } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConnectionDashboardComponent } from './connection-dashboard/connection-dashboard.component';
import { CreateConnectionComponent } from './create-connection/create-connection.component';
import { CreateOpportunityComponent } from './create-opportunity/create-opportunity.component';
import { CreatePartnerComponent } from './create-partner/create-partner.component';
import { CreatePlanComponent } from './create-plan/create-plan.component';
import { CreateSolutionComponent } from './create-solution/create-solution.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DefaultComponent } from './default/default.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { LandingComponent } from './landing/landing.component';
import { HeaderComponent } from './navbars/header/header.component';
import { LeftSideBarComponent } from './navbars/left-side-bar/left-side-bar.component';
import { RightSideBarComponent } from './navbars/right-side-bar/right-side-bar.component';
import { OpportunityComponent } from './opportunity/opportunity.component';
import { PartnerDashboardComponent } from './partner-dashboard/partner-dashboard.component';
import { PlanDashboardComponent } from './plan-dashboard/plan-dashboard.component';
import { PlanDetailComponent } from './plan-detail/plan-detail.component';
import { ReportComponent } from './report/report.component';
import { AuthService } from './services/auth.service';
import { CommonEmitterService } from './services/common-emitter';
import { HttpConfigInterceptor } from './services/httpconfig.service';
import { LocalStorageService } from './services/local-storage.service';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignupComponent } from './signup/signup.component';
import { SolutionDashboardComponent } from './solution-dashboard/solution-dashboard.component';
import { ConnectionsComponent } from './admin/connections/connections.component';
import { AddTeamComponent } from './add-team/add-team.component';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ProfileComponent } from './profile/profile.component'
import { NgSelectModule } from '@ng-select/ng-select';
import { SetPasswordComponent } from './set-password/set-password.component';
import { NgIdleModule } from '@ng-idle/core';
import { RefreshComponent } from './refresh/refresh.component'
import { CommonModule } from '@angular/common';
import { CustomerComponent } from './customer/customer.component';
import { AddCustomerComponent } from './add-customer/add-customer.component';
import { SalesDashboardComponent } from './sales-dashboard/sales-dashboard.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { SalesListComponent } from './sales-list/sales-list.component';
import { OrderComponent } from './order/order.component';
import { MatTableExporterModule } from 'mat-table-exporter';
import { PartnerComponent } from './partners/partner.component';
import { TeamComponent } from './team/team.component';
import { CreateTeamComponent } from './create-team/create-team.component';
import { BrandComponent } from './admin/brand/brand.component';



@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LandingComponent,
    HeaderComponent,
    LeftSideBarComponent,
    RightSideBarComponent,
    SignInComponent,
    SignupComponent,
    DefaultComponent,
    CreateSolutionComponent,
    SolutionDashboardComponent,
    ConnectionDashboardComponent,
    PartnerDashboardComponent,
    PlanDashboardComponent,
    CreatePlanComponent,
    CreatePartnerComponent,
    CreateConnectionComponent,
    AccountVerifyComponent,
    AccountConfirmComponent,
    PlanDetailComponent,
    ForgetPasswordComponent,
    AdminDashboardComponent,
    AddUserComponent,
    OpportunityComponent,
    CreateOpportunityComponent,
    ActivityComponent,
    AddActivityComponent,
    CustomerComponent,
    AddCustomerComponent,
    UsersComponent,
    ReportComponent,
    ConnectionsComponent,
    AddTeamComponent,
    ChangePasswordComponent,
    ProfileComponent,
    SetPasswordComponent,
    RefreshComponent,
    SalesDashboardComponent,
    SalesListComponent,
    OrderComponent,
    PartnerComponent,
    TeamComponent,
    CreateTeamComponent,
    BrandComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MultiselectDropdownModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatBadgeModule,
    RxReactiveFormsModule,
    NgSelectModule,
    CommonModule,
    NgIdleModule.forRoot(),
    NgxDaterangepickerMd.forRoot(),
    MatTableExporterModule
  ],
  providers: [
    AuthService,
    LocalStorageService,
    OnlyLoggedInUsersGuard,
    OnlyAdminsUsersGuard,
    CommonEmitterService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
