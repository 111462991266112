<div>
  <form [formGroup]="signinForm" (ngSubmit)="submit()">
    <div class="row" style="margin-top: 30px">
      <div class="col-md">
        <input
          matInput
          type="text"
          formControlName="email"
          placeholder="Email"
          [(ngModel)]="email"
        />
        <div
          *ngIf="
            (formControls['email'].dirty ||
              formControls['email'].touched ||
              formError) &&
            formControls['email'].errors
          "
        >
          <div
            class="errorMessage"
            *ngIf="formControls['email'].errors.required"
          >
            Email is required
          </div>
          <div
            class="errorMessage"
            *ngIf="formControls['email'].errors.pattern"
          >
            Enter Valid Email
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md">
        <input
          matInput
          type="password"
          formControlName="password"
          placeholder="Password"
          [(ngModel)]="password"
        />
        <div
          *ngIf="
            (formControls['password'].dirty ||
              formControls['password'].touched ||
              formError) &&
            formControls['password'].errors
          "
        >
          <div
            class="errorMessage"
            *ngIf="formControls['password'].errors.required"
          >
            Password is required
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="displayError">
      <div class="row">
        <div class="col" style="text-align: center">
          <div class="errorMessage">
            {{ errMessage }}
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="formError && !this.signinForm.valid">
      <div class="row">
        <div class="col" style="text-align: center">
          <div class="errorMessage">
            {{ errMessage }}
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md">
        <button type="submit" class="btn btn-block btn-primary submit">
          Sign In
        </button>
      </div>
    </div>
    <div class="mt-3 text-center">
      <span class="icon-ui align-middle mr-1"></span>
      <a
        href="javascript:;"
        (click)="changePage()"
        class="a-link text-sm sectionForgot"
        >Forgot Password</a
      >
    </div>
  </form>
</div>
