<form [formGroup]="activityForm">
  <div style="
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
    ">
    <div class="form-container">
      <div class="row mrgbtm">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 section-border" style="padding-top: 20px">
          <div class="form-section">
            <div class="row mrgbtmn">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                <p class="head_type">
                  Activity Name<sub class="required">*</sub>:
                </p>
              </div>
              <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9" style="padding-top: 10px">
                <input matInput formControlName="activity" type="text" [(ngModel)]="activity.activity" name="activity"
                  [readonly]="activity_id" style="width: 80%" placeholder="Activity Name" />
                <div class="mrgbtm" *ngIf="
                    (formControls['activity'].dirty ||
                      formControls['activity'].touched ||
                      formError) &&
                    formControls['activity'].errors
                  ">
                  <div class="errorMessage" *ngIf="formControls['activity'].errors.required">
                    Activity Name is required.
                  </div>
                </div>
              </div>
            </div>
            <div class="row mrgbtmn">
              <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                <p class="head_type">Customer:</p>
              </div>
              <div class="col-lg-5 col-md-5 col-sm-4 col-xs-4 col-4">
                <input matInput type="text" id="customer-auto" autocomplete="off" class="fc-black-1 border-none"
                  placeholder="Enter Customer Name" (blur)="blurCustomer()" (focus)="getFilteredCustomers($event)"
                  (keypress)="getFilteredCustomers($event)" name="customer_name" formControlName="customer_name"
                  [(ngModel)]="activity.customer_name" />
                <div class="tt-menu" style="
                    position: absolute;
                    top: 60%;
                    left: 9px;
                    z-index: 100;
                    display: block;
                    width: 100%;
                  " *ngIf="filteredCustomerList.length">
                  <div class="tt-dataset tt-dataset-cars" *ngFor="let cus of filteredCustomerList">
                    <div class="tt-suggestion tt-selectable" (click)="setCustomer(cus)">
                      {{ cus.name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mrgbtmn">
              <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                <p class="head_type">Partners:</p>
              </div>

              <div class="col-lg-5 col-md-5 col-sm-4 col-xs-4 col-4">
                <ng-select class="border-none owner" (ngModelChange)="onPartnerSelect($event)" [multiple]="true"
                  name="partners" [(ngModel)]="activity.partners" formControlName="partners" [items]="partnersList"
                  bindLabel="partner_name" bindValue="partner_id" placeholder="Select">
                </ng-select>
              </div>

              <div class="col-lg-3 col-md-3 col-sm-4 col-xs-4 col-4 partnerDrop"
                style="display: flex; align-content: center">
                <div class="tbl_in_icon" style="display: flex; margin-top: auto; margin-bottom: auto">
                  <a style="cursor: pointer; font-size: 10px;width:max-content;" (click)="newPartner()"
                    class="data_field_a"><i class="fa fa-plus fc-blue" id="partnerButton_1" aria-hidden="true"
                      style="font-size: 24px"></i>
                    <span (click)="newPartner()" id="partnerButton_2" style="
                        position: relative;
                        bottom: 24px;
                        right: 8px;
                        width: fit-content;
                      ">Add Partner</span>
                  </a>
                </div>
              </div>
            </div>
            <div class="row mrgbtmn">
              <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                <p class="head_type">Assign To<sub class="required">*</sub>:</p>
              </div>
              <div class="col-lg-5 col-md-5 col-sm-4 col-xs-4 col-4">
                <ng-select class="border-none owner" [multiple]="true" name="partners" [(ngModel)]="activity.assignee"
                  [items]="usersList" bindLabel="email" bindValue="email" formControlName="assignee"
                  placeholder="Select">
                </ng-select>
                <div class="mrgbtm" *ngIf="
                    (formControls['assignee'].dirty ||
                      formControls['assignee'].touched ||
                      formError) &&
                    formControls['assignee'].errors
                  ">
                  <div *ngIf="formControls['assignee'].errors.required" class="errorMessage">
                    Activity must be assigned to atleast one member.
                  </div>
                </div>
              </div>
              <div style="margin: 7px 0 0 0;display: flex;align-content: center;"
                class="col-lg-2 col-md-2 col-sm-4 col-xs-4 col-4  partnerDrop">
                <div class="tbl_in_icon" style="display: flex;margin-top: auto;margin-bottom:auto">
                  <a style="cursor: pointer;font-size: 10px;width:max-content;" class="data_field_a"><i
                      class="fa fa-plus fc-blue" (click)="openTeam()" aria-hidden="true" style="font-size: 24px;"></i>
                    <span (click)="openTeam()"
                      style="position: relative;
                  bottom: 24px;
                  right: 8px;width:fit-content">{{this.invitedTeam.length < 1 ? "Invite" : this.invitedTeam.length + " invited"}}</span>
                  </a>
                </div>
              </div>
            </div>
            <div class="row mrgbtmn">
              <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                <p class="head_type">
                  Target End Date<sub class="required">*</sub>:
                </p>
              </div>
              <div class="col-lg-5 col-md-5 col-sm-4 col-xs-4 col-4">
                <input style="border: none" name="activity-end-date" type="text" id="activity-end-date"
                  [(ngModel)]="activity.end_date" formControlName="end_date" placeholder="Target End Date"
                  autocomplete="off" />
                <div class="mrgbtm" *ngIf="
                    (formControls['end_date'].dirty ||
                      formControls['end_date'].touched ||
                      formError) &&
                    formControls['end_date'].errors
                  ">
                  <div *ngIf="formControls['end_date'].errors.required" class="errorMessage">
                    Target End Date is required.
                  </div>
                </div>
              </div>
            </div>
            <div class="row mrgbtmn">
              <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                <p class="head_type">Priority<sub class="required">*</sub>:</p>
              </div>
              <div class="col-lg-5 col-md-5 col-sm-4 col-xs-4 col-4">
                <ng-select class="border-none owner" name="priority" [(ngModel)]="activity.priority"
                  formControlName="priority" placeholder="Select">
                  <ng-option [value]="item.name" *ngFor="let item of priorityList">
                    <div [ngStyle]="{'color': item.color}">
                      {{ item.name | titlecase }}
                    </div>
                  </ng-option>
                </ng-select>
                <div class="mrgbtm" *ngIf="
                    (formControls['priority'].dirty ||
                      formControls['priority'].touched ||
                      formError) &&
                    formControls['priority'].errors
                  ">
                  <div *ngIf="formControls['priority'].errors.required" class="errorMessage">
                    Priority is required.
                  </div>
                </div>
              </div>
            </div>
            <div class="row mrgbtmn">
              <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                <p class="head_type">Approver(s):</p>
              </div>
              <div class="col-lg-5 col-md-5 col-sm-4 col-xs-4 col-4">
                <h4 class="fz-16 fc-black-1 fw-4">{{ activity.approvers }}</h4>
              </div>
            </div>
            <br>
            <div class="row mrgbtmn">
              <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                <p class="head_type">Activity Type:</p>
              </div>
              <div class="col-lg-5 col-md-5 col-sm-4 col-xs-4 col-4">
                <ng-select class="border-none owner" name="priority" [(ngModel)]="activity.type" formControlName="type"
                  placeholder="Select">
                  <ng-option [value]="item.name" *ngFor="let item of typeList">
                    {{ item.name}}
                  </ng-option>
                </ng-select>
              </div>
            </div>
            <div *ngIf="activity_id">
              <div class="row mrgbtmn">
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                  <p class="head_type">Completion Date:</p>
                </div>
                <div class="col-lg-5 col-md-5 col-sm-4 col-xs-4 col-4">
                  <input style="border: none" name="activity-complete-date" type="text" id="activity-completion-date"
                    [(ngModel)]="activity.completionDate" formControlName="completion_date"
                    placeholder="Completion Date" autocomplete="off" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" style="padding-top: 20px">
          <div class="form-section">
            <!-- documents section start -->
            <div>
              <div class="row">
                <div class="col-md-8 col-12">
                  <p class="head_type">Activity Documents:</p>
                </div>
                <div class="col-md-4 col-12">
                  <div class="tbl_in_icon">
                    <!--<a href="#" data-toggle="modal" data-target="#myModaladd">
                      <i
                        class="fa fa-download"
                        aria-hidden="true"
                        style="color: #44a2f8;"
                      ></i>
                    </a>-->
                    <a href="javascript:;" data-toggle="modal" data-target="#docModal">
                      <i class="fa fa-paperclip" aria-hidden="true" style="color: #44a2f8"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="row mrgbtm">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12"></div>
              </div>
              <div class="row mrgbtm">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 table-responsive">
                  <table class="table solntblnew border border-dark">
                    <thead class="thead-dark">
                      <tr>
                        <th style="background-color: black" scope="col">
                          File Name
                        </th>
                        <th scope="col">Description</th>
                        <th scope="col">Date Modified</th>
                      </tr>
                    </thead>
                    <tbody *ngIf="activity.documents.length > 0">
                      <tr style="display: none">
                        <td>
                          <!-- <input type="checkbox" name="" />
                          <span style="padding-left: 3px; font-weight: bold;">
                            Select All</span> -->
                        </td>

                        <td colspan="2" style="text-align: right; font-size: 15px">
                          <!-- <a href="javascript:;" data-toggle="modal" data-target="#docModal"
                            style="font-size: 20px; color: #44A2F8;"><i class="fa fa-paperclip"
                              aria-hidden="true"></i></a> -->
                        </td>
                      </tr>
                      <tr *ngFor="let f of activity.documents; let i = index">
                        <td>
                          <!-- <input type="checkbox" name="" /> -->
                          <span style="padding-left: 3px">
                            {{ f.fileName }}</span>
                        </td>
                        <td>{{ f.description }}</td>
                        <td>
                          {{ f.createdDate | date: 'MMM dd, yyyy' }}
                          <label class="float-right">
                            <a href="javascript:;" class="text-primary pr-3" (click)="downloadFile(f)"><i
                                class="fa fa-download ml-2"></i></a>
                            <a href="javascript:;" class="text-primary" (click)="removeDoc(i)"><i
                                class="fa fa-trash"></i></a>
                          </label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- documents section start -->
            <div class="row mrgbtmn">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                <input type="checkbox" name="activity-additionalInfo" formControlName="additional"
                  [(ngModel)]="activity.additional" style="top: 5px" />
                <label class="head_type fz-14 check-box" for="additionalInfo" for="additionalInfo">
                  Additional Activity Details (Optional)</label>
              </div>
            </div>
            <div *ngIf="activity.additional">
              <div class="row mrgbtmn">
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                  <p class="head_type">Tag to Plan:</p>
                </div>
                <div class="col-lg-5 col-md-5 col-sm-4 col-xs-4 col-4">
                  <ng-select class="border-none owner" [multiple]="true" name="plans" formControlName="plan_id"
                    [(ngModel)]="activity.plan_id" [items]="plansList" bindLabel="plan_name" bindValue="id"
                    placeholder="Select">
                  </ng-select>
                </div>
              </div>
              <div class="row mrgbtmn">
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                  <p class="head_type">Tag to Solution:</p>
                </div>
                <div class="col-lg-5 col-md-5 col-sm-4 col-xs-4 col-4">
                  <ng-select class="border-none owner" [multiple]="true" name="solutions"
                    [(ngModel)]="activity.solution_id" formControlName="solution_id" [items]="solutionList"
                    bindLabel="solution_name" bindValue="id" placeholder="Select">
                  </ng-select>
                </div>
              </div>
              <div class="row mrgbtmn">
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                  <p class="head_type">Tag to Opportunity:</p>
                </div>
                <div class="col-lg-5 col-md-5 col-sm-4 col-xs-4 col-4">
                  <ng-select class="border-none owner" [multiple]="true" name="opportunities"
                    [(ngModel)]="activity.opportunity_id" formControlName="opportunity_id" [items]="opportunitiesList"
                    bindLabel="name" bindValue="id" placeholder="Select">
                  </ng-select>
                </div>
              </div>
            </div>
            <!-- approvals section -->
            <div *ngIf="(activity.completion_date)&& approvalsAccess  ">
              <!-- <div *ngIf="activity.completion_date && approvalsAccess"> -->
              <div class="row mrgbtmn pl-3">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                  <p class="head_type" class="row">
                    Approvals:
                  </p>
                </div>
              </div>
              <div class="row mrgbtmn">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
                  <input type="checkbox" name="activity-approve" class="cbk" value="Approved" style="top: 5px" />
                  <label class="head_type fz-14 check-box" for="activity-approve">
                    Approved</label>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
                  <input type="checkbox" name="activity-reject" value="Rejected" class="cbk" style="top: 5px" />
                  <label class="head_type fz-14 check-box" for="activity-reject">
                    Rejected</label>
                </div>
              </div>
              <div *ngIf="activity.approval_status == 'Rejected'" class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                  <span class="head_type"> Comments for rejection:</span>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                  <textarea [(ngModel)]="activity.comments" style="height: 100px;" formControlName="comments"
                    name="approvalComment" placeholder="Enter comment for rejection"></textarea>
                </div>
              </div>
            </div>

            <!-- </div> -->
          </div>
        </div>
      </div>
      <div *ngIf="domainMatchError" class="row mrgbtm">
        <div class="col-md-12">
          <div align="center" class="errorMessage">
            Partner Owner(s) and Team members domains does not match.
          </div>
        </div>
      </div>
      <div class="row mrgbtm">
        <div class="col-md-12">
          <div align="center">
            <a type="button" (click)="goBack()" class="btn cnclbtn">CANCEL</a>
            <button *ngIf="!activity_id" type="button" (click)="openConfirmPopup()" [disabled]="isProgress"
              class="btn addbtn">
              CREATE ACTIVITY
            </button>
            <button type="button" *ngIf="activity_id" (click)="openConfirmPopup()" [disabled]="isProgress"
              class="btn addbtn">
              UPDATE ACTIVITY
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
<!-----------Document model popup-------->
<!-- The Modal -->
<div class="modal" id="docModal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal body -->
      <div class="modal-body">
        <div class="row">
          <div style="text-align: center" class="col">
            <h5 class="modal-title mrgbtmn">Choose file to attach:</h5>
          </div>
        </div>
        <div class="mrgbtm row">
          <label for="inputPassword" class="col-sm-4 col-form-label">
            <p class="head_type">Document</p>
          </label>
          <div class="col-sm-8">
            <input type="file" #file name="file_upload" (change)="handleFileSelect($event)" class=""
              placeholder="Notes" />
            <div class="mrgbtm" *ngIf="errors.document">
              <div class="errorMessage" *ngIf="errors.document">
                {{ errors.document }}
              </div>
            </div>
          </div>
        </div>
        <div class="mrgbtm row">
          <label for="inputPassword" class="col-sm-4 col-form-label">
            <p class="head_type">Description:</p>
          </label>
          <div class="col-sm-8">
            <input type="text" #description name="file_name" class="form-control" placeholder="Document Description" />
            <div class="mrgbtm" *ngIf="errors.description">
              <div class="errorMessage" *ngIf="errors.description">
                {{ errors.description }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal footer -->
      <div class="modal-footer" style="text-align: center; display: inline">
        <a type="button" class="btn cnclbtn" data-dismiss="modal">CANCEL</a>
        <button type="button" [disabled]="isProgress" class="btn addbtn" (click)="addDocument(description.value)">
          CONFIRM
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal" id="myModalcnfrmDelete">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal body -->
      <div class="modal-body" style="text-align: center; display: inline; color: red">
        <h5 class="modal-title mrgbtmn">Are You Sure?</h5>
        <p>Do you want to delete this document permanently?</p>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer" style="text-align: center; display: inline">
        <button type="button" class="btn cnclbtn" data-dismiss="modal">
          CANCEL
        </button>

        <button type="button" [disabled]="isProgress" (click)="removeDoc(selectedDocument, true)" class="btn addbtn">
          CONFIRM
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="confirmPopup">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal body -->
      <div class="modal-body" style="text-align: center; display: inline">
        <h5 class="modal-title mrgbtm">Are You Sure?</h5>
        <p *ngIf="confirmMessage == 1">
          All invitees can access Activity, on confirmation
        </p>
        <p *ngIf="confirmMessage == 2">
          All changes will be saved, on confirmation
        </p>
        <p *ngIf="confirmMessage == 3">
          Activity will be sent for approval.
        </p>
        <p *ngIf="confirmMessage == 4">
          Activity will be saved and marked as completed, on confirmation.
        </p>
      </div>
      <!-- Modal footer -->
      <div class="modal-footer" style="text-align: center; display: inline">
        <button type="button" (click)="closeModal()" class="btn cnclbtn">
          CANCEL
        </button>
        <button [disabled]="submitInProgress" type="button" class="btn addbtn" (click)="submit()">
          CONFIRM
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal" id="teamModal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal body -->
      <div class="modal-body" style="text-align: center; display: inline; padding: 0;">
        <app-add-team [onlySuperUsers]="false" [teamList]="usersList" [meData]="userDataPartners"
          [selfPartners]="selfPartners" [domainList]="this.domainList" [partner_owner_email]="this.userPartnerEmail"
          (submitForm)="saveTeam($event)" (closeModal)="closeTeam()" (domainMismatch)="domainChange($event)">
        </app-add-team>
      </div>
    </div>
  </div>
</div>
<div class="modal" id="successModal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content modal-custom">
      <!-- Modal body -->
      <div *ngIf="displayResult == 'success'" class="modal-body" style="text-align: center; display: inline;">
        <div class="row mrgbtmn">
          <div class="col-md-12">
            <h5 *ngIf="!activity_id" class="modal-title mrgbtm">Activity Created!</h5>
            <h5 *ngIf="activity_id" class="modal-title mrgbtm">Activity Updated!</h5>
            <p *ngIf="invitedTeam.length > 0">
              Invites will be sent to
              <span *ngFor="let member of invitedTeam; index as i">
                {{ member.email
            }}{{ i < invitedTeam.length - 1 ? "," : "." }}
              </span></p>
          </div>
        </div>
        <div class="row mrgbtm">
          <div class="col-md-12">
            <div align="center">
              <button (click)="this.goBack()" type="button" class="btn addbtn">
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="displayResult == 'failure'" class="modal-body" style="text-align: center; display: inline;">
        <div>
          <div class="row mrgbtm">
            <div class="col-md-12">
              <div align="center" class="errorMessage">
                {{ errorMessage }}
              </div>
            </div>
          </div>
          <div class="row mrgbtm">
            <div class="col-md-12">
              <div align="center">
                <button (click)="closeModal()" type="button" class="btn addbtn">
                  CLOSE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="displayResult == ''" class="modal-body" style="text-align: center; display: inline;">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <p>{{ activity_id ? "Updating the" : "Creating a" }} Activity...</p>
      </div>
    </div>
  </div>
</div>